import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { RouteComponentProps, useHistory } from 'react-router'

import { mdiPlus } from '@mdi/js'
import { nanoid } from 'nanoid'

import { SessionService, GroupService } from '../../services'
import { PeopleFlowCombinedReducer } from '../../store'
import { ColorPalette } from '../../config/colors'
import NavBar from '../../components/Navigation/NavBar'
import SectionHeaderPrimary from '../../components/Headings/SectionHeaderPrimary'
import { ActionButtonType, Toolbar } from '../../components/GeneralUI/Toolbar'
import AlertModalOneButton from '../../components/Modals/AlertModalOneButton'
import LoadingModal from '../../components/Modals/LoadingModal'
import { GroupAddModal } from '../../components/Groups/GroupAddModal'
import { DateRangeModal } from '../../components/Modals/DateRangeModal'
import { GroupStatusFilter } from '../../components/Groups/GroupStatusFilter'
import { GroupsList } from '../../components/Groups/GroupsList'
import { ActiveDateRange, Group, GroupStatus } from '../../types'
import { GroupCardContainer } from './GroupCardContainer'
import GroupCard from '../../components/Groups/GroupCard'
import { isSameDate } from '../../utils'
import { UserFeedback } from '../../components/GeneralUI/Feedback/UserFeedback'

type GroupsProps = RouteComponentProps

const GROUP_ID_LENGTH = 6
const initialDate = new Date()

const Groups = (props: GroupsProps) => {
  const sectionsRef = useRef(null)
  const history = useHistory()
  const [groupStatusFilters, setGroupStatusFilters] = useState<GroupStatus[]>(['Upcoming', 'Active'])
  const [addNewGroupModalOpen, setAddNewGroupModalOpen] = useState(false)
  const [dateRangeModalOpen, setDateRangeModalOpen] = useState('')
  const [existingGroupActiveDateRange, setExistingGroupActiveDateRange] = useState<ActiveDateRange>({
    startDate: initialDate,
    endDate: initialDate,
  })
  const [loadingModalOpen, setLoadingModalOpen] = useState(true)
  const [groups, setGroups] = useState<Group[]>([])
  const [refreshTimestamp, setRefreshTimestamp] = useState(0)

  const userIdPassport = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.idPassport)
  const password = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.password)
  const selectedAssociation = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation,
  )
  const selectedCohort = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.selectedCohort)
  const navMenuAccess = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.navMenuAccess)

  useEffect(() => {
    initGroupService()
  }, [])

  const initGroupService = async () => {
    const token = await SessionService.prepareAuthTokens(userIdPassport, password)
    const groups = await GroupService.fetchGroups(selectedAssociation, selectedCohort, token)
    setGroups(groups)
    toggleLoadingModal()
  }

  const toggleLoadingModal = () => setLoadingModalOpen((loadingModalOpen) => !loadingModalOpen)

  const saveNewGroup = async (name: string, startDate: Date, endDate: Date) => {
    if (GroupService) {
      /**
       * Note we're erring on the side of the frontend being a fat client
       * so we assign the ID here (even though this will likely never be
       * used on the app)
       */
      const group: Group = {
        id: nanoid(GROUP_ID_LENGTH),
        division: selectedCohort, // <---- a critical assumption at this point
        name,
        startDate,
        endDate,
        members: {},
      }
      const token = await SessionService.prepareAuthTokens(userIdPassport, password)
      await GroupService.postGroup(selectedAssociation, group, token)
      setGroups((groups) => [...groups, group])
    }
  }

  const saveExistingGroup = async (dateRange: ActiveDateRange) => {
    if (GroupService) {
      const groupId = dateRangeModalOpen
      const token = await SessionService.prepareAuthTokens(userIdPassport, password)
      const group = await GroupService.fetchGroup(selectedAssociation, selectedCohort, groupId, token)
      group.startDate = dateRange.startDate
      group.endDate = dateRange.endDate
      await GroupService.postGroup(selectedAssociation, group, token)

      // Logic to maintain order of groups
      const groupIndex = groups.findIndex((group) => group.id === groupId)
      const otherGroups = groups.filter((group) => group.id !== groupId)
      const newGroups = [...otherGroups]
      newGroups.splice(groupIndex, 0, group)
      setGroups(newGroups)
    }
  }

  const toggleDateRangeModal = (groupId?: string) => {
    if (groupId) {
      const group = groups.find((group) => group.id === groupId)
      if (group) {
        setExistingGroupActiveDateRange({
          startDate: group.startDate,
          endDate: group.endDate,
        })
      }
      setDateRangeModalOpen(groupId)
      return
    }
    setDateRangeModalOpen('')
  }
  const toggleAddNewGroupModal = () => {
    setAddNewGroupModalOpen((setAddNewGroupModalOpen) => !addNewGroupModalOpen)
  }

  const toggleStatusFilter = (e: React.ChangeEvent) => {
    const id: GroupStatus = e.target.id as GroupStatus
    if (groupStatusFilters.includes(id)) {
      setGroupStatusFilters(groupStatusFilters.filter((filter) => filter !== id))
    } else {
      setGroupStatusFilters([...groupStatusFilters, id])
    }
  }

  const handleDateRangeSave = async (startDate: Date, endDate: Date) => {
    const dateRange = {
      startDate,
      endDate,
    }
    setExistingGroupActiveDateRange(dateRange)
    toggleDateRangeModal()
    await saveExistingGroup(dateRange)
    setRefreshTimestamp(Date.now())
  }

  const allGroupStatuses: GroupStatus[] = ['Upcoming', 'Active', 'Closed']
  const toolbarActionButtons: ActionButtonType[] = [
    {
      iconPath: mdiPlus,
      onClick: toggleAddNewGroupModal,
      label: 'ADD GROUP',
    },
  ]

  const generateGroupContent = (groupData: Group[]) => {
    let groupContent = <div style={styles.userFeedbackMessage}>{<UserFeedback message="No groups to view" />}</div>
    let list = groupData.map((group: Group, index: number) => {
      let status: GroupStatus = 'Active'
      let today = initialDate
      if (!isSameDate(group.startDate, today) && group.startDate > today) {
        status = 'Upcoming'
      } else if (!isSameDate(group.endDate, today) && group.endDate < today) {
        status = 'Closed'
      }
      if (!groupStatusFilters.includes(status)) {
        return null
      }
      return (
        <div style={styles.groupItemContainer} key={`group-${group.division}_${group.name}_${index}`}>
          <GroupCard
            status={status}
            groupId={group.id}
            groupName={group.name}
            activeDatePeriod={{
              startDate: group.startDate,
              endDate: group.endDate,
            }}
            editableDateRange={status !== 'Closed'}
            groupMembers={group.members || {}}
            toggleEditDateRange={toggleDateRangeModal}
            onViewGroupClick={() => history.push(`/people/groups/${group.id}`)}
          />
        </div>
      )
    })
    list = list.filter((item) => item !== null)
    if (list.length) {
      groupContent = <div style={styles.groupList}>{list}</div>
    }
    return groupContent
  }

  const groupContent = generateGroupContent(groups)

  return (
    <div style={styles.container}>
      <NavBar match={props.match} location={props.location} history={props.history} />
      <SectionHeaderPrimary style={styles.sectionHeader} disabled={true} searchString={''} onClick={() => ({})}>
        Groups
      </SectionHeaderPrimary>

      <div style={styles.contentContainer}>
        <div style={styles.rightSide}>
          <Toolbar actionButtons={toolbarActionButtons} />
          <div style={styles.rightSideContent} ref={sectionsRef}>
            <GroupStatusFilter
              groupStatusOptions={allGroupStatuses}
              selectedFilterOptions={groupStatusFilters}
              onFilterOptionClick={toggleStatusFilter}
            />
            {groupContent}
          </div>
        </div>
      </div>
      {addNewGroupModalOpen && <GroupAddModal toggle={toggleAddNewGroupModal} onSave={saveNewGroup} />}
      <DateRangeModal
        open={!!dateRangeModalOpen}
        title="Edit Group Date Range"
        initialStartDate={existingGroupActiveDateRange.startDate}
        initialExpiryDate={existingGroupActiveDateRange.endDate}
        onCancelClick={toggleDateRangeModal}
        onSaveClick={handleDateRangeSave}
      />
      <AlertModalOneButton
        // TODO: There should only be one AlertModalOneButton component per screen with the required headers, body, and functions being set accordingly by a handler.
        open={!navMenuAccess.hasGroupAccess}
        header={'Not Authorised'}
        body={"You don't have permission to view/edit groups."}
        buttonLabel={'Ok'}
        opaqueBackground={true}
        onClick={() => props.history.goBack()}
      />
      <LoadingModal open={loadingModalOpen}>Fetching group data...</LoadingModal>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundImage: `linear-gradient(to bottom, ${ColorPalette.SCREEN_TOP_GRADIENT}, ${ColorPalette.SCREEN_BOTTOM_GRADIENT})`,
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 1%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    paddingInline: 'min(3em, 3%)',
    width: '100%',
    overflow: 'hidden',
  },
  rightSideContent: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    display: 'flex',
    flexDirection: 'column' as 'column',
    width: '100%',
    height: '100%',
    backgroundColor: ColorPalette.CARD_WHITE,
    overflow: 'auto',
  },
  groupList: {
    display: 'flex',
    flexWrap: 'wrap' as 'wrap',
    justifyContent: 'space-between',
    padding: '3em 3.5em',
    gap: '4em',
    width: '100%',
    height: '100%',
  },
  groupItemContainer: {
    flexDirection: 'column' as 'column',
    flex: 0.5,
    maxWidth: 'calc(50% - 2em)',
  },
  userFeedbackMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '2em',
    width: '100%',
    height: '100%',
  },
}

export default Groups
