import _ from 'lodash'
import {
  getAnalyticsConfigs,
  updateAnalyticsConfig,
  createAnalyticsConfig,
  getAnalyticsResults,
  triggerAnalyticsResultsGeneration,
} from '../providers'
import { ensureArrayFormat } from '../utils'
import {
  DateRangeKey,
  DocFilterParams,
  DocFilterTimeRange,
  FieldConfigItemSchema,
  FieldFilterParams,
  FieldOperation,
  Filter,
  Query,
  QueryFilter,
  QueryResults,
  ResultExportTargetEmails,
} from '../types'
import { FilterTypeEnum } from '../enums'
import { QUERY_OPERATIONS_TO_KEY } from '../constants'

export class QueryService {
  static async getQueries(association: string, username: string, password: string): Promise<Query[]> {
    const { data } = await getAnalyticsConfigs(association, username, password)
    const queries = data.allConfigs as Query[]
    return queries
  }

  static async updateQuery(association: string, username: string, password: string, query: Query): Promise<Query> {
    const data = await updateAnalyticsConfig(association, username, password, query)
    return data.config
  }

  static async createQuery(association: string, queryName: string, username: string, password: string): Promise<Query> {
    const { data } = await createAnalyticsConfig(association, queryName, username, password)
    const { blobStoreDir, pk, sk, ...query } = data.config
    return query
  }

  static async getQueryResults(
    fieldConfig: Record<string, FieldConfigItemSchema>,
    association: string,
    queryId: string,
    unixMs: number = 0,
    username: string,
    password: string,
  ): Promise<QueryResults> {
    const insertTrendWarningLabels = (queryResults: QueryResults) => {
      queryResults.trends = queryResults.trends.map((trend) => {
        let warningFieldLabels = [] as string[]
        trend.trendData.forEach((item) => {
          item.warnings.forEach(({ key }) => {
            if (fieldConfig[key] && !warningFieldLabels.includes(fieldConfig[key].label)) {
              warningFieldLabels.push(fieldConfig[key].label)
            }
          })
        })
        trend.warningFieldLabels = warningFieldLabels
        return trend
      })
      return queryResults
    }

    const data = await getAnalyticsResults(association, queryId, unixMs, username, password)
    return insertTrendWarningLabels(data)
  }

  static async triggerResults(association: string, queryId: string, username: string, password: string) {
    await triggerAnalyticsResultsGeneration(association, queryId, username, password)
  }

  static async exportResults(
    association: string,
    queryId: string,
    username: string,
    password: string,
    emailAddresses: ResultExportTargetEmails,
  ) {
    await triggerAnalyticsResultsGeneration(association, queryId, username, password, emailAddresses)
  }

  static getTranslatedFilters = (filters: QueryFilter[][]): Filter[][] => {
    return filters.map((orBlock: QueryFilter[]) => {
      return orBlock.map((filter: QueryFilter) => {
        const { filterType, filterParams } = filter
        let translatedFilter: Partial<Filter> = {
          filterType,
        }
        if (filterType === FilterTypeEnum.FIELD) {
          const fieldFilterParams = filterParams as FieldFilterParams
          translatedFilter.comparatorType = fieldFilterParams.operation.comparatorType
          translatedFilter.filterValue = fieldFilterParams.operation.comparatorValue
          // @ts-ignore
          translatedFilter.selectedTarget = fieldFilterParams.targetPath
        } else {
          // type "DOCUMENT"
          const docFilterParams = filterParams as DocFilterParams
          const timeRange = docFilterParams.timeRange as DocFilterTimeRange
          if (timeRange === 'NONE') {
            translatedFilter.filterValue = undefined
          } else {
            const startDate = new Date(timeRange.startUnixMs)
            const endDate = new Date(timeRange.endUnixMs)
            translatedFilter.filterValue = {
              startDate,
              endDate,
            }
          }
          translatedFilter.selectedTarget = docFilterParams.targetDoc
        }

        return translatedFilter as Filter
      })
    })
  }

  static translateFilters = (filters: Filter[][]): QueryFilter[][] => {
    return filters.map((orBlock: Filter[]) => {
      return orBlock.map((filter: Filter) => {
        const { comparatorType, filterType, filterValue, selectedTarget } = filter
        let translatedFilter: Partial<QueryFilter> = {
          filterType,
          filterParams: undefined,
        }
        if (filterType === 'field') {
          const filterParams = {
            targetPath: [''],
            operation: { comparatorType: '', comparatorValue: filterValue },
          }

          filterParams.targetPath = ensureArrayFormat(selectedTarget)
          filterParams.operation = {
            comparatorType: QUERY_OPERATIONS_TO_KEY[comparatorType] || comparatorType,
            comparatorValue: filterValue,
          } as FieldOperation

          // @ts-ignore
          translatedFilter.filterParams = filterParams
        }

        if (filterType === 'document') {
          const filterParams = {
            targetDoc: '',
            timeRange: 'NONE' as DocFilterTimeRange,
          }

          filterParams.targetDoc = selectedTarget
          if (filterValue) {
            const startDate = (filterValue as Record<DateRangeKey, Date>).startDate
            const endDate = (filterValue as Record<DateRangeKey, Date>).endDate
            filterParams.timeRange = {
              startUnixMs: new Date(startDate).getTime(),
              endUnixMs: new Date(endDate).getTime(),
            }
          }
          translatedFilter.filterParams = filterParams
        }

        return translatedFilter as QueryFilter
      })
    })
  }
}
