import React, { useState } from 'react'
import Icon from '@mdi/react'
import { mdiDownload } from '@mdi/js'
import { mdiFileAlertOutline, mdiFileCheckOutline } from '@mdi/js'
import _ from 'lodash'
import Radium from 'radium'

import { ColorPalette } from '../../config'
import ButtonGrey from '../BaseComponents/Buttons/ButtonGrey'
import ButtonBlue from '../BaseComponents/Buttons/ButtonBlue'
import LoadingModal from './LoadingModal'
import AlertModalOneButton from './AlertModalOneButton'
import FileSelectorButton from '../BaseComponents/Buttons/FileSelectorButton'
import { packageDataAndDownloadFile, filePicker, csvToJson } from '../../utils'
import { FieldConfigKey } from '../../types'

// export type CsvFileColumnValuePairs = Record<string, string>

type CsvFileProcessingModalProps = {
  open: boolean
  title: string
  csvColumnConfig: Record<FieldConfigKey, string>
  instructions?: string
  customUi?: React.ReactNode
  onCancel: () => void
  onSubmit: (jsonFileContent: Record<string, string>[]) => void
}

const CsvFileProcessingModal = (props: CsvFileProcessingModalProps) => {
  const [selectedFile, setSelectedFile] = useState<File>()
  const [loadingModalOpen, setLoadingModalOpen] = useState(false)
  const [loadingModalMessage, setLoadingModalMessage] = useState('')
  const [warningModalState, setWarningModalState] = useState({
    warningModalOpen: false,
    warningModalHeader: '',
    warningModalMessage: '',
  })
  const { open, title, csvColumnConfig, onCancel, onSubmit } = props

  const validate = (jsonFileContent: Record<string, string>[]) => {
    if (!jsonFileContent.length) {
      throw { code: 'MissingData' }
    }
    const firstItem = jsonFileContent[0]
    const csvLabels = Object.keys(firstItem)
    const configuredCsvColumnLabels = Object.values(csvColumnConfig)
    const missingLabels = _.difference(configuredCsvColumnLabels, csvLabels)
    if (missingLabels.length) {
      throw { code: 'MissingLabels', missingLabels }
    }
  }

  const labelsToPfKeys = (jsonFileContent: Record<string, string>) => {
    Object.keys(jsonFileContent).forEach((csvLabel) => {
      const fieldConfigKeys = Object.keys(csvColumnConfig)
      for (const fieldConfigKey of fieldConfigKeys) {
        if (csvColumnConfig[fieldConfigKey] === csvLabel) {
          jsonFileContent[fieldConfigKey] = jsonFileContent[csvLabel]
          delete jsonFileContent[csvLabel]
        }
      }
    })
    return jsonFileContent
  }

  const onActionButtonClick = async () => {
    try {
      if (!selectedFile) {
        throw { code: 'NoSelectedFile' }
      }
      setLoadingModalMessage('Processing CSV data...')
      setLoadingModalOpen(true)
      const jsonFileData = (await csvToJson(selectedFile)) as Record<string, string>[]
      console.log('jsonFileData: ', jsonFileData)
      //@ts-ignore
      validate(jsonFileData)
      const trasnformedData = jsonFileData.map(labelsToPfKeys)
      onSubmit(trasnformedData)
    } catch (error) {
      errorHandler(error)
    }
  }

  const getFileSelectorLabel = () => {
    let fileSelectorButtonLabel = 'BROWSE AND SELECT FILE (CSV format only)'
    if (selectedFile) {
      fileSelectorButtonLabel = `CLICK TO CHOOSE A DIFFERENT FILE.`
    }
    return fileSelectorButtonLabel
  }

  const onFileSelect = async (event: React.ChangeEvent<{ value: string }>) => {
    const { file, type } = filePicker(event)[0]
    if (type !== 'text/csv') {
      setWarningModalState({
        warningModalOpen: true,
        warningModalHeader: 'Invalid format',
        warningModalMessage: 'Your file must be in .csv format',
      })
      return
    }
    setSelectedFile(file)
  }

  const errorHandler = (error: any) => {
    let warningModalHeader = ''
    let warningModalMessage = ''
    if (error.code === 'MissingLabels') {
      warningModalHeader = 'Invalid Template'
      warningModalMessage = `Your file is missing the following columns: ${error.missingLabels.join(', ')}`
    } else if (error.code === 'MissingData') {
      warningModalHeader = 'No Data'
      warningModalMessage = "Your file doesn't contain any data"
    } else if (error.code === 'NoSelectedFile') {
      warningModalHeader = 'No file selected'
      warningModalMessage = 'Select a file to proceed.'
    }
    setLoadingModalOpen(false)
    setWarningModalState({
      warningModalOpen: true,
      warningModalHeader,
      warningModalMessage,
    })
  }

  let modal = null
  if (open) {
    if (warningModalState.warningModalOpen) {
      modal = (
        <AlertModalOneButton
          open={true}
          dismiss={() => setWarningModalState((prevState) => ({ ...prevState, warningModalOpen: false }))}
          onClick={() => setWarningModalState((prevState) => ({ ...prevState, warningModalOpen: false }))}
          buttonLabel={'Ok'}
          header={warningModalState.warningModalHeader}
          body={warningModalState.warningModalMessage}
        />
      )
    } else {
      modal = (
        <div style={styles.screenContainer}>
          <div style={styles.cardContainer}>
            <h1 style={{ ...styles.textStyle }}>{title}</h1>
            <div
              onClick={() =>
                packageDataAndDownloadFile([...Object.values(csvColumnConfig)].join(','), 'template.csv', 'text/csv')
              }
              style={styles.downloadContainer}>
              <Icon path={mdiDownload} color={ColorPalette.PRIMARY_TEXT} style={styles.buttonIconStyle} />
              <p>Download CSV template</p>
            </div>
            <p style={styles.instructionMessage}>{props.instructions}</p>
            <FileSelectorButton
              style={styles.selector}
              id="addFile"
              buttonLabel={getFileSelectorLabel()}
              fileName={selectedFile ? selectedFile.name : ''}
              fileHandler={onFileSelect}
              onCancelSelection={() => setSelectedFile(undefined)}
              iconPath={selectedFile ? mdiFileCheckOutline : mdiFileAlertOutline}
            />
            {props.customUi}
            <div style={styles.actionButtons}>
              <ButtonGrey style={{ margin: 0 }} onClick={onCancel} disabled={false}>
                Cancel
              </ButtonGrey>
              <ButtonBlue style={{ margin: 0 }} onClick={onActionButtonClick}>
                Next
              </ButtonBlue>
            </div>
          </div>
          <LoadingModal open={loadingModalOpen}>{loadingModalMessage}</LoadingModal>
        </div>
      )
    }
  }
  return modal
}

const styles = {
  screenContainer: {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    backgroundColor: ColorPalette.MODAL_BACKGROUND_OVERLAY,
    width: window.innerWidth,
    height: window.innerHeight,
    zIndex: 1000,
    overflow: 'hidden',
  },
  cardContainer: {
    position: 'absolute' as 'absolute',
    top: window.innerHeight * 0.05,
    right: window.innerWidth * 0.34,
    zIndex: 100000,
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'flex-start',
    alignItems: 'center' as 'center',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.2)',
    backgroundColor: ColorPalette.CARD_WHITE,
    marginTop: window.innerHeight * 0.03,
    width: window.innerWidth * 0.32,
    padding: '30px 35px 30px 35px',
    borderRadius: 8,
  },
  textStyle: {
    textAlign: 'center' as 'center',
    alignSelf: 'center',
    fontFamily: 'roboto',
    fontWeight: 'bold',
    color: ColorPalette.PRIMARY_TEXT,
    fontSize: '1rem',
  },
  buttonIconStyle: {
    marginRight: 5,
    width: 15,
    height: 'auto',
  },
  actionButtons: {
    display: 'flex',
    alignContent: 'center',
    marginTop: 40,
    gap: 20,
    width: '100%',
  },
  downloadContainer: {
    color: ColorPalette.PRIMARY_TEXT,
    margin: '10px 10px 0px 10px',
    fontWeight: 'bolder',
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'center',
    cursor: 'pointer',
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
    },
    ':active': {
      filter: 'brightness(75%)',
    },
  },
  instructionMessage: { textAlign: 'center' as 'center', color: ColorPalette.PRIMARY_TEXT, marginTop: '0px' },
  selector: {
    height: '20vh',
    width: '92%',
    marginTop: '2em',
  },
}

export default Radium(CsvFileProcessingModal)
