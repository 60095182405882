import { MasterSettings } from '../models'
import { masterSettingsIdentifier } from '../config/localStorage'
import { LocalStorageProvider } from '../providers'

export class MasterSettingsRepository {
  private masterSettings: MasterSettings | undefined

  async initialise(jsonData: Record<string, any> | undefined) {
    const storageId = this.getStorageId()
    if (jsonData !== undefined) {
      await LocalStorageProvider.setData(storageId, jsonData)
    }
    const masterSettingsJson = await LocalStorageProvider.getData(storageId)
    this.masterSettings = new MasterSettings(masterSettingsJson)
  }

  getMailConfig() {
    if (!this.masterSettings) {
      throw { code: 'MasterSettingsNotInitialised' }
    }
    return this.masterSettings?.credentials?.mailConfig
  }

  getStorageId() {
    return `${masterSettingsIdentifier}`
  }
}
