import React, { useEffect, useRef } from 'react'
import Radium from 'radium'
import Lottie from 'react-lottie'
import Icon from '@mdi/react'
import Spinner from 'react-spinkit'
import { Property } from 'csstype'
import { mdiDownload } from '@mdi/js'

import { ColorPalette } from '../../config/colors'
import ButtonGeneric from '../../components/BaseComponents/Buttons/ButtonGeneric'
import syncArrows from '../../assets/syncArrows.json'
import { DocDownloadQueueItem } from '../../screens/Exports/DocExport'
import { DocsService } from '../../services'
import { AsyncTaskDocExport } from '../../types'

interface DownloadQueueModalProps {
  open: boolean
  header: string
  body?: string
  queuedTasks: AsyncTaskDocExport[]
  itemsDownloading: string[]
  itemsBeingGenerated: DocDownloadQueueItem[]
  isUpdatingDocExportQueue: boolean
  updateQueueData: () => void
  download: (filePath: string, queueId: string) => void
  dismiss: () => void
}

const DownloadQueueModal = (props: DownloadQueueModalProps) => {
  const intervalManager = useRef<number>()

  useEffect(() => {
    intervalManager.current = updateIntervalManager()
    return () => clearInterval(intervalManager.current)
  }, [])

  const updateIntervalManager = () => {
    return window.setInterval(() => {
      try {
        props.updateQueueData()
      } catch (error) {
        console.error('error: ', error)
      }
    }, 5000)
  }

  const getButtonColor = (queueItem: DocDownloadQueueItem, busyDownloading: boolean) => {
    if (queueItem.status === 'PENDING') {
      return ColorPalette.LIGHT_GREY
    }
    if (busyDownloading) {
      return ColorPalette.DARK_GREY
    }
    return ColorPalette.PRIMARY_BLUE
  }

  const getButtonLabel = (queueItem: DocDownloadQueueItem, busyDownloading: boolean) => {
    let prefix = ''
    if (queueItem.status === 'PENDING') {
      prefix = 'Generating \xa0 | \xa0'
    }
    if (busyDownloading) {
      prefix = 'Downloading \xa0 | \xa0'
    }
    return `${prefix} ${queueItem.label}`
  }

  const generateDownloadQueue = (
    queuedTasks: AsyncTaskDocExport[],
    itemsBeingGenerated: DocDownloadQueueItem[],
    isUpdatingQueue: boolean,
  ) => {
    if (isUpdatingQueue) {
      return (
        <Spinner
          style={styles.downloadQueueUpdating}
          fadeIn="quarter"
          name="three-bounce"
          color={ColorPalette.PRIMARY_TEXT}
        />
      )
    }
    const downloadQueue = DocsService.asyncTasksToDownloadQueueItems(queuedTasks)
    const uiList = [...itemsBeingGenerated, ...downloadQueue]
    if (!uiList || !uiList.length) {
      return <h1 style={styles.downloadQueueItem}>{'No files in the queue'}</h1>
    }
    return uiList.map((queueItem) => {
      const { queueId, status, fileLocation } = queueItem
      const busyDownloading = props.itemsDownloading.includes(queueId)
      let icon = (
        <div key="viewQueueButton" style={{ marginRight: 8, marginTop: 5 }}>
          <Icon size={0.8} path={mdiDownload} color={ColorPalette.CARD_WHITE} />
        </div>
      )
      if (busyDownloading || status === 'PENDING') {
        icon = (
          <div
            key={busyDownloading ? 'viewQueueButton_downloading' : 'viewQueueButton_generating'}
            style={{ width: 18, height: 18, marginRight: 8 }}>
            <Lottie speed={1.6} options={{ animationData: syncArrows, loop: true }} />
          </div>
        )
      }

      const label = getButtonLabel(queueItem, busyDownloading)
      return (
        <ButtonGeneric
          key={queueId}
          style={{
            ...styles.downloadQueueButton,
            backgroundColor: getButtonColor(queueItem, busyDownloading),
          }}
          labelStyle={{
            ...styles.downloadQueueButtonlLabel,
            maxWidth: window.innerWidth * 0.26,
          }}
          iconBefore={icon}
          label={`${label}`.padEnd(35)}
          onClick={() => props.download(fileLocation.key, queueId)}
          disabled={busyDownloading || status === 'PENDING'}
        />
      )
    })
  }

  const { open, header, body = '', dismiss, queuedTasks, itemsBeingGenerated, isUpdatingDocExportQueue } = props
  const screenContainer = {
    ...styles.screenContainer,
    animation: open ? 'x 0.05s ease-in' : 'x 0.1s ease-out',
    animationName: open ? fadeInBackground : fadeOutBackground,
  }

  const cardContainer = {
    ...styles.cardContainer,
    animation: open ? 'x 0.05s ease-in' : 'x 0.1s ease-out',
    animationName: open ? fadeInCard : fadeOutCard,
  }
  const queueUiList = generateDownloadQueue(queuedTasks, itemsBeingGenerated, isUpdatingDocExportQueue)

  let modalContents = null
  if (open) {
    modalContents = (
      <>
        <button style={screenContainer} onClick={dismiss} />
        <div style={cardContainer}>
          <h1 style={styles.title}>{header}</h1>
          <h1 style={styles.textStyle}>{body}</h1>
          <div style={styles.sectionDivider} />
          <div style={styles.downloadQueueListContainer}>{queueUiList}</div>
        </div>
      </>
    )
  }
  return modalContents
}

const fadeInBackground = Radium.keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
}) as Property.AnimationName

const fadeOutBackground = Radium.keyframes({
  '0%': { opacity: 1 },
  '100%': { opacity: 0 },
}) as Property.AnimationName

const fadeInCard = Radium.keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1, marginRight: window.innerHeight * 0.05 },
}) as Property.AnimationName

const fadeOutCard = Radium.keyframes({
  '0%': { marginRight: window.innerHeight * 0.05 },
  '50%': { opacity: 0 },
  '100%': { marginRight: -window.innerHeight * 0.13, opacity: 0 },
}) as Property.AnimationName

const styles: Record<string, React.CSSProperties> = {
  screenContainer: {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    width: window.innerWidth,
    height: window.innerHeight,
    backgroundColor: ColorPalette.MODAL_BACKGROUND_OVERLAY,
    zIndex: 10000,
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 0,
    border: 'none',
    overflow: 'hidden',
  },
  cardContainer: {
    zIndex: 10000,
    position: 'absolute',
    left: window.innerWidth * 0.29,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
    backgroundColor: ColorPalette.CARD_WHITE,
    marginTop: window.innerHeight * 0.14,
    width: window.innerWidth * 0.42,
    maxHeight: '65%',
    padding: '25px 35px 65px 35px',
    borderRadius: 8,
  },
  sectionDivider: {
    borderBottom: `1px solid ${ColorPalette.PRIMARY_BLUE}`,
    width: '90%',
    marginBottom: 15,
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 15,
  },
  textStyle: {
    fontSize: '0.9rem',
    fontWeight: '300',
    textAlign: 'center',
    color: ColorPalette.PRIMARY_TEXT,
    padding: '0px 10px',
    marginTop: 0,
  },
  title: {
    textAlign: 'center',
    color: ColorPalette.PRIMARY_TEXT,
    padding: '0px 10px',
    marginTop: 15,
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
  downloadQueueListContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    width: '100%',
    padding: '0 4%',
  },
  downloadQueueItem: {
    textAlign: 'center',
    color: ColorPalette.PRIMARY_TEXT,
    padding: '0px 10px',
    marginTop: 15,
    marginBottom: 20,
    fontWeight: 'normal',
    fontSize: '1rem',
  },
  downloadQueueUpdating: {
    transform: 'scale(0.8)',
    alignSelf: 'center',
    marginBottom: '18%',
    marginTop: '10%',
  },
  downloadQueueButton: {
    height: 35,
    justifyContent: 'center',
    borderRadius: 20,
    marginTop: 15,
  },
  downloadQueueButtonlLabel: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}

export default Radium(DownloadQueueModal)
