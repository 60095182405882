import { PpeActionRecord, PpeInstanceRecordSchema } from '../../models/ppe'
import { AssociationId } from '../../types'
import { httpGet, httpPost } from '../remoteDataProvider'

export async function getPpeInstances(association: string, token: string) {
  try {
    const { data } = await httpGet('people_flow_core', `/inventory/ppe/instances/association/${association}`, token)
    return data
  } catch (error) {
    console.log('getPpeInstances error: ', error)
    throw error
  }
}

export async function getPpeRequests(association: string, token: string) {
  const { data } = await httpGet('people_flow_core', `/inventory/ppe/requests/association/${association}`, token)
  return data
}

export async function uploadPpeActionRecords(
  association: string,
  ppeActionRecords: Partial<PpeActionRecord>[],
  token: string,
) {
  console.log('association ', association)
  try {
    const { data } = await httpPost(
      'people_flow_core',
      `/inventory/ppe/actionrecords/association/${association}`,
      ppeActionRecords,
      token,
    )
    console.log('data: ', data)
    return data
  } catch (error) {
    console.log('error: ', error)
  }
}

export async function exportPpeInventory(association: AssociationId, targetEmails: string[], token: string) {
  if (!targetEmails.length) {
    throw { code: 'MissingEmailAddresses' }
  }
  const response = await httpPost(
    'people_flow_core',
    `/inventory/export/ppe/association/${association}/stock`,
    { targetEmails },
    token,
  )
  if (response.data.result !== 'success') {
    throw { code: 'UnknownError' }
  }
}
