import { createNewBatch, deleteBatch, getBatches } from '../providers/endpointDataProvider/asyncTasks'
import { AsyncTaskDocExport, AsyncTaskTypesEnum, AuthCredentials } from '../types'
import { sortArrayOfObjects } from '../utils'
import { SessionService } from './sessionService'

type UserInfo = {
  name: string
  surname: string
}

export class AsyncTaskService {
  async createEmployeeCardExport(
    selectedAssociation: string,
    authCredentials: AuthCredentials,
    selectedProfilePks: string[],
    userName: string,
    userSurname: string,
  ) {
    const { username, password } = authCredentials
    const token = await SessionService.prepareAuthTokens(username, password)
    const userInfo: UserInfo = {
      name: userName,
      surname: userSurname,
    }

    const payload = {
      asyncTaskType: AsyncTaskTypesEnum.EMPLOYEE_CARDS,
      selectedProfilePks,
      userInfo,
    }
    const batch = await createNewBatch(selectedAssociation, payload, token)
    return batch
  }

  async getEmployeeCardExportBatchItems(selectedAssociation: string, authCredentials: AuthCredentials) {
    function sortTasks(tasks: AsyncTaskDocExport[]) {
      function sortTasksByDate(tasks: AsyncTaskDocExport[]) {
        return sortArrayOfObjects('createdAtUnixMs', 'descending', tasks)
      }

      function sortTasksByBatchNumber(tasks: AsyncTaskDocExport[]) {
        return tasks.sort((a, b) => {
          if (a.createdAtUnixMs === b.createdAtUnixMs) {
            const aBatchNumber = a.payload.batchNumber as number
            const bBatchNumber = b.payload.batchNumber as number
            if (aBatchNumber < bBatchNumber) {
              return -1
            }
            if (aBatchNumber > bBatchNumber) {
              return 1
            }
          }
          return 0
        })
      }

      const sortedByDate = sortTasksByDate(tasks)
      const sortedAlsoByBatchNumber = sortTasksByBatchNumber(sortedByDate)
      return sortedAlsoByBatchNumber
    }

    const { username, password } = authCredentials
    const token = await SessionService.prepareAuthTokens(username, password)

    const data = await getBatches(selectedAssociation, AsyncTaskTypesEnum.EMPLOYEE_CARDS, token)
    const asyncTasks = data.asyncTasks
    const sortedTasks: AsyncTaskDocExport[] = sortTasks(asyncTasks)
    return sortedTasks
  }

  async deleteBatchItem(selectedAssociation: string, taskId: string, authCredentials: AuthCredentials) {
    const { username, password } = authCredentials
    const token = await SessionService.prepareAuthTokens(username, password)
    await deleteBatch(selectedAssociation, taskId, token)
  }
}
