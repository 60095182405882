import React, { Component } from 'react'
import Radium from 'radium'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import Lottie from 'react-lottie'

import Checkbox from '@material-ui/core/Checkbox'
import Grid, { GridSize } from '@material-ui/core/Grid'
import Icon from '@mdi/react'
import { mdiTextBoxPlus, mdiUpload, mdiMinus, mdiClipboardArrowRight } from '@mdi/js'

import { ColorPalette, importsSectionConfig } from '../../config'
import { ActionType } from '../../store/actions/actions'
import {
  formatDateAndTime,
  toUpperCaseCustom,
  toLowerCaseCustom,
  titleCase,
  addUnderScores,
  removeUnderScores,
  filePicker,
  writeLocalFileToRemoteStorage,
  csvToJson,
  jsonToCsv,
} from '../../utils'
import { SessionService, ProfileService } from '../../services'
import NavBar from '../../components/Navigation/NavBar'
import SectionHeader from '../../components/Headings/SectionHeaderPrimary'
import LoadingModal from '../../components/Modals/LoadingModal'
import AlertModalOneButton from '../../components/Modals/AlertModalOneButton'
import DataFlowNavigator from '../../components/Navigation/DataFlowNavigator'
import DocumentTable from '../../components/Tables/DocumentTable/DocumentTable'
import OptionsSelectorModal from '../../components/Modals/OptionsSelectorModal'
import ButtonRound from '../../components/BaseComponents/Buttons/ButtonRound'
import syncArrows from '../../assets/syncArrowsBlue.json'
import { PickerHandlerEvent } from '../../components/BaseComponents/Pickers/Picker'
import { PeopleFlowCombinedReducer, PerformanceImportTracker } from '../../store'
import { AssociationSettingsRepository, ProfileRepository, UsersRepository } from '../../repositories'
import { IFileMeta, ISectionConfigItem } from '../../interfaces'
import { ValidEmploymentStatus, ScreenNamesEnum, NavMenuAccess } from '../../types'
import { fetchImportedDocsList, fetchImportHistory } from '../../providers/remoteDataProvider'

const profileService = new ProfileService()

const documentsColumnConfig = [
  {
    id: 'select',
    label: 'Add documents',
    iconComponent: (
      <div style={{ marginRight: 10 }}>
        {' '}
        <Icon size={0.75} path={mdiTextBoxPlus} color={ColorPalette.CARD_WHITE} />
      </div>
    ),
  },
  {
    id: 'upload',
    label: 'Upload',
    iconComponent: (
      <div style={{ marginRight: 10 }}>
        {' '}
        <Icon size={0.75} path={mdiUpload} color={ColorPalette.CARD_WHITE} />
      </div>
    ),
  },
  {
    id: 'assign',
    label: 'Assign',
    iconComponent: (
      <div style={{ marginRight: 10 }}>
        {' '}
        <Icon size={0.75} path={mdiClipboardArrowRight} color={ColorPalette.CARD_WHITE} />
      </div>
    ),
  },
]

const performanceColumnConfig = [
  {
    id: 'select',
    label: 'Add CSV file',
    iconComponent: (
      <div style={{ marginRight: 10 }}>
        {' '}
        <Icon size={0.75} path={mdiTextBoxPlus} color={ColorPalette.CARD_WHITE} />
      </div>
    ),
  },
  {
    id: 'upload',
    label: 'Upload',
    iconComponent: (
      <div style={{ marginRight: 10 }}>
        {' '}
        <Icon size={0.75} path={mdiUpload} color={ColorPalette.CARD_WHITE} />
      </div>
    ),
  },
]

const labelMap: Record<string, string> = {
  documents: 'Documents_to_upload',
  performanceData: 'File_to_upload',
}

const importTrackerMap: Record<string, string> = {
  documents: 'documentImportTracker',
  performanceData: 'performanceImportTracker',
}

const maxFileMap: Record<string, number> = {
  documents: 50,
  performanceData: 1,
}

const mediaTypeMap: Record<string, string | string[]> = {
  documents: 'application/pdf',
  performanceData: ['text/csv', 'application/vnd.ms-excel'],
}

const englishMediaTypeMap: Record<string, string> = {
  documents: 'PDF',
  performanceData: 'CSV',
}

interface ImportsProps extends RouteComponentProps {
  selectedAssociation: string
  selectedCohort: string
  associationRepo: AssociationSettingsRepository
  userRepo: UsersRepository
  idPassport: string
  password: string
  performanceImportTracker: PerformanceImportTracker[]
  currentScreen: ScreenNamesEnum
  selectedEmploymentStatus: ValidEmploymentStatus
  profileRepo: ProfileRepository
  navMenuAccess: NavMenuAccess

  updateState: (data: any) => void
  changeScreen: (screen: string) => void
}

interface ImportsState {
  loadingModalOpen: boolean
  warningModalOpen: boolean
  optionsSelectorModalOpen: boolean
  docNameSelectorModalOpen: boolean
  profileSelectorModalOpen: boolean
  loadingModalMessage: string
  warningModalHeader: string
  warningModalMessage: string
  sideMenuVisible: false
  tableWidth: GridSize
  selectedFileNames: string[]
  sectionConfig: ISectionConfigItem[]
  currentSectionName: string
  docViewerInfo: Record<string, IFileMeta[]>
  selectedDate: Date
  selectedPosition: string
  allProfileIds: string[]
  selectedDocName: string
  selectedIdPassport: string
  expandedFolders: string[]
  performanceImportTracker: PerformanceImportTracker[]
  [index: string]: any
  height: number
}

class Imports extends Component<ImportsProps, ImportsState> {
  inputRef?: React.RefObject<HTMLInputElement>
  busy: boolean = false

  initialModalState = {
    loadingModalOpen: false,
    warningModalOpen: false,
    optionsSelectorModalOpen: false,
    docNameSelectorModalOpen: false,
    profileSelectorModalOpen: false,
    height: window.innerHeight,
  }

  state: ImportsState = {
    ...this.initialModalState,
    docImportList: [],
    documentImportTracker: [],
    loadingModalMessage: '',
    warningModalHeader: '',
    warningModalMessage: '',
    sideMenuVisible: false,
    tableWidth: 10,
    selectedFileNames: [],
    sectionConfig: importsSectionConfig,
    currentSectionName: 'documents',
    docViewerInfo: {
      Documents_to_upload: [],
      Upload_history: [],
    },
    selectedDate: new Date(),
    selectedPosition: 'Picker',
    allProfileIds: [],
    selectedDocName: '',
    selectedIdPassport: '',
    expandedFolders: [],
    performanceImportTracker: [],
  }

  componentDidMount() {
    try {
      this.inputRef = React.createRef()
      this.props.updateState({
        currentSection: ScreenNamesEnum.TRANSFER,
        currentScreen: ScreenNamesEnum.IMPORT,
      })
      this.intialiseImports()
    } catch (error) {
      this.closeModals()
    }
  }

  async componentDidUpdate(prevProps: ImportsProps) {
    if (prevProps.selectedAssociation !== this.props.selectedAssociation && !this.busy) {
      this.busy = true
      this.setState({ docImportList: [], documentImportTracker: [] })
      await this.intialiseImports()
    }
    this.busy = false
  }

  async intialiseImports(hideLoader: boolean = false) {
    const { selectedAssociation, profileRepo, idPassport, password } = this.props
    const { docImportList, documentImportTracker } = this.state

    let allProfileIds = profileRepo.getAllProfileIdPassports()
    allProfileIds = allProfileIds.sort((a, b) => a.localeCompare(b))

    if (!(docImportList.length || documentImportTracker.length)) {
      this.setState({
        loadingModalOpen: !hideLoader,
        loadingModalMessage: 'Fetching import history...',
      })
    }

    await this.updateDocImportTracker(selectedAssociation, idPassport, password)
    this.updatePerformanceImportTracker(selectedAssociation, idPassport, password)
    this.setState({ ...this.initialModalState, allProfileIds })
  }

  closeModals = () => {
    this.setState({ ...this.initialModalState })
  }

  setColumnConfig = () => {
    let columnConfig = documentsColumnConfig
    if (this.state.currentSectionName === 'performanceData') {
      columnConfig = performanceColumnConfig
    }
    return columnConfig
  }

  onChange(event: PickerHandlerEvent, type: string) {
    this.setState({ [type]: event.target.value })
  }

  toolbarHandler(buttonId: string) {
    switch (buttonId) {
      case 'select':
        document.getElementById('document-selector')?.click()
        break

      case 'upload':
        this.onUploadTriggered()
        break

      case 'assign':
        if (this.state.selectedFileNames.length === 0) {
          this.setState({
            warningModalOpen: true,
            warningModalHeader: 'None selected',
            warningModalMessage: 'Select a document to continue',
          })
          return
        }

        const company = addUnderScores(this.props.selectedAssociation)
        let folders = Object.keys(this.props.associationRepo.getPublishedDocTemplates(company))
        folders = folders.map((folder) => removeUnderScores(folder))
        this.setState({ docNameSelectorModalOpen: true, folders })
        break
    }
  }

  expandCollapseFolder(folder: string): void {
    let expandedFolders: string[] = []
    if (!this.state.expandedFolders.includes(folder)) {
      expandedFolders = [folder]
    }
    this.setState({ expandedFolders })
  }

  fileSelectionHandler(selectedFileName: string) {
    let selectedFileNames = this.state.selectedFileNames
    if (selectedFileNames.includes(selectedFileName)) {
      selectedFileNames = selectedFileNames.filter((docName) => docName !== selectedFileName)
    } else {
      selectedFileNames.push(selectedFileName)
    }
    const docImportList = this.generateUnassignedList(this.state.docImportList, selectedFileNames)
    this.setState((prevState) => ({
      selectedFileNames,
      docViewerInfo: {
        Documents_to_upload: prevState.docViewerInfo.Documents_to_upload,
        Unassigned: docImportList,
        Assigned: prevState.docViewerInfo.Assigned,
      },
    }))
  }

  generateUploadHistory(remoteImportTracker: Record<string, any>[], currentSectionName: string): IFileMeta[] {
    let Upload_history: IFileMeta[] = []
    if (remoteImportTracker) {
      if (currentSectionName === 'performanceData') {
        Upload_history = remoteImportTracker.map((trackerItem) => {
          const { day, month, year, minutes, hour } = formatDateAndTime(new Date(parseInt(trackerItem.timestamp)))
          let docName = ''
          if (trackerItem.processing) {
            docName = `${hour}:${minutes}, ${day} ${month} ${year} - ${toUpperCaseCustom(
              trackerItem.fileName,
            )} \xa0 - Processing`
          } else if (trackerItem.code === 'MissingColumn') {
            docName = `${hour}:${minutes}, ${day} ${month} ${year} - ${toUpperCaseCustom(
              trackerItem.fileName,
            )} \xa0 - \xa0 Import Failed \xa0 | \xa0 Missing column headings: ${trackerItem.missingColumns.join(', ')} `
          } else if (trackerItem.code === 'NoJSONData') {
            docName = `${hour}:${minutes}, ${day} ${month} ${year} - ${toUpperCaseCustom(
              trackerItem.fileName,
            )} \xa0 - Import Failed \xa0 | \xa0 Unable to read data`
          } else {
            docName = `${hour}:${minutes}, ${day} ${month} ${year} - ${toUpperCaseCustom(
              trackerItem.fileName,
            )} \xa0 - \xa0 ${trackerItem.itemCount} items imported  \xa0 | \xa0 ${
              trackerItem.incompleteData
            } incomplete item \xa0 | \xa0 ${trackerItem.formatErrors} field format errors  \xa0`
          }
          return {
            docNameDisplay: docName,
            fileInfo: { fileName: docName },
            metaData: `${remoteImportTracker.length} IMPORTED`,
            leftComponent: trackerItem.processing ? this.generateRowComponent(docName, 'processing') : null,
          } as IFileMeta
        })
      }
    }
    return Upload_history
  }

  generateUnassignedList(docImportList: string[], selectedFileNames: any): IFileMeta[] {
    return docImportList.map((docName, i) => {
      return {
        docNameDisplay: toUpperCaseCustom(docName),
        fileInfo: { fileName: docName },
        metaData: `${docImportList.length} UNASSIGNED`,
        rightComponent: this.generateRowComponent(`${docName}_${i}`, 'checkbox', selectedFileNames),
      }
    })
  }

  generateAssignedList(documentImportTracker: any[]): IFileMeta[] {
    return documentImportTracker.map((trackerItem) => {
      const { day, month, year, minutes, hour } = formatDateAndTime(new Date(parseInt(trackerItem.timestamp)))
      const { docName, assignedBy, assignedTo } = trackerItem
      const docNameDisplay = `${hour}:${minutes}, ${day} ${month} ${year} - ${toUpperCaseCustom(
        docName,
      )} \xa0 - \xa0 Assigned by: ${titleCase(assignedBy)} \xa0 | \xa0 Assigned to: ${titleCase(assignedTo)} `

      return {
        docNameDisplay,
        fileInfo: { fileName: docName },
        lastGenerated: `${documentImportTracker.length} ASSIGNED`,
      }
    })
  }

  updateDocumentLists = (
    currentSectionName: string,
    documentImportTracker: Record<string, any>[],
    docImportList: any[],
    selectedFileNames: string[],
  ) => {
    const unassigned = this.generateUnassignedList(docImportList, selectedFileNames)
    const assigned = this.generateAssignedList(documentImportTracker)

    if (currentSectionName === 'documents') {
      this.setState((prevState) => ({
        docViewerInfo: {
          Documents_to_upload: prevState.docViewerInfo.Documents_to_upload,
          Unassigned: unassigned,
          Assigned: assigned,
        },
      }))
    }
  }

  async updatePerformanceImportTracker(selectedAssociation: string, idPassport: string, password: string) {
    try {
      let localImportTracker = this.props.performanceImportTracker
      const mostRecentTimestamp =
        localImportTracker.length && localImportTracker[0].timestamp ? localImportTracker[0].timestamp : '0'
      const { currentSectionName } = this.state
      const token = await SessionService.prepareAuthTokens(idPassport, password)
      const { upToDate, remoteImportTracker } = await fetchImportHistory(
        'performance',
        selectedAssociation,
        mostRecentTimestamp,
        token,
      )

      let performanceImportTracker: any[] = []
      if (upToDate) {
        performanceImportTracker = remoteImportTracker
      } else {
        remoteImportTracker.forEach((remoteTrackerItem) => {
          localImportTracker = localImportTracker.filter(
            (localTrackerItem) => localTrackerItem.timestamp !== remoteTrackerItem.timestamp,
          )
        })
        performanceImportTracker = [...localImportTracker, ...remoteImportTracker]
      }
      this.props.updateState({ performanceImportTracker })

      if (currentSectionName === 'performanceData') {
        const uploadHistory = this.generateUploadHistory(performanceImportTracker, currentSectionName)
        this.setState((prevState) => ({
          docViewerInfo: {
            File_to_upload: prevState.docViewerInfo.File_to_upload,
            Upload_history: uploadHistory,
          },
        }))
      }
      if (!upToDate) {
        setTimeout(() => this.updatePerformanceImportTracker(selectedAssociation, idPassport, password), 5000)
      }
    } catch (error) {
      console.error('updatePerformanceImportTracker: ', error)
    }
  }

  async updateDocImportTracker(selectedAssociation: string, idPassport: string, password: string) {
    try {
      const { docImportList, documentImportTracker, selectedFileNames, currentSectionName } = this.state
      this.updateDocumentLists(currentSectionName, documentImportTracker, docImportList, selectedFileNames)
      const token = await SessionService.prepareAuthTokens(idPassport, password)
      const latestDocImportList = await fetchImportedDocsList(selectedAssociation, token)
      const { remoteImportTracker } = await fetchImportHistory('documents', selectedAssociation, null, token)

      this.setState({
        docImportList: latestDocImportList,
        documentImportTracker: remoteImportTracker,
      })
      this.updateDocumentLists(currentSectionName, remoteImportTracker, latestDocImportList, selectedFileNames)
    } catch (error) {}
  }

  loadSection(currentSectionName: string) {
    if (currentSectionName === 'performanceData') {
      const Upload_history = this.generateUploadHistory(
        (this.props as any)[importTrackerMap[currentSectionName]],
        currentSectionName,
      )
      this.setState({
        currentSectionName,
        docViewerInfo: {
          File_to_upload: [],
          Upload_history,
        },
      })
    } else {
      const docImportList = this.generateUnassignedList(this.state.docImportList, this.state.selectedFileNames)
      const documentImportTracker = this.generateAssignedList(this.state.documentImportTracker)

      this.setState(
        {
          currentSectionName,
          docViewerInfo: {
            Documents_to_upload: [],
            Unassigned: [],
            Assigned: [],
          },
        },
        () =>
          this.setState({
            // This double state update is to overcome unexpalined UI behaviour whereby items from performance import tracker are shown in unassigned doc list
            docViewerInfo: {
              Documents_to_upload: [],
              Unassigned: docImportList,
              Assigned: documentImportTracker,
            },
          }),
      )
    }
  }

  generateRowComponent(id: string, type: string, selectedFileNames: string[] = []): JSX.Element | undefined {
    let component = undefined
    switch (type) {
      case 'delete':
        component = (
          <ButtonRound
            icon={<Icon size={0.9} path={mdiMinus} color={ColorPalette.PRIMARY_BLUE} />}
            onClick={() => this.deleteDocument(id)}
          />
        )
        break
      case 'processing':
        component = (
          <Lottie
            speed={1.6}
            options={{ animationData: syncArrows, loop: true }}
            style={{ ...styles.downloadIndicator }}
          />
        )
        break
      case 'checkbox':
        component = (
          <Checkbox
            key={`checkbox_${id}`}
            style={{
              color: selectedFileNames.includes(id) ? ColorPalette.PRIMARY_BLUE : ColorPalette.TERTIARY_TEXT,
              marginLeft: -10,
            }}
            checked={selectedFileNames.includes(id)}
            onChange={() => this.fileSelectionHandler(id)}
          />
        )
        break
    }

    return component
  }

  fileHandler(event: React.ChangeEvent<{ value: string }>) {
    const { currentSectionName } = this.state
    const maxFiles = maxFileMap[currentSectionName]
    const validTypes = mediaTypeMap[currentSectionName]

    let selectedFiles = this.state.docViewerInfo[labelMap[currentSectionName]]
    let encounteredIncorrectFormat = false
    let files = filePicker(event).filter((file) => {
      if (file.type && !validTypes.includes(toLowerCaseCustom(file.type))) {
        encounteredIncorrectFormat = !validTypes.includes(toLowerCaseCustom(file.type))
          ? true
          : encounteredIncorrectFormat
        return false
      }
      return true
    })

    if (selectedFiles.length + files.length > maxFiles) {
      this.displayWarning({ code: `MaxFileLimit ${maxFiles} ` })
      return
    }

    for (const file of files) {
      selectedFiles = selectedFiles.filter((item) => item.docNameDisplay !== file.name)
      selectedFiles.unshift({
        docNameDisplay: file.name,
        metaData: '',
        fileInfo: { ...file, fileName: file.name },
        rightComponent: this.generateRowComponent(file.name, 'delete'),
      })
    }

    const numberOfFiles = selectedFiles.length
    selectedFiles = selectedFiles.map((item) => {
      item = { ...item, metaData: `${numberOfFiles} SELECTED` }
      return item
    })

    if (currentSectionName === 'documents') {
      this.setState((prevState) => ({
        docViewerInfo: {
          Documents_to_upload: selectedFiles,
          Unassigned: prevState.docViewerInfo.Unassigned,
          Assigned: prevState.docViewerInfo.Assigned,
        },
        expandedFolders: [labelMap[currentSectionName]],
      }))
    } else {
      this.setState((prevState) => ({
        docViewerInfo: {
          File_to_upload: selectedFiles,
          Upload_history: prevState.docViewerInfo.Upload_history,
        },
        expandedFolders: [labelMap[currentSectionName]],
      }))
    }

    if (encounteredIncorrectFormat) {
      this.displayWarning({ code: 'FileFormatError' })
    }
  }

  deleteDocument(docName: string) {
    const { currentSectionName } = this.state
    let selectedFiles = this.state.docViewerInfo[labelMap[currentSectionName]]
    selectedFiles = selectedFiles.filter((item) => item.docNameDisplay !== docName)

    if (currentSectionName === 'documents') {
      this.setState((prevState) => ({
        docViewerInfo: {
          Documents_to_upload: selectedFiles,
          Unassigned: prevState.docViewerInfo.Unassigned,
          Assigned: prevState.docViewerInfo.Assigned,
        },
      }))
    } else {
      this.setState((prevState) => ({
        docViewerInfo: {
          File_to_upload: selectedFiles,
          Upload_history: prevState.docViewerInfo.Upload_history,
        },
      }))
    }
  }

  onUploadTriggered(): Promise<void> {
    const docviewerkey = labelMap[this.state.currentSectionName]
    const doc = this.state.docViewerInfo[docviewerkey]
    if (!doc.length) {
      throw { code: 'NoFiles' }
    }
    switch (this.state.currentSectionName) {
      case 'documents':
        return this.upload()

      case 'performanceData':
        this.setState({ ...this.initialModalState, optionsSelectorModalOpen: true })
        break
    }
    return new Promise<void>((res) => res())
  }

  writeCSVFileToRemoteStorage = async (fileName: string, filePath: string, fileData: string[], fileType: string) => {
    const file = new File(fileData, fileName, { type: fileType })
    await writeLocalFileToRemoteStorage(filePath, file, fileType)
  }

  parseAndDoPkTranslation = async (file: File) => {
    try {
      const data = (await csvToJson(file)) as Record<string, string>[]
      return data.map((item) => this.idPassportToPk(item))
    } catch (error) {
      console.error('CSV to JSON error', error)
    }
    return Promise.reject("Couldn't parse CSV file")
  }

  idPassportToPk = (item: Record<string, string>) => {
    const { profileRepo } = this.props
    if (item.hasOwnProperty('ID/Passport')) {
      const idPassport = toUpperCaseCustom(item['ID/Passport'])
      const profile = profileRepo.getProfileByIdPassport(idPassport)
      if (!profile) {
        return ''
      }
      item['ID/Passport'] = profile.getPk()
    }
    return item
  }

  async upload() {
    function splitStringPerLine(originalString: string, maintainNewLinesChars: boolean = true) {
      return originalString.split('\r\n').map((row) => (row += '\r\n'))
    }

    let filesWithUploadErrors: IFileMeta[] = []
    let newImportTrackerItem: PerformanceImportTracker = {} as PerformanceImportTracker
    const { currentSectionName, selectedDate, selectedPosition } = this.state
    const { selectedAssociation } = this.props
    const currentTime = new Date().getTime().toString()
    const selectedFiles = this.state.docViewerInfo[labelMap[currentSectionName]]
    const numberOfFiles = selectedFiles.length
    let successfulFileNames: string[] = []

    if (numberOfFiles === 0) {
      this.displayWarning({ code: 'NoFiles' })
      return
    }
    this.setState({ ...this.initialModalState, loadingModalOpen: true })

    for (let i = 0; i < numberOfFiles; i++) {
      this.setState({ loadingModalMessage: `Uploading ${i + 1} /${numberOfFiles}` })

      const fileObject = selectedFiles[i].fileInfo
      let path = ''
      let localFileName: any = fileObject.fileName
      const fileType = fileObject.type || ''

      if (currentSectionName === 'documents') {
        path = `Companies/${selectedAssociation}/Documents/Imports/${localFileName}`
      } else if (currentSectionName === 'performanceData') {
        const pathPrefix = 'Companies/All/Data'
        const filePath = `${pathPrefix}/Performance/`
        const fileName = `${currentTime}___${localFileName.substring(
          0,
          localFileName.length - 4,
        )}___${selectedAssociation}___${selectedPosition.toLowerCase()}___${selectedDate.getTime()}.csv`
        path = `${filePath}${fileName}`
        newImportTrackerItem = {
          fileName: localFileName,
          timestamp: currentTime,
          processing: true,
        }
      }

      const jsonData = await this.parseAndDoPkTranslation(fileObject.file as File)
      const csvData = jsonToCsv(jsonData)
      try {
        if (currentSectionName === 'performanceData') {
          const rows = splitStringPerLine(csvData)
          await this.writeCSVFileToRemoteStorage(localFileName, path, rows, fileType)
        } else {
          await writeLocalFileToRemoteStorage(path, fileObject.file, fileType)
        }
        successfulFileNames.push(localFileName)
      } catch (error) {
        filesWithUploadErrors.push(selectedFiles[i])
      }
    }

    let { performanceImportTracker } = this.props
    performanceImportTracker = Object.keys(newImportTrackerItem).length
      ? [newImportTrackerItem, ...performanceImportTracker]
      : performanceImportTracker

    const Upload_history = this.generateUploadHistory(performanceImportTracker, currentSectionName)
    this.props.updateState({ performanceImportTracker })

    let docViewerInfo = this.state.docViewerInfo
    if (currentSectionName === 'performanceData') {
      docViewerInfo = { ...docViewerInfo, Upload_history }
    } else {
      const docImportList = [...successfulFileNames, ...this.state.docImportList]
      this.setState({ docImportList })
      docViewerInfo = { ...docViewerInfo, Unassigned: this.generateUnassignedList(docImportList, []) }
    }

    if (filesWithUploadErrors.length) {
      setTimeout(() => {
        this.setState({
          ...this.initialModalState,
          docViewerInfo: {
            ...docViewerInfo,
            [labelMap[currentSectionName]]: filesWithUploadErrors,
          },
          warningModalOpen: true,
          warningModalHeader: 'Complete | Errors occurred',
          warningModalMessage:
            'We experienced a problem during upload. ' +
            (numberOfFiles === 1
              ? 'As such, your file did not successfully reach its destination.'
              : 'Some of your files did not reach their destination and are shown here. ') +
            ' Refresh the page and if the problem persists, then contact tech support.',
          expandedFolders: ['Upload history', 'Unassigned'],
        })
      }, 1000)
    } else {
      setTimeout(() => {
        this.setState({
          ...this.initialModalState,
          docViewerInfo: {
            ...docViewerInfo,
            [labelMap[currentSectionName]]: [],
          },
          warningModalOpen: true,
          warningModalHeader: 'Success',
          warningModalMessage:
            numberOfFiles === 1
              ? 'Your file was successfully uploaded.' +
                (currentSectionName === 'performanceData'
                  ? ' If your data adhered to our CSV template parameters, then you should see it reflect in a few minutes.'
                  : '')
              : 'All ' + numberOfFiles + ' of your files were uploaded successfully. ',
          expandedFolders: ['Upload history', 'Unassigned'],
        })
      }, 1000)
    }
    if (currentSectionName === 'performanceData') {
      const { selectedAssociation, idPassport, password } = this.props
      setTimeout(() => this.updatePerformanceImportTracker(selectedAssociation, idPassport, password), 1000)
    }
  }

  assignDocuments = async () => {
    try {
      this.setState({
        loadingModalOpen: true,
        loadingModalMessage: `Assigning documents...`,
      })
      const selectedDocName = addUnderScores(this.state.selectedDocName)
      const { idPassport, password, selectedAssociation, userRepo } = this.props
      const newlyCreatedDocs = this.state.selectedFileNames.map((docId) => {
        const timestamp = new Date().getTime().toString()
        const docNameElements = docId.split('_')
        docNameElements.pop()
        const originalDocName = docNameElements.join('_')
        const fileName = `${timestamp}___Import___${selectedDocName}.pdf`
        return {
          originalDocName,
          fileName,
          timestamp,
          action: 'create',
        }
      })

      const user = userRepo.getCurrentUserEntity()
      let documentImportTracker = await profileService.assignDocumentsToProfile(
        this.state.selectedIdPassport,
        selectedAssociation,
        selectedDocName,
        newlyCreatedDocs,
        user.getPersonalUserInfo(),
        { username: idPassport, password },
      )

      let docImportList = this.state.docImportList.filter((docInfo: string) => {
        let shouldReturn = true
        newlyCreatedDocs.forEach((docItem) => (docItem.originalDocName === docInfo ? (shouldReturn = false) : null))
        return shouldReturn
      })

      this.setState({ documentImportTracker, docImportList })
      const unassignedlist = this.generateUnassignedList(docImportList, [])
      documentImportTracker = this.generateAssignedList(documentImportTracker)

      this.setState((prevState) => ({
        ...this.initialModalState,
        selectedFileNames: [],
        docViewerInfo: {
          Documents_to_upload: prevState.docViewerInfo.Documents_to_upload,
          Unassigned: unassignedlist,
          Assigned: documentImportTracker,
        },
      }))
    } catch (error: any) {
      this.displayWarning(error)
    }
  }

  importSuccessHandler = () => {
    if (this.state.docNameSelectorModalOpen) {
      this.setState({ ...this.initialModalState }, () => this.setState({ profileSelectorModalOpen: true }))
      return
    }
    this.assignDocuments()
  }

  displayWarning(error: any) {
    let header = 'Warning'
    let warning = ''

    try {
      if (error.code === 'NetworkError') {
        warning = 'Seems like your internet connection is down. Reconnect to the network, then try again.'
      } else if (error.code === 'FileFormatError') {
        warning = `Incorrect format - You chose a file that was not in ${
          englishMediaTypeMap[this.state.currentSectionName]
        } format, so it was not added.`
      } else if (error.code === 'NetworkError') {
        warning = 'Seems like your internet connection is down. Reconnect to the network, then try again.'
      } else if (error.code === 'ProfileDoesntExist') {
        header = "Doesn't Exist"
        warning = 'Seems like that profile no longer exists.'
        this.intialiseImports(true)
      } else if (error.code === 'NoFiles') {
        warning = 'Select a file to upload.'
      } else if (error.code) {
        const codeElements = error.code.split(' ')
        if (codeElements[0] === 'MaxFileLimit') {
          const maxFiles = codeElements[1]
          warning = `You may only upload ${maxFiles} file${maxFiles === 1 ? 's' : ''} at a time.`
        } else {
          warning =
            'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
        }
      } else if ('message' in error) {
        warning =
          "The following error message was returned when logging in:\n\n'" +
          error.message +
          "'. \n\nRefresh the page and try again. If unsuccessful, then contact tech support"
      } else if ('message' in error) {
        warning =
          "The following error message was returned when logging in:\n\n'" +
          error.message +
          "'. \n\nRefresh the page and try again. If unsuccessful, then contact tech support"
      } else {
        warning = 'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
      }
    } catch (error) {
      warning = 'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
    }

    this.setState({
      ...this.initialModalState,
      warningModalOpen: true,
      warningModalHeader: header,
      warningModalMessage: warning,
    })
  }

  render() {
    const { hasImportAccess } = this.props.navMenuAccess

    let optionsSelectorModal = null
    if (this.state.optionsSelectorModalOpen) {
      optionsSelectorModal = (
        <OptionsSelectorModal
          open={true}
          onSuccess={() => this.upload()}
          onReject={this.closeModals}
          successLabel={'Upload'}
          cancelLabel={'Cancel'}
          // label1={"Position"}
          // options1={this.props.allPositions}
          // optionsName1={"selectedPosition"}
          selectedOption1={this.state.selectedPosition}
          onChange={(p, name) => this.onChange(p, name)}
          dateLabel={'When was this captured?'}
          showCalendar={true}
          selectedDate={this.state.selectedDate}
          handleDateChange={(selectedDate) => this.setState({ selectedDate })}
        />
      )
    } else if (this.state.docNameSelectorModalOpen || this.state.profileSelectorModalOpen) {
      const successLabel = this.state.docNameSelectorModalOpen ? 'Next' : 'Assign'
      const label = this.state.docNameSelectorModalOpen ? 'Folder' : 'ID / passport to assign?'
      const options = this.state.docNameSelectorModalOpen ? this.state.folders : this.state.allProfileIds
      const optionsName = this.state.docNameSelectorModalOpen ? 'selectedDocName' : 'selectedIdPassport'

      let selectedOption = null
      if (this.state.docNameSelectorModalOpen) {
        selectedOption = this.state.selectedDocName
      } else {
        const profile = this.props.profileRepo.getProfileByIdPassport(this.state.selectedIdPassport)
        selectedOption = profile?.getPk()
      }

      optionsSelectorModal = (
        <OptionsSelectorModal
          open={true}
          onSuccess={this.importSuccessHandler}
          onReject={this.closeModals}
          successLabel={successLabel}
          cancelLabel={'Cancel'}
          label1={label}
          searchEnabled1={true}
          options1={options}
          optionsName1={optionsName}
          selectedOption1={selectedOption}
          onChange={(e, name) => this.onChange(e, name)}
        />
      )
    }

    return (
      <div style={{ ...styles.container, minHeight: this.state.height, maxHeight: this.state.height }}>
        <NavBar match={this.props.match} location={this.props.location} history={this.props.history} />

        <SectionHeader
          style={{ marginTop: 25 }}
          labelStyle={{ paddingLeft: 0 }}
          searchString={this.state.searchString}
          disabled={true}
          onClick={() => this.setState({ sideMenuVisible: false, tableWidth: 10 })}>
          {this.props.currentScreen}
        </SectionHeader>

        <div
          style={{
            width: '100%',
            alignSelf: 'center',
            marginLeft: window.innerWidth * 0.16,
          }}>
          <Grid
            style={{
              ...styles.outerCard,
              minHeight: this.state.height * 0.82,
              marginTop: this.state.height * 0.06,
            }}
            item
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
            xs={this.state.tableWidth}>
            <DataFlowNavigator
              buttonStyle={{ width: 260 }}
              tableWidth={10}
              sectionConfig={this.state.sectionConfig}
              currentSectionName={this.state.currentSectionName}
              onSectionClick={(sectionName: string) => this.loadSection(sectionName)}
            />
            <DocumentTable
              tableWidth={this.state.tableWidth}
              columnConfig={this.setColumnConfig()}
              data={this.state.docViewerInfo}
              expandedFolders={this.state.expandedFolders}
              onToolbarClicked={(buttonid: string) => this.toolbarHandler(buttonid)}
              expandCollapseFolder={(folder: string) => this.expandCollapseFolder(folder)}
            />
            <input
              ref={this.inputRef}
              type="file"
              id={'document-selector'}
              style={styles.inputStyle}
              onChange={(e) => this.fileHandler(e)}
              multiple
            />
          </Grid>
        </div>

        {optionsSelectorModal}
        <AlertModalOneButton
          open={!hasImportAccess}
          header={'Not Authorised'}
          body={"You don't have permission to import any data/files."}
          buttonLabel={'Ok'}
          opaqueBackground={true}
          onClick={() => this.props.history.goBack()}
        />
        <AlertModalOneButton
          open={this.state.warningModalOpen}
          header={this.state.warningModalHeader}
          body={this.state.warningModalMessage}
          buttonLabel={'Ok'}
          onClick={this.closeModals}
        />
        <LoadingModal open={this.state.loadingModalOpen}>{this.state.loadingModalMessage}</LoadingModal>
      </div>
    )
  }
}

const styles: Record<string, React.CSSProperties | Record<string, React.CSSProperties>> = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: `linear-gradient(to bottom, ${ColorPalette.SCREEN_TOP_GRADIENT}, ${ColorPalette.SCREEN_BOTTOM_GRADIENT})`,
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  outerCard: {
    zIndex: 1,
    alignSelf: 'center',
    backgroundColor: ColorPalette.CARD_WHITE,

    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    overflow: 'hidden',
  },
  iconStyle: {
    color: ColorPalette.CARD_WHITE,
    width: '1.1rem',
    height: '1.1rem',
    marginRight: 10,
    marginLeft: 10,
  },
  inputStyle: {
    width: '0.1px',
    height: '0.1px',
    opacity: 0,
    position: 'absolute' as 'absolute',
    // pointEvents: "none" as "none",
  },
  errorButton: {
    fontWeight: '550',
    fontSize: '0.8rem',
    color: ColorPalette.PRIMARY_LIGHT_TEXT,
    width: 160,
    borderRadius: 30,
    padding: 8,
    paddingLeft: 12,
    marginTop: 30,
    marginLeft: window.innerWidth * 0.08,
    ':hover': {
      filter: 'brightness(85%)',
    },
    ':active': {
      filter: 'brightness(75%)',
    },
  },
  errorIconContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
  },
  downloadIndicator: {
    marginLeft: -22,
    marginRight: 0,
    height: 22,
    width: 22,
  },
}

const mapStateToProps = (state: PeopleFlowCombinedReducer) => {
  return {
    idPassport: state.sessionManager.idPassport,
    password: state.sessionManager.password,
    associationRepo: state.sessionManager.associationRepo as AssociationSettingsRepository,
    profileRepo: state.sessionManager.profileRepo as ProfileRepository,
    userRepo: state.sessionManager.userRepo as UsersRepository,
    selectedAssociation: state.sessionManager.selectedAssociation,
    selectedEmploymentStatus: state.sessionManager.selectedEmploymentStatus,
    selectedCohort: state.sessionManager.selectedCohort,
    currentScreen: state.sessionManager.currentScreen,
    profile: state.sessionManager.profile,
    // allPositions: state.sessionManager.allPositions,
    navMenuAccess: state.sessionManager.navMenuAccess,
    // hasWorkforceAccess: state.sessionManager.hasWorkforceAccess,
    performanceImportTracker: state.sessionManager.performanceImportTracker,
    // documentImportTracker: state.sessionManager.documentImportTracker,
    // docImportList: state.sessionManager.docImportList,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateState: (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data }),
    changeScreen: (screen: string) => dispatch({ type: ActionType.CHANGE_SCREEN, data: { currentScreen: screen } }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Radium(Imports))
