import { CSSProperties, useEffect, useState } from 'react'
import { isEmpty } from 'lodash'

import { ColorPalette } from '../../../config/colors'
import { DataQueryFilters } from './DataQueryFilters'
import { QueryService } from '../../../services/queryService'
import { CustomTimePeriod, Filter, QueryFilter, QueryFilterParams, TimePeriod } from '../../../types'
import { DataQueryTimePeriodSelector } from './DataQueryTimePeriodSelector'
import { ConfigSectionHeader } from '../ConfigSectionHeader'

interface DataQuerySettingsProps {
  filterParams: QueryFilterParams
  selectedAssociation?: string
  style?: CSSProperties
  saveChanges: (
    filters: QueryFilter[][],
    timePeriod?: TimePeriod | {},
    customTimePeriod?: CustomTimePeriod | {},
  ) => Promise<void>
}

export const DataQuerySettings = (props: DataQuerySettingsProps) => {
  const [timePeriodSelection, setTimePeriodSelection] = useState<TimePeriod | {}>({})
  const [customTimePeriodRange, setCustomTimePeriodRange] = useState<CustomTimePeriod | {}>({})
  const [isScreenContentVisible, setScreenContentVisibility] = useState(false)

  useEffect(() => {
    if (!isEmpty(props.filterParams.period)) {
      setTimePeriodSelection(props.filterParams.period)
    }
    if (!isEmpty(props.filterParams.timeRange)) {
      setCustomTimePeriodRange(props.filterParams.timeRange)
    }
    setScreenContentVisibility(true)
  }, [])

  const handleFilterChangeDoneClick = async (filters: Filter[][]) => {
    const queryFilters: QueryFilter[][] = QueryService.translateFilters(filters)
    await props.saveChanges(queryFilters)
  }

  const handleTimePeriodSelection = async (timePeriod?: TimePeriod) => {
    if (timePeriod) {
      await props.saveChanges(props.filterParams.filters, timePeriod, {})
      setTimePeriodSelection(timePeriod)
    }
  }

  const handleCustomDateRangeSelection = async (timeRange: CustomTimePeriod) => {
    await props.saveChanges(props.filterParams.filters, {}, timeRange)
    setCustomTimePeriodRange(timeRange)
  }

  let screenContent = null
  if (isScreenContentVisible) {
    screenContent = (
      <div style={{ width: '90%', height: '100%', ...props.style }}>
        <div style={styles.section}>
          <ConfigSectionHeader>TIME RANGE</ConfigSectionHeader>
          <div style={styles.sectionInput}>
            <DataQueryTimePeriodSelector
              timePeriod={timePeriodSelection}
              customTimeRange={customTimePeriodRange}
              setTimePeriod={handleTimePeriodSelection}
              setCustomDateRange={handleCustomDateRangeSelection}
            />
          </div>
        </div>
        <div style={styles.section}>
          <ConfigSectionHeader>FILTERS TO BE APPLIED</ConfigSectionHeader>
          <div style={styles.sectionInput}>
            <DataQueryFilters
              filters={QueryService.getTranslatedFilters(props.filterParams.filters)}
              onDoneClick={handleFilterChangeDoneClick}
            />
          </div>
        </div>
      </div>
    )
  }

  return screenContent
}

const styles = {
  section: {
    width: '96%',
    margin: '4% 2%',
    paddingBottom: '20px',
  },
  sectionInput: {
    // margin: '0px 1.5em 0px 1.5em',
    padding: '5px 25px 20px 25px',
  },
}
