import _ from 'lodash'

import { toLowerCaseCustom } from '../../utils'
import {
  Username,
  StopGapResourceBasedPolicy,
  SystemFeaturesEnum,
  SystemOperationsEnum,
  SystemAccessActionsEnum,
  ResourceName,
  ResourceId,
  AccountStatusEnum,
} from '../../types'
import { IamServiceErrorCodesEnum } from '../../enums'
import { PolicySchema } from '../policy'

const { AccountInArrears, AccountDeactivated } = IamServiceErrorCodesEnum
const { allow, deny } = SystemAccessActionsEnum

export type IamSchema = {
  permissions: { role: string; policies: PolicySchema[] }[]
  version: number
  stopGapResourceBasedPolicy?: StopGapResourceBasedPolicy
}
export class Iam {
  private permissions: { role: string; policies: PolicySchema[] }[]
  private stopGapResourceBasedPolicy?: StopGapResourceBasedPolicy

  constructor(jsonData: IamSchema) {
    this.permissions = jsonData.permissions || [{ policies: [], role: '' }]
    this.stopGapResourceBasedPolicy = jsonData.stopGapResourceBasedPolicy || ({} as StopGapResourceBasedPolicy)
  }

  checkAccess = (
    targetFeature: SystemFeaturesEnum,
    targetOperation?: SystemOperationsEnum,
    targetResource?: ResourceName,
  ) => {
    try {
      targetOperation = targetOperation ? (toLowerCaseCustom(targetOperation) as SystemOperationsEnum) : targetOperation
      targetResource = targetResource ? toLowerCaseCustom(targetResource) : targetResource

      let hasAccess = false
      for (const permission of this.permissions) {
        for (const policy of permission.policies) {
          let { action = '', feature = '', operations = [], resources = [] } = policy
          operations = operations.map((operation: string) => toLowerCaseCustom(operation))
          resources = resources.map((resource) => toLowerCaseCustom(resource))

          if (
            toLowerCaseCustom(feature) === toLowerCaseCustom(targetFeature) &&
            toLowerCaseCustom(action) === allow &&
            (!targetOperation ||
              operations.includes(targetOperation) ||
              operations.includes(SystemOperationsEnum.all) ||
              operations.includes('*')) &&
            (!targetResource ||
              resources.includes(targetResource) ||
              resources.includes('all') ||
              resources.includes('*'))
          ) {
            hasAccess = true
          }
        }
      }
      return hasAccess
    } catch (error) {
      console.log('checkAccess error: ', error)
    }
    return false
  }

  extractAuthedCohorts(allCohorts: ResourceId[], targetOperation: SystemOperationsEnum) {
    let authedCohorts = []
    for (const cohort of allCohorts) {
      const hasAccess = this.checkAccess(SystemFeaturesEnum.workforce, targetOperation, cohort)
      if (hasAccess) {
        authedCohorts.push(cohort)
      }
    }
    return authedCohorts
  }

  getPermittedFeatureResources(targetFeature: SystemFeaturesEnum) {
    let targetResources = []
    for (const permission of this.permissions) {
      for (const policy of permission.policies) {
        let { feature = '', resources = [] } = policy
        if (feature === targetFeature) {
          targetResources.push(...resources)
        }
      }
    }
    return [...new Set(targetResources)]
  }

  getAccountStatus(username: Username) {
    //TODO: This must be deprecated as soon a permanent resource based policy schema is agreed upon.

    let defaultSettings = { isActive: true, warningCode: '' }
    if (!this.stopGapResourceBasedPolicy) {
      return defaultSettings
    }
    const userAccessAllow = this.stopGapResourceBasedPolicy[allow]?.find(
      (obj) => obj.idPassport === username || obj.idPassport === '*',
    )
    const userAccessDeny = this.stopGapResourceBasedPolicy[deny]?.find(
      (obj) => obj.idPassport === username || obj.idPassport === '*',
    )

    if (!userAccessAllow && userAccessDeny && userAccessDeny.access?.type && userAccessDeny.access?.expiryDate) {
      let accessType = userAccessDeny.access.type
      const expiryDate = new Date(userAccessDeny.access.expiryDate)
      const today = new Date()
      if (expiryDate < today) {
        if (accessType === AccountStatusEnum.arrears) {
          return { isActive: true, warningCode: AccountInArrears }
        }
        if (accessType === AccountStatusEnum.deactivated) {
          return { isActive: false, warningCode: AccountDeactivated }
        }
      }
    }
    return defaultSettings
  }
}
