import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { difference, isEmpty, pick, pickBy } from 'lodash'
import equal from 'deep-equal'

import CheckboxTabLabelled from '../../BaseComponents/Checkboxes/CheckboxTabLabelled'
import TwoColumnSelector from '../TwoColumnSelector/TwoColumnSelector'
import { ExpandableSection } from '../../GeneralUI/ExpandableSection/ExpandableSection'
import ButtonBlue from '../../BaseComponents/Buttons/ButtonBlue'

import { ColorPalette } from '../../../config/colors'
import MultiButton from '../../BaseComponents/Buttons/MultiButton'
import ModalPortal from '../../Modals/ModalPortal'
import InfoCollectorModal from '../../Modals/InfoCollector'
import { PeopleFlowCombinedReducer } from '../../../store'
import AlertModalTwoButton from '../../Modals/AlertModalTwoButton'
import SavingModal from '../../Modals/LoadingModal'
import { getConfiguredProcessInfo, toUpperCaseCustom } from '../../../utils'
import { SessionService, ConfigService } from '../../../services'
import { ActionType } from '../../../store/actions/actions'
import { AssociationSettingsRepository } from '../../../repositories'
import { DocConfigItem } from '../../../models'

interface ISubSection {
  name: string
  label: string
  value: string[] | boolean
  configContext?: string
  route?: any
  onSelect?: (selectorIdentifier: string, selectorItems: string[], selectorType?: string) => void
  onChange?: (value: boolean) => void
}

interface ISectionProps {
  selectedDivision: string
  selectedJob: string
  sectionName: string
  title?: string
  subSections: ISubSection[]
  showSpinner?: boolean
  style?: React.CSSProperties
}

export const Section = (props: ISectionProps) => {
  const { selectedDivision, selectedJob, sectionName, title, subSections, showSpinner } = props
  const history = useHistory()
  return (
    <ExpandableSection title={title || sectionName.toUpperCase()} initialCollapsed={true} style={props.style}>
      {showSpinner ? (
        <div>Spinner</div>
      ) : (
        <>
          {subSections.map((subSection: ISubSection) => {
            // contextObj with job, section
            const { name, label, configContext, value, route, onSelect, onChange } = subSection
            if (configContext === 'docsAndData') {
              return (
                <DocsAndDataButton
                  label={label}
                  selectedDivision={selectedDivision}
                  selectedJobType={selectedJob}
                  sectionName={sectionName}
                  sectionLabel={title || ''}
                  key={`subSection_${configContext}_${label}`}
                />
              )
            }
            if (configContext === 'redirect') {
              return (
                <div style={styles.sectionInput} key={`subSection_${configContext}_${label}`}>
                  <ButtonBlue onClick={() => history.push(`${route}/${selectedJob}`)}>{label}</ButtonBlue>
                </div>
              )
            }

            if (Array.isArray(value) && onSelect) {
              return (
                <TwoColumnSelector
                  label={label}
                  values={value}
                  onClick={() => onSelect(`${sectionName}.${name}`, value)}
                  key={`subSection_array_${label}`}
                />
              )
            }

            if (onChange) {
              return (
                <CheckboxTabLabelled
                  style={styles.sectionInput}
                  itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
                  label={label}
                  values={value ? ['yes'] : ['no']}
                  selectorItems={['no', 'yes']}
                  selectionHandler={(values: string[], isMount) => onChange(values[0] === 'YES')}
                  disabled={value === undefined}
                  key={`subSection_checkbox_${label}`}
                />
              )
            }

            return null
          })}
        </>
      )}
    </ExpandableSection>
  )
}

const styles = {
  sectionInput: {
    margin: '40px 1.5em 0px 1.5em',
  },
}

interface IDocsAndDataButtonProps {
  label: string
  selectedDivision: string
  selectedJobType: string
  selectedSection?: string
  sectionName: string
  sectionLabel: string
  onSaveClick?: () => void
}

type SaveSnapshot = {
  unsavedItem?: string
  allItemsInclUnsaved?: string[]
}

const DocsAndDataButton = (props: IDocsAndDataButtonProps) => {
  const { selectedDivision, selectedJobType, sectionName, sectionLabel, onSaveClick } = props
  // const [modalOpen, setModalOpen] = useState(false);
  const [selectorIdentifier, setSelectorIdentifier] = useState('')
  const [selectorItems, setSelectorItems] = useState<string[]>([])
  const [docNames, setDocNames] = useState<string[]>([])
  const [dataFlowNames, setDataFlowNames] = useState<string[]>([])
  const [savingModalOpen, setSavingModalOpen] = useState(false)
  const [savePromptModalOpen, setSavePromptModalOpen] = useState<SaveSnapshot | {}>({})

  const idPassport = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.idPassport)
  const password = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.password)
  const associationRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.associationRepo,
  ) as AssociationSettingsRepository
  const selectedAssociation = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation,
  )
  const dispatch = useDispatch()

  const processConfig = associationRepo.getProcessConfig(selectedAssociation)
  const history = useHistory()

  useEffect(() => {
    let docNames: string[] = []
    let dataFlowNames: string[] = []
    if (selectedJobType && sectionLabel) {
      docNames = getConfiguredProcessInfo('docNames', sectionName, sourceData, processConfig) as string[]
      dataFlowNames = getConfiguredProcessInfo('dataFlowNames', sectionName, sourceData, processConfig) as string[]

      if (docNames.constructor === Object && isEmpty(docNames)) {
        // this condition will be truthy if no allActions object was found at the lowest level when extracting config
        docNames = []
      }
      if (dataFlowNames.constructor === Object && isEmpty(dataFlowNames)) {
        dataFlowNames = []
      }

      setDocNames(docNames)
      setDataFlowNames(dataFlowNames)
    }
  }, [])

  const sourceData = {
    division: selectedDivision,
    jobType: selectedJobType,
    jobSubType: '',
  }

  const changeSelectorIdentifier = (identifier: string) => {
    setSelectorIdentifier(identifier)
    if (identifier === '') {
      setSelectorItems([])
    }
    if (identifier === 'docNames') {
      setSelectorItems([...docNames])
    }
    if (identifier === 'dataFlowNames') {
      const readableDataFlowNames = dataFlowNames.map((name: string) => removeDataFlowPartAndUnderscores(name))
      setSelectorItems([...readableDataFlowNames])
    }
  }

  const closeSelectorModal = () => {
    changeSelectorIdentifier('')
  }
  const toggleSavingModalOpen = () => {
    setSavingModalOpen((savingModalOpen) => !savingModalOpen)
  }

  const saveNewDataFlow = async (newDataFlowName: string) => {
    const changes = [
      {
        editedBy: idPassport,
        updatePath: [],
        updatedData: {
          dataFlow: {},
          sectionConfig: [],
        },
        updatedMs: +new Date(),
      },
    ]

    const response = await ConfigService.updateConfig(
      selectedAssociation,
      'dataFlow',
      changes,
      { username: idPassport, password },
      newDataFlowName,
    )

    if (response.result === 'success') {
      associationRepo.setDataFlowConfig(selectedAssociation, newDataFlowName, response.updatedConfig)
      dispatch({ type: ActionType.UPDATE_STATE, data: { associationRepo } })
    }
  }

  const saveDocsToSignToProcessConfig = async (jobType: string, docNames: string[]) => {
    const divisionPath = selectedDivision
    const jobTypePath = jobType || 'allJobTypes'

    let updatePath = [sectionName, 'docCaptureConfig', divisionPath, jobTypePath, 'allJobSubTypes', 'docNames']
    const changes = [
      {
        editedBy: idPassport,
        updatePath,
        updatedData: docNames,
        updatedMs: +new Date(),
      },
    ]
    const response = await ConfigService.updateConfig(selectedAssociation, 'processConfig', changes, {
      username: idPassport,
      password,
    })
    if (response.result === 'success') {
      associationRepo.setProcessConfigItem(selectedAssociation, pick(response.updatedConfig, sectionName))
      return associationRepo
    }
    return null
  }

  const saveDataFlowsToProcessConfig = async (jobType: string, dataFlowNames: string[]) => {
    const divisionPath = selectedDivision
    const jobTypePath = jobType || 'allJobTypes'

    let updatePath = [sectionName, 'dataCaptureConfig', divisionPath, jobTypePath, 'allJobSubTypes', 'dataFlowNames']
    const changes = [
      {
        editedBy: idPassport,
        updatePath,
        updatedData: dataFlowNames,
        updatedMs: +new Date(),
      },
    ]
    const response = await ConfigService.updateConfig(selectedAssociation, 'processConfig', changes, {
      username: idPassport,
      password,
    })
    if (response.result === 'success') {
      associationRepo.setProcessConfigItem(selectedAssociation, pick(response.updatedConfig, sectionName))
      return associationRepo
    }
    return null
  }

  const handleSelection = (items: string[]) => {
    if (selectorIdentifier === 'docNames') {
      setDocNames(items)
      saveDocNames(items)
    }
    if (selectorIdentifier === 'dataFlowNames') {
      const dataFlowItems = items.map((name: string) => restoreOriginalDataFlowName(name))
      setDataFlowNames(dataFlowItems)
      saveDataFlows(dataFlowItems)
    }
    closeSelectorModal()
  }

  const saveDocNames = async (docNames: string[]) => {
    toggleSavingModalOpen()
    const currentDocNames = getConfiguredProcessInfo('docNames', sectionName, sourceData, processConfig)
    if (!equal(docNames, currentDocNames)) {
      const associationRepo = await saveDocsToSignToProcessConfig(selectedJobType, docNames)
      if (associationRepo) {
        dispatch({ type: ActionType.UPDATE_STATE, data: { associationRepo } })
      }
    }
    toggleSavingModalOpen()
  }

  const saveDataFlows = async (dataFlowNames: string[]) => {
    toggleSavingModalOpen()
    const currentDataFlowNames = getConfiguredProcessInfo(
      'dataFlowNames',
      sectionName,
      sourceData,
      processConfig,
    ) as string[]
    if (!equal(dataFlowNames, currentDataFlowNames)) {
      const associationRepo = await saveDataFlowsToProcessConfig(selectedJobType, dataFlowNames)
      const addedDataFlowNames = difference(dataFlowNames, currentDataFlowNames)
      if (associationRepo) {
        dispatch({ type: ActionType.UPDATE_STATE, data: { associationRepo } })
        await Promise.all(
          addedDataFlowNames.map(async (dataFlowName: string) => {
            const isExistingDataFlow = associationRepo.getAllDataFlows(selectedAssociation).hasOwnProperty(dataFlowName)
            if (isExistingDataFlow) {
              return
            }
            await saveNewDataFlow(dataFlowName)
          }),
        )
      }
    }
    toggleSavingModalOpen()
  }

  const saveChangesAndNavigateToDataFlow = async () => {
    // @ts-ignore
    const { unsavedItem, allItemsInclUnsaved } = savePromptModalOpen
    const dataFlowItems = allItemsInclUnsaved.map((name: string) => restoreOriginalDataFlowName(name))
    setDataFlowNames(dataFlowItems)
    setSavePromptModalOpen({})
    await saveDataFlows(dataFlowItems)
    if (onSaveClick) {
      onSaveClick()
    }
    history.push(`/configurator/dataflows/${unsavedItem}`)
  }

  const handleItemNavigateClick = (item: string, items: string[]) => {
    const dataFlowName = restoreOriginalDataFlowName(item)
    if (!availableDataFlows[dataFlowName]) {
      setSavePromptModalOpen({
        unsavedItem: item,
        allItemsInclUnsaved: items,
      })
    } else {
      history.push(`/configurator/dataflows/${item}`)
    }
  }

  const getInfoCollectorHeader = (selectorIdentifier: string): string => {
    return selectorIdentifier === 'dataFlowNames' ? 'DATA FLOW SELECTION' : 'DOC SELECTION'
  }

  const getInfoCollectorSubHeader = (selectorIdentifier: string): string => {
    if (selectorIdentifier === 'dataFlowNames') {
      return `Which data flows should be used for capturing data during the ${toUpperCaseCustom(
        props.sectionName,
      )} process? (If you choose more than one, they will be merged together)`
    }
    return `Which docs should be captured during the ${toUpperCaseCustom(props.sectionName)} process?`
  }

  const removeDataFlowPartAndUnderscores = (name: string) => name.replace('dataFlow___', '').replace(/___/g, ' ')
  const restoreOriginalDataFlowName = (name: string) => `dataFlow___${name.replace(/\s/g, '___')}`

  const docIsPublished = (docObj: DocConfigItem) => !isEmpty(docObj.published)

  const docConfig = associationRepo.getDocConfig(selectedAssociation)
  const availableDocsToChooseFrom = pickBy(docConfig, docIsPublished)
  const availableDataFlows = associationRepo.getAllDataFlows(selectedAssociation)

  let pickerItems: string[] = []
  if (selectorIdentifier) {
    if (selectorIdentifier === 'docNames') {
      pickerItems = Object.keys(availableDocsToChooseFrom)
    }
    if (selectorIdentifier === 'dataFlowNames') {
      const readableDataFlowNames = Object.keys(availableDataFlows).map((name: string) =>
        removeDataFlowPartAndUnderscores(name),
      )
      pickerItems = ['+ Add new flow', ...readableDataFlowNames]
    }
    if (selectorItems.length > 0) {
      pickerItems = difference(pickerItems, selectorItems)
    }
  }
  return (
    <div style={styles.sectionInput}>
      <MultiButton
        clickableItems={[
          {
            label: 'Data',
            title: 'Choose which data should be completed',
            onClick: () => changeSelectorIdentifier('dataFlowNames'),
            style: {
              width: '100%',
            },
          },
          {
            label: 'Docs',
            title: 'Choose which documents should be completed',
            onClick: () => changeSelectorIdentifier('docNames'),
            style: {
              width: '100%',
            },
          },
        ]}
      />
      {selectorIdentifier !== '' && (
        <ModalPortal>
          <InfoCollectorModal
            open={true}
            defaultItems={selectorItems.sort()}
            pickerItems={pickerItems.sort()}
            header={getInfoCollectorHeader(selectorIdentifier)}
            subHeader={getInfoCollectorSubHeader(selectorIdentifier)}
            warningMessage="Add at least one item"
            validateInput={() => true}
            placeholder={selectorIdentifier === 'dataFlowNames' ? 'SELECT A DATA FLOW' : 'SELECT A DOC'}
            successLabel="Save"
            minimumItems={1} // this could be changed to 0 if necessary to remove a last item, allowing a empty list
            dismiss={closeSelectorModal}
            onSuccess={handleSelection}
            onReject={closeSelectorModal}
            type="picker"
            addOnFirstItemClick={selectorIdentifier === 'dataFlowNames'}
            onItemNavigateClick={selectorIdentifier === 'dataFlowNames' ? handleItemNavigateClick : undefined}
            itemAddTransform={(item: string) =>
              (item[0].toUpperCase() + item.slice(1)).replace(/\s/g, '___').replace(/___/g, ' ')
            }
            uppercasePickerItems={true}
            key={`infoCollector_${selectorIdentifier}`}
          />
        </ModalPortal>
      )}
      {savePromptModalOpen.hasOwnProperty('unsavedItem') && (
        <ModalPortal>
          <AlertModalTwoButton
            open={true}
            header="Save flow(s) recently added?"
            body="Saving is necessary before navigation to the flow details page is possible"
            dismiss={() => setSavePromptModalOpen({})}
            onClick1={() => setSavePromptModalOpen({})}
            onClick2={saveChangesAndNavigateToDataFlow}
            buttonLabel1="Cancel"
            buttonLabel2="Save changes"
          />
        </ModalPortal>
      )}
      {savingModalOpen && (
        <ModalPortal>
          <SavingModal open={true} style={{ zIndex: 20000 }}>
            Saving changes...
          </SavingModal>
        </ModalPortal>
      )}
    </div>
  )
}
