import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { createStore, combineReducers, applyMiddleware } from 'redux'
// import logger from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension'
import { datadogRum } from '@datadog/browser-rum'
import { isEmpty } from 'lodash'

import App from './App'
import { sessionManager } from './store'
import { IamService, SessionService } from './services'
import LoadingModal from './components/Modals/LoadingModal'
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary'
import { Beforeunload } from 'react-beforeunload'
import { datadogConfig } from './config/datadog'
import { ReduxRepository } from './repositories/reduxRepository'
import { createMuiTheme, CssBaseline, MuiThemeProvider } from '@material-ui/core'
import { ColorPalette } from './config'

const reduxRepo = new ReduxRepository()
const sessionService = new SessionService()
const iamService = new IamService()

export const AppInitialiser = () => {
  const [initialising, setInitialising] = useState(true)
  const [reduxState, setReduxState] = useState<any>({})

  useEffect(() => {
    initialise()
  }, [])

  const initialise = async () => {
    const reduxStateSnapshot: any = reduxRepo.loadStateSnapshot()
    const currentPath = window.location.pathname
    const onLoginPage = currentPath === '/' || currentPath === '/login'
    if (!isEmpty(reduxStateSnapshot)) {
      if (!onLoginPage) {
        const { sessionManager = {} } = reduxStateSnapshot
        const { idPassport, password } = sessionManager
        const config = await sessionService.login(idPassport, password, iamService)
        reduxStateSnapshot.sessionManager = { ...sessionManager, ...config }
      }
      setReduxState(reduxStateSnapshot)
    }
    setInitialising(false)
  }

  if (process.env.NODE_ENV === 'production') {
    datadogRum.init(datadogConfig)
    datadogRum.startSessionReplayRecording()
  }

  if (initialising) {
    return <LoadingModal open={true}>Reloading app</LoadingModal>
  }

  const rootReducer = combineReducers({ sessionManager })
  // const composeEnhancers = composeWithDevTools(applyMiddleware(logger))
  const composeEnhancers = composeWithDevTools()
  const store = createStore(rootReducer, reduxState, composeEnhancers)

  const handleBeforeUnload = async (event: any) => {
    const currentState = store.getState()
    const { sessionManager } = currentState
    const { userRepo, associationRepo, profileRepo, ...stateToPersist } = sessionManager
    reduxRepo.saveStateSnapshot({ sessionManager: stateToPersist })
  }

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: ColorPalette.PRIMARY_TEXT,
      },
      secondary: {
        main: ColorPalette.PRIMARY_BLUE,
      },
    },
  })

  theme.breakpoints.keys = [...theme.breakpoints.keys, 'sm']
  theme.breakpoints.values.sm = 780
  theme.breakpoints.values.xl = 1440

  return (
    <Provider store={store}>
      <ErrorBoundary>
        <Beforeunload onBeforeunload={handleBeforeUnload}>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <App />
          </MuiThemeProvider>
        </Beforeunload>
      </ErrorBoundary>
    </Provider>
  )
}

const app = <AppInitialiser />
ReactDOM.render(app, document.getElementById('root'))
serviceWorker.unregister()
