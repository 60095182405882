import { toUpperCaseCustom, removeUnderScores, ensureArrayFormat } from '../../../utils'

import React, { Component } from 'react'
import Checkbox from './Checkbox'
import { ColorPalette } from '../../../config'
import { isEmpty } from 'lodash'

interface CheckboxProps {
  selectorItems: string[]
  maxItemsPerRow?: number
  maxItems?: number
  values: string[]
  defaultValues?: string[]
  disabled?: boolean | undefined
  clickableLabels?: string[] | undefined
  style?: React.CSSProperties | undefined
  checkboxStyle?: React.CSSProperties | undefined
  labelStyle?: React.CSSProperties | undefined
  lineContainerStyle?: React.CSSProperties | undefined
  selectedLabelStyleAdjustment?: (label: string) => React.CSSProperties | undefined
  selectionHandler?: (values: string[], isMount: boolean) => void
  onLabelClick?: (label: string) => void
}

interface CheckboxState {
  customValue: string
  customSelected: boolean
  maxItemsSelected: boolean
  maxItemsPerRow?: number
  defaultSelectorItems: string[]
  selectorItems: string[]
}

class CheckboxTab extends Component<CheckboxProps, CheckboxState> {
  state: CheckboxState = {
    customValue: '',
    customSelected: false,
    maxItemsSelected: false,
    selectorItems: [],
    defaultSelectorItems: ['YES', 'NO'],
  }

  componentDidMount() {
    try {
      const { defaultValues } = this.props
      let { values = [], selectorItems = [] } = this.formatVariables(this.props.values, this.props.selectorItems)
      let initialValues = ensureArrayFormat(values)
      if (!initialValues.length) {
        if (defaultValues) {
          initialValues = ensureArrayFormat(defaultValues)
        }
      }
      if (initialValues.length) {
        this.selectionHandler(initialValues, true)
      }
      const { customValue, customSelected } = this.identifyCustomValues(initialValues, [
        ...selectorItems,
        ...this.state.defaultSelectorItems,
      ])
      this.setState({ selectorItems, customValue, customSelected })
    } catch (error) {}
  }

  selectItem = (value: string, isCustom: boolean | null) => {
    let { maxItems = 1, values = [] } = this.props

    if (values.includes(value)) {
      values = [...values].filter((oldValue) => oldValue !== value)
      this.setState((prevState) => ({
        maxItemsSelected: false,
        customSelected: isCustom ? false : prevState.customSelected,
      }))
    } else {
      values.push(value)
      let { maxItemsSelected } = this.state
      if (values.length > maxItems) {
        if (maxItems === 1) {
          values.shift()
        } else {
          values.pop()
          maxItemsSelected = true
        }
      }
      this.setState((prevState) => ({
        maxItemsSelected,
        customSelected: isCustom ? true : prevState.customSelected,
      }))
    }
    this.selectionHandler(values)
  }

  selectionHandler = (values: string[], isMount: boolean = false): void => {
    if (this.props.selectionHandler === undefined) {
      return
    }
    this.props.selectionHandler(values, isMount)
  }

  onLabelClick = (label: string) => {
    if (this.props.onLabelClick === undefined) {
      return
    }
    this.props.onLabelClick(label)
  }

  formatVariables = (values: string[] = [], selectorItems: string[] = []) => {
    const newValues = Array.isArray(values)
      ? values.map((item) => toUpperCaseCustom(removeUnderScores(item)))
      : [values]
    const newSelectorItems = Array.isArray(selectorItems)
      ? selectorItems.map((item) => toUpperCaseCustom(removeUnderScores(item)))
      : []
    return { values: newValues, selectorItems: newSelectorItems }
  }

  identifyCustomValues = (values: string[] = [], selectorItems: string[] = []) => {
    let customValue = ''
    let customSelected = false

    values.forEach((value) => {
      if (value && !selectorItems.includes(value)) {
        customValue = value
        customSelected = true
      }
    })
    return { customValue, customSelected }
  }

  initialiseLineItems = (values: string[]) => {
    const { selectorItems } = this.state
    const { maxItemsPerRow = 3, clickableLabels, disabled, checkboxStyle } = this.props

    let lineItemsToRender: any[][] = [[]]
    let counter = 0
    let index = 0

    const generateCheckboxItems = (label: string, i: number, selectorItemUndefined?: boolean) => {
      let checked = false
      if (values) {
        values.forEach((value) => (toUpperCaseCustom(value) === toUpperCaseCustom(label) ? (checked = true) : false))
      }

      let labelStyle = this.props.labelStyle
      if (this.props.selectedLabelStyleAdjustment) {
        const style = this.props.selectedLabelStyleAdjustment(label) || {}
        if (!isEmpty(style)) {
          labelStyle = { ...labelStyle, ...style }
        }
      }

      const itemToRender = (
        <Checkbox
          key={`${label}_${i}`}
          checked={checked}
          onClick={() => this.selectItem(label, null)}
          label={label}
          clickableLabelEnabled={clickableLabels?.includes(label)}
          onLabelClick={() => this.onLabelClick(label)}
          disabled={disabled}
          style={{ marginLeft: '3%', marginRight: '3%' }}
          checkboxStyle={selectorItemUndefined ? { ...checkboxStyle, color: ColorPalette.WARNING_RED } : checkboxStyle}
          labelStyle={labelStyle}
          title={selectorItemUndefined ? 'This option is not defined in the field config' : undefined}
        />
      )
      if (counter === maxItemsPerRow) {
        lineItemsToRender = [...lineItemsToRender, [itemToRender]]
        counter = 1
        index += 1
      } else {
        lineItemsToRender[index] = [...lineItemsToRender[index], itemToRender]
        counter += 1
      }
    }
    const allValuesHaveOptionsDefinedInFieldConfig = values.every(
      (value) => value && selectorItems.includes(value.toUpperCase()),
    )
    selectorItems.forEach((label, index) => generateCheckboxItems(label, index))

    if (!allValuesHaveOptionsDefinedInFieldConfig) {
      let missingSelectorItems: string[] = []
      values.forEach((value) => {
        if (value && !selectorItems.includes(value)) {
          missingSelectorItems.push(value)
        }
      })
      missingSelectorItems.forEach((label, index) => generateCheckboxItems(label, index, true))
    }

    return lineItemsToRender
  }

  render() {
    const { maxItemsSelected } = this.state
    const values =
      Array.isArray(this.props.values) && Array.isArray(this.props.values[0])
        ? this.props.values[0]
        : ensureArrayFormat(this.props.values)
    const lineItemsToRender = this.initialiseLineItems(values)
    const selectorItemsToRender = lineItemsToRender.map((lineItem, j) => {
      return (
        <div key={j.toString()} style={{ ...styles.selectorLineItem, ...this.props.lineContainerStyle }}>
          {lineItem}
        </div>
      )
    })
    let warning = null
    if (maxItemsSelected) {
      warning = <p style={{ ...styles.warningText }}>Only {this.props.maxItems ? this.props.maxItems : 1} allowed</p>
    }

    return (
      <div style={{ ...styles.container, ...this.props.style }}>
        {warning}
        {selectorItemsToRender}
      </div>
    )
  }
}

const styles = {
  container: {
    padding: 10,
    paddingLeft: 0,
  },
  selectorLineItem: {
    display: 'flex',
    // flexDirection: 'row' as 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    paddingTop: 15,
    paddingBottom: 15,
  },
  warningText: {
    marginTop: 20,
    alignSelf: 'center',
    fontFamily: 'roboto',
    fontSize: '0.8rem',
    textAlign: 'center' as 'center',
    color: ColorPalette.WARNING_RED,
  },
}

export default CheckboxTab
