import { cloneDeep, get } from 'lodash'
import { IProcessConfig } from '../interfaces'
import { addUnderScores, toUpperCaseCustom } from './stringUtils'
import { AssociationSettingsRepository } from '../repositories'
import { FieldConfigItemSchema } from '../types'
import { getFieldConfigExclPpeItems } from './ppeUtils'

type ExtractionConfigItemKey = 'targetKey' | 'fallbackKey'
type ExtractionConfigItem = Record<ExtractionConfigItemKey, string>
type ExtractionConfig = ExtractionConfigItem[]

export enum CsvProcessIdentifier {
  BULK_TERMINATION = 'bulkTermination',
  PPE_INVENTORY = 'ppeStock',
}
export type CsvProcess = CsvProcessIdentifier.BULK_TERMINATION | CsvProcessIdentifier.PPE_INVENTORY | string
type CsvField = {
  fields: FieldConfigItemSchema[]
}
export type OrgConfigCsvIngest = Record<CsvProcess, CsvField>

export const processTypes = {
  adHocDocSign: 'adHocDocSign',
  events: 'eventConfig',
  competencies: 'competencies',
  generalEmployee: 'generalEmployee',
  generalCandidate: 'generalCandidate',
  discipline: 'discipline',
  employ: 'employ',
  promote: 'promote',
  ppeIssue: 'ppeIssue',
  ppeIssueRequest: 'ppeIssueRequest',
  ppeReturn: 'ppeReturn',
  ppeSizeSelection: 'ppeSizeSelection',
  recruit: 'recruit',
  transfer: 'transfer',
  terminate: 'terminate',
  viewData: 'viewData',
}

const generateDataFlowExtractionConfig = (
  sourceData: Record<string, any>,
  selectedProcessName: string | null,
  configType: string = 'dataFlowNames',
): Record<string, string>[] => {
  const {
    division = '',
    jobType = '',
    jobSubType = '',
    competencyName = '',
    action = '',
    offence = '',
    terminationReason = '',
    terminationSubReasons = '',
  } = sourceData

  const extractionConfigMap: Record<string, Record<string, string>[]> = {
    competencies: [
      { targetKey: division, fallbackKey: 'allDivisions' },
      { targetKey: jobType, fallbackKey: 'allJobTypes' },
      { targetKey: jobSubType, fallbackKey: 'allJobSubTypes' },
      { targetKey: competencyName, fallbackKey: 'allCompetencies' },
      { targetKey: configType },
    ],
    discipline: [
      { targetKey: division, fallbackKey: 'allDivisions' },
      { targetKey: jobType, fallbackKey: 'allJobTypes' },
      { targetKey: jobSubType, fallbackKey: 'allJobSubTypes' },
      { targetKey: offence, fallbackKey: 'allOffences' },
      { targetKey: action, fallbackKey: 'allActions' },
      { targetKey: configType },
    ],
    terminate: [
      { targetKey: division, fallbackKey: 'allDivisions' },
      { targetKey: jobType, fallbackKey: 'allJobTypes' },
      { targetKey: jobSubType, fallbackKey: 'allJobSubTypes' },
      { targetKey: terminationReason, fallbackKey: 'allTerminationReasons' },
      { targetKey: terminationSubReasons, fallbackKey: 'allTerminationSubReasons' },
      { targetKey: configType },
    ],
  }
  let extractionConfig: Record<string, string>[] = [
    { targetKey: division, fallbackKey: 'allDivisions' },
    { targetKey: jobType, fallbackKey: 'allJobTypes' },
    { targetKey: jobSubType, fallbackKey: 'allJobSubTypes' },
    { targetKey: configType },
  ]

  if (selectedProcessName != null && selectedProcessName in extractionConfigMap) {
    extractionConfig = extractionConfigMap[selectedProcessName]
  }
  return extractionConfig
}

export const getConfiguredProcessInfo = (
  configType: string,
  process: string,
  segmentInfo: any,
  processConfig: Record<string, IProcessConfig>,
) => {
  const processConfigTypeMap: Record<string, string> = {
    dataFlowNames: 'dataCaptureConfig',
    docNames: 'docCaptureConfig',
    actionFlow: 'offenceConfig',
    actionExpiry: 'offenceConfig',
  }
  const {
    division,
    jobType,
    jobSubType,
    reasonForTermination,
    subReasonForTermination,
    eventType,
    action,
    offence,
    competencyName,
  } = segmentInfo

  let extractionConfig: ExtractionConfig = [
    { targetKey: addUnderScores(division), fallbackKey: 'allDivisions' },
    { targetKey: addUnderScores(jobType), fallbackKey: 'allJobTypes' },
    { targetKey: addUnderScores(jobSubType), fallbackKey: 'allJobSubTypes' },
  ]
  if (process === processTypes.competencies) {
    extractionConfig = [
      ...extractionConfig,
      { targetKey: addUnderScores(competencyName), fallbackKey: 'allCompetencies' },
    ]
  }
  if (process === processTypes.terminate) {
    extractionConfig = [
      ...extractionConfig,
      { targetKey: addUnderScores(reasonForTermination), fallbackKey: 'allTerminationReasons' },
      { targetKey: addUnderScores(subReasonForTermination), fallbackKey: 'allTerminationSubReasons' },
    ]
  }
  if (process === processTypes.discipline) {
    if (processConfigTypeMap[configType] === 'offenceConfig') {
      extractionConfig = [{ targetKey: offence, fallbackKey: 'allOffences' }, ...extractionConfig]
    } else if (configType) {
      extractionConfig = [
        ...extractionConfig,
        { targetKey: offence, fallbackKey: 'allOffences' },
        { targetKey: action, fallbackKey: 'allActions' },
      ]
    }
  }
  if (process === processTypes.events) {
    extractionConfig = [{ targetKey: eventType, fallbackKey: 'allEventTypes' }]
  }
  extractionConfig.push({ targetKey: configType } as ExtractionConfigItem)
  return extractTargetConfig(
    (processConfig as Record<string, any>)[process][processConfigTypeMap[configType]],
    extractionConfig,
  )
}

export const extractTargetConfig = (sourceData: Record<string, any>, extractionConfig: ExtractionConfig) => {
  let searchString = ''
  let currentDataLevel = cloneDeep(sourceData)
  extractionConfig.forEach((configItem) => {
    const { targetKey, fallbackKey } = configItem
    const keyToAdd = targetKey && targetKey in currentDataLevel ? targetKey : fallbackKey
    if (searchString) {
      searchString = `${searchString}${keyToAdd ? `["${keyToAdd}"]` : ''}`
    } else {
      searchString = `${keyToAdd ? `["${keyToAdd}"]` : ''}`
    }
    currentDataLevel = get(sourceData, searchString, {})
  })
  return currentDataLevel
}

export function getFieldKeyFromLabel(fieldConfig: Record<string, FieldConfigItemSchema>, label: string): string {
  const allKeys = Object.keys(fieldConfig)
  let targetKey = ''
  for (const key of allKeys) {
    if (
      fieldConfig[key].label &&
      label &&
      toUpperCaseCustom(fieldConfig[key].label.split(' ').join('')) === toUpperCaseCustom(label.split(' ').join(''))
    ) {
      targetKey = key
      break
    }
  }
  return targetKey
}

export function getFieldLabelFromKey(
  fieldConfig: Record<string, FieldConfigItemSchema>,
  key: string,
  fallbackOnKey?: boolean,
): string {
  if (fallbackOnKey) {
    return fieldConfig.hasOwnProperty(key) && fieldConfig[key].label ? fieldConfig[key].label : key.toUpperCase()
  }

  return fieldConfig[key].label
}

export const getJobSubTypesFromConfig = (
  selectedDivision: string,
  selectedJob: string,
  organisationConfig: Record<string, any>,
) => {
  const jobTypes = organisationConfig.divisionConfig.config[selectedDivision].jobTypes
  const jobSubTypes = jobTypes.hasOwnProperty(selectedJob) ? Object.keys(jobTypes[selectedJob].jobSubTypes) : []

  return {
    jobSubTypes,
  }
}

export const getCompetenciesFromConfig = (
  // TODO v2: Look at using/modify/expanding the 'extractTargetConfig' method from 'src/utils/config.utils' to manage the drilling down through the config.
  selectedDivision: string,
  selectedJob: string,
  processConfig: Record<string, any>,
) => {
  const competencyConfig = processConfig?.competenciesv2?.competencyConfig
  const divisionSpecificCompetencyConfig = competencyConfig.hasOwnProperty(selectedDivision)
    ? competencyConfig[selectedDivision]
    : competencyConfig.allDivisions
  const jobSpecificCompetencyConfig = divisionSpecificCompetencyConfig.hasOwnProperty(selectedJob)
    ? divisionSpecificCompetencyConfig[selectedJob]
    : divisionSpecificCompetencyConfig.allJobTypes
  const jobSpecificRequiredCompentencies = jobSpecificCompetencyConfig
    ? jobSpecificCompetencyConfig.allJobSubTypes.requiredCompetencies
    : []

  const dataCaptureConfig = processConfig?.competenciesv2.dataCaptureConfig
  const divisionSpecificDataCaptureConfig = dataCaptureConfig.hasOwnProperty(selectedDivision)
    ? dataCaptureConfig[selectedDivision]
    : dataCaptureConfig.allDivisions
  const jobSpecificDataCaptureConfig = divisionSpecificDataCaptureConfig.hasOwnProperty(selectedJob)
    ? divisionSpecificDataCaptureConfig[selectedJob]
    : divisionSpecificDataCaptureConfig.allJobTypes
  const jobSpecificDataFlows = jobSpecificDataCaptureConfig
    ? jobSpecificDataCaptureConfig.allJobSubTypes.allCompetencies.dataFlowNames
    : []

  const useProcessFlow = processConfig?.competenciesv2?.useProcessFlow

  const competencySnapshot = {
    requiredCompetencies: jobSpecificRequiredCompentencies,
    dataFlowNames: jobSpecificDataFlows,
    useProcessFlow: !!useProcessFlow,
  }
  return {
    ...competencySnapshot,
  }
}

export const getTerminateFromConfig = (
  selectedDivision: string,
  selectedJob: string,
  processConfig: Record<string, any>,
) => {
  const docCaptureConfig = processConfig?.terminate.docCaptureConfig
  const dataCaptureConfig = processConfig?.terminate.dataCaptureConfig

  const divisionSpecificDocCaptureConfig = docCaptureConfig.hasOwnProperty(selectedDivision)
    ? docCaptureConfig[selectedDivision]
    : docCaptureConfig.allDivisions
  const jobSpecificDocCaptureConfig = divisionSpecificDocCaptureConfig.hasOwnProperty(selectedJob)
    ? divisionSpecificDocCaptureConfig[selectedJob]
    : divisionSpecificDocCaptureConfig.allJobTypes
  const jobSpecificDocs = jobSpecificDocCaptureConfig
    ? jobSpecificDocCaptureConfig.allJobSubTypes.allTerminationReasons.allTerminationSubReasons.docNames
    : undefined

  const divisionSpecificDataCaptureConfig = dataCaptureConfig.hasOwnProperty(selectedDivision)
    ? dataCaptureConfig[selectedDivision]
    : dataCaptureConfig.allDivisions
  const jobSpecificDataCaptureConfig = divisionSpecificDataCaptureConfig.hasOwnProperty(selectedJob)
    ? divisionSpecificDataCaptureConfig[selectedJob]
    : divisionSpecificDataCaptureConfig.allJobTypes
  const jobSpecificDataFlows = jobSpecificDataCaptureConfig
    ? jobSpecificDataCaptureConfig.allJobSubTypes.allTerminationReasons.allTerminationSubReasons.dataFlowNames
    : undefined

  const useProcessFlow = processConfig?.terminate?.useProcessFlow
  const terminateSnapshot = {
    // reasons: getTerminateReasons(processConfig),
    docNames: jobSpecificDocs,
    dataFlowNames: jobSpecificDataFlows,
    useProcessFlow: !!useProcessFlow,
  }
  return {
    ...terminateSnapshot,
  }
}

export const getDisciplineFromConfig = (
  selectedDivision: string,
  selectedJob: string,
  processConfig: Record<string, any>,
) => {
  const docCaptureConfig = processConfig?.discipline.docCaptureConfig
  const dataCaptureConfig = processConfig?.discipline.dataCaptureConfig

  const divisionSpecificDocCaptureConfig = docCaptureConfig.hasOwnProperty(selectedDivision)
    ? docCaptureConfig[selectedDivision]
    : docCaptureConfig.allDivisions
  const jobSpecificDocCaptureConfig = divisionSpecificDocCaptureConfig.hasOwnProperty(selectedJob)
    ? divisionSpecificDocCaptureConfig[selectedJob]
    : divisionSpecificDocCaptureConfig.allJobTypes
  let allOffences = jobSpecificDocCaptureConfig ? jobSpecificDocCaptureConfig.allJobSubTypes.allOffences : undefined

  let jobSpecificDocs = ['']
  let allOffencesObjKeys = Object.keys(allOffences)
  if (allOffencesObjKeys.length > 1) {
    allOffencesObjKeys.forEach((key) => {
      jobSpecificDocs = [...jobSpecificDocs, ...allOffences[key].docNames]
    })
  }

  const divisionSpecificDataCaptureConfig = dataCaptureConfig.hasOwnProperty(selectedDivision)
    ? dataCaptureConfig[selectedDivision]
    : dataCaptureConfig.allDivisions
  const jobSpecificDataCaptureConfig = divisionSpecificDataCaptureConfig.hasOwnProperty(selectedJob)
    ? divisionSpecificDataCaptureConfig[selectedJob]
    : divisionSpecificDataCaptureConfig.allJobTypes
  allOffences = jobSpecificDataCaptureConfig ? jobSpecificDataCaptureConfig.allJobSubTypes.allOffences : undefined
  let jobSpecificDataFlows = ['']
  allOffencesObjKeys = Object.keys(allOffences)
  if (allOffencesObjKeys.length > 1) {
    // assuming allActions will at least exist
    allOffencesObjKeys.forEach((key) => {
      // if (key !== "allActions") {
      jobSpecificDataFlows = [...jobSpecificDataFlows, ...allOffences[key].dataFlowNames]
      // }
    })
  }

  const useProcessFlow = processConfig?.discipline?.useProcessFlow
  const disciplineSnapshot = {
    docNames: jobSpecificDocs,
    dataFlowNames: jobSpecificDataFlows,
    useProcessFlow: !!useProcessFlow,
  }
  return {
    ...disciplineSnapshot,
  }
}

const getDocNames = (docCaptureConfig: any, selectedDivision: string, selectedJob: string) => {
  const divisionSpecificDocCaptureConfig = docCaptureConfig.hasOwnProperty(selectedDivision)
    ? docCaptureConfig[selectedDivision]
    : docCaptureConfig.allDivisions
  const jobSpecificDocCaptureConfig = divisionSpecificDocCaptureConfig.hasOwnProperty(selectedJob)
    ? divisionSpecificDocCaptureConfig[selectedJob]
    : divisionSpecificDocCaptureConfig.allJobTypes
  const jobSpecificDocs = jobSpecificDocCaptureConfig ? jobSpecificDocCaptureConfig.allJobSubTypes.docNames : undefined

  return jobSpecificDocs
}

const getDataFlowNames = (dataCaptureConfig: any, selectedDivision: string, selectedJob: string) => {
  const divisionSpecificDataCaptureConfig = dataCaptureConfig.hasOwnProperty(selectedDivision)
    ? dataCaptureConfig[selectedDivision]
    : dataCaptureConfig.allDivisions
  const jobSpecificDataCaptureConfig = divisionSpecificDataCaptureConfig.hasOwnProperty(selectedJob)
    ? divisionSpecificDataCaptureConfig[selectedJob]
    : divisionSpecificDataCaptureConfig.allJobTypes
  const jobSpecificDataFlows = jobSpecificDataCaptureConfig
    ? jobSpecificDataCaptureConfig.allJobSubTypes.dataFlowNames
    : undefined

  return jobSpecificDataFlows
}

export const getCoreInfoFromConfig = (
  sectionName: string,
  selectedDivision: string,
  selectedJob: string,
  //selectedJobSubType: string,  // TODO
  processConfig: Record<string, any>,
) => {
  const docCaptureConfig = processConfig[sectionName].docCaptureConfig
  const dataCaptureConfig = processConfig[sectionName].dataCaptureConfig
  const useProcessFlow = processConfig[sectionName].useProcessFlow

  const jobSpecificDocs = getDocNames(docCaptureConfig, selectedDivision, selectedJob)
  const jobSpecificDataFlows = getDataFlowNames(dataCaptureConfig, selectedDivision, selectedJob)

  const snapshot = {
    docNames: jobSpecificDocs,
    dataFlowNames: jobSpecificDataFlows,
    useProcessFlow: !!useProcessFlow,
  }
  return {
    ...snapshot,
  }
}

export const getSnapshotFromConfig = (
  sectionName: string,
  selectedAssociation: string,
  selectedDivision: string,
  selectedJob: string,
  associationRepo: AssociationSettingsRepository,
  excludeDocsAndFlows: boolean = false,
) => {
  const processConfig: Record<string, IProcessConfig> = associationRepo.getProcessConfig(selectedAssociation)

  if (sectionName === 'jobSubTypes') {
    const organisationConfig = associationRepo.getOrganisationConfig(selectedAssociation)
    return getJobSubTypesFromConfig(selectedDivision, selectedJob, organisationConfig)
  }

  if (sectionName === 'competencies') {
    return getCompetenciesFromConfig(selectedDivision, selectedJob, processConfig)
  }

  if (sectionName === 'discipline') {
    const snapshot = getDisciplineFromConfig(selectedDivision, selectedJob, processConfig)
    if (excludeDocsAndFlows) {
      const { docNames, dataFlowNames, ...snapshotMinusDocsAndFlows } = snapshot
      return snapshotMinusDocsAndFlows
    }
    return snapshot
  }

  if (sectionName === 'terminate') {
    const snapshot = getTerminateFromConfig(selectedDivision, selectedJob, processConfig)
    if (excludeDocsAndFlows) {
      const { docNames, dataFlowNames, ...snapshotMinusDocsAndFlows } = snapshot
      return snapshotMinusDocsAndFlows
    }
    return snapshot
  }

  if (['employ', 'promote', 'transfer', 'generalCandidate', 'generalEmployee'].indexOf(sectionName) > -1) {
    const snapshot = getCoreInfoFromConfig(sectionName, selectedDivision, selectedJob, processConfig)
    if (excludeDocsAndFlows) {
      const { docNames, dataFlowNames, ...snapshotMinusDocsAndFlows } = snapshot
      return snapshotMinusDocsAndFlows
    }
    return snapshot
  }

  return {}
}

export const getDivisionJobTypeJobSubType = (
  offenceConfig: any,
  division: string,
  jobType: string,
  jobSubType: string,
  createNodes: boolean = false, // TODO: explain createNodes usage
) => {
  if (createNodes) {
    const divisionNode = division || 'allDivisions'
    const jobTypeNode = jobType || 'allJobTypes'
    const jobSubTypeNode = jobSubType || 'allJobSubTypes'

    return {
      divisionNode,
      jobTypeNode,
      jobSubTypeNode,
    }
  }

  const divisionNode = division && offenceConfig.hasOwnProperty(division) ? division : 'allDivisions'
  const jobTypeNode = jobType && offenceConfig[divisionNode].hasOwnProperty(jobType) ? jobType : 'allJobTypes'
  const jobSubTypeNode =
    jobSubType && offenceConfig[divisionNode][jobTypeNode].hasOwnProperty(jobSubType) ? jobSubType : 'allJobSubTypes'

  return {
    divisionNode,
    jobTypeNode,
    jobSubTypeNode,
  }
}

export const getOffenceEndNodeValue = (
  offenceConfig: any,
  division: string,
  jobType: string,
  jobSubType: string,
  endNodeName: string,
) => {
  // TODO: use getConfiguredProcessInfo instead of getDivisionJobTypeJobSubType
  const { divisionNode, jobTypeNode, jobSubTypeNode } = getDivisionJobTypeJobSubType(
    offenceConfig,
    division,
    jobType,
    jobSubType,
  )

  return offenceConfig &&
    offenceConfig.hasOwnProperty(divisionNode) &&
    offenceConfig[divisionNode].hasOwnProperty(jobTypeNode) &&
    offenceConfig[divisionNode][jobTypeNode].hasOwnProperty(jobSubTypeNode) &&
    offenceConfig[divisionNode][jobTypeNode][jobSubTypeNode].hasOwnProperty(endNodeName)
    ? offenceConfig[divisionNode][jobTypeNode][jobSubTypeNode][endNodeName]
    : []
}

// export const getCsvFieldHeaderNames = (orgConfig: any, csvProcessName: string, fallbackValues?: string[]) => {
//   const headerFieldNames: string[] = []
//   if (orgConfig) {
//     const csvFileIngest: OrgConfigCsvIngest = orgConfig.csvFileIngest
//     if (csvFileIngest && csvFileIngest[csvProcessName]) {
//       const fields = csvFileIngest[csvProcessName].fields
//       fields.forEach((value: FieldConfigItemSchema) => {
//         headerFieldNames.push(value.label)
//       })
//     }
//   }

//   if (headerFieldNames.length === 0 && fallbackValues) {
//     headerFieldNames.push(...fallbackValues)
//   }

//   return headerFieldNames
// }

// export const getCsvFieldHeaderKeyByKey = (
//   orgConfig: any,
//   csvProcessName: string,
//   fieldKey: string,
//   fallbackKey?: string,
// ) => {
//   let headerKey = fieldKey
//   if (orgConfig) {
//     const csvFileIngest: OrgConfigCsvIngest = orgConfig.csvFileIngest
//     if (csvFileIngest && csvFileIngest[csvProcessName]) {
//       const fields = csvFileIngest[csvProcessName].fields
//       fields.forEach((obj: FieldConfigItemSchema) => {
//         const fieldKeyNoSpaces = fieldKey.replace(/\s/g, '')
//         const keyNoSpaces = obj.key.replace(/\s/g, '')
//         if (keyNoSpaces.toLowerCase() === fieldKeyNoSpaces.toLowerCase()) {
//           headerKey = obj.key
//         }
//       })
//     }
//   }

//   if (!headerKey && fallbackKey) {
//     headerKey = fallbackKey
//   }

//   return headerKey
// }

export const getCsvFieldHeaderKeyByName = (
  orgConfig: any,
  csvProcessName: string,
  fieldName: string,
  fallbackKey?: string,
) => {
  let headerKey = ''
  if (orgConfig) {
    const csvFileIngest: OrgConfigCsvIngest = orgConfig.csvFileIngest
    if (csvFileIngest && csvFileIngest[csvProcessName]) {
      const fields = csvFileIngest[csvProcessName].fields
      fields.forEach((obj: FieldConfigItemSchema) => {
        const fieldNameNoSpaces = fieldName.replace(/\s/g, '')
        const labelNoSpaces = obj.label.replace(/\s/g, '')
        if (labelNoSpaces.toLowerCase() === fieldNameNoSpaces.toLowerCase()) {
          headerKey = obj.key
        }
      })
    }
  }

  if (!headerKey && fallbackKey) {
    headerKey = fallbackKey
  }

  return headerKey
}

export const getCurrentFieldConfigSnapshot = (
  associationRepo: AssociationSettingsRepository,
  selectedAssociation: string,
  selectedField: string,
) => {
  const fieldConfig = getFieldConfigExclPpeItems(associationRepo, selectedAssociation)
  const fieldDetails: any = selectedField ? fieldConfig[selectedField] : {}
  let fieldDetailsSnapshot: FieldConfigItemSchema | {} = {}

  let selectorItems = undefined
  if (selectedField && fieldConfig[selectedField]) {
    if (
      selectedField &&
      ['selector', 'picker', 'itemSelector'].indexOf(fieldConfig[selectedField].compType) > -1 &&
      fieldConfig[selectedField].hasOwnProperty('selectorItems') &&
      // @ts-ignore
      fieldConfig[selectedField].selectorItems.length > 0
    ) {
      selectorItems = fieldConfig[selectedField].selectorItems
    }

    let conditionalSelectorItems = undefined
    if (
      selectedField &&
      fieldConfig[selectedField].compType === 'conditionalSelector' &&
      fieldConfig[selectedField].hasOwnProperty('conditionalSelectorItems')
    ) {
      conditionalSelectorItems = { ...fieldConfig[selectedField].conditionalSelectorItems }
    }

    fieldDetailsSnapshot = {
      key: fieldDetails.key,
      compType: fieldDetails.compType,
      compulsory: fieldDetails.compulsory,
      allowCustomOption: fieldDetails.allowCustomOption,
      dateFormat: fieldDetails.dateFormat,
      multiLine: fieldDetails.multiLine,
      suggestionsEnabled: fieldDetails.suggestionsEnabled,
      useDefaultValue: fieldDetails.useDefaultValue,
      defaultValue: fieldDetails.defaultValue,
      setDefaultValueFromHistory: fieldDetails.setDefaultValueFromHistory,
      label: fieldDetails.label,
      disabled: fieldDetails.disabled,
      maxItems: fieldDetails.maxItems,
      autoPopulate: fieldDetails.autoPopulate,
      selectorItems,
      conditionalSelectorItems,
      singleSelectionMode: fieldDetails.singleSelectionMode,
    }
  }
  return fieldDetailsSnapshot
}
