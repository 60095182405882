export const requestConfig = {
  labourRequisition: {
    name: 'Labour Requisition',
    key: 'labourRequisition',
    description: 'foo bar baz',
    author: 'Andrew',
    createdUnixMs: '12345321',
    labourRequisitionConfig: {
      formUpload: {
        requiredForms: 1,
        label: 'Form Upload',
      },
      auth: {
        permittedRole: 'ADMIN',
        requiredAuths: 1,
        label: 'Authorisation',
      },

      marshalForms: {
        stampLocation: {
          unit: 'percent',
          fromLeft: 95,
          fromBottom: 14,
        },
        label: 'Marshal Forms',
      },
      email: {
        label: 'Email',
        defaultEmails: ['andrew@worklight.co.za'],
      },
    },
    stepOrder: [
      {
        index: 3,
        stepName: 'email',
      },
      {
        index: 1,
        stepName: 'formUpload',
      },
      {
        index: 2,
        stepName: 'auth',
      },
    ],
  },
}

export const requestInstances = [
  {
    id: '493031cd-ac9d-408b-b9ab-b2cad3187ca1',
    createdUnixMs: '1654071947985',
    // Specify status label //
    status: 'OPEN',
    //

    requestConfig: {
      name: 'Labour Requisition',
      key: 'labourRequisition',
      description: 'foo bar baz',
      author: 'Andrew',
      createdUnixMs: '12345321',
      labourRequisitionConfig: {
        formUpload: {
          requiredForms: 1,
          label: 'Form Upload',
        },
        auth: {
          permittedRole: 'ADMIN',
          requiredAuths: 1,
          label: 'Authorisation',
        },
        marshalForms: {
          stampLocation: {
            unit: 'percent',
            fromLeft: 95,
            fromBottom: 14,
          },
          label: 'Marshal Forms',
        },
        email: {
          label: 'Email',
          defaultEmails: ['andrew@worklight.co.za'],
        },
      },
      // Move step order inside here to match config template //
      stepOrder: [
        {
          index: 3,
          stepName: 'email',
        },
        {
          index: 1,
          stepName: 'formUpload',
        },
        {
          index: 2,
          stepName: 'auth',
        },
      ],
      // //
    },
    steps: {
      formUpload: {
        forms: [
          {
            fileType: '',
            name: 'LR_something',
            url: 's3://peopleflow-bucket/requests/ 432324/something.pdf',
          },
        ],
        active: false,
        completed: true,
        // Add this
        completedByIdPassport: '',
        //
      },
      auth: {
        auths: [
          {
            name: 'andrew',
            // Specify this label //
            choice: 'declined',
            //

            // Add this //
            idPassport: '123456',
            //
          },
          {
            name: 'rich',
            // Specify this label//
            choice: 'authed',
            //
            idPassport: '9211155023082',
          },
        ],
        active: false,
        completed: true,
      },
      email: {
        defaultEmails: ['richard@peopleflow.co.za'],
        // Add this //
        sentTo: ['richard@peopleflow.co.za', 'test@test.com'],
        //
        active: true,
        completed: false,
      },
    },
    logs: [
      {
        unixMs: '1654071947985',
        message: 'Request created',
      },
      {
        unixMs: '1654071947985',
        message: "Form 'LR_something' with url 's3://peopleflow-bucket/requests/432324/something.pdf added",
      },
      {
        unixMs: '1654071947985',
        message: "Authorizer 'andrew' with role 'ADMIN' added",
      },
      {
        unixMs: '1654071947985',
        message: "Authorizer 'rich' with role 'ADMIN' added",
      },
    ],
  },
]
