import { Auth } from 'aws-amplify'
import { CognitoUser } from '@aws-amplify/auth'
import _ from 'lodash'

import { checkPasswordFormat, getErrorMessage, validateForgotPasswordInputs } from '../utils'
import { LocalStorageProvider, fetchSystemConfig } from '../providers'
import {
  AssociationSettingsRepository,
  ProfileRepository,
  UsersRepository,
  MasterSettingsRepository,
} from '../repositories'
import { GeneralErrorCodesEnum, SessionErrorCodesEnum } from '../enums/errorCodeTypes'
import { AssociationId, AuthCredentials, CohortName } from '../types'
import { IamService } from './iamService'
import { lastSelectedAssocationIdentifier, lastSelectedCohortIdentifier } from '../config/localStorage'
import { Iam, IamSchema } from '../models'
const { NewPasswordRequired } = SessionErrorCodesEnum
const { NetworkTimeout } = GeneralErrorCodesEnum

export class SessionService {
  async login(username: string, password: string, iamService: IamService) {
    try {
      if (username.length === 0 || password.length === 0) {
        throw { code: GeneralErrorCodesEnum.MissingInfo }
      }
      const user = await Auth.signIn({ username: username, password })
      if (user.challengeName === NewPasswordRequired) {
        throw { code: NewPasswordRequired, user }
      }
      const token = await SessionService.prepareAuthTokens(username, password)
      const { userProfile, clientSettings, masterSettings } = await fetchSystemConfig(username, [], token)
      const masterSettingsRepo = new MasterSettingsRepository()
      await masterSettingsRepo.initialise(masterSettings)

      const userRepo = new UsersRepository(userProfile)
      const allAssociationIamSettings = this.extractIamSettings(clientSettings)
      userRepo.setIamPermissions(username, allAssociationIamSettings)

      const associationRepo = new AssociationSettingsRepository()
      await associationRepo.initialise() // Need to initialise from local storage in order to check which associations have settings cached
      const associationsNoCachedSettings = associationRepo.findAssociationsWithoutSettings(Object.keys(clientSettings))
      const newAssociationSettingsJson = await this.hydrateAssociationSettings(associationsNoCachedSettings, {
        username,
        password,
      })
      await associationRepo.initialise(newAssociationSettingsJson)
      const { selectedAssociation, selectedCohort } = await this.getAppLaunchSettings(
        iamService,
        userRepo.getCurrentUserEntity().getAllAssocationIamEntities(),
        associationRepo,
      )

      const currentAssociationSettingsJson = await this.hydrateAssociationSettings([selectedAssociation], {
        username,
        password,
      })
      await associationRepo.initialise(currentAssociationSettingsJson)

      const profileRepo = new ProfileRepository()
      await profileRepo.loadLocalProfileEntities(selectedAssociation)
      return {
        masterSettingsRepo,
        userRepo,
        associationRepo,
        profileRepo,
        selectedAssociation,
        selectedCohort,
      }
    } catch (error) {
      if (getErrorMessage(error) === 'timeout of 0ms exceeded') {
        throw { code: NetworkTimeout }
      }
      throw error
    }
  }

  extractIamSettings(allAssociationSettings: Record<AssociationId, Record<string, any>>) {
    const getStopGapResourceBasedPolicy = (associationSettings: Record<string, any>) => {
      // TODO: This is a temp solution that must be replaced once a resource-based-policy is properly implemented.
      return _.get(associationSettings, 'organisationConfig.stopGapResourceBasedPolicy', {})
    }
    let iamSettings = {} as Record<AssociationId, IamSchema>
    Object.keys(allAssociationSettings).forEach((association) => {
      const stopGapResourceBasedPolicy = getStopGapResourceBasedPolicy(allAssociationSettings[association])
      iamSettings[association] = {
        ...allAssociationSettings[association].iam,
        stopGapResourceBasedPolicy,
      }
    })
    return iamSettings
  }

  async getAppLaunchSettings(
    iamService: IamService,
    allAssociationIamSettings: Record<AssociationId, Iam>,
    associationRepo: AssociationSettingsRepository,
  ) {
    const lastSelectedAssociation = await LocalStorageProvider.getData(lastSelectedAssocationIdentifier)
    const lastSelectedCohortName = await LocalStorageProvider.getData(lastSelectedCohortIdentifier)

    const { association, cohort } = iamService.identifyLoginAssociationCohort(
      lastSelectedAssociation,
      lastSelectedCohortName,
      allAssociationIamSettings,
      associationRepo,
    )
    return {
      selectedAssociation: association,
      selectedCohort: cohort,
    }
  }

  async saveAppLaunchSettings(assocation: AssociationId, cohort: CohortName) {
    await LocalStorageProvider.setData(lastSelectedAssocationIdentifier, assocation)
    await LocalStorageProvider.setData(lastSelectedCohortIdentifier, cohort)
  }

  async hydrateAssociationSettings(associationNames: AssociationId[], authCredentials: AuthCredentials) {
    const { username, password } = authCredentials
    const token = await SessionService.prepareAuthTokens(username, password)
    let hydratedClientSettings = {}
    await Promise.all(
      associationNames.map(async (associationName) => {
        const associationConfig = await fetchSystemConfig(username, [associationName], token)
        hydratedClientSettings = {
          ...hydratedClientSettings,
          [associationName]: associationConfig.clientSettings[associationName],
        }
      }),
    )
    return hydratedClientSettings
  }

  static async prepareAuthTokens(username: string, password: string) {
    try {
      const session = await Auth.currentSession()
      return session.getIdToken().getJwtToken()
    } catch (error) {
      console.error(`prepareAuthTokens username: ${username}`, error)
      try {
        const user = await Auth.signIn({ username, password })
        return user.signInUserSession.idToken.jwtToken
      } catch (err) {
        throw err
      }
    }
  }

  async forgotPassword(idPassport: string): Promise<any> {
    if (!idPassport.length) {
      throw { code: GeneralErrorCodesEnum.MissingInfo }
    }
    return await Auth.forgotPassword(idPassport)
  }

  static submitChangedPassword(idPassport: string, otp: string, newPassword: string = ''): Promise<any> {
    const validationResult = validateForgotPasswordInputs(otp, newPassword)
    if (validationResult.result !== 'success') {
      return new Promise((res, rej) => rej(validationResult))
    }
    return Auth.forgotPasswordSubmit(idPassport, otp, newPassword)
  }

  async submitNewPassword(awsUser?: CognitoUser, newPassword?: string, confirmNewPassword?: string): Promise<void> {
    if (!newPassword || !confirmNewPassword) {
      throw { code: GeneralErrorCodesEnum.NoInput }
    } else if (newPassword !== confirmNewPassword) {
      throw { code: GeneralErrorCodesEnum.PasswordMismatch }
    } else if (!checkPasswordFormat(newPassword)) {
      throw { code: GeneralErrorCodesEnum.InvalidPassword }
    }
    await Auth.completeNewPassword(awsUser, newPassword, {})
  }

  async logout(): Promise<void> {
    try {
      await Auth.signOut()
      localStorage.clear()
    } catch (err: any) {
      throw err
    }
  }
}
