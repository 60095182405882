import { useState } from 'react'
import Radium from 'radium'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { mdiAlphaQCircleOutline } from '@mdi/js'

import { ColorPalette } from '../../config/colors'
import { removeUnderScores, toUpperCaseCustom } from '../../utils'
import TextInputOutlined from '../BaseComponents/Text/TextInputOutlined'
import { SideMenuOptions } from './SideMenuOptions'
import { SideMenuTitle } from './SideMenuTitle'

dayjs.extend(relativeTime)

interface PPEConfigSideMenuProps {
  itemNames: string[]
  selectedItemName: string
  onItemAddClick: () => void
  onItemSelection: (item: string) => void
}

const PPEConfigSideMenu = (props: PPEConfigSideMenuProps) => {
  const [searchString, setSearchString] = useState('')

  const searchHandler = (e: any) => {
    setSearchString(toUpperCaseCustom(e.target.value))
  }

  const getOptionStyle = (itemName: string) => {
    return {
      fontSize: 'smaller',
      maxWidth: '100%',
      backgroundColor: selectedItemName === itemName ? ColorPalette.BUTTON_ACTIVE_GREY : ColorPalette.OFF_WHITE_LIGHT,
    }
  }

  const { selectedItemName, onItemAddClick, onItemSelection } = props

  let items = [...props.itemNames].sort()
  // let items = {
  //   Boots: ['B001', 'B002'],
  //   'N95 Mask': ['N001', 'N002'],
  // }
  if (searchString.length) {
    items = items.filter((flow) => {
      return flow.toUpperCase().includes(searchString)
    })
  }

  return (
    <div style={styles.container}>
      <SideMenuTitle>PPE Items</SideMenuTitle>
      <TextInputOutlined
        style={styles.textStyle}
        label="ITEM SEARCH"
        placeholder="Search for item"
        value={searchString}
        textHandler={searchHandler}
        disabled={false}
      />
      <SideMenuOptions
        addButtonLabel="Add item"
        addButtonClick={onItemAddClick}
        addButtonIconStyle={styles.button}
        options={items}
        optionLabel={(queryName: string) => removeUnderScores(queryName)}
        optionClick={onItemSelection}
        optionButtonLabelStyle={{ width: '100%' }}
        optionMdiIconPath={mdiAlphaQCircleOutline}
        optionStyle={getOptionStyle}
        // expandableOptionData={items}
      />
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    width: '100%',
    overflow: 'auto',
  },
  textStyle: {
    color: ColorPalette.PRIMARY_TEXT,
    padding: '0px 12px 10px 12px',
    marginTop: 20,
    minWidth: 120,
    width: '80%',
  },
  button: {
    width: '1.5rem',
    height: '1.5rem',
    marginRight: 15,
    marginLeft: -2,
  },
}

export default Radium(PPEConfigSideMenu)
