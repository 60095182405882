import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import Icon from '@mdi/react'
import {
  mdiExport,
  mdiPackageVariantClosedMinus,
  mdiPackageVariantClosedPlus,
  mdiTruckFast,
  mdiPlaylistPlus,
  mdiPlaylistMinus,
} from '@mdi/js'

import NavBar from '../../components/Navigation/NavBar'
import SectionHeaderPrimary from '../../components/Headings/SectionHeaderPrimary'
import SideMenu from '../../components/Navigation/SideMenu'
import { Toolbar, ActionButtonType } from '../../components/GeneralUI/Toolbar'
import RouteLeavingGuard from '../../components/Navigation/RouteLeavingGuard'
import LoadingModal from '../../components/Modals/LoadingModal'
import AlertModalTwoButton from '../../components/Modals/AlertModalTwoButton'
import { ColorPalette } from '../../config/colors'
import { PeopleFlowCombinedReducer } from '../../store'
import PpeStockSideMenu from '../../components/SideMenus/PpeStockSideMenu'
import AlertModalOneButton from '../../components/Modals/AlertModalOneButton'
import { ConfigService, PpeService } from '../../services'
import CsvFileProcessingModal from '../../components/Modals/CsvFileProcessingModal'
import {
  checkEmailFormat,
  toLowerCaseCustom,
  toUpperCaseCustom,
  removeUnderScores,
  groupObjectsByKey,
  addUnderScores,
} from '../../utils'
import { AssociationSettingsRepository, UsersRepository } from '../../repositories'
import {
  PpeTypeConfig,
  PpeInventoryTransferTableRowItem,
  ToolbarNavButtons,
  PpeInventoryViewModes,
  SystemFeaturesEnum,
  FieldConfigKey,
  PpeSku,
  PpeSize,
  PpeQuantity,
  AssociationId,
} from '../../types'
import InfoCollectorModal from '../../components/Modals/InfoCollector'
import { IColumnConfig } from '../../components/Tables/DataTable/DataTableHeader'
import DataTable from '../../components/Tables/DataTable/DataTable'
import { NewPpeInstanceParams, PpeAvailability, PpeInstanceRecordSchema } from '../../models/ppe'
import LabelCollector from '../../components/Modals/LabelCollector'

type PpeStockProps = {
  match: any
  location: any
  history: any
}

const { INVENTORY_ADDITION, INVENTORY_TRANSFER, INVENTORY_REMOVAL, INVENTORY_LIST } = PpeInventoryViewModes
const ppeService = new PpeService()
const configService = new ConfigService()
enum LabelCollectionMode {
  Location = 'Location',
  Reason = 'Reason',
}

enum CsvColumnConfigEnum {
  sku = 'SKU',
  ppeSize = 'Size',
  qty = 'Quantity',
  purchaseOrderId = 'Purchase Order No',
  supplier = 'Supplier',
}
let listTableColumnConfig: IColumnConfig[] = [
  { id: 'sku', label: 'SKU', sizeFactor: 1 },
  { id: 'total', label: 'Total', sizeFactor: 1 },
  { id: 'qtyReserved', label: 'Reserved', sizeFactor: 1 },
  { id: 'available', label: 'Available', sizeFactor: 1 },
]
const importTableColumnConfig: IColumnConfig[] = [
  { id: 'label', label: 'Item', sizeFactor: 1 },
  { id: 'sku', label: CsvColumnConfigEnum.sku, sizeFactor: 1 },
  { id: 'ppeSize', label: CsvColumnConfigEnum.ppeSize, sizeFactor: 1 },
  { id: 'qty', label: CsvColumnConfigEnum.qty, sizeFactor: 1 },
  { id: 'purchaseOrderId', label: CsvColumnConfigEnum.purchaseOrderId, sizeFactor: 1 },
  // { id: 'supplier', label: CsvColumnConfigEnum.supplier, sizeFactor: 1 },
]
const removalTableColumnConfig: IColumnConfig[] = [
  { id: 'label', label: 'Item', sizeFactor: 1 },
  { id: 'ppeSize', label: 'Size', sizeFactor: 1 },
  { id: 'availableToTransfer', label: 'Available to transfer', sizeFactor: 1 },
  { id: 'noOfUnits', label: 'Quantity to transfer', sizeFactor: 1 },
]
const transferLabelSeparator = ' - '

export const PpeStock = (props: PpeStockProps) => {
  const idPassport = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.idPassport)
  const password = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.password)
  const selectedAssociation = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation,
  )
  const userRepo = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.userRepo) as UsersRepository
  const associationRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.associationRepo,
  ) as AssociationSettingsRepository
  const navMenuAccess = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.navMenuAccess)

  const [selectedTypeLabel, setSideMenuSelection] = useState('All')
  const [viewMode, setViewMode] = useState('')
  const [allPpeTypeConfigs, setAllPpeTypeConfigs] = useState<PpeTypeConfig[]>([])
  const [allPpeInstances, setAllInstances] = useState<PpeInstanceRecordSchema[]>([])
  const [changesMade, setChangesMade] = useState(false)
  const [inventoryImportData, setIventoryImportData] = useState<NewPpeInstanceParams[]>([])
  const [selectedInventory, setSelectedInventory] = useState<PpeInventoryTransferTableRowItem[]>([])
  const [itemOptionKeySkuMap, setPpeItemOptionKeyMap] = useState<Record<string, { key: FieldConfigKey; sku: PpeSku }>>(
    {},
  )
  const [transferTarget, setTransferTarget] = useState('')
  const [csvProcessingModalOpen, setCsvProcessingModalOpen] = useState(false)
  const [loadingModalOpen, setLoadingModalOpen] = useState(false)
  const [loadingModalMessage, setLoadingModalMessage] = useState('')
  const [warningModalOpen, setWarningModalOpen] = useState(false)
  const [decisionModalOpen, setDecisionModalOpen] = useState(false)
  const [exportEmailCollectorModalOpen, setExportEmailCollectorModalOpen] = useState(false)

  const [warningModalConfig, setWarningModalConfig] = useState({
    warningModalHeader: '',
    warningModalMessage: '' as any,
  })
  const [labelCollectorModalConfig, setLabelCollectorModalConfig] = useState({
    header: '',
    message: '',
    placeholder: '',
    warningMessage: 'Enter a value to proceed',
    pickerItems: [] as string[],
  })
  const [labelCollectorModalOpen, setLabelCollectorModalOpen] = useState(false)
  const [labelCollectionMode, setLabelCollectionMode] = useState(LabelCollectionMode.Location)
  const [currentCellInputFocus, setCurrentCellInputFocus] = useState<string[]>([])

  const tableRef = useRef<DataTable>(null)

  useEffect(() => {
    initialise()
  }, [])

  const initialise = async () => {
    if (!navMenuAccess.hasPpeStockAccess) {
      setWarningModalConfig({
        warningModalHeader: 'Not Authorised',
        warningModalMessage: "You don't have permission to viewMode PPE inventory.",
      })
      setWarningModalOpen(true)
      return
    }
    setLoadingModalMessage('Loading inventory data...')
    setLoadingModalOpen(true)
    initialisePpeTypeConfig()
    await initialisePpeInstances()
    setViewMode(INVENTORY_LIST)
    setLoadingModalOpen(false)
  }

  const initialisePpeTypeConfig = () => {
    const ppeTypeConfigs = ppeService.getAllTypeConfigs(selectedAssociation, associationRepo)
    setAllPpeTypeConfigs(ppeTypeConfigs)
  }

  const initialisePpeInstances = async () => {
    const ppeInstancesObject = await ppeService.getInstances(selectedAssociation, { username: idPassport, password })
    setAllInstances(Object.values(ppeInstancesObject))
  }

  const closeModals = () => {
    setLoadingModalOpen(false)
    setWarningModalOpen(false)
    setCsvProcessingModalOpen(false)
    setDecisionModalOpen(false)
    setExportEmailCollectorModalOpen(false)
  }

  const getToolbarNavButtons = (targetViewMode: string): ToolbarNavButtons => {
    let navButtons: ToolbarNavButtons = {}
    if (
      targetViewMode === INVENTORY_TRANSFER ||
      targetViewMode === INVENTORY_REMOVAL ||
      targetViewMode === INVENTORY_ADDITION
    ) {
      navButtons.right = { label: 'NEXT', onClick: () => onNextClick(targetViewMode) }
      navButtons.left = { label: 'BACK', onClick: () => setViewMode(INVENTORY_LIST) }
    }
    return navButtons
  }

  const getToolbarActionButtonConfig = (targetViewMode: string): ActionButtonType[] => {
    let config = [
      {
        label: 'ADD',
        iconPath: mdiPackageVariantClosedPlus,
        onClick: () => setCsvProcessingModalOpen(true),
        title: 'Add new inventory via CSV file',
      },
      {
        label: 'REMOVE',
        iconPath: mdiPackageVariantClosedMinus,
        onClick: () => onTransferClick(INVENTORY_REMOVAL),
        title: 'Remove inventory',
      },
      {
        label: 'TRANSFER',
        iconPath: mdiTruckFast,
        onClick: () => onTransferClick(INVENTORY_TRANSFER),
        title: 'Transfer inventory',
      },
      {
        label: 'EXPORT',
        iconPath: mdiExport,
        onClick: () => setExportEmailCollectorModalOpen(true),
        title: 'Export inventory',
      },
    ]
    if (targetViewMode === INVENTORY_ADDITION) {
      config = []
    }
    if (targetViewMode === INVENTORY_REMOVAL || targetViewMode === INVENTORY_TRANSFER) {
      config = [
        {
          label: 'ADD ROW',
          iconPath: mdiPlaylistPlus,
          onClick: () => addTransferItemHandler(),
          title: 'Add inventory item to table',
        },
        {
          label: 'REMOVE ROW',
          iconPath: mdiPlaylistMinus,
          onClick: () => removeTransferItemsHandler(),
          title: 'Remove inventory item from table',
        },
      ]
    }
    return config
  }

  const onTransferClick = (targetViewMode: PpeInventoryViewModes) => {
    setSelectedInventory([])
    addTransferItemHandler()
    setViewMode(targetViewMode)
  }

  const importInventoryHandler = () => {
    try {
      console.log('tableRef.current?.state?.selectedRows: ', tableRef.current?.state?.selectedRows)
      if (!Object.keys(tableRef.current?.state?.selectedRows).length) {
        throw { code: 'NoItemsSelected' }
      }
      setDecisionModalOpen(true)
    } catch (error) {
      errorHandler(error)
    }
  }

  const editInventoryItemHandler = (rowId: string, editType: string, value: string) => {
    try {
      function validateSelection(
        currentInventoryData: PpeInventoryTransferTableRowItem[],
        targetLabel: string,
        targetSize: string,
      ) {
        for (const item of currentInventoryData) {
          if (item.label.value === targetLabel && item.ppeSize.value === targetSize) {
            throw { code: 'AlreadyExists' }
          }
        }
      }
      const targetRowIndex = selectedInventory.findIndex((item) => item.id === rowId)
      if (targetRowIndex === undefined) {
        return
      }
      const targetRow = selectedInventory[targetRowIndex]
      if (editType === 'label') {
        const [typeLabel, sku] = value.split(transferLabelSeparator)
        const itemSizeOptions = getSizeOptions(typeLabel, sku)
        targetRow.label.value = value
        targetRow.ppeSize.disabled = false
        targetRow.ppeSize.selectorItems = itemSizeOptions
      }
      if (editType === 'ppeSize') {
        validateSelection(selectedInventory, targetRow.label.value, value)
        targetRow.ppeSize.value = value
      }
      if (editType === 'noOfUnits') {
        if (value && isNaN(parseInt(value))) {
          return
        }
        targetRow.noOfUnits.value = value
      }
      const updatedInventorySelection = [...selectedInventory].map((item) =>
        item.id === targetRow.id ? targetRow : item,
      )
      setSelectedInventory(updatedInventorySelection)
    } catch (error) {
      errorHandler(error)
    }
  }

  const getSizeOptions = (typeLabel: string, targetSku: string) => {
    const targetConfig = allPpeTypeConfigs.find((config) => config.label === typeLabel)
    if (!targetConfig) {
      return []
    }
    const sku = targetConfig.skus.find((sku) => sku === targetSku)
    if (!sku) {
      return []
    }
    return targetConfig.suppliers[sku]?.supplierSizeOptions || []
  }

  const addTransferItemHandler = () => {
    const buildTransferLabel = (label: string, sku: string) => {
      return `${label}${transferLabelSeparator}${sku}`
    }
    const initialisePpeItemOptions = () => {
      const ppeItemOptions: string[] = []
      let ppeItemOptionKeySkuMap = {} as Record<string, { key: FieldConfigKey; sku: PpeSku }>
      allPpeTypeConfigs.forEach((config) => {
        config.skus.forEach((sku) => {
          const transferLabel = buildTransferLabel(config.label, sku)
          ppeItemOptions.push(transferLabel)
          ppeItemOptionKeySkuMap[transferLabel] = { key: config.key, sku }
        })
      })
      return { ppeItemOptions, ppeItemOptionKeySkuMap }
    }
    const { ppeItemOptions, ppeItemOptionKeySkuMap } = initialisePpeItemOptions()
    const newItem = configService.buildInventoryTransferTableRowConfig(ppeItemOptions)
    setPpeItemOptionKeyMap(ppeItemOptionKeySkuMap)
    setSelectedInventory((prevItems) => [newItem, ...prevItems])
  }

  const removeTransferItemsHandler = () => {
    const selectedRowIds = Object.keys(tableRef.current?.state?.selectedRows)
    const updatedInventorySelection = [...selectedInventory].filter((item) => !selectedRowIds.includes(item.id))
    setSelectedInventory(updatedInventorySelection)
  }

  const importInventory = async () => {
    const selectedRowIds = Object.keys(tableRef.current?.state?.selectedRows)
    const targetRows = selectedRowIds.map((rowId: string) => {
      return inventoryImportData.find(
        (item) => buildImportItemId(item.sku, item.ppeSize, item.qty) === rowId,
      ) as NewPpeInstanceParams
    })
    closeModals()
    setLoadingModalMessage('Importing inventory...')
    setLoadingModalOpen(true)
    const { name = '', surname = '' } = userRepo.getCurrentUserEntity().getPersonalUserInfo()
    await ppeService.createPpeInstances(selectedAssociation, targetRows, {
      username: idPassport,
      password,
      name,
      surname,
    })
    await initialisePpeInstances()
    setViewMode(INVENTORY_LIST)
    closeModals()
    setWarningModalConfig({
      warningModalHeader: 'Success',
      warningModalMessage: 'Inventory levels were successfully updated.',
    })
    setWarningModalOpen(true)
  }

  const normaliseInventoryImportData = (
    inventoryImportItems: NewPpeInstanceParams[],
    organisationConfig: Record<string, any>,
  ) => {
    // const fuzzyMatchSupplierName = (supplierName: string) => {
    //   const supplierOptions = Object.keys(organisationConfig.ppeSuppliers).map((key) => ({
    //     supplierId: key,
    //     supplierName: organisationConfig.ppeSuppliers[key],
    //   }))
    //   const result = fuzzyMatch(supplierOptions, supplierName, {
    //     keys: ['supplierName'],
    //     threshold: 0.2, // 0 is a perfect match, 1 is a perfect mismatch
    //     includeScore: true,
    //   })
    //   if (result.length) {
    //     // @ts-ignore
    //     return result[0]?.item.supplierName
    //   }
    //   return supplierName
    // }

    const extractPpeLabel = (sku: string) => {
      const targetPpeTypeConfig = allPpeTypeConfigs.find((ppeTypeConfig: PpeTypeConfig) =>
        ppeTypeConfig.skus?.includes(sku),
      )
      return targetPpeTypeConfig?.label || ''
    }

    return [...inventoryImportItems].map((item) => {
      const itemKeys = Object.keys(item) as string[]
      itemKeys.forEach((key) => {
        //@ts-ignore
        if (typeof item[key] === 'string') {
          //@ts-ignore
          item[key] = toUpperCaseCustom(item[key])
        }
        // if (key === 'supplier') {
        //   item.supplier = fuzzyMatchSupplierName(item.supplier)
        // }
        if (key === 'sku') {
          item.label = extractPpeLabel(item.sku)
        }
      })
      return item
    })
  }

  const validateInventoryImportData = (
    inventoryImportItems: NewPpeInstanceParams[],
    organisationConfig: Record<string, any>,
  ) => {
    const checkSkuExistence = (sku: any) => {
      const targetPpeConfig = allPpeTypeConfigs.find((ppeTypeConfig: PpeTypeConfig) =>
        ppeTypeConfig.skus?.includes(sku),
      )
      if (targetPpeConfig) {
        return true
      }
      return false
    }

    // const checkSupplierExistence = (supplier: any) => {
    //   return Object.values(organisationConfig.ppeSuppliers).includes(supplier)
    // }

    let dataWarnings = {} as Record<string, string[]>
    for (const importItem of inventoryImportItems) {
      const { sku } = importItem
      const isValidSku = checkSkuExistence(importItem.sku)
      // const isValidSupplier = checkSupplierExistence(supplier)
      if (!isValidSku) {
        if (!dataWarnings.invalidSkus) {
          dataWarnings.invalidSkus = []
        }
        dataWarnings.invalidSkus.push(sku)
      }
      // if (!isValidSupplier) {
      //   if (!dataWarnings.invalidSuppliers) {
      //     dataWarnings.invalidSuppliers = []
      //   }
      //   dataWarnings.invalidSuppliers.push(supplier)
      // }
    }
    if (Object.keys(dataWarnings).length) {
      throw { code: 'InvalidDataValues', dataWarnings }
    }
  }

  const loadInventoryImportView = (importData: any[]) => {
    try {
      closeModals()
      const organisationConfig = associationRepo.getOrganisationConfig(selectedAssociation)
      importData = normaliseInventoryImportData(importData, organisationConfig)
      validateInventoryImportData(importData, organisationConfig)
      setIventoryImportData(importData)
      setViewMode(INVENTORY_ADDITION)
    } catch (error) {
      errorHandler(error)
    }
  }

  const onNextClick = (targetViewMode: string) => {
    try {
      function validateInputs(targetInventory: PpeInventoryTransferTableRowItem[]) {
        if (!targetInventory.length) {
          throw { code: 'NoItemsSelected' }
        }
        const fieldsToValidate = ['label', 'ppeSize', 'noOfUnits']
        for (const item of targetInventory) {
          for (const field of fieldsToValidate) {
            //@ts-ignore
            if (!item[field].value) {
              throw { code: 'MissingFieldValues' }
            }
          }
        }
      }

      const transferAssociations =
        userRepo
          .getCurrentUserEntity()
          .getAssocationIamEntity(selectedAssociation)
          ?.getPermittedFeatureResources(SystemFeaturesEnum.ppe) || []
      const pickerItems = transferAssociations?.map((association) => removeUnderScores(toUpperCaseCustom(association)))
      switch (targetViewMode) {
        case INVENTORY_TRANSFER:
          validateInputs(selectedInventory)
          setLabelCollectionMode(LabelCollectionMode.Location)
          setLabelCollectorModalConfig({
            header: 'Transfer Location',
            message: 'Where is this inventory being transferred to?',
            placeholder: 'Choose a location',
            warningMessage: 'Enter a location to proceed',
            pickerItems,
          })
          setLabelCollectorModalOpen(true)
          break
        case INVENTORY_REMOVAL:
          validateInputs(selectedInventory)
          setLabelCollectionMode(LabelCollectionMode.Reason)
          setLabelCollectorModalConfig({
            header: `Reason for ${viewMode === INVENTORY_REMOVAL ? 'Removal' : 'Transfer'}`,
            message: `Why is this inventory being ${viewMode === INVENTORY_REMOVAL ? 'removed' : 'transferred'}?`,
            placeholder: 'Enter reason',
            warningMessage: 'Enter a reason to proceed',
            pickerItems: [],
          })
          setLabelCollectorModalOpen(true)
          break
        case INVENTORY_ADDITION:
          importInventoryHandler()
          break
      }
    } catch (error) {
      errorHandler(error)
    }
  }

  const onLabelCollectorSubmit = (label: string, mode: LabelCollectionMode) => {
    if (mode === LabelCollectionMode.Location) {
      setTransferTarget(label)
      setLabelCollectionMode(LabelCollectionMode.Reason)
      setLabelCollectorModalConfig({
        header: `Reason for ${viewMode === INVENTORY_REMOVAL ? 'Removal' : 'Transfer'}`,
        message: `Why is this inventory being ${viewMode === INVENTORY_REMOVAL ? 'removed' : 'transferred'}?`,
        placeholder: 'Enter reason',
        warningMessage: 'Enter a reason to proceed',
        pickerItems: [],
      })
    } else {
      onTransferSubmit(label)
    }
  }

  const onTransferSubmit = async (reasonForTransfer: string) => {
    try {
      function packageTransferParams(targetInventory: PpeInventoryTransferTableRowItem[], association: AssociationId) {
        let transferParams = {} as Record<FieldConfigKey, Record<PpeSize, { qty: PpeQuantity; skus?: PpeSku[] }[]>>
        targetInventory.forEach((item) => {
          const { key, sku } = itemOptionKeySkuMap[item.label.value]
          const ppeSize = item.ppeSize.value
          const qty = parseInt(item.noOfUnits.value)

          if (!transferParams[key]) {
            transferParams[key] = {}
          }
          if (!transferParams[key][ppeSize]) {
            transferParams[key][ppeSize] = []
          }
          transferParams[key][ppeSize].push({ qty, skus: [sku] })
        })
        return { [association]: transferParams }
      }
      const isRemoval = viewMode === INVENTORY_REMOVAL
      setLabelCollectorModalOpen(false)
      setLoadingModalMessage(`${isRemoval ? 'Removing' : 'Transferring'} inventory...`)
      setLoadingModalOpen(true)

      const association = isRemoval ? selectedAssociation : toLowerCaseCustom(addUnderScores(transferTarget))
      const requestedTransferInstanceParams = packageTransferParams(selectedInventory, association)
      const userEntity = userRepo.getCurrentUserEntity()
      const authCredentials = { username: idPassport, password }
      const response = await ppeService.transferInventory(
        requestedTransferInstanceParams,
        selectedAssociation,
        userEntity,
        authCredentials,
        reasonForTransfer,
      )
      console.log('response:', response)
      if (response.unavailablePpeItems) {
        generateAndDisplayUnavailableItemsWarning(response.unavailablePpeItems)
        return
      }
      setLoadingModalMessage(`Loading updated inventory...`)
      await initialisePpeInstances()
      setLoadingModalOpen(false)
      setViewMode(INVENTORY_LIST)
    } catch (error) {
      errorHandler(error)
    }
  }

  const triggerExport = async (targetEmails: string[]) => {
    try {
      if (!targetEmails.length) {
        throw { code: 'MissingEmailAddresses' }
      }
      ppeService.exportPpeInventory(selectedAssociation, targetEmails, { username: idPassport, password })
      setExportEmailCollectorModalOpen(false)
      setWarningModalConfig({
        warningModalHeader: 'Triggered Inventory Export',
        warningModalMessage:
          'Your inventory export has been triggered. (It will take a few minutes to reach the target inbox)',
      })
      setWarningModalOpen(true)
    } catch (error) {
      errorHandler(error)
    }
  }

  const warningModalEventHandler = (isActionButtonClick?: boolean) => {
    if (!isActionButtonClick) {
      if (!navMenuAccess.hasPpeStockAccess) {
        props.history.goBack()
        return
      } else {
        closeModals()
      }
      return
    }
    if (isActionButtonClick) {
      if (warningModalOpen) {
        closeModals()
        return
      }
    }
  }

  const getItemDetails = (typeLabel: string) => {
    const getBookedQty = (targetInstances: PpeInstanceRecordSchema[]) => {
      return ppeService.getInstancesWithAvailaibility(targetInstances, [PpeAvailability.RESERVED]).length
    }
    const packageInventoryInstancesBySku = (
      targetInstances: PpeInstanceRecordSchema[],
      targetPpeConfigs: PpeTypeConfig[],
    ) => {
      const skuGroupedItems = groupObjectsByKey(targetInstances, 'sku')
      let itemsToDisplay: any[] = []
      Object.keys(skuGroupedItems).forEach((sku: string) => {
        const targetConfig = targetPpeConfigs.find((config) => config.skus?.includes(sku))
        const total = skuGroupedItems[sku].length
        const qtyReserved = getBookedQty(skuGroupedItems[sku])
        const rowData = {
          label: targetConfig?.label || 'NOT CONFIGURED',
          ppeSize: '',
          sku,
          total,
          qtyReserved,
          available: total - qtyReserved,
        }
        itemsToDisplay.push(rowData)
      })
      return itemsToDisplay
    }
    const packageInventoryInstancesBySize = (
      targetInstances: PpeInstanceRecordSchema[],
      targetPpeConfigs: PpeTypeConfig[],
    ) => {
      let itemsToDisplay: any[] = []
      const skuGroupedItems = groupObjectsByKey(targetInstances, 'sku')
      Object.keys(skuGroupedItems).forEach((sku: string) => {
        const ppeInstances = skuGroupedItems[sku]
        const targetConfig = targetPpeConfigs.find((config) => config.skus?.includes(sku))
        const sizeGroupedItems = groupObjectsByKey(ppeInstances, 'ppeSize')
        Object.keys(sizeGroupedItems).forEach((ppeSize: string) => {
          const total = sizeGroupedItems[ppeSize].length
          const qtyReserved = getBookedQty(sizeGroupedItems[ppeSize])
          const rowData = {
            label: targetConfig?.label || 'NOT CONFIGURED',
            ppeSize,
            sku,
            total,
            qtyReserved,
            available: total - qtyReserved,
          }
          itemsToDisplay.push(rowData)
        })
      })
      return itemsToDisplay
    }

    if (typeLabel === 'All') {
      return packageInventoryInstancesBySku(allPpeInstances, allPpeTypeConfigs)
    } else {
      const targetPpeConfigs = allPpeTypeConfigs.filter((config) => config.label === typeLabel)
      const targetSkus = targetPpeConfigs.flatMap((config) => config.skus || [])
      const targetInstances = allPpeInstances.filter((item) => targetSkus.includes(item.sku))
      return packageInventoryInstancesBySize(targetInstances, targetPpeConfigs)
    }
  }

  const generateAndDisplayUnavailableItemsWarning = (
    unavailablePpeItems: Record<FieldConfigKey, Record<string, number>>,
  ) => {
    const messages = ppeService.generateUnavailableItemMessageList(
      unavailablePpeItems,
      associationRepo,
      selectedAssociation,
    )
    const warningModalHeader = 'Items Unavailable'
    const warningModalMessage = messages.map((message, i) => <p key={`displayWarning_${i}`}>{message}</p>)
    setWarningModalConfig({ warningModalHeader, warningModalMessage })
    setWarningModalOpen(true)
  }

  const errorHandler = (error: any = {}) => {
    let warningModalHeader = 'Warning'
    let warningModalMessage =
      'An error occurred. Refresh the browser and try again. If the problem persists, then reach out to tech support.' as any
    switch (error.code) {
      case 'InvalidDataValues':
        const { dataWarnings } = error
        warningModalHeader = 'Warning'
        warningModalMessage = []
        if (dataWarnings.hasOwnProperty('invalidSkus')) {
          const invalidSkuListString = `${dataWarnings.invalidSkus.join('; ')}`
          warningModalMessage.push(<p> {`SKU's not configured on PeopleFlow:  ${invalidSkuListString}`}</p>)
        }
        if (dataWarnings.hasOwnProperty('invalidSuppliers')) {
          const invalidSupplierListString = `${dataWarnings.invalidSuppliers.join('; ')}`
          warningModalMessage.push(<p> {`Suppliers not configured on PeopleFlow:  ${invalidSupplierListString} `}</p>)
        }
        warningModalMessage.push(<p> {`Visit CONFIGURATOR > PPE to configure missing resources.`} </p>)
        break
      case 'MissingEmailAddresses':
        warningModalHeader = 'Warning'
        warningModalMessage = 'Enter at least one email address to proceed.'
        break
      case 'NoItemsSelected':
        warningModalHeader = 'No Items Selected'
        warningModalMessage = 'Select at least one item to continue'
        break
      case 'MissingFieldValues':
        warningModalHeader = 'Missing Values'
        warningModalMessage = 'Ensure all data fields are filled in'
        break
      case 'MissingTargetLocation':
        warningModalHeader = 'Choose recipient'
        warningModalMessage = 'Choose a transfer recipient to continue'
        break
      case 'AlreadyExists':
        warningModalHeader = 'Already Exists'
        warningModalMessage = 'This item already exists in the list'
        break
    }
    setLoadingModalOpen(false)
    setWarningModalConfig({ warningModalHeader, warningModalMessage })
    setWarningModalOpen(true)
  }

  const getPageHeader = (targetViewMode: string) => {
    switch (targetViewMode) {
      case INVENTORY_TRANSFER:
        return 'Inventory Transfer'
      case INVENTORY_REMOVAL:
        return 'Inventory Removal'
      case INVENTORY_ADDITION:
        return 'Inventory Import'
    }
    return `Inventory`
  }

  const buildImportItemId = (sku: string, ppeSize: string, qty: number) => {
    return `${sku}-${ppeSize}-${qty}`
  }

  const handleCellInputFocus = (rowId: string, itemId: string) => {
    const [currentRowFocused, currentCellFocused] = currentCellInputFocus
    if (currentRowFocused === rowId && currentCellFocused === itemId) {
      return
    }
    setCurrentCellInputFocus([rowId, itemId])
  }

  const getPageContent = (targetViewMode: string, labelType: string) => {
    if (targetViewMode === INVENTORY_LIST) {
      const tableData = getItemDetails(labelType)
      let tableColumnConfig = [...listTableColumnConfig]
      if (labelType === 'All') {
        tableColumnConfig = [{ id: 'label', label: 'Item', sizeFactor: 1 }, ...listTableColumnConfig]
      } else {
        tableColumnConfig.splice(1, 0, { id: 'ppeSize', label: 'Size', sizeFactor: 1 })
      }
      return (
        <DataTable
          tableData={tableData}
          columnConfig={tableColumnConfig}
          tableWidth={9}
          filterState={{}}
          selectedRowItemId={''}
          selectionEnabled={false}
          disabled={true}
          key={`${Date.now()}`}
        />
      )
    }
    if (targetViewMode === INVENTORY_ADDITION) {
      const tableData = inventoryImportData.map((item) => ({
        ...item,
        id: buildImportItemId(item.sku, item.ppeSize, item.qty),
      }))
      return (
        <DataTable
          ref={tableRef}
          tableData={tableData}
          columnConfig={importTableColumnConfig}
          tableWidth={11}
          filterState={{}}
          selectedRowItemId={''}
          selectionEnabled={true}
          disableRowClick={true}
          disabled={true}
          key={`${Date.now()}`}
        />
      )
    }
    if (targetViewMode === INVENTORY_TRANSFER || targetViewMode === INVENTORY_REMOVAL) {
      return (
        <DataTable
          ref={tableRef}
          tableData={selectedInventory}
          columnConfig={removalTableColumnConfig}
          tableWidth={11}
          filterState={{}}
          selectedRowItemId={''}
          selectionEnabled={true}
          disableRowClick={true}
          currentCellInputFocus={currentCellInputFocus}
          onCellInputChange={editInventoryItemHandler}
          onCellInputFocus={handleCellInputFocus}
          userFeedbackMessage={'Click "ADD ITEM" to choose which inventory to transfer'}
          disabled={false}
          rowHeight={50}
          key={`${Date.now()}`}
        />
      )
    }
    return null
  }

  const toolbarActionButtons = getToolbarActionButtonConfig(viewMode)
  const toolbarNavButtons = getToolbarNavButtons(viewMode)
  const pageHeader = getPageHeader(viewMode)
  const pageContent = getPageContent(viewMode, selectedTypeLabel)

  let confirmationModal = null
  if (decisionModalOpen) {
    const selectedRowIds = Object.keys(tableRef.current?.state?.selectedRows)
    confirmationModal = (
      <AlertModalTwoButton
        open={decisionModalOpen}
        dismiss={() => setDecisionModalOpen(false)}
        onClick1={() => setDecisionModalOpen(false)}
        onClick2={importInventory}
        buttonLabel1="Cancel"
        buttonLabel2="Yes, continue"
        header="Confirm"
        buttonStyle={{ marginTop: 30 }}
        body={`You are about to import ${selectedRowIds.length} new inventory item${
          selectedRowIds.length !== 1 ? 's' : ''
        }. Would you like to continue?`}
      />
    )
  }

  let warningModal = null
  if (warningModalOpen) {
    warningModal = (
      <AlertModalOneButton
        open={true}
        dismiss={() => warningModalEventHandler()}
        onClick={() => warningModalEventHandler(true)}
        buttonLabel={'Ok'}
        header={warningModalConfig.warningModalHeader}
        subHeader={warningModalConfig.warningModalMessage}
      />
    )
  }

  let exportEmailCollectorModal = null
  if (exportEmailCollectorModalOpen) {
    const user = userRepo.getCurrentUserEntity()
    const userEmailAddress = user.getEmail()
    exportEmailCollectorModal = (
      <InfoCollectorModal
        open={true}
        defaultItems={[userEmailAddress]}
        header="EMAIL TO"
        subHeader="Which email addresses should we send to?"
        warningMessage="Add at least one email address"
        validateInput={checkEmailFormat}
        transformInput={toLowerCaseCustom}
        placeholder="Email address"
        minimumItems={1}
        dismiss={() => setExportEmailCollectorModalOpen(false)}
        onReject={() => setExportEmailCollectorModalOpen(false)}
        onSuccess={triggerExport}
      />
    )
  }

  let csvProcessingModal = null
  if (csvProcessingModalOpen) {
    csvProcessingModal = (
      <CsvFileProcessingModal
        open={csvProcessingModalOpen}
        title="Upload New Inventory"
        instructions={'Download CSV template and add inventory data.'}
        csvColumnConfig={CsvColumnConfigEnum}
        onCancel={closeModals}
        onSubmit={loadInventoryImportView}
      />
    )
  }

  let labelCollectorModal = null
  if (labelCollectorModalOpen) {
    labelCollectorModal = (
      <LabelCollector
        key={Date.now()}
        open={labelCollectorModalOpen}
        warning={labelCollectorModalConfig.warningMessage}
        placeholder={labelCollectorModalConfig.placeholder}
        pickerItems={labelCollectorModalConfig.pickerItems}
        header={labelCollectorModalConfig.header}
        message={labelCollectorModalConfig.message}
        buttonLabel={'Next'}
        iconName={'docs'}
        dismiss={() => setLabelCollectorModalOpen(false)}
        submit={(text: string) => onLabelCollectorSubmit(text, labelCollectionMode)}
      />
    )
  }

  return (
    <div style={styles.container}>
      <NavBar match={props.match} location={props.location} history={props.history} />
      <SectionHeaderPrimary style={styles.sectionHeader} disabled={true} searchString={''} onClick={() => ({})}>
        {pageHeader}
      </SectionHeaderPrimary>
      <div style={styles.contentContainer}>
        <SideMenu
          visible={viewMode !== INVENTORY_ADDITION && viewMode !== INVENTORY_TRANSFER && viewMode !== INVENTORY_REMOVAL}
          menuComponents={
            <PpeStockSideMenu
              typeNames={[...new Set(allPpeTypeConfigs.map((config) => removeUnderScores(config.label)))].sort()}
              selectedItemName={selectedTypeLabel}
              onItemSelection={(sideMenuSelection: string) => setSideMenuSelection(sideMenuSelection)}
            />
          }
        />
        <div style={styles.rightSide}>
          <Toolbar actionButtons={toolbarActionButtons} navButtons={toolbarNavButtons} />
          <div style={styles.rightSideContent}>{pageContent}</div>
        </div>
      </div>
      {confirmationModal}
      {warningModal}
      {exportEmailCollectorModal}
      <LoadingModal open={loadingModalOpen}>{loadingModalMessage}</LoadingModal>
      {csvProcessingModal}
      {labelCollectorModal}
      <RouteLeavingGuard
        when={changesMade} // should have true value if there are any changes unsaved
        navigate={(path) => props.history.push(path)}
        shouldBlockNavigation={(location) => true}
        alertHeader="Discard changes"
        alertBody={
          <div>
            You have unsaved changes.
            <br />
            Are you sure you want to leave without saving?
          </div>
        }
      />
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundImage: `linear-gradient(to bottom, ${ColorPalette.SCREEN_TOP_GRADIENT}, ${ColorPalette.SCREEN_BOTTOM_GRADIENT})`,
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 1%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    paddingInline: 'max(2em, 2%)',
    width: '100%',
    overflow: 'hidden',
  },
  rightSideContent: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    display: 'flex',
    flex: 1,
    overflow: 'auto',
    backgroundColor: ColorPalette.CARD_WHITE,
  },
  buttonIconStyle: {
    marginRight: 5,
    width: 15,
    height: 'auto',
  },
  downloadContainer: {
    color: ColorPalette.PRIMARY_TEXT,
    margin: '2em auto 0em',
    fontWeight: 'bolder',
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'center',
    cursor: 'pointer',
  },
}
