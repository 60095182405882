import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { mdiInformationOutline } from '@mdi/js'
import Icon from '@mdi/react'

import AlertModalOneButton from '../../components/Modals/AlertModalOneButton'
import { Toolbar, ActionButtonType } from '../../components/GeneralUI/Toolbar'
import LoadingModal from '../../components/Modals/LoadingModal'
import NavBar from '../../components/Navigation/NavBar'
import PPEConfigSideMenu from '../../components/SideMenus/PpeConfigSideMenu'
import PpeItemDetails, { ItemSize } from '../../components/Configurator/Ppe/PpeItemDetails'
import RouteLeavingGuard from '../../components/Navigation/RouteLeavingGuard'
import SectionHeaderPrimary from '../../components/Headings/SectionHeaderPrimary'
import SideMenu from '../../components/Navigation/SideMenu'
import { ActionType } from '../../store/actions/actions'
import { ColorPalette } from '../../config/colors'
import { getUniqueTypeLabelsInclSku, preparePpeTypeUpsertPayload } from '../../utils/ppeUtils'
import { PeopleFlowCombinedReducer } from '../../store'
import { PpeService } from '../../services'
import { removeUnderScores } from '../../utils/stringUtils'
import { AssociationSettingsRepository, UsersRepository } from '../../repositories'
import { PpeSupplierKeyValuePairs, PpeTypeConfig, PpeTypeUpsert } from '../../types'

const initialModalState = {
  //   deleteConfirmModalOpen: false,
  addModalOpen: false,
  selectorModalOpen: false,
  saveFailedModalOpen: false,
  unsavedChangesModalOpen: false,
  fieldSelectionModalOpen: false,
}

type PPEConfigProps = {
  match: any
  location: any
  history: any
}

const ppeService = new PpeService()

export const PpeConfig = (props: PPEConfigProps) => {
  const idPassport = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.idPassport)
  const password = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.password)
  const selectedAssociation = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation,
  )
  const associationRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.associationRepo,
  ) as AssociationSettingsRepository
  const userRepo = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.userRepo) as UsersRepository
  const navMenuAccess = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.navMenuAccess)

  const dispatch = useDispatch()
  const changeState = (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data })

  const [selectedItemName, setSelectedItemName] = useState('')
  const [savingModalOpen, setSavingModalOpen] = useState(false)
  const [modalState, setModalState] = useState(initialModalState)
  const [itemAdditionMode, setItemAdditionMode] = useState(false)
  const [allPpeTypeConfigs, setAllPpeTypeConfigs] = useState<PpeTypeConfig[]>([])
  const [changesMade, setChangesMade] = useState(false)

  useEffect(() => {
    initialisePpeTypeConfigs(associationRepo)
  }, [])

  const initialisePpeTypeConfigs = (associationRepo: AssociationSettingsRepository) => {
    const allPpeTypeConfigs = ppeService.getAllTypeConfigs(selectedAssociation, associationRepo)
    setAllPpeTypeConfigs(allPpeTypeConfigs)
  }

  const changeModalState = (newState: any) => setModalState((modalState) => ({ ...modalState, ...newState }))
  const closeModals = () => setModalState(initialModalState)

  const toggleSaveInProgressModal = () => setSavingModalOpen((savingModalOpen) => !savingModalOpen)
  const toggleSaveFailedModal = () => changeModalState({ saveFailedModalOpen: !modalState.saveFailedModalOpen })
  //   const toggleDeleteConfirmModal = () =>
  //     changeModalState({ deleteConfirmModalOpen: !modalState.deleteConfirmModalOpen })

  //   const deleteSelectedItemName = () => {
  //     toggleDeleteConfirmModal()
  //     // toggleSaveInProgressModal();
  //   }

  const toggleItemAdditionMode = () => {
    setItemAdditionMode((itemAdditionMode) => !itemAdditionMode)
  }

  const saveType = async (item: PpeTypeUpsert & { sku: string }) => {
    const itemPayload = preparePpeTypeUpsertPayload(item, selectedAssociation, associationRepo)
    setSavingModalOpen(true)
    const user = userRepo.getCurrentUserEntity()
    const { updatedType, updatedAssociationRepo } = await ppeService.upsertType(
      selectedAssociation,
      itemPayload,
      user.getUsername(),
      password,
      associationRepo,
    )
    changeState({ associationRepo: updatedAssociationRepo })
    initialisePpeTypeConfigs(updatedAssociationRepo)
    if (itemAdditionMode) {
      setTimeout(() => {
        const typeNameWithSku = updatedType.skus ? `${updatedType.label} - ${updatedType.skus}` : ''
        handleSideMenuItemSelection(typeNameWithSku)
      }, 750)
      setSavingModalOpen(false)
    } else {
      setSavingModalOpen(false)
    }
  }

  const updateSupplierOptions = async (suppliers: PpeSupplierKeyValuePairs) => {
    const assocRepo = await ppeService.upsertSuppliers(
      selectedAssociation,
      idPassport,
      password,
      associationRepo,
      suppliers,
    )
    changeState({ associationRepo: assocRepo })
  }

  const handleSidemenuItemAddClick = () => {
    setSelectedItemName('')
    toggleItemAdditionMode()
  }

  const handleSideMenuItemSelection = (item: string) => {
    setItemAdditionMode(false)
    setSelectedItemName(item)
  }

  const getRightSideContentToDisplay = () => {
    const [selectedItemNamePart, selectedSkuPart] = selectedItemName.split(' - ')
    const typeKeyLabelPairs = allPpeTypeConfigs.map((config) => ({ key: config.label, label: config.label }))
    const itemDetails =
      allPpeTypeConfigs.find(
        (config) => config.label === selectedItemNamePart && config.skus?.includes(selectedSkuPart),
      ) || undefined

    let content = <SelectFieldInstruction />
    if (selectedItemName || itemAdditionMode) {
      content = (
        <PpeItemDetails
          selectedAssociation={selectedAssociation}
          associationRepo={associationRepo}
          additionMode={itemAdditionMode}
          selectedItem={selectedItemName}
          allItems={typeKeyLabelPairs}
          itemSizes={typeSizes}
          itemDetails={itemDetails}
          selectedSku={selectedSkuPart}
          onSaveChangesClick={saveType}
          updateUserProfileState={(suppliers: PpeSupplierKeyValuePairs) => updateSupplierOptions(suppliers)}
          key={`ppeItemDetails_${selectedItemName}_${itemAdditionMode}`}
        />
      )
    }

    return content
  }

  // const typeNames = getUniqueTypeLabels(allPpeTypeConfigs)
  const typeNamesWithSku = getUniqueTypeLabelsInclSku(allPpeTypeConfigs)
  const typeSizes: ItemSize[] = []
  allPpeTypeConfigs.forEach((config: PpeTypeConfig) => {
    const suppliers = config.suppliers
    const skus = config.skus || []
    skus.forEach((sku) => {
      typeSizes.push({
        name: removeUnderScores(config.label),
        sku,
        sizes: suppliers && suppliers[sku] ? suppliers[sku].supplierSizeOptions : [],
      })
    })
    return typeSizes
  })
  // TODO: consolidate the above two into a single object

  const rightSideContent = getRightSideContentToDisplay()

  let saveFailedModal = null
  if (modalState.saveFailedModalOpen) {
    saveFailedModal = (
      <AlertModalOneButton
        open={modalState.saveFailedModalOpen}
        header="Failed to save changes"
        body={
          <div>
            Changes won't be permanently applied. Please try again.
            <p>Contact customer support if the problem persists.</p>
          </div>
        }
        buttonLabel="Ok"
        onClick={toggleSaveFailedModal}
      />
    )
  }

  return (
    <div style={styles.container}>
      <NavBar match={props.match} location={props.location} history={props.history} />
      <SectionHeaderPrimary style={styles.sectionHeader} disabled={true} searchString={''} onClick={() => ({})}>
        PPE
      </SectionHeaderPrimary>

      <div style={styles.contentContainer}>
        <SideMenu
          visible={true}
          menuComponents={
            <PPEConfigSideMenu
              itemNames={typeNamesWithSku}
              selectedItemName={selectedItemName}
              onItemAddClick={handleSidemenuItemAddClick}
              onItemSelection={handleSideMenuItemSelection}
            />
          }
        />
        <div style={styles.rightSide}>
          <Toolbar navButtons={{ left: { label: 'BACK' } }} />
          <div style={styles.rightSideContent}>
            <div style={styles.details}>{rightSideContent}</div>
          </div>
        </div>
      </div>
      {saveFailedModal}
      <AlertModalOneButton
        // TODO: There should only be one AlertModalOneButton component per screen with the required headers, body, and functions being set accordingly by a handler.
        open={!navMenuAccess.hasPpeStockConfigAccess}
        header={'Not Authorised'}
        body={"You don't have permission to view/manage the PPE settings."}
        buttonLabel={'Ok'}
        opaqueBackground={true}
        onClick={() => props.history.goBack()}
      />
      <LoadingModal open={savingModalOpen}>Saving changes...</LoadingModal>
      <RouteLeavingGuard
        when={changesMade} // should have true value if there are any changes unsaved
        navigate={(path) => props.history.push(path)}
        shouldBlockNavigation={(location) => true}
        alertHeader="Discard changes"
        alertBody={
          <div>
            You have unsaved changes.
            <br />
            Are you sure you want to leave this page without saving?
          </div>
        }
      />
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundImage: `linear-gradient(to bottom, ${ColorPalette.SCREEN_TOP_GRADIENT}, ${ColorPalette.SCREEN_BOTTOM_GRADIENT})`,
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 1%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    paddingInline: 'max(2em, 2%)',
    width: '100%',
    overflow: 'hidden',
  },
  rightSideContent: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    display: 'flex',
    flex: 1,
    overflow: 'auto',
    backgroundColor: ColorPalette.CARD_WHITE,
  },
  button: {
    fontWeight: 'bolder',
    fontSize: '0.8rem',
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
    },
    ':active': {
      color: ColorPalette.DARK_GREY,
    },
  },
  details: {
    width: '91%',
    margin: '0 auto',
  },
}

const SelectFieldInstruction = () => (
  <div
    style={{
      paddingTop: '25%',
      fontSize: '0.85rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: ColorPalette.SECONDARY_TEXT,
    }}>
    <Icon path={mdiInformationOutline} size={1} color={ColorPalette.PRIMARY_BLUE} style={{ marginRight: '.75em' }} />
    Select a field on the left to manage item settings
  </div>
)
