import {
  mdiAlertCircle,
  mdiAccountMultipleCheck,
  mdiFileDocumentMultiple,
  mdiClipboardClock,
  mdiCardAccountDetails,
} from '@mdi/js'

import { ScreenNamesEnum, MenuSchema, NavMenuAccess } from '../types'

export class NavBarService {
  buildMenuTabConfig(menuAccess: NavMenuAccess): MenuSchema[] {
    const maybeConfiguratorMenu = this.buildConfiguratorMenu(menuAccess)
    const maybeReportingMenu = this.buildReportingMenu(menuAccess)
    const maybeRequestMenu = this.buildRequestMenu(menuAccess)
    const maybePeopleMenu = this.buildPeopleMenu(menuAccess)
    const maybeBulkActionsMenu = this.buildBulkActionsMenu(menuAccess)
    const maybeLogsMenu = this.buildLogsMenu(menuAccess)

    let config: MenuSchema[] = []
    if (maybeConfiguratorMenu) {
      config.push(maybeConfiguratorMenu)
    }
    if (maybeReportingMenu) {
      config.push(maybeReportingMenu)
    }
    if (maybeRequestMenu) {
      config.push(maybeRequestMenu)
    }
    if (maybePeopleMenu) {
      config.push(maybePeopleMenu)
    }
    if (maybeBulkActionsMenu) {
      config.push(maybeBulkActionsMenu)
    }
    if (maybeLogsMenu) {
      config.push(maybeLogsMenu)
    }
    return config
  }

  private buildConfiguratorMenu(menuAccess: NavMenuAccess) {
    const buildSubmenus = (menuAccess: NavMenuAccess) => {
      return [
        {
          label: ScreenNamesEnum.USERS,
          screenName: ScreenNamesEnum.USERS,
          hasAccess: menuAccess.hasUserManagementAccess,
        },
        {
          label: ScreenNamesEnum.DATA_QUERIES,
          screenName: ScreenNamesEnum.DATA_QUERIES,
          hasAccess: menuAccess.hasAdvancedDataQueryAccess,
        },
        {
          label: ScreenNamesEnum.FORMS,
          screenName: ScreenNamesEnum.FORMS,
          hasAccess: menuAccess.hasDocumentLibraryAccess,
        },
        {
          label: ScreenNamesEnum.PPE_CONFIG,
          screenName: ScreenNamesEnum.PPE_CONFIG,
          hasAccess: menuAccess.hasPpeStockConfigAccess,
        },
        {
          label: ScreenNamesEnum.DATA_FIELDS,
          screenName: ScreenNamesEnum.DATA_FIELDS,
          hasAccess: menuAccess.hasFieldConfigAccess,
        },
        {
          label: ScreenNamesEnum.DATA_FLOWS,
          screenName: ScreenNamesEnum.DATA_FLOWS,
          hasAccess: menuAccess.hasDataFlowConfigAccess,
        },
        {
          label: ScreenNamesEnum.PROCESSES,
          screenName: ScreenNamesEnum.PROCESSES,
          hasAccess: menuAccess.hasProcessConfigAccess,
        },
        {
          label: ScreenNamesEnum.JSON_CONFIG_FILES,
          screenName: ScreenNamesEnum.JSON_CONFIG_FILES,
          hasAccess: menuAccess.hasJsonConfigFileAccess,
        },
      ]
    }
    const subMenus = buildSubmenus(menuAccess)
    if (!subMenus.length) {
      return null
    }
    return {
      label: ScreenNamesEnum.CONFIGURATOR,
      screenName: ScreenNamesEnum.CONFIGURATOR,
      subMenus,
    } as MenuSchema
  }

  private buildReportingMenu(menuAccess: NavMenuAccess) {
    const buildSubmenus = (menuAccess: NavMenuAccess) => {
      return [
        {
          label: ScreenNamesEnum.BILLING,
          screenName: ScreenNamesEnum.BILLING,
          hasAccess: menuAccess.hasBillingAccess,
        },
        {
          label: ScreenNamesEnum.DOCUMENT_VALIDITY,
          screenName: ScreenNamesEnum.DOCUMENT_VALIDITY,
          hasAccess: menuAccess.hasDocValidityAccess,
        },
        {
          label: ScreenNamesEnum.PPE_INVENTORY,
          screenName: ScreenNamesEnum.PPE_INVENTORY,
          hasAccess: menuAccess.hasPpeStockAccess,
        },
      ]
    }
    const subMenus = buildSubmenus(menuAccess)
    if (!subMenus.length) {
      return null
    }
    return {
      label: ScreenNamesEnum.REPORTING,
      screenName: ScreenNamesEnum.REPORTING,
      subMenus,
    } as MenuSchema
  }

  private buildBulkActionsMenu(menuAccess: NavMenuAccess) {
    const buildSubmenus = (menuAccess: NavMenuAccess) => {
      return [
        {
          label: ScreenNamesEnum.DISCIPLINE,
          screenName: ScreenNamesEnum.DISCIPLINE,
          hasAccess: menuAccess.hasBulkDisciplineAccess,
        },
        {
          label: ScreenNamesEnum.EXPORT,
          screenName: ScreenNamesEnum.EXPORT,
          hasAccess: menuAccess.hasExportAccess,
        },
        {
          label: ScreenNamesEnum.IMPORT,
          screenName: ScreenNamesEnum.IMPORT,
          hasAccess: menuAccess.hasImportAccess,
        },
        {
          label: ScreenNamesEnum.TERMINATE,
          screenName: ScreenNamesEnum.TERMINATE,
          hasAccess: menuAccess.hasBulkTerminationAccess,
        },
      ]
    }

    const buildSubSubMenus = (menuAccess: NavMenuAccess) => {
      let subSubMenuConfig = {} as Record<ScreenNamesEnum, any>
      subSubMenuConfig[ScreenNamesEnum.EXPORT] = [
        {
          iconName: mdiFileDocumentMultiple,
          screenName: ScreenNamesEnum.EXPORT_DOCS,
          label: 'DOCS',
          hasAccess: menuAccess.hasDocExportAccess,
        },
        {
          iconName: mdiClipboardClock,
          screenName: ScreenNamesEnum.EXPORT_DOC_VALIDITY_DATA,
          label: 'DOC VALIDITY DATA',
          hasAccess: menuAccess.hasDocValidityAccess,
        },
        {
          iconName: mdiCardAccountDetails,
          screenName: ScreenNamesEnum.EXPORT_EMPLOYEE_CARDS,
          label: 'EMPLOYEE CARDS',
          hasAccess: true,
        },
        {
          iconName: mdiAccountMultipleCheck,
          screenName: ScreenNamesEnum.EXPORT_PROFILES,
          label: 'PROFILES',
          hasAccess: menuAccess.hasExportAccess,
        },
      ]
      subSubMenuConfig[ScreenNamesEnum.DISCIPLINE] = [
        {
          iconName: mdiAlertCircle,
          screenName: ScreenNamesEnum.UNDER_PERFORMANCE,
          hasAccess: menuAccess.hasBulkDisciplineAccess,
          label: 'UNDER PERFORMANCE',
        },
      ]
      return subSubMenuConfig
    }
    const subMenus = buildSubmenus(menuAccess)
    const subSubMenus = buildSubSubMenus(menuAccess)
    return {
      label: ScreenNamesEnum.BULK_ACTIONS,
      screenName: ScreenNamesEnum.BULK_ACTIONS,
      subMenus,
      subSubMenus,
    } as MenuSchema
  }

  private buildRequestMenu(menuAccess: NavMenuAccess) {
    const buildSubmenus = (menuAccess: NavMenuAccess) => {
      let subMenuConfig = []
      if (menuAccess.hasPpeRequestAccess) {
        subMenuConfig.push({
          label: ScreenNamesEnum.REQUESTS_PPE,
          screenName: ScreenNamesEnum.REQUESTS_PPE,
          hasAccess: menuAccess.hasPpeRequestAccess,
        })
      }
      return subMenuConfig
    }
    const subMenus = buildSubmenus(menuAccess)
    if (!subMenus.length) {
      return null
    }
    return {
      label: ScreenNamesEnum.REQUESTS,
      screenName: ScreenNamesEnum.REQUESTS,
      subMenus,
    } as MenuSchema
  }

  private buildPeopleMenu(menuAccess: NavMenuAccess) {
    const buildSubmenus = (menuAccess: NavMenuAccess) => {
      return [
        {
          label: ScreenNamesEnum.EMPLOYEES,
          screenName: ScreenNamesEnum.EMPLOYEES,
          hasAccess: menuAccess.hasEmployeesAccess,
        },
        {
          label: ScreenNamesEnum.CANDIDATES,
          screenName: ScreenNamesEnum.CANDIDATES,
          hasAccess: menuAccess.hasCandidatesAccess,
        },
        { label: ScreenNamesEnum.TRASH, screenName: ScreenNamesEnum.TRASH, hasAccess: menuAccess.hasTrashAccess },
        { label: ScreenNamesEnum.GROUPS, screenName: ScreenNamesEnum.GROUPS, hasAccess: menuAccess.hasGroupAccess },
      ]
    }
    const subMenus = buildSubmenus(menuAccess)
    if (!subMenus.length) {
      return null
    }
    return {
      label: ScreenNamesEnum.PEOPLE,
      screenName: ScreenNamesEnum.PEOPLE,
      subMenus,
    } as MenuSchema
  }

  private buildLogsMenu(menuAccess: NavMenuAccess) {
    const buildSubmenus = (menuAccess: NavMenuAccess) => {
      return [
        {
          label: ScreenNamesEnum.DATA_LOGS,
          screenName: ScreenNamesEnum.DATA_LOGS,
          hasAccess: menuAccess.hasDataLogsAccess,
        },
        {
          label: ScreenNamesEnum.DEVICE_LOGS,
          screenName: ScreenNamesEnum.DEVICE_LOGS,
          hasAccess: menuAccess.hasDeviceLogsAccess,
        },
      ]
    }
    const subMenus = buildSubmenus(menuAccess)
    if (!subMenus.length) {
      return null
    }
    return {
      label: ScreenNamesEnum.GLOBAL_LOGS,
      screenName: ScreenNamesEnum.GLOBAL_LOGS,
      subMenus,
    } as MenuSchema
  }
}
