import React, { useEffect, useState } from 'react'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import radium from 'radium'
import Analytics from '@aws-amplify/analytics'
import Auth from '@aws-amplify/auth'
import API from '@aws-amplify/api'
import Storage from '@aws-amplify/storage'
import { useIdleTimer } from 'react-idle-timer'

import Billing from './screens/Billing/Billing'
import ComingSoon from './screens/ComingSoon/ComingSoon'
import Documents from './screens/Documents/Documents'
import FaceShots from './screens/FaceShots/FaceShots'
import GlobalLogs from './screens/Logs/Global'
import DeviceLogs from './screens/Logs/Devices'
import DocLibrary from './screens/DocLibrary/DocLibrary'
import Imports from './screens/Imports/Imports'
import ExportDocs from './screens/Exports/DocExport'
import Login from './screens/Login/Login'
import IndividualLogs from './screens/Logs/Individual'
import Profiles from './screens/Profiles/Profiles'
import DataManager from './screens/DataManager/DataManager'
import Performance from './screens/Performance/Performance'
import Discipline from './screens/Discipline/Discipline'
import TermsAndConditions from './screens/TermsAndConditions/TermsAndConditions'

import Config from './screens/Config/Config'
import DataFields from './screens/DataFields/DataFields'
import DataFlows from './screens/DataFlows/DataFlows'
import DataQueries from './screens/DataQueries/DataQueries'
import DisciplineConfig from './screens/DisciplineConfig/DisciplineConfig'
import DocValidity from './screens/DocValidity/DocValidity'
import Groups from './screens/Groups/Groups'
import GroupMembers from './screens/GroupMembers/GroupMembers'
import GroupMembersAdd from './screens/GroupMembers/GroupMembersAdd'
import { GroupMemberPpe } from './components/Groups/GroupMemberPpe'
import BulkTerminations from './screens/BulkTerminations/BulkTerminations'
import { PpeConfig } from './screens/Ppe/PpeConfig'
import Processes from './screens/Processes/Processes'
import { RequestsPpe } from './screens/Requests/RequestsPpe'
import RequestHandler from './screens/Requests/Requests'
import RequestManager from './screens/Requests/RequestManager'
import TerminationConfig from './screens/TerminationConfig/TerminationConfig'
import Trash from './screens/Trash/Trash'
import Users from './screens/Users/Users'

import { getAwsConfig } from './cloud-config'
import { PpeStock } from './screens/Ppe/PpeStock'
import PpeRequestDetails from './components/Requests/Ppe/PpeRequestDetails'
import { getDataSyncTemp, setDataSyncTemp } from './providers/sessionDataProvider'
import { CommonUnixMsEnum } from './enums'
import '../src/assets/css/global.css'
import { EmployeeCards } from './screens/EmployeeCards/EmployeeCards'

const awsconfig = getAwsConfig('prod')
Auth.configure(awsconfig)
Storage.configure(awsconfig)
API.configure(awsconfig)
Analytics.configure({ disabled: true })

const App = (props: any) => {
  const sessionAuthenticated = getDataSyncTemp('authenticated')
  const [authenticated, setAuthenticated] = useState(sessionAuthenticated)

  useEffect(() => {
    setDataSyncTemp('authenticated', authenticated)
  }, [authenticated])

  const onIdle = () => {
    window.location.pathname = '/'
  }
  useIdleTimer({ timeout: CommonUnixMsEnum.ONE_HOUR, disabled: !authenticated, onIdle })

  let termsandconditions = (
    <Route path="/termsandconditions" exact render={(props: any) => <TermsAndConditions {...props} />} />
  )
  let login: JSX.Element | null = (
    <Route path="/login" exact render={(props: any) => <Login {...props} setAuthenticated={setAuthenticated} />} />
  )
  let defaultpage: JSX.Element | null = (
    <Route path="/" render={(props: any) => <Login {...props} setAuthenticated={setAuthenticated} />} />
  )
  let comingSoon: JSX.Element | null = null
  let imports: JSX.Element | null = null
  let exportDocs: JSX.Element | null = null
  let transfer: JSX.Element | null = null
  let profiles: JSX.Element | null = null
  let employees: JSX.Element | null = null
  let exportEmployeeCards: JSX.Element | null = null
  let candidates: JSX.Element | null = null
  let dataManager: JSX.Element | null = null
  let individualLogs: JSX.Element | null = null
  let globalLogs: JSX.Element | null = null
  let groups: JSX.Element | null = null
  let groupMembers: JSX.Element | null = null
  let groupMembersAdd: JSX.Element | null = null
  let groupMemberPpe: JSX.Element | null = null
  let dataLogs: JSX.Element | null = null
  let deviceLogs: JSX.Element | null = null
  let docs: JSX.Element | null = null
  let faceShots: JSX.Element | null = null
  let forms: JSX.Element | null = null
  let formviewer: JSX.Element | null = null
  let performance: JSX.Element | null = null
  let discipline: JSX.Element | null = null
  let requests: JSX.Element | null = null
  let docValidity: JSX.Element | null = null
  let ppeConfig: JSX.Element | null = null
  let ppeStock: JSX.Element | null = null
  let requestsPpe: JSX.Element | null = null
  let ppeRequestDetails: JSX.Element | null = null
  let requestManager: JSX.Element | null = null
  let disciplineDataCapture: JSX.Element | null = null
  let configurator: JSX.Element | null = null
  let users: JSX.Element | null = null
  let config: JSX.Element | null = null
  let bulkTerminations: JSX.Element | null = null
  let processes: JSX.Element | null = null
  let dataFields: JSX.Element | null = null
  let dataFlows: JSX.Element | null = null
  let dataQueries: JSX.Element | null = null
  let disciplineConfig: JSX.Element | null = null
  let terminationConfig: JSX.Element | null = null
  let billing: JSX.Element | null = null
  let trash: JSX.Element | null = null

  if (authenticated) {
    comingSoon = <Route path="/comingsoon" exact render={(props: any) => <ComingSoon {...props} />} />
    imports = <Route path="/import" exact render={(props: any) => <Imports {...props} />} />
    exportDocs = <Route path="/exportdocs" exact render={(props: any) => <ExportDocs {...props} />} />
    exportEmployeeCards = <Route path="/employeecards" exact render={(props: any) => <EmployeeCards {...props} />} />
    profiles = <Route path="/people" exact render={(props: any) => <Profiles {...props} />} />
    employees = <Route path="/people/employees" exact render={(props: any) => <Profiles {...props} />} />
    candidates = <Route path="/people/candidates" exact render={(props: any) => <Profiles {...props} />} />
    trash = <Route path="/people/trash" exact render={(props: any) => <Trash {...props} />} />
    dataManager = <Route path="/people/datamanager" exact render={(props: any) => <DataManager {...props} />} />
    docs = <Route path="/people/documents" exact render={(props: any) => <Documents {...props} />} />
    faceShots = <Route path="/people/faceshots" exact render={(props: any) => <FaceShots {...props} />} />
    performance = <Route path="/people/performance" exact render={(props: any) => <Performance {...props} />} />
    discipline = <Route path="/people/discipline" exact render={(props: any) => <Discipline {...props} />} />
    individualLogs = <Route path="/people/logs" exact render={(props: any) => <IndividualLogs {...props} />} />
    forms = <Route path="/configurator/forms" exact render={(props: any) => <DocLibrary {...props} />} />
    globalLogs = <Route path="/globallogs" exact render={(props: any) => <GlobalLogs {...props} />} />
    groups = <Route path="/people/groups" exact render={(props: any) => <Groups {...props} />} />
    groupMembers = <Route path={'/people/groups/:groupId'} exact component={GroupMembers} />
    groupMembersAdd = <Route path={'/people/groups/:groupId/add'} exact component={GroupMembersAdd} />
    groupMemberPpe = <Route path={'/people/groups/:groupId/member/:profilePk/ppe'} exact component={GroupMemberPpe} />
    dataLogs = <Route path="/globallogs/datalogs" exact render={(props: any) => <GlobalLogs {...props} />} />
    docValidity = <Route path="/reporting/documentvalidity" exact render={(props: any) => <DocValidity {...props} />} />
    billing = <Route path="/reporting/billing" exact component={Billing} />
    deviceLogs = <Route path="/globallogs/devicelogs" exact render={(props: any) => <DeviceLogs {...props} />} />
    configurator = <Route path="/configurator" exact render={(props: any) => <Users {...props} />} />
    ppeConfig = <Route path="/configurator/ppe" exact render={(props: any) => <PpeConfig {...props} />} />
    ppeStock = <Route path="/reporting/ppeinventory" render={(props: any) => <PpeStock {...props} />} />
    requestsPpe = <Route path="/requests/pperequests" exact render={(props: any) => <RequestsPpe {...props} />} />
    ppeRequestDetails = (
      <Route path="/requests/pperequests/:requestId" exact render={(props: any) => <PpeRequestDetails {...props} />} />
    )
    requestManager = (
      <Route path="/requests/requestmanager" exact render={(props: any) => <RequestManager {...props} />} />
    )
    requests = <Route path="/requests" exact render={(props: any) => <RequestHandler {...props} />} />
    users = <Route path="/configurator/users" exact render={(props: any) => <Users {...props} />} />
    config = <Route path="/configurator/configfiles" exact render={(props: any) => <Config {...props} />} />
    processes = <Route path="/configurator/processes" exact render={(props: any) => <Processes {...props} />} />
    dataFields = <Route path="/configurator/datafields" exact render={(props: any) => <DataFields {...props} />} />
    dataFlows = (
      <Route path={['/configurator/dataflows', '/configurator/dataflows/:dataFlow']} exact component={DataFlows} />
    )
    dataQueries = <Route path="/configurator/dataqueries" exact render={(props: any) => <DataQueries {...props} />} />
    disciplineConfig = <Route path="/configurator/disciplineconfig/:jobType" exact component={DisciplineConfig} />
    terminationConfig = <Route path="/configurator/termination/:jobType" exact component={TerminationConfig} />
    bulkTerminations = <Route path="/bulkterminations" exact render={(props: any) => <BulkTerminations {...props} />} />
  }

  const onLoginPage = window.location.pathname === '/' || window.location.pathname === '/login'
  if (!authenticated && !onLoginPage) {
    window.location.pathname = '/'
  }

  return (
    <React.StrictMode>
      <radium.StyleRoot>
        <BrowserRouter>
          <Switch>
            {termsandconditions}
            {login}
            {comingSoon}
            {transfer}
            {imports}
            {exportDocs}
            {requestManager}
            {requests}
            {docValidity}
            {billing}
            {profiles}
            {employees}
            {exportEmployeeCards}
            {candidates}
            {groups}
            {groupMembers}
            {groupMembersAdd}
            {groupMemberPpe}
            {dataManager}
            {docs}
            {faceShots}
            {forms}
            {formviewer}
            {performance}
            {discipline}
            {disciplineDataCapture}
            {individualLogs}
            {globalLogs}
            {dataLogs}
            {deviceLogs}
            {configurator}
            {users}
            {config}
            {bulkTerminations}
            {ppeConfig}
            {ppeStock}
            {requestsPpe}
            {ppeRequestDetails}
            {processes}
            {dataFields}
            {dataFlows}
            {dataQueries}
            {disciplineConfig}
            {terminationConfig}
            {trash}
            {defaultpage}
          </Switch>
        </BrowserRouter>
      </radium.StyleRoot>
    </React.StrictMode>
  )
}

export default radium(App)
