import { ColorPalette } from '../../config'

type SideMenuTitleProps = {
  children: string
}

export const SideMenuTitle = (props: SideMenuTitleProps) => (
  <h1
    style={{
      marginTop: 0,
      fontSize: '0.9rem',
      fontWeight: '550',
      fontFamily: 'Roboto',
      color: ColorPalette.PRIMARY_TEXT,
      width: '84%',
      textAlign: 'center' as 'center',
      textTransform: 'uppercase',
    }}>
    {props.children}
  </h1>
)
