import _ from 'lodash'
import { useState, useEffect } from 'react'

import PickerContained from '../BaseComponents/Pickers/PickerContained'
import { PickerHandlerEvent } from '../BaseComponents/Pickers/Picker'
import { SelectorConfig, SelectorItemsConfig } from '../../types'
import { toUpperCaseCustom, removeUnderScores, addUnderScores } from '../../utils'
import ButtonBlue from '../BaseComponents/Buttons/ButtonBlue'
import { ColorPalette } from '../../config'

type HierarchialPickerModalProps = {
  config: {
    selectorConfig: SelectorConfig
    selectorItemsConfig: SelectorItemsConfig
  }
  warningMessage?: string
  onActionButtonPress: (values: Record<string, string>) => void
}

const HierarchialPicker = (props: HierarchialPickerModalProps) => {
  const [isMounted, setMountedState] = useState(false)
  const [selectorConfig, setSelectorConfig] = useState([] as SelectorConfig)
  const [selectorItemsConfig, setSelectorItemsConfig] = useState({})
  const [warningMessage, setWarningMessage] = useState('')
  const noOptionsPlaceholder = 'NO OPTIONS AVAILABLE'

  useEffect(() => {
    const initialisedSelectorConfig = initialiseValues(props.config.selectorConfig, props.config.selectorItemsConfig)
    setMountedState(true)
    setSelectorConfig(initialisedSelectorConfig)
    setSelectorItemsConfig(props.config.selectorItemsConfig)
  }, [])

  useEffect(() => {
    setWarningMessage(props.warningMessage || '')
  }, [props.warningMessage])

  const initialiseValues = (initalSelectorConfig: SelectorConfig, intialSelectorItemsConfig: SelectorItemsConfig) => {
    let initialisedSelectorConfig = [] as SelectorConfig
    initalSelectorConfig.forEach((configItem) => {
      let searchString = ''
      initialisedSelectorConfig.forEach((configItem) => {
        const searchStringValue = configItem.value
        if (searchString.length) {
          searchString = `${searchString}.${searchStringValue}`
        } else {
          searchString = `${searchStringValue}`
        }
      })
      let selectorItems = Object.keys(_.get(intialSelectorItemsConfig, searchString, intialSelectorItemsConfig))

      selectorItems = selectorItems.sort()
      // configItem.selectorItems = selectorItems.map((item) => toUpperCaseCustom(removeUnderScores(item)))
      configItem.selectorItems = selectorItems

      if (!configItem.value || !selectorItems.includes(configItem.value)) {
        configItem.value = selectorItems.length ? selectorItems[0] : noOptionsPlaceholder
      }
      initialisedSelectorConfig.push(configItem)
    })
    return initialisedSelectorConfig
  }

  const selectorHandler = (value: string, index: number) => {
    let updatedSelectorConfig = [...selectorConfig]
    updatedSelectorConfig[index].value = value
    updatedSelectorConfig = initialiseValues(updatedSelectorConfig, selectorItemsConfig)
    setSelectorConfig(updatedSelectorConfig)
  }

  const initialiseSelectors = () => {
    const selectors = selectorConfig.map((selectorConfigItem, index) => {
      const { key, label, value, selectorItems } = selectorConfigItem
      return (
        <>
          <h1 style={{ ...styles.header, margin: 0 }}>{label}</h1>
          <PickerContained
            key={`${key}_${Date.now()}`}
            style={styles.picker}
            items={selectorItems}
            value={value}
            transformToUpperCase={true}
            searchEnabled={false}
            onChange={(event: PickerHandlerEvent) => selectorHandler(event.target.value, index)}
          />
        </>
      )
    })
    return selectors
  }

  const onActionPress = () => {
    let values = {} as Record<string, string>
    selectorConfig.forEach(
      (configItem) => (values[configItem.key] = configItem.value === noOptionsPlaceholder ? '' : configItem.value),
    )
    props.onActionButtonPress(values)
  }

  let selectors = null
  if (isMounted) {
    selectors = initialiseSelectors()
  }

  return (
    <div style={styles.screenContainer}>
      <div style={styles.cardContainer}>
        {selectors}
        <ButtonBlue style={styles.labelledButton} onClick={() => onActionPress()}>
          Done
        </ButtonBlue>
      </div>
    </div>
  )
}

const styles = {
  screenContainer: {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    backgroundColor: ColorPalette.MODAL_BACKGROUND_OVERLAY,
    width: window.innerWidth,
    height: window.innerHeight,
    zIndex: 1000,
    overflow: 'hidden',
  },
  cardContainer: {
    position: 'absolute' as 'absolute',
    top: 25,
    right: 25,
    zIndex: 100000,
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.2)',
    backgroundColor: ColorPalette.CARD_WHITE,
    width: window.innerWidth * 0.22,
    padding: '40px 30px 40px 30px',
    borderRadius: 8,
  },
  header: {
    color: ColorPalette.PRIMARY_TEXT,
    fontSize: '1rem',
    fontFamily: 'roboto',
    fontWeight: 'bold',
    marginTop: '1.5em',
    textAlign: 'center' as 'center',
  },
  picker: { marginTop: 10, marginBottom: 35, width: '100%' },
  labelledButton: { width: '100%', marginTop: 1 },
}

export default HierarchialPicker
