import { forwardRef, useEffect, useRef, useState } from 'react'
import { cloneDeep } from 'lodash'

import { filterDataFlow, inputValidator } from '../../utils/workflowUtils'
import DataFlowNavigator from '../Navigation/DataFlowNavigator'
import DataFlowSummary from '../DataFlows/DataFlowSummary'
import { toUpperCaseCustom } from '../../utils'
import { ISectionConfigItem } from '../../interfaces'
import { FieldConfigItemSchema } from '../../types'

// TODO: move to components/Modals

interface ConfigurableFormProps {
  fieldConfig: Record<string, Record<string, any>>
  sectionConfig: ISectionConfigItem[]
  dataFlow: Record<string, Record<string, any>[]>
  data: Record<string, any>
  showSectionHeader: boolean
  highlightEmpty: boolean
  dataFlowDisabled: boolean
}

function ConfigurableForm(props: ConfigurableFormProps, ref: any) {
  const dataFlowNavigatorMenuRef = useRef<DataFlowNavigator>(null)

  const [showSectionHeader, setShowSectionHeader] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [data, setData] = useState<Record<string, any>>({})
  const [dataFlowFiltered, setDataFlowFiltered] = useState<Record<string, FieldConfigItemSchema[]>>({})
  const [selectedSectionFlowFiltered, setSelectedSectionFlowFiltered] = useState<FieldConfigItemSchema[]>([])
  const [currentSectionName, setCurrentSectionName] = useState('')
  const [currentSectionLabel, setCurrentSectionLabel] = useState('')
  const [fieldWarnings, setFieldWarnings] = useState<Record<string, string>>({})

  useEffect(() => {
    const { dataFlow = {}, data = {}, showSectionHeader = false, sectionConfig = [] } = props

    if (sectionConfig.length) {
      const currentSectionName = sectionConfig[0].sectionName
      const [filteredConfig, dataFlowFilters] = filterDataFlow(dataFlow, data)

      setData(data)
      setShowSectionHeader(showSectionHeader)
      setDataFlowFiltered(filteredConfig)
      setSelectedSectionFlowFiltered(filteredConfig[currentSectionName])
      setCurrentSectionName(currentSectionName)
      setCurrentSectionLabel(sectionConfig[0].sectionLabel)
      setShowForm(true)
    }
  }, [])

  function getCapturedData() {
    return data
  }

  function goToSubmenu(nextSectionName: string) {
    setSelectedSectionFlowFiltered(dataFlowFiltered[nextSectionName])
    setCurrentSectionName(nextSectionName)
  }

  function remountForm(isPriField: boolean) {
    if (isPriField) {
      // this.setState({ showForm: false }, () => this.setState({ showForm: true }))
      setShowForm(false)
    }
  }

  function handleRef(element: any) {
    if (element) {
      ref.current = {
        getCapturedData,
      }
    }
  }

  function textHandler(
    text: string,
    key: string,
    validationType: string | undefined,
    compulsory: boolean,
    autoPopulate: Record<string, Record<string, any>> = {},
    isPriField: boolean,
  ) {
    let warning = ''
    if (validationType !== undefined) {
      warning = inputValidator(compulsory, validationType, text).warning
    }
    let newData = { ...data, [key]: toUpperCaseCustom(text) }
    if (text in autoPopulate) {
      newData = { ...data, ...autoPopulate[text] }
    }
    const newFieldWarnings = { ...fieldWarnings, [key]: warning }
    setData(newData)
    setFieldWarnings(newFieldWarnings)
    remountForm(isPriField && text in autoPopulate)
    // this.setState({ data: newData, fieldWarnings: newFieldWarnings }, () => this.remountForm(isPriField && text in autoPopulate))
  }

  function selectionHandler(
    values: string[],
    key: string,
    workflowBranch: boolean,
    autoPopulate: Record<string, Record<string, any>> = {},
    isPriField: boolean,
  ) {
    const { dataFlow } = props
    let selectedSectionFlowFiltered = dataFlowFiltered[currentSectionName]
    let newData: Record<string, any> = { ...cloneDeep(data) }
    newData[key] = values
    if (values.length && values[0] in autoPopulate) {
      newData = { ...newData, ...autoPopulate[values[0]] }
    }
    if (workflowBranch) {
      const [filteredConfig, dataFlowFilters] = filterDataFlow(dataFlow, data)
      setDataFlowFiltered(filteredConfig)
    }
    setSelectedSectionFlowFiltered(selectedSectionFlowFiltered)
    setData(newData)
    remountForm(isPriField)
    // this.setState({ data }, () =>
    //   this.remountForm(isPriField),
    // )
  }
  const { sectionConfig, fieldConfig, highlightEmpty, dataFlowDisabled } = props

  let sectionMenu = null
  if (showSectionHeader) {
    sectionMenu = (
      <DataFlowNavigator
        ref={dataFlowNavigatorMenuRef}
        sectionConfig={sectionConfig}
        currentSectionName={currentSectionName}
        onSectionClick={goToSubmenu}
      />
    )
  }
  let form = null
  if (showForm) {
    form = (
      <DataFlowSummary
        style={{ marginTop: showSectionHeader ? 0 : 35 }}
        data={data}
        fieldConfig={fieldConfig}
        sectionLabel={currentSectionLabel}
        sectionFlow={selectedSectionFlowFiltered}
        fieldWarnings={fieldWarnings}
        highlightEmpty={highlightEmpty}
        dataFlowDisabled={dataFlowDisabled}
        verifiedFields={data.verifiedFields ? data.verifiedFields : {}}
        textHandler={textHandler}
        selectionHandler={selectionHandler}
      />
    )
  }

  return (
    <div ref={handleRef}>
      {sectionMenu}
      {form}
    </div>
  )
}

export default forwardRef(ConfigurableForm)
