import { useEffect, useState } from 'react'

import DataTable from '../../Tables/DataTable/DataTable'
import { IColumnConfig } from '../../Tables/DataTable/DataTableHeader'
import { ProfilePk, QueryResult } from '../../../types'

type DataQueryTrandsProfileProps = {
  initialSelectedRows: string[]
  profileFilterConfig: { key: string; label: string }[]
  profileTableColumnConfig: IColumnConfig[]
  profileTableRowData: QueryResult[]
  onBackClick: () => void
  onProfileSelect: (profileIds: string[]) => void
}

export const DataQueryTrendsProfiles = (props: DataQueryTrandsProfileProps) => {
  const [tableData, setTableData] = useState<Record<string, string>[]>([])
  const [refreshTimestamp, setRefreshTimestamp] = useState<number>(Date.now())

  useEffect(() => {
    prepareTableData()
  }, [])

  const prepareTableData = () => {
    const data = props.profileTableRowData.map(generateRowData)
    setTableData(data)
    setRefreshTimestamp(Date.now())
  }

  const generateRowData = (result: QueryResult, index: number) => {
    let rowData = { id: result.profilePk } as Record<string, string>
    props.profileTableColumnConfig.forEach((column) => (rowData[column.id] = result[column.id] ?? '-'))
    return rowData
  }

  const handleRowSelect = (selectedRows: Record<ProfilePk, boolean>) => {
    props.onProfileSelect(Object.keys(selectedRows))
  }

  return (
    <div style={styles.container}>
      <DataTable
        tableData={tableData}
        columnConfig={props.profileTableColumnConfig}
        tableWidth={10}
        filterState={{}}
        selectedRowItemId={''}
        customFilterConfig={props.profileFilterConfig}
        selectionEnabled={true}
        onRowSelect={handleRowSelect}
        disableRowClick={true}
        style={{ flex: 1 }}
        initialSelectedRows={props.initialSelectedRows}
        key={`dataTable_${refreshTimestamp}`}
      />
    </div>
  )
}

const styles = {
  container: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    display: 'flex',
    margin: '10px 14px 15px 10px',
    flexDirection: 'column' as 'column',
    height: '70vh',
  },
}
