import { Profile } from '../../models'

export enum ActionType {
  CHANGE_CURRENT_SECTION = 'CHANGE_CURRENT_SCREEN',
  CHANGE_SCREEN = 'CHANGE_SCREEN',
  CLEAR_PROFILE_WORKFLOW_CHANGES = 'CLEAR_PROFILE_WORKFLOW_CHANGES',
  OVERRIDE_PROFILE = 'OVERRIDE_PROFILE',
  PROFILE_CHANGES = 'PROFILE_CHANGES',
  RESET_CREDENTIALS = 'RESET_CREDENTIALS',
  // SET_ALL_COHORTS = 'SET_ALL_COHORTS',
  // SET_ALL_COMPANIES = 'SET_ALL_COMPANIES',
  // SET_ALL_DQ_PROFILES = 'SET_ALL_DQ_PROFILES',
  SET_ALL_PROFILES = 'SET_ALL_PROFILES',
  SET_ALL_REQUESTS = 'SET_ALL_REQUESTS',
  // SET_ALL_ROLES = 'SET_ALL_ROLES',
  SET_NESTED_OBJECT_VALUE_USER_MANAGER = 'SET_NESTED_OBJECT_VALUE_USER_MANAGER',
  SET_SELECTED_ASSOCIATION = 'SET_SELECTED_ASSOCIATION',
  SET_SELECTED_COHORT = 'SET_SELECTED_COHORT',
  SET_SELECTED_ROLE = 'SET_SELECTED_ROLE',
  UPDATE_STATE = 'UPDATE_STATE',
}

export interface ReducerAction {
  type: ActionType
  data: any
}

export function dataIsIProfile(data: any): data is Profile {
  return data.getPersonalUserInfo !== undefined
}
