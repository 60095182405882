import React, { Component } from 'react'

import { ColorPalette } from '../../../config/colors'
import { toUpperCaseCustom } from '../../../utils'
import CheckboxTab from '../Checkboxes/CheckboxTab'

interface CheckboxProps {
  highlightEmpty?: boolean
  values: string[]
  defaultValues?: string[]
  maxItemsPerRow?: number
  checkboxStyle?: React.CSSProperties | undefined
  itemLabelStyle?: React.CSSProperties | undefined
  style?: React.CSSProperties | undefined
  label: string
  selectorItems: string[]
  clickableLabels?: string[]
  disabled?: boolean
  maxItems?: number
  children?: React.ReactNode
  lineContainerStyle?: React.CSSProperties
  selectionHandler?: (values: string[], isMount: boolean) => void
  selectedLabelStyleAdjustment?: (label: string) => React.CSSProperties | undefined
  onLabelClick?: (label: string) => void
}

interface CheckboxState {
  isEmpty: boolean
}

class CheckboxTabLabelled extends Component<CheckboxProps, CheckboxState> {
  state = {
    isEmpty: false,
  }

  selectionHandler(values: string[], isMount: boolean): void {
    if (this.props.selectionHandler === undefined) {
      return
    }
    this.props.selectionHandler(values, isMount)
  }

  onLabelClick(label: string): void {
    if (this.props.onLabelClick === undefined) {
      return
    }
    this.props.onLabelClick(label)
  }

  render() {
    styles.label = {
      ...styles.label,
      color: this.state.isEmpty ? ColorPalette.WARNING_RED : ColorPalette.LIGHT_GREY,
    }
    styles.container = {
      ...styles.container,
      borderColor: this.state.isEmpty ? ColorPalette.WARNING_RED : 'rgba(195,205,225,0.6)',
    }

    return (
      <div style={{ ...styles.container, ...this.props.style }}>
        <p style={styles.label}>{toUpperCaseCustom(this.props.label)}</p>
        <CheckboxTab
          style={{ padding: 0 }}
          selectorItems={this.props.selectorItems}
          values={this.props.values}
          defaultValues={this.props.defaultValues}
          clickableLabels={this.props.clickableLabels}
          onLabelClick={(label: string) => this.onLabelClick(label)}
          selectionHandler={(values: string[], isMount: boolean) => this.selectionHandler(values, isMount)}
          disabled={this.props.disabled}
          labelStyle={this.props.itemLabelStyle}
          checkboxStyle={this.props.checkboxStyle}
          maxItemsPerRow={this.props.maxItemsPerRow}
          maxItems={this.props.maxItems}
          lineContainerStyle={this.props.lineContainerStyle}
          selectedLabelStyleAdjustment={this.props.selectedLabelStyleAdjustment}
        />
        {this.props.children}
      </div>
    )
  }
}

let styles: Record<string, React.CSSProperties> = {
  container: {
    zIndex: 1,
    marginTop: 20,
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    border: `1px solid ${ColorPalette.PRIMARY_BLUE}`,
    borderRadius: 12,
    paddingBottom: 15,
    paddingLeft: 60,
    paddingRight: 60,
  },
  label: {
    zIndex: 2,
    marginTop: -8,
    alignSelf: 'center',
    fontSize: '0.65rem',
    backgroundColor: ColorPalette.CARD_WHITE,
    paddingLeft: 10,
    paddingRight: 10,
  },
}

export default CheckboxTabLabelled
