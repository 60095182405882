import dayjs from 'dayjs'

import { AssociationId, CognitoUser, RoleName, Username } from '../../types/index'
import { Iam, IamSchema } from '../iam'
import { CommonUnixMsEnum } from '../../enums'

export type UserProfileSchema = {
  username: Username
  name?: string
  surname?: string
  email?: string
  mobile?: string
  roleNames?: RoleName[]
  authedMobileAppVersion?: string
}

export class User {
  private username: Username
  private name: string
  private surname: string
  private mobile: string
  private email: string
  private roleNames: RoleName[]
  private associationIamEntities?: Record<AssociationId, Iam>
  private signedIn: boolean
  private cognitoUser: CognitoUser

  constructor(obj: UserProfileSchema & { signedIn?: boolean; cognitoUser?: CognitoUser }) {
    this.username = obj.username
    this.name = obj.name || ''
    this.surname = obj.surname || ''
    this.mobile = obj.mobile || ''
    this.email = obj.email || ''
    this.roleNames = obj.roleNames || []
    this.signedIn = obj.signedIn !== undefined ? obj.signedIn : false
    this.cognitoUser = obj.cognitoUser || ({} as CognitoUser)
  }

  getCognitoConfirmationStatus() {
    return this.getCognitoUser().UserStatus
  }

  getCognitoUser() {
    return this.cognitoUser
  }

  getName(): string {
    return this.name
  }

  getSurname(): string {
    return this.surname
  }

  getFullName(): string {
    return `${this.getName()} ${this.getSurname()}`
  }

  getMobileNumber(): string {
    return this.mobile || ''
  }

  getEmail(): string {
    return this.email || ''
  }

  getPasswordExpiry(): string {
    let passwordExpiry = 'PROBLEM | CONTACT TECH SUPPORT'
    const { UserCreateDate = '', UserLastModifiedDate = '', UserStatus } = this.getCognitoUser()
    if (UserStatus === 'FORCE_CHANGE_PASSWORD') {
      const dateCreate = new Date(UserCreateDate)
      const dateModified = new Date(UserLastModifiedDate)
      const targetDate = dateModified ? dateModified : dateCreate
      const MS_IN_29_DAYS = 29 * CommonUnixMsEnum.ONE_DAY

      if (targetDate) {
        if (targetDate.getTime() + MS_IN_29_DAYS <= Date.now()) {
          passwordExpiry = 'EXPIRED'
        } else {
          passwordExpiry = `TEMP | EXPIRES ON ${dayjs(targetDate).add(29, 'day').format('YYYY/MM/DD')}`
        }
      }
    }
    return passwordExpiry
  }

  getAssocationIamEntity(associationId: AssociationId) {
    if (!this.associationIamEntities) {
      return
    }
    if (!this.associationIamEntities[associationId]) {
      return
    }
    return this.associationIamEntities[associationId]
  }

  getAllAssocationIamEntities() {
    return this.associationIamEntities || {}
  }

  getRoleNames(): string[] {
    return this.roleNames
  }

  getUsername(): string {
    return this.username
  }

  getPersonalUserInfo(): UserProfileSchema {
    return {
      username: this.username,
      name: this.name,
      surname: this.surname,
      mobile: this.mobile,
      email: this.email,
    }
  }

  getSigninState() {
    return this.signedIn
  }

  setSignin(signedIn: boolean) {
    this.signedIn = signedIn
  }

  setIamPermissions(iamPermissions: Record<AssociationId, IamSchema>) {
    let associationIamEntities = {} as Record<AssociationId, Iam>
    Object.keys(iamPermissions).forEach(
      (association) => (associationIamEntities[association] = new Iam(iamPermissions[association])),
    )
    this.associationIamEntities = associationIamEntities
  }

  toJson(): UserProfileSchema {
    return {
      username: this.username,
      name: this.name,
      surname: this.surname,
      mobile: this.mobile,
      email: this.email,
      roleNames: this.roleNames,
    }
  }
}
