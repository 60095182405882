import { userIdentifier } from '../config/localStorage'
import { User, UserProfileSchema } from '../models/user/user'
import { LocalStorageProvider } from '../providers'
import { AssociationId, Username } from '../types'
import { SessionErrorCodesEnum } from '../enums'
import { IamSchema } from '../models'

const { UsernameMismatch } = SessionErrorCodesEnum

export class UsersRepository {
  private associationUserEntities: Record<string, User> = {}
  private userProfileEntity: User

  constructor(userProfile: UserProfileSchema) {
    this.userProfileEntity = new User(userProfile)
    this.putLocalEntity(this.userProfileEntity)
  }

  getAssocationUserEntities() {
    return this.associationUserEntities
  }

  getCurrentUserEntity() {
    return this.userProfileEntity
  }

  putLocalEntity(userEntity: User) {
    const storageKey = this.getStorageId(userEntity.getUsername())
    LocalStorageProvider.setData(storageKey, userEntity.toJson())
  }

  getStorageId(username: Username) {
    return `${userIdentifier}${username}`
  }

  setAssociationUser(user: UserProfileSchema) {
    this.associationUserEntities[user.username] = new User(user)
  }

  setAssociationUsers(users: UserProfileSchema[]) {
    let entities = {} as Record<string, User>
    users.map((userSchema) => (entities[userSchema.username] = new User(userSchema)))
    this.associationUserEntities = entities
  }

  setIamPermissions(username: Username, iamSettings: Record<AssociationId, IamSchema>) {
    if (this.userProfileEntity.getUsername() !== username) {
      throw { code: UsernameMismatch }
    }
    this.userProfileEntity.setIamPermissions(iamSettings)
  }
}
