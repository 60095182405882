import { httpGet, httpPost } from '../remoteDataProvider'

export async function getCohortGroup(association: string, cohort: string, groupId: string, token: string) {
  const { data } = await httpGet(
    'people_flow_core',
    `/employees/groups/association/${association}/cohort/${cohort}/group/${groupId}`,
    token,
  )
  return data
}

export async function getCohortGroups(association: string, cohort: string, token: string) {
  const { data } = await httpGet(
    'people_flow_core',
    `/employees/groups/association/${association}/cohort/${cohort}`,
    token,
  )
  return data
}

export async function updateGroup(association: string, payload: any, token: string) {
  const { data } = await httpPost('people_flow_core', `/employees/groups/association/${association}`, payload, token)
  return data
}
