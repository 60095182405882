export enum GeneralErrorCodesEnum {
  AlreadyExists = 'AlreadyExists',
  AssociationMismatch = 'AssociationMismatch',
  // NoWebAccess = 'NoWebAccess',
  // LoginFailed = 'LoginFailed',
  InvalidRequestInstanceTimestamp = 'InvalidRequestInstanceTimestamp',
  NoDocs = 'NoDocs',
  NoExportProfilesSelected = 'NoExportProfilesSelected',
  NoExportProfilesSelectedFromProfilesPage = 'NoExportProfilesSelectedFromProfilesPage',
  NoInput = 'NoInput',
  NoToken = 'NoToken',
  PasswordMismatch = 'PasswordMismatch',
  InvalidPassword = 'InvalidPassword',
  MissingInfo = 'MissingInfo',
  NetworkTimeout = 'NetworkTimeout',
  MissingProfile = 'MissingProfile',
}

export enum DataQueryErrorCodesEnum {
  MaxTimeRangeExceeded = 'MaxTimeRangeExceeded',
  MissingQueryId = 'MissingQueryId',
  DuplicateTrendName = 'DuplicateTrendName',
}

export enum SessionErrorCodesEnum {
  LoginError = 'LoginError',
  MissingAssociationSettings = 'MissingAssociationSettings',
  MissingUsername = 'MissingUsername',
  NewPasswordRequired = 'NewPasswordRequired',
  UsernameMismatch = 'UsernameMismatch',
}

export enum IamServiceErrorCodesEnum {
  NoWebAccess = 'NoWebAccess',
  AccountInArrears = 'AccountInArrears',
  AccountDeactivated = 'AccountDeactivated',
  Unauthorised = 'Unauthorised',
}
