import { ColorPalette } from '../../config/colors'
import { toUpperCaseCustom, toLowerCaseCustom, removeUnderScores, formatDateAndTime } from '../../utils'

import React, { Component } from 'react'
import Radium from 'radium'
import { ScrollView } from '@cantonjs/react-scroll-view'
import Icon from '@mdi/react'
import { mdiPlusCircleOutline, mdiCog } from '@mdi/js'
import DescriptionIconOutlined from '@material-ui/icons/DescriptionOutlined'

import ButtonGeneric from '../BaseComponents/Buttons/ButtonGeneric'
import ButtonRound from '../BaseComponents/Buttons/ButtonRound'

interface FormSideMenuProps {
  published?: Record<string, any>
  depublished?: Record<string, any>
  selectedVersion?: string
  drafts: Record<string, any>
  docName: string

  addDraft: () => void
  onDocSettingsClicked: () => void
  onVersionClicked: (category: string, version: string) => void
}

class FormSideMenu extends Component<FormSideMenuProps> {
  generateSection = (category: string, data: Record<string, any>, showDividerLine: boolean = false) => {
    const versions = Object.keys(data).reverse()
    let addDraftButton = null
    let categoryButtons: JSX.Element | JSX.Element[] = (
      <p style={styles.noVersionsMessage}>{`No ${toLowerCaseCustom(category)} ${
        category === 'Drafts' ? '' : 'templates'
      }`}</p>
    )
    let dividerLine = null

    if (showDividerLine) {
      dividerLine = <div style={{ ...styles.dividerLine, marginLeft: window.innerWidth * 0.015 }} />
    }
    if (category === 'Drafts') {
      addDraftButton = (
        <ButtonGeneric
          style={{ ...styles.button, backgroundColor: 'transparent' }}
          iconBefore={<Icon path={mdiPlusCircleOutline} size={1} color={ColorPalette.PRIMARY_BLUE} />}
          label={'\xa0\xa0\xa0Add draft'}
          onClick={() => this.props.addDraft()}
        />
      )
    }

    if (versions.length) {
      categoryButtons = versions.map((version, index) => {
        const { day, month, year, hour, minutes } = formatDateAndTime(new Date(parseInt(version)))
        const label = `${day}-${month}-${year}, ${hour}:${minutes}`
        return (
          <ButtonGeneric
            key={label}
            style={{
              ...styles.button,
              backgroundColor: this.props.selectedVersion === version ? ColorPalette.BUTTON_ACTIVE_GREY : 'transparent',
            }}
            iconBefore={<DescriptionIconOutlined style={styles.buttonIcon} />}
            label={label}
            onClick={() => this.props.onVersionClicked(toLowerCaseCustom(category), version)}
          />
        )
      })
    }

    const component = (
      <div style={styles.versionCategory}>
        {dividerLine}
        <p style={{ ...styles.categoryHeader, marginTop: dividerLine ? 20 : 0 }}>{category}</p>
        {addDraftButton}
        {categoryButtons}
      </div>
    )

    return component
  }

  render() {
    return (
      <div style={styles.container}>
        <ButtonRound
          style={{ marginTop: 30 }}
          diameter={80}
          icon={<Icon path={mdiCog} size={3} color={ColorPalette.PRIMARY_BLUE} />}
          label={''}
          onClick={this.props.onDocSettingsClicked}
        />

        <p style={styles.nameStyle}>{toUpperCaseCustom(removeUnderScores(this.props.docName))}</p>

        <div style={styles.dividerLine} />

        <ScrollView style={{ height: window.innerHeight * 0.5 }} contentContainerStyle={{ paddingBottom: 40 }}>
          {this.generateSection('Drafts', this.props.drafts)}
          {this.generateSection('Published', this.props?.published || {}, true)}
          {this.generateSection('Depublished', this.props?.depublished || {}, true)}
        </ScrollView>
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  nameStyle: {
    marginTop: 15,
    fontSize: '0.9rem',
    fontWeight: '550',
    fontFamily: 'Roboto',
    color: ColorPalette.PRIMARY_TEXT,
    width: '84%',
    textAlign: 'center' as 'center',
  },
  dividerLine: {
    width: '86%',
    marginTop: 10,
    marginBottom: 10,
    borderBottom: `1px solid ${ColorPalette.LIGHT_GREY}`,
  },
  versionCategory: {
    width: window.innerWidth * 0.21,
    marginTop: 10,
    marginBottom: 20,
  },
  categoryHeader: {
    fontSize: '0.8rem',
    fontWeight: '600',
    color: ColorPalette.SECONDARY_TEXT,
    marginLeft: 30,
  },
  noVersionsMessage: {
    color: ColorPalette.TERTIARY_TEXT,
    fontSize: '0.8rem',
    fontWeight: '600',
    marginLeft: 50,
    marginBottom: 30,
  },
  button: {
    fontWeight: '550',
    fontSize: '0.8rem',
    backgroundColor: ColorPalette.CARD_WHITE,
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    paddingLeft: 30,
    marginTop: 0,
    ':hover': {
      backgroundColor: 'rgba(242,242,250,1)',
    },
    ':active': {
      backgroundColor: 'rgba(222,222,230,1)',
    },
  },
  iconStyle: {
    marginTop: 30,
    width: '5.5rem',
    height: '5.5rem',
    color: ColorPalette.PRIMARY_BLUE,
  },
  buttonIcon: {
    width: '1.3rem',
    height: '1.3rem',
    color: ColorPalette.PRIMARY_BLUE,
    marginRight: 15,
  },
}

export default Radium(FormSideMenu)
