import Dialog from '@material-ui/core/Dialog'
import { ColorPalette } from '../../config/colors'

type ModalProps = {
  open: boolean
  title: string
  subTitle?: string
  children: React.ReactNode
  actionButtons?: React.ReactNode[]
  borderRadius?: number
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  fullScreen?: boolean
  fullWidth?: boolean
  titleStyle?: React.CSSProperties
  buttonStyle?: React.CSSProperties
  contentStyle?: React.CSSProperties
  style?: React.CSSProperties
  onBackdropClick?: () => void
}

const Modal = (props: ModalProps) => {
  const {
    open,
    title,
    children,
    actionButtons,
    fullScreen,
    fullWidth,
    maxWidth,
    buttonStyle,
    titleStyle,
    style,
    onBackdropClick,
  } = props
  let dialogActions = null
  if (actionButtons && actionButtons.length > 0) {
    dialogActions = (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', ...buttonStyle }}>
        {actionButtons}
      </div>
    )
  }

  let subTitle = null
  if (props.subTitle) {
    subTitle = (
      <p style={{ ...styles.textStyle, marginTop: 10, fontSize: '0.9rem', fontWeight: 'normal' }}>{props.subTitle}</p>
    )
  }

  return (
    <Dialog
      open={open}
      onBackdropClick={onBackdropClick}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      BackdropProps={{ invisible: true }}
      PaperProps={{ style: { borderRadius: 8 } }}
      style={{ ...styles.modal, ...style }}>
      <div
        style={{
          width: '100%',
          padding: '30px 20px',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <h1 style={{ ...styles.textStyle, ...titleStyle }}>{title}</h1>
        {subTitle}
        {children}
        {dialogActions}
      </div>
    </Dialog>
  )
}

export default Modal

const styles = {
  modal: {
    backgroundColor: ColorPalette.MODAL_BACKGROUND_OVERLAY,
  },
  textStyle: {
    margin: 0,
    width: '94%',
    textAlign: 'center' as 'center',
    alignSelf: 'center' as 'center',
    fontFamily: 'roboto',
    fontWeight: 'bold',
    color: 'rgb(35, 20, 140)',
    fontSize: '1.2rem',
  },
}
