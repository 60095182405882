import { ColorPalette } from '../../../config/colors'

import React from 'react'
import Radium from 'radium'
import { GridSize } from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'

import ButtonGeneric from '../../BaseComponents/Buttons/ButtonGeneric'

interface TableHeaderProps {
  selectionEnabled?: boolean
  allSelected?: boolean
  tableWidth: GridSize
  columnConfig: any[]
  onClick: (id: string) => void
  onSelectAll?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const tableHeader: React.FC<TableHeaderProps> = (props: TableHeaderProps) => {
  let checkbox: JSX.Element | null = null

  if (props.selectionEnabled) {
    checkbox = (
      <div key={'checkbox'} style={{ ...styles.checkboxContainer }}>
        <Checkbox style={{ ...styles.checkbox }} checked={props.allSelected} onChange={props?.onSelectAll} />
      </div>
    )
  }
  return (
    <div
      style={{
        ...styles.rowContainer,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flex: '0 0 auto',
      }}>
      {checkbox}
      {[...props.columnConfig].map((configItem, i) => (
        <ButtonGeneric
          key={configItem.id}
          style={{
            ...styles.cellStyle,
            width: ((window.innerWidth * (props.tableWidth as number)) / 12 - 90) / props.columnConfig.length,
          }}
          iconBefore={configItem.iconComponent}
          label={configItem.label}
          onClick={() => props.onClick(configItem.id)}
          disabled={configItem.disabled}
        />
      ))}
    </div>
  )
}

const styles = {
  rowContainer: {
    paddingRight: 15,
    height: 45,
    backgroundColor: ColorPalette.DARK_GREY,
    width: '100%',
  },
  checkboxContainer: {
    width: 75,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkbox: {
    backgroundColor: 'white',
    padding: 0,
    borderRadius: 0,
    border: `1px solid ${ColorPalette.SECONDARY_TEXT}`,
    width: 16,
    height: 16,
  },
  cellStyle: {
    height: 40,
    padding: '0px 20px',
    backgroundColor: ColorPalette.DARK_GREY,
  },
  cellIcon: {
    width: '1.5rem',
    height: '1.5rem',
    color: ColorPalette.PRIMARY_LIGHT_TEXT,
    marginLeft: 20,
  },
}

export default Radium(tableHeader)
