import { FormControl } from '@material-ui/core'

import { ColorPalette } from '../../../config/colors'
import TextInputLabelled from '../../BaseComponents/Text/TextInputLabelled'
import Picker, { PickerHandlerEvent } from '../../BaseComponents/Pickers/Picker'

type DescriptionProps = {
  issuedPrice: string
  skuCode: string
  selectedSupplier: string
  selectedItemDescription: string
  suppliers: string[]
  currentItemDescriptions: string[]
  newItemDescription?: string
  additionMode: boolean
  onInputChange: (field: string, value: string) => void
  onSupplierAdd: () => void
  onItemDescriptionAdd: () => void
}

export const Description = (props: DescriptionProps) => {
  const {
    issuedPrice,
    skuCode,
    selectedSupplier,
    selectedItemDescription,
    currentItemDescriptions,
    newItemDescription,
    suppliers,
    additionMode,
    onInputChange,
    onItemDescriptionAdd,
    onSupplierAdd,
  } = props

  const handleItemDescriptionChange = (event: PickerHandlerEvent) => {
    const selection = event.target.value
    if (selection === 'Choose item description') {
      onInputChange('itemDescription', '')
      return
    }
    if (selection === '+ Add item description') {
      onItemDescriptionAdd()
      return
    }
    onInputChange('itemDescription', selection)
  }

  const handleSkuCodeChange = (value: string, isMount?: boolean) => {
    onInputChange('skuCode', value)
  }

  const handleSupplierChange = (event: PickerHandlerEvent) => {
    const selection = event.target.value
    if (selection === 'Choose supplier') {
      onInputChange('supplier', '')
      return
    }
    if (selection === '+ Add supplier') {
      onSupplierAdd()
      return
    }
    onInputChange('supplier', selection)
  }

  let itemDescriptionOptions = ['Choose item description']
  if (additionMode) {
    itemDescriptionOptions = [...itemDescriptionOptions, '+ Add item description']
  }
  itemDescriptionOptions = [...itemDescriptionOptions, ...currentItemDescriptions]
  if (newItemDescription && newItemDescription.length > 0) {
    itemDescriptionOptions.push(newItemDescription)
  }

  const supplierOptions = ['Choose supplier', '+ Add supplier', ...suppliers]
  let lastUpdatedDateInfo = null
  if (!additionMode) {
    lastUpdatedDateInfo = (
      <div
        style={{
          color: ColorPalette.PRIMARY_BLUE,
          fontSize: '0.7rem',
          float: 'right',
          marginTop: 3,
          marginRight: 'calc(2.5% + 3ch)',
        }}>
        {/* Last updated: 01/01/2023 */}
      </div>
    )
  }

  return (
    <FormControl style={styles.inputContainer}>
      <p style={styles.inputContainerLabel}>PPE DESCRIPTION</p>
      <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
        <div style={{ width: '50%', marginTop: 10 }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Picker
              label="ITEM DESCRIPTION"
              value={selectedItemDescription}
              placeholder=""
              items={itemDescriptionOptions}
              onChange={handleItemDescriptionChange}
              style={{ border: 'none', margin: 0, width: '90%' }}
              fontStyle={{ color: ColorPalette.PRIMARY_TEXT, fontSize: '0.9rem' }}
              labelStyle={{ color: ColorPalette.LIGHT_GREY, fontSize: '0.9rem' }}
              disabled={!additionMode}
              key="itemDescription"
            />
          </div>
        </div>
        <div style={{ width: '50%', marginTop: 10 }}>
          <TextInputLabelled
            inputProps={{ color: ColorPalette.PRIMARY_TEXT, fontSize: '0.9rem' }}
            key="skuCode"
            label="SKU CODE"
            // icon={item.iconName}
            value={skuCode}
            // defaultValue={defaultValue}
            // maxLength={100}
            textHandler={handleSkuCodeChange}
            disabled={!additionMode}
          />
        </div>
        <div style={{ width: '50%', marginTop: 30 }}>
          <TextInputLabelled
            inputProps={{ color: ColorPalette.PRIMARY_TEXT, fontSize: '0.9rem' }}
            key="issuedPrice"
            label="ISSUED PRICE"
            // icon={item.iconName}
            value={issuedPrice}
            // defaultValue={defaultValue}
            // maxLength={100}
            textHandler={(value, isMount) => {
              onInputChange('issuedPrice', value)
            }}
          />
          {lastUpdatedDateInfo}
        </div>
        <div style={{ width: '50%', marginTop: 30 }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Picker
              label="SUPPLIER"
              value={selectedSupplier}
              placeholder=""
              items={supplierOptions}
              onChange={handleSupplierChange}
              style={{ border: 'none', margin: 0, width: '90%' }}
              fontStyle={{ color: ColorPalette.PRIMARY_TEXT, fontSize: '0.9rem' }}
              labelStyle={{ color: ColorPalette.LIGHT_GREY, fontSize: '0.9rem' }}
              disabled={!additionMode}
            />
          </div>
        </div>
      </div>
    </FormControl>
  )
}

const styles = {
  inputContainer: {
    minWidth: 120,
    marginTop: 5,
    paddingTop: 15,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 40,
    border: `1px solid rgba(195,205,225,0.6)`,
    borderRadius: 12,
    display: 'flex',
    justifyContent: 'center',
  },
  inputContainerLabel: {
    zIndex: 2,
    marginTop: -22,
    alignSelf: 'center',
    fontSize: '0.65rem',
    color: ColorPalette.LIGHT_GREY,
    backgroundColor: ColorPalette.CARD_WHITE,
    paddingLeft: 10,
    paddingRight: 10,
  },
}
