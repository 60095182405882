import { AssociationSettingsRepository } from '../repositories'
import { ProfilePk } from './common'
import { PpeActionReason, PpeActions, PpeStatus } from '../enums'
import { FieldConfigItemSchema, FieldConfigKey, ValidFieldType } from './fieldConfigTypes'
import { PpeInstanceRecordSchema } from '../models/ppe'

export type PpeType = string
export type PpeSku = string
export type PpeSize = string
export type PpeQuantity = number

export type PpeTypeUpsert = Pick<PpeTypeConfig, 'key'> & Partial<PpeTypeConfig>

export type PpeActionResponse = {
  message: string
  result: any
}
export type PpeInstancesResponse = {
  data: {
    message: string
    instanceRecordDtos: Record<PpeRecordSk, PpeInstanceRecordSchema>
  }
}
export type PpeRequestItem = {
  selectedSizes: Record<string, any>[]
}
// Record<
//         Association,
//         Record<FieldConfigKey, Record<PpeSize, { qty: PpeQuantity; skus?: PpeSku[] }[]>>
//     >;
export type PpeStatusType =
  | PpeStatus.READY_FOR_ISSUE
  | PpeStatus.ISSUING
  | PpeStatus.RETURN_IN_PROGRESS
  | PpeStatus.COMPLETE

export type PpeRequest = {
  pk: string
  sk: string
  association: string
  dateActioned: number // UNIX timestamp
  requestName: string
  status: PpeStatusType
  lastStatus?: PpeStatusType
  seedGroupId?: string
  statusSeedGroupId?: string
}
export type PpeRecordSk = string
export type PpeSupplierKeyValuePairs = Record<string, string>
export type PpeUpsertResponse = {
  updatedType: Record<string, PpeTypeConfig>
  updatedAssociationRepo: AssociationSettingsRepository
}

export type PpeActionReasonType =
  | PpeActionReason.RESERVE
  | PpeActionReason.LOST
  | PpeActionReason.DAMAGED
  | PpeActionReason.STOLEN
  | PpeActionReason.RETURNED

export type PpeInstanceRecordSk = string
export type RequestedPpeIssueItems = Record<ProfilePk, Record<FieldConfigKey, PpeRequestItem>>

type PpeActionKey = keyof typeof PpeActions
export type PpeAction = (typeof PpeActions)[PpeActionKey]

export type PpeSupplierName = string
export type PpeSupplier = {
  bookValue: string
  bookValueCurrency?: 'ZAR'
  supplier: string
  supplierSizeOptions: string[]
}

export type PpeSuppliers = Record<PpeSku, PpeSupplier>
export type PpeTypeConfig = {
  key: string
  bookValue: string
  bookValueCurrency?: 'ZAR'
  label: string
  compType: ValidFieldType
  selectorItems?: string[]
  selectionPriority?: number
  supplier?: string
  suppliers: PpeSuppliers
  skus: string[]
  isPpe: boolean
}

export type PpeInventoryTransferTableRowItem = {
  id: string
  label: FieldConfigItemSchema & {
    value: string
    props: { style?: React.CSSProperties; fontStyle?: React.CSSProperties }
  }
  ppeSize: FieldConfigItemSchema & {
    value: string
    props: { style?: React.CSSProperties; fontStyle?: React.CSSProperties }
  }
  noOfUnits: FieldConfigItemSchema & {
    value: string
    props: { placeholder?: string; style?: React.CSSProperties; fontStyle?: React.CSSProperties }
  }
  availableToTransfer: string
}

export enum PpeInventoryViewModes {
  INVENTORY_ADDITION = 'INVENTORY_ADDITION',
  INVENTORY_TRANSFER = 'INVENTORY_TRANSFER',
  INVENTORY_REMOVAL = 'INVENTORY_REMOVAL',
  INVENTORY_LIST = 'INVENTORY_LIST',
}
