import { get } from 'lodash'

import { checkEmailFormat, checkDigitsOnly } from './regexUtils'
import { copyObjectImmutibly } from './objectUtils'
import { toUpperCaseCustom } from './stringUtils'

export function getSectionCompletionState(
  dataFlow: Record<string, Record<string, any>[]>,
  dataObject: Record<string, any>,
  treatAllFieldsAsCompuslory: boolean = false,
) {
  let completeSections: Record<string, boolean> = {}
  const sectionKeys = Object.keys(dataFlow)

  sectionKeys.forEach((currentSection) => {
    let sectionComplete = true
    dataFlow[currentSection].forEach((item) => {
      const value = get(dataObject, `${item.key}`, '')
      if ((item.compulsory || treatAllFieldsAsCompuslory) && !value.length) {
        sectionComplete = false
      }
    })
    completeSections[currentSection] = sectionComplete
  })
  return completeSections
}

export function filterDataFlow(originalState: Record<string, Record<string, any>[]>, sourceData: Record<string, any>) {
  let filters: Record<string, any> = {}
  let config = copyObjectImmutibly(originalState)

  try {
    const sectionKeys = Object.keys(config)
    sectionKeys.forEach((section) => {
      config[section].forEach((item) => {
        try {
          if (item.workflowBranch && sourceData[item.key] && sourceData[item.key].length) {
            filters[item.key] =
              typeof sourceData[item.key] === 'string' ? sourceData[item.key] : sourceData[item.key][0]
          }
        } catch (err) {}
      })
    })

    Object.keys(filters).forEach((filterKey) => {
      sectionKeys.forEach((section) => {
        config[section] = [...config[section]].filter((item) => {
          if (
            item.filterParameters &&
            filterKey in item.filterParameters &&
            toUpperCaseCustom(item.filterParameters[filterKey]) !== toUpperCaseCustom(filters[filterKey])
          ) {
            return false
          } else {
            return true
          }
        })
      })
    })
  } catch (error) {}
  return [config, filters]
}

export function inputValidator(compulsory: boolean, validationType: string, text: string = '') {
  if (compulsory && !text.length) {
    return { valid: false, warning: 'This field is compulsory' }
  } else if (validationType === 'email') {
    if (text && !checkEmailFormat(text)) {
      return { valid: false, warning: 'Invalid email address' }
    }
  } else if (validationType === 'mobile') {
    if (text.length !== 10 && text.length !== 0) {
      return { valid: false, warning: 'Must be a 10 digit SA mobile number' }
    } else if (!checkDigitsOnly(text) && text.length !== 0) {
      return { valid: false, warning: 'Must only contain numbers' }
    }
  } else if (validationType === 'landline') {
    if (text.length !== 10 && text.length !== 0) {
      return { valid: false, warning: 'Must be a 10 digit SA landline number' }
    } else if (!checkDigitsOnly(text) && text.length !== 0) {
      return { valid: false, warning: 'Must only contain numbers' }
    }
  } else if (validationType === 'bankInitialSurname') {
    let textElements = text.split(' ')
    textElements.shift()
    const surnameLength = textElements.join('').length

    if (surnameLength < 3) {
      return { valid: false, warning: 'Surname must be at least 3 letters' }
    }
  } else if (validationType === 'accNumber') {
    if (text === '0') {
      return { valid: false, warning: 'Invalid account number' }
    } else if (!checkDigitsOnly(text)) {
      return { valid: false, warning: 'Must only contain numbers' }
    }
  }
  return { valid: true, warning: '' }
}
