import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory, RouteComponentProps } from 'react-router'
import { mdiInformationOutline } from '@mdi/js'
import { Icon } from '@mdi/react'

import { ColorPalette, rootNavPaths, navButtonsWithCustomActions } from '../../config'
import {
  ScreenNamesEnum,
  MenuSchema,
  SelectorConfig,
  SelectorItemsConfig,
  NavMenuAccess,
  EmploymentStatus,
} from '../../types'
import { toLowerCaseCustom, checkEmailFormat, addUnderScores, toUpperCaseCustom } from '../../utils'
import { ActionType } from '../../store/actions/actions'
import NavButton from '../BaseComponents/Buttons/NavButton'
import logoHeader from '../../assets/logoHeader2.png'
import AccountManagementButton from '../BaseComponents/Buttons/AccountManagementButton'
import AccountManagementModal from '../Modals/AccountManagementModal'
import ForgotPasswordModal from '../Modals/ForgotPassword'
import OptionsSelectorModal from '../Modals/OptionsSelectorModal'
import TerminationDataUploadModal from '../Modals/BulkTerminationModal'
import AlertModalOneButton from '../Modals/AlertModalOneButton'
import LoadingModal from '../Modals/LoadingModal'
import InfoCollectorModal from '../Modals/InfoCollector'
import WithRouterHistory from '../Routing/WithRouterHistory'
import { PeopleFlowCombinedReducer } from '../../store'
import { PickerHandlerEvent } from '../BaseComponents/Pickers/Picker'
import { CommonTs } from '../../models/common-ts/commonts'
// import { SettingsModal } from '../Modals/SettingsModal'
import { SessionService, ImportExportService, NavBarService, ConfigService, IamService } from '../../services'
import HierarchialPicker from '../Modals/HierarchialPickerModal'
import { AssociationSettingsRepository, ProfileRepository, UsersRepository } from '../../repositories'
import { GeneralErrorCodesEnum } from '../../enums/errorCodeTypes'

const importExportService = new ImportExportService()
const sessionService = new SessionService()
const navBarService = new NavBarService()
const configService = new ConfigService()
const iamService = new IamService()

const subMenuEmploymentStatusMap = {
  [ScreenNamesEnum.EMPLOYEES]: EmploymentStatus.EMPLOYEE,
  [ScreenNamesEnum.CANDIDATES]: EmploymentStatus.CANDIDATE,
} as Record<string, EmploymentStatus>

/**
TODO: Use one OptionSelectorModal instance along with the required function handlers. i.e. Don't repeat OptionSelectorModal.
*/
const availableExportFormats = ['xlsx', 'txt', 'csv']
type MenuActions = 'exportTemplate' | 'exportFormat'

interface NavigationBarProps extends RouteComponentProps {
  primaryTableRef?: React.RefObject<any>
  reloadPageData?: () => void
}

const NavBar = (props: NavigationBarProps) => {
  const location = useLocation()
  const history = useHistory()

  const initialModalState = {
    loadingModalOpen: false,
    warningModalOpen: false,
    accountModalOpen: false,
    forgotPasswordModalOpen: false,
    settingsModalOpen: false,
    dataOptionsSelectorModalOpen: false,
    emailCollectorModalOpen: false,
    docAuditReportEmailCollectorModalOpen: false,
    bulkDisciplineEmailCollectorModalOpen: false,
    bulkDisciplineDateModalOpen: false,
    templateOptionsSelectorModalOpen: false,
    terminationModalOpen: false,
    docAuditReportExportSuccessModalOpen: false,
    messageModalHeader: '',
    messageModalMessage: '' as string | JSX.Element,
    loadingModalMessage: '',
  }

  const [modalState, setModalState] = useState(initialModalState)
  const [disciplineWarningMessage, setDisciplineWarningMessage] = useState('')
  const [menuStructure, setMenuStructure] = useState([] as MenuSchema[])
  const [exportTemplate, setExportTemplate] = useState('')
  const [exportFormat, setExportFormat] = useState('xlsx')
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [availableExportConfigs, setAvailableExportConfigs] = useState<string[]>([])

  const [refreshTimestamp, setRefreshTimestamp] = useState<Date>(new Date())
  const [accountSettingsPickerConfig, setAccountSettingsPickerConfig] = useState({
    selectorConfig: [],
    selectorItemsConfig: {},
  } as { selectorConfig: SelectorConfig; selectorItemsConfig: SelectorItemsConfig })

  const idPassport = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.idPassport)
  const password = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.password)
  const associationRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.associationRepo,
  ) as AssociationSettingsRepository
  const userRepo = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.userRepo) as UsersRepository
  const selectedAssociation = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation,
  )
  const selectedCohort = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.selectedCohort)
  const currentScreen = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.currentScreen)
  const currentSection = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.currentSection)

  const selectedEmploymentStatus = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedEmploymentStatus,
  )

  const dispatch = useDispatch()
  const updateState = (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data })
  const setSelectedAssociation = (selectedAssociation: string) =>
    dispatch({ type: ActionType.SET_SELECTED_ASSOCIATION, data: { selectedAssociation } })
  const setSelectedCohort = (selectedCohort: string) =>
    dispatch({ type: ActionType.SET_SELECTED_COHORT, data: { selectedCohort } })

  useEffect(() => {
    initialise()
    const { pathname, state = {} } = props.history.location
    const accountPickerConfig = configService.generateHierarchialAccountPickerConfig(associationRepo, {
      selectedAssociation,
      selectedCohort,
    })
    setAccountSettingsPickerConfig(accountPickerConfig)
    // @ts-ignore
    if (pathname === '/people/employees' && state.openTerminationModal) {
      setModalState({ ...initialModalState, terminationModalOpen: true })
    }
  }, [])

  useEffect(() => {
    if (!selectedAssociation || !selectedCohort) {
      return
    }
    initialise()
  }, [selectedAssociation, selectedCohort])

  const initialise = () => {
    const iamEntity = userRepo.getCurrentUserEntity().getAssocationIamEntity(selectedAssociation)
    if (!iamEntity) {
      console.log("No IAM entity found on the user's profile for the selected association")
      return
    }
    const navMenuAccess = iamService.buildNavMenuAccess(iamEntity)
    const menuStructure = navBarService.buildMenuTabConfig(navMenuAccess)
    const availableExportConfigs = associationRepo.getAvailableExportConfigs(selectedAssociation)
    updateState({ navMenuAccess })
    setMenuStructure(menuStructure)
    setExportTemplate(availableExportConfigs.length ? availableExportConfigs[0] : '')
    setAvailableExportConfigs(availableExportConfigs)
  }

  const closeModals = () => {
    setModalState(initialModalState)
  }

  const forgotPassword = async () => {
    closeModals()
    setTimeout(() => setModalState((modalState) => ({ ...modalState, forgotPasswordModalOpen: true })), 250)
    await sessionService.forgotPassword(idPassport)
  }

  const openSettings = () => {
    if (currentScreen !== ScreenNamesEnum.DATA_MANAGER) {
      setModalState({ ...initialModalState, settingsModalOpen: true })
      return
    }
    const messageModalHeader = 'Disabled'
    const messageModalMessage = "Setting changes are disabled while you're busy on this screen."
    setModalState({
      ...initialModalState,
      warningModalOpen: true,
      messageModalHeader,
      messageModalMessage,
    })
  }

  const settingsChangedHandler = async (targetSettings: Record<string, string>) => {
    setModalState({
      ...initialModalState,
      loadingModalOpen: true,
      loadingModalMessage: 'Refreshing...',
    })
    let { association, cohort } = targetSettings
    association = addUnderScores(toLowerCaseCustom(association))
    cohort = toUpperCaseCustom(cohort)
    const hasAssociationChange = association !== selectedAssociation
    const hasCohortChange = cohort !== selectedCohort
    if (!hasAssociationChange && !hasCohortChange) {
      closeModals()
      return
    }
    setTimeout(async () => {
      if (hasAssociationChange) {
        const newAssociationSettingsJson = await sessionService.hydrateAssociationSettings([association], {
          username: idPassport,
          password,
        })
        await associationRepo.initialise(newAssociationSettingsJson)
        const newProfileRepo = new ProfileRepository()
        await newProfileRepo.loadLocalProfileEntities(association)
        updateState({ profileRepo: newProfileRepo })
      }
      setSelectedAssociation(association)
      setSelectedCohort(cohort)
      await sessionService.saveAppLaunchSettings(association, cohort)

      const { pathname } = location
      const onPeopleListingPage = pathname === '/people/employees' || pathname === '/people/candidates'
      if (!onPeopleListingPage) {
        history.push('/people/employees')
      }
      if (props.reloadPageData) {
        props.reloadPageData()
      }
      closeModals()
    }, 200)
  }

  const exportPicker = (event: PickerHandlerEvent, keyName: MenuActions) => {
    if (keyName === 'exportTemplate') {
      setExportTemplate(event.target.value)
    } else if (keyName === 'exportFormat') {
      setExportFormat(event.target.value)
    }
  }

  const exportHandler = (type: string) => {
    if (type === 'profileData') {
      setModalState({ ...initialModalState, emailCollectorModalOpen: true })
    } else if (type === 'bulkDiscipline') {
      setModalState({ ...initialModalState, bulkDisciplineEmailCollectorModalOpen: true })
    }
  }

  const dateHandler = (selectedDate: Date) => {
    const currentDate = new Date()
    if (selectedDate.getTime() > currentDate.getTime() && selectedDate.getDay() !== currentDate.getDay()) {
      setDisciplineWarningMessage("That's in the future")
    } else {
      setSelectedDate(selectedDate)
      setDisciplineWarningMessage('')
    }
  }

  const getSelectedProfileIdPassports = (): string[] | undefined => {
    let selected = [] as string[]
    if (!props.primaryTableRef) {
      return undefined // indicates that we are not on the Profiles pages
    }
    if (props.primaryTableRef.current) {
      selected = Object.keys(props.primaryTableRef.current.getSelectedRows())
    }
    return selected
  }

  const planTerminationImport = async (csvFileName: string): Promise<CommonTs.Import.IImportPlan[] | void> => {
    try {
      setModalState({ ...initialModalState, loadingModalOpen: true, loadingModalMessage: 'Processing...' })
      const options: CommonTs.Import.ITerminateProfileOptions = {
        beforeAfterOptions: {
          profileKeysToKeep: ['idNumber'],
          generalDataKeysToKeep: ['idNumber', 'name', 'surname', 'terminationDate'],
        },
      }
      const results = await importExportService.planTerminationImport(
        selectedAssociation,
        csvFileName,
        { username: idPassport, password },
        options,
      )
      setModalState((modalState) => ({ ...modalState, loadingModalOpen: false, loadingModalMessage: '' }))
      return results || []
    } catch (error) {
      console.error(error)
      displayWarning(error)
    }
  }

  const exportProfiles = async (emailAddresses: string[]): Promise<void> => {
    try {
      setModalState({
        ...initialModalState,
        loadingModalOpen: true,
        loadingModalMessage: 'Exporting...',
      })
      const selectedProfileIdPassports = getSelectedProfileIdPassports()

      if (selectedProfileIdPassports === undefined) {
        throw { code: GeneralErrorCodesEnum.NoExportProfilesSelectedFromProfilesPage }
      }

      if (!selectedProfileIdPassports?.length) {
        throw { code: GeneralErrorCodesEnum.NoExportProfilesSelected }
      }
      importExportService.exportRemoteData(
        selectedAssociation,
        selectedProfileIdPassports,
        exportTemplate,
        exportFormat,
        emailAddresses,
        { username: idPassport, password },
      )
      setTimeout(() => {
        /**
         * Added to enhance the UX as it is disconcerting when an immediate result is given. (Makes the user feel as though nothing happened).
         */
        displayWarning({ code: 'ExportTriggered' })
      }, 500)
    } catch (error) {
      displayWarning(error)
    }
  }

  const exportDocAuditReports = async (emailAddresses: string[]): Promise<void> => {
    setModalState({
      ...initialModalState,
      loadingModalOpen: true,
      loadingModalMessage: 'Generating reports...',
    })
    const allCohorts = associationRepo.getAssociationSettingsEntity(selectedAssociation).getAllCohorts()
    importExportService.exportDocAuditReports(selectedAssociation, emailAddresses, allCohorts, {
      username: idPassport,
      password,
    })
    setModalState({
      ...initialModalState,
      docAuditReportExportSuccessModalOpen: true,
      messageModalHeader: 'Success',
      messageModalMessage: (
        <p>
          Your report is being generated and will be sent out shortly.
          <br />
          Check your inbox in a few minutes.
        </p>
      ),
    })
  }

  const generateBulkDisciplineForms = async (emailAddresses: string[]): Promise<void> => {
    try {
      setModalState({
        ...initialModalState,
        loadingModalOpen: true,
        loadingModalMessage: 'Generating forms...',
      })
      await importExportService.generateBulkDisciplineForms(selectedAssociation, selectedDate, emailAddresses, {
        username: idPassport,
        password,
      })

      displayWarning({ code: 'GenerateBulkDisciplineSuccess' })
    } catch (error) {
      displayWarning(error)
    }
  }

  const emailSuccessHandler = (addresses: string[]) => {
    const { emailCollectorModalOpen, docAuditReportEmailCollectorModalOpen, bulkDisciplineEmailCollectorModalOpen } =
      modalState

    if (emailCollectorModalOpen) {
      exportProfiles(addresses)
    } else if (docAuditReportEmailCollectorModalOpen) {
      exportDocAuditReports(addresses)
    } else if (bulkDisciplineEmailCollectorModalOpen) {
      generateBulkDisciplineForms(addresses)
    }
  }

  const onMenuClick = (menuName: string, subMenuName?: string, subSubMenuName?: string, hasAccess?: boolean) => {
    if (!hasAccess) {
      displayWarning({ code: 'NotAuthorised' })
      return
    }

    if (subSubMenuName !== undefined) {
      if (navButtonsWithCustomActions.includes(subSubMenuName)) {
        if (subSubMenuName === ScreenNamesEnum.EXPORT_PROFILES) {
          setModalState({ ...initialModalState, templateOptionsSelectorModalOpen: true })
        }
        if (subSubMenuName === ScreenNamesEnum.EXPORT_DOC_VALIDITY_DATA) {
          setModalState({ ...initialModalState, docAuditReportEmailCollectorModalOpen: true })
        }
        if (subSubMenuName === ScreenNamesEnum.EXPORT_DOCS) {
          props.history.push('/exportdocs')
        }
        if (subSubMenuName === ScreenNamesEnum.EXPORT_EMPLOYEE_CARDS) {
          props.history.push('/employeecards')
        }
        if (subSubMenuName === ScreenNamesEnum.UNDER_PERFORMANCE) {
          setModalState({ ...initialModalState, bulkDisciplineDateModalOpen: true })
        }
      }
      return
    }
    if (subMenuName !== undefined && navButtonsWithCustomActions.includes(subMenuName)) {
      if (subMenuName === ScreenNamesEnum.TERMINATE) {
        updateState({ currentSection: subMenuName, currentScreen: menuName })
        setModalState({ ...initialModalState, terminationModalOpen: true })
      }
      return
    }
    let employmentStatus = selectedEmploymentStatus
    if (subMenuName && subMenuEmploymentStatusMap[subMenuName]) {
      employmentStatus = subMenuEmploymentStatusMap[subMenuName]
    }
    updateState({
      selectedEmploymentStatus: employmentStatus,
      currentScreen: menuName,
      currentSection: subMenuName,
    })
  }

  const generateNavButtons = (): JSX.Element[] => {
    return menuStructure.map((menuItem: MenuSchema, i) => {
      return (
        <NavButton
          key={`${i}_${menuItem.label}`}
          label={menuItem.label}
          subMenus={menuItem.subMenus}
          subSubMenus={menuItem.subSubMenus}
          rootPaths={rootNavPaths}
          buttonsWithCustomActions={navButtonsWithCustomActions}
          primaryTableRef={props.primaryTableRef}
          onMenuClick={(menuName: string, subMenuName?: string, subSubMenuName?: string, hasAccess?: boolean) =>
            onMenuClick(menuName, subMenuName, subSubMenuName, hasAccess)
          }
          style={{
            marginRight: 10,
            height: '100%',
            zIndex: 1000,
          }}
        />
      )
    })
  }

  const handleMessageModalClick = () => {
    if (modalState.terminationModalOpen) {
      setModalState({
        ...initialModalState,
        terminationModalOpen: true,
      })
      setRefreshTimestamp(new Date())
      return
    }
    closeModals()
  }

  const initiateBulkTermination = async (fileName: string) => {
    const records = await planTerminationImport(fileName)
    if (records && records.length > 0) {
      history.push('/bulkterminations', {
        records,
        csvFileName: fileName,
        via: 'bulkActions',
      })
    } else {
      displayWarning({ code: 'NoRecords' })
    }
  }

  const logout = async () => {
    props.history.replace('/')
  }

  const displayWarning = (error: any) => {
    let header = 'Warning'
    let warning = ''
    try {
      if (error.code === 'NetworkError') {
        warning = 'Seems like your internet connection is down. Reconnect to the network, then try again.'
      } else if (error.code === 'NoWebAccess') {
        header = 'Not authorised'
        warning = 'You are not authorised to perform this function.'
      } else if ('message' in error) {
        warning =
          "The following error message was returned:\n\n'" +
          error.message +
          "'. \n\n Refresh the page and try again. If unsuccessful, then contact tech support"
      } else if (error.code === 'NotAuthorisedBulkDiscipline') {
        header = 'Not authorised'
        warning = "You don't have permission to generate discipline forms."
      } else if (error.code === 'ExportDocAuditReportError') {
        warning = `We ran into a problem while generating your report. Try logging out and in, then retry. If still unsuccessful, then reach out to tech support for assistance.`
      } else if (error.code === 'ExportTriggered') {
        header = 'Triggered export'
        warning = `Your export has been triggered. (It will take a few minutes before it reaches your inbox).`
      } else if (error.code === 'NoExportProfilesSelected') {
        header = 'Make a selection'
        warning = `No CANDIDATE/EMPLOYEES selected`
      } else if (error.code === 'NoExportProfilesSelectedFromProfilesPage') {
        header =
          'Visit the "CANDIDATES" or "EMPLOYEES" screens, contained under the "PEOPLE" menu, to select profiles for export.'
        warning =
          "You haven't selected any items to export from the employee or canditates pages.  You'll be redirected there."
      } else if (error.code === 'GenerateBulkDisciplineSuccess') {
        header = 'Success'
        warning = `Your forms are being generated.`
      } else if (error.code === 'NoRecords') {
        header = 'Bulk termination'
        warning = 'No records found after file processing'
      } else if (error.code === 'NotAuthorised') {
        header = 'Unauthorised'
        warning = `You don't have permission to perform this action.`
      } else {
        warning = 'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
      }
    } catch (error) {
      warning = 'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
    }
    setModalState({
      ...initialModalState,
      warningModalOpen: true,
      messageModalHeader: header,
      messageModalMessage: warning,
    })
  }

  let backButton = null
  const {
    accountModalOpen,
    settingsModalOpen,
    bulkDisciplineDateModalOpen,
    bulkDisciplineEmailCollectorModalOpen,
    docAuditReportEmailCollectorModalOpen,
    emailCollectorModalOpen,
    forgotPasswordModalOpen,
    loadingModalOpen,
    loadingModalMessage,
    templateOptionsSelectorModalOpen,
    terminationModalOpen,
    warningModalOpen,
    docAuditReportExportSuccessModalOpen,
  } = modalState
  const navButtons = generateNavButtons()
  const user = userRepo?.getCurrentUserEntity()

  if (!user) {
    return null
  }

  const { name = '', surname = '', mobile = '', email = '' } = user.getPersonalUserInfo()
  const anyEmailCollectorModalOpen =
    emailCollectorModalOpen || docAuditReportEmailCollectorModalOpen || bulkDisciplineEmailCollectorModalOpen
  let settingsModal = null
  if (settingsModalOpen) {
    settingsModal = (
      <HierarchialPicker
        config={accountSettingsPickerConfig}
        onActionButtonPress={(selectedValues: Record<string, string>) => settingsChangedHandler(selectedValues)}
        key={`settingsModal`}
      />
    )
  }
  return (
    <div style={styles.container}>
      {backButton}
      <img src={logoHeader} alt="PeopleFlow" style={styles.logoStyle} />
      <div style={styles.navButtonContainer}>
        {navButtons}
        <AccountManagementButton
          style={styles.accountManagementButton}
          name={name}
          surname={surname}
          selectedAssociation={selectedAssociation}
          selectedCohort={selectedCohort}
          onClick={() =>
            setModalState((modalState) => ({ ...modalState, accountModalOpen: !modalState.accountModalOpen }))
          }
        />
      </div>
      <AccountManagementModal
        open={accountModalOpen}
        name={name}
        surname={surname}
        email={email}
        mobile={mobile}
        idPassport={idPassport}
        dismiss={() => closeModals()}
        onSettingsClicked={() => openSettings()}
        onLogoutClicked={() => logout()}
        onForgotPasswordClicked={() => forgotPassword()}
      />
      {settingsModal}
      <OptionsSelectorModal
        open={templateOptionsSelectorModalOpen}
        onSuccess={() => exportHandler('profileData')}
        onReject={() => closeModals()}
        successLabel={'Export'}
        cancelLabel={'Cancel'}
        label1={'Export template'}
        options1={availableExportConfigs}
        optionsName1="exportTemplate"
        selectedOption1={exportTemplate}
        label2={'Export format'}
        options2={availableExportFormats}
        optionsName2="exportFormat" // TODO: Check - should this be set to something else?
        selectedOption2={exportFormat}
        onChange={(e, name) => exportPicker(e, name as MenuActions)}
      />
      <OptionsSelectorModal
        open={bulkDisciplineDateModalOpen}
        onSuccess={() => exportHandler('bulkDiscipline')}
        onReject={closeModals}
        warningMessage={disciplineWarningMessage}
        successLabel="Next"
        cancelLabel="Cancel"
        dateLabel="Date of underperformance"
        showCalendar={true}
        selectedDate={selectedDate}
        handleDateChange={(selectedDate) => dateHandler(selectedDate)}
      />
      <InfoCollectorModal
        open={anyEmailCollectorModalOpen}
        defaultItems={[email]}
        header="EMAIL TO"
        subHeader="Which email addresses should we send to?"
        warningMessage="Add at least one email address"
        validateInput={checkEmailFormat}
        transformInput={toLowerCaseCustom}
        placeholder="Email address"
        minimumItems={1}
        dismiss={closeModals}
        onSuccess={(addresses) => emailSuccessHandler(addresses)}
        onReject={closeModals}
      />
      <ForgotPasswordModal open={forgotPasswordModalOpen} idPassport={idPassport} dismiss={closeModals} />
      <WithRouterHistory>
        {(history: RouteComponentProps['history']) => (
          <TerminationDataUploadModal
            open={terminationModalOpen}
            onNextClick={initiateBulkTermination}
            onCancelClick={closeModals}
            // onInvalidFileContentFound={triggerInvalidFileContentWarning}
            key={`terminationDataUploadModal_${refreshTimestamp}`}
          />
        )}
      </WithRouterHistory>
      <AlertModalOneButton
        open={warningModalOpen || docAuditReportExportSuccessModalOpen}
        header={modalState.messageModalHeader}
        body={modalState.messageModalMessage}
        buttonLabel="Ok"
        onClick={handleMessageModalClick}
      />
      <LoadingModal open={loadingModalOpen}>{loadingModalMessage}</LoadingModal>
    </div>
  )
}

const styles = {
  container: {
    height: 85,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '1.5%',
  },
  logoStyle: {
    marginLeft: 10,
    marginTop: 40,
    height: 60,
  },
  accountManagementButton: {
    marginTop: 20,
    marginLeft: 40,
    marginRight: 25,
  },
  navButtonContainer: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingLeft: 20,
    paddingRight: 20,
    width: '100%',
    height: window.innerHeight * 0.05,
  },
}

export default NavBar
