import { PpeAction } from '../../types'
import { PpeActions } from '../../enums'

export type PpeRequestRecordPk = string
export type PpeRequestRecordSk = string
export type PpeRequestName = string
export type PpeRequestDateActioned = string

export enum PpeRequestStatus {
  READY_FOR_ISSUE = 'READY_FOR_ISSUE',
  ISSUING = 'ISSUING',
  RETURN_IN_PROGRESS = 'RETURN_IN_PROGRESS',
  COMPLETE = 'COMPLETE',
  TRANSFERRED = 'TRANSFERRED',
}
export interface PpeRequestRecordDto {
  pk: PpeRequestRecordPk
  sk: PpeRequestRecordSk
  requestName: PpeRequestName
  dateActioned: string
  association: string
  status: PpeRequestStatus
  lastStatus?: PpeRequestStatus
  seedGroupId?: string
  statusSeedGroupId?: string
}
export class PpeRequestRecord {
  pk: string = ''
  sk: string = ''
  association: string = ''
  dateActioned: number = 0
  requestName: string = ''
  status: PpeAction = PpeActions.SELECT_SIZE

  constructor(json: any) {
    if (!json) {
      return
    }
    this.pk = json.pk
    this.sk = json.sk
    this.association = json.association
    this.dateActioned = json.dateActioned
    this.requestName = json.requestName
  }

  getPk = () => {
    return this.pk || ''
  }

  getSk = () => {
    return this.sk || ''
  }

  getDateActioned = () => {
    return this.dateActioned || 0
  }

  getRequestName = () => {
    return this.requestName || ''
  }
}
