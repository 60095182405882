import { difference, isEmpty, pick } from 'lodash'
import { removeUnderScores } from '../utils/stringUtils'
import { DocServiceFactory } from './'
import { getCohortGroup, getCohortGroups, updateGroup } from '../providers'
import { getConfiguredProcessInfo, processTypes } from '../utils'
import { AssociationSettingsRepository } from '../repositories'
import { Profile, TimeBasedDocs } from '../models'
import { Group, GroupView, PpeSelections, RequestedPpeIssueItems, SubGroups } from '../types'
import { TimeBasedDocStatusEnum } from '../enums'

function toGroupView(group: Group): GroupView {
  return {
    id: group.id,
    cohortName: group.division,
    name: group.name,
    startUnixMs: group.startDate.getTime(),
    endUnixMs: group.endDate.getTime(),
    members: Object.fromEntries(
      Object.entries(group.members).map(([profileId, member]) => [
        profileId,
        {
          profileId,
          jobType: member.jobType,
          proposedStartUnixMs: member.proposedStartDate?.getTime(),
          proposedTerminationUnixMs: member.proposedTerminationDate?.getTime(),
          validTimeBasedDocs: member.validTimeBasedDocs,
        },
      ]),
    ),
    subGroups: group.subGroups || {},
  }
}

function fromGroupView(groupView: GroupView): Group {
  const division = groupView.cohortName
  return {
    id: groupView.id,
    division,
    name: groupView.name,
    startDate: new Date(groupView.startUnixMs),
    endDate: new Date(groupView.endUnixMs),
    members: Object.fromEntries(
      Object.entries(groupView.members).map(([profileId, member]) => [
        profileId,
        {
          profileId,
          jobType: member.jobType,
          proposedStartDate: member.proposedStartUnixMs ? new Date(member.proposedStartUnixMs) : undefined,
          proposedTerminationDate: member.proposedTerminationUnixMs
            ? new Date(member.proposedTerminationUnixMs)
            : undefined,
          validTimeBasedDocs: member.validTimeBasedDocs,
        },
      ]),
    ),
    subGroups: groupView.subGroups,
  }
}

export class GroupService {
  static async fetchGroup(association: string, cohortName: string, groupId: string, token: string): Promise<Group> {
    const data = await getCohortGroup(association, cohortName, groupId, token)
    const groupView = data as GroupView
    return fromGroupView(groupView)
  }

  static async fetchGroups(association: string, cohortName: string, token: string): Promise<Group[]> {
    const data = await getCohortGroups(association, cohortName, token)
    return data.map(fromGroupView)
  }

  static async postGroup(association: string, group: Group, token: string): Promise<void> {
    const payload = toGroupView(group)
    return await updateGroup(association, payload, token)
  }

  // group member specific functions
  static getRequiredCompetencies = (
    associationRepo: AssociationSettingsRepository,
    selectedAssociation: string,
    jobType: string,
  ) => {
    const processConfig = associationRepo.getProcessConfig(selectedAssociation)
    const division = 'ADMIN' // TODO: division needs to be optionally passed into this function
    // @ts-ignore
    let requiredCompetencyDocs: string[] = []
    if (
      processConfig?.competenciesv2.competencyConfig &&
      processConfig?.competenciesv2.competencyConfig.hasOwnProperty(division) &&
      processConfig?.competenciesv2.competencyConfig[division].hasOwnProperty(jobType)
    ) {
      requiredCompetencyDocs = processConfig?.competenciesv2.competencyConfig[division][
        jobType
      ].allJobSubTypes.requiredCompetencies.map((competency: string) => removeUnderScores(competency))
    }
    return requiredCompetencyDocs
  }

  static getDocsRequired = (
    associationRepo: AssociationSettingsRepository,
    selectedAssociation: string,
    jobType: string,
  ) => {
    const competenciesRequired = this.getRequiredCompetencies(associationRepo, selectedAssociation, jobType)
    return competenciesRequired
  }

  static getSelectedPpeCountFromGeneralData = (profile: Profile, selectedPpeKeys: string[]) => {
    const generalData = profile?.getGeneralData()
    const alreadySelectedPPE = pick(generalData, selectedPpeKeys)
    return Object.keys(alreadySelectedPPE).length
  }

  static getGroupMembersMinStartDate = (selectedMemberIds: string[], group?: Group, startDate?: Date) => {
    if (group) {
      const groupMembers = group.members || []
      const numberOfSelectedGroupMembers = selectedMemberIds.length
      let groupStartDate = group.startDate || new Date()
      let groupEndDate = group.endDate || new Date()
      let minGroupMemberStartDate = new Date(groupStartDate)

      if (numberOfSelectedGroupMembers === 1) {
        const selectedIdPassport = selectedMemberIds[0]
        const selectedGroupMember = groupMembers[selectedIdPassport]
        if (selectedGroupMember) {
          const groupMemberStartDate = startDate || selectedGroupMember.proposedStartDate
          if (
            groupMemberStartDate &&
            groupMemberStartDate > groupStartDate &&
            groupMemberStartDate > minGroupMemberStartDate &&
            groupMemberStartDate < groupEndDate
          ) {
            minGroupMemberStartDate = groupMemberStartDate
          }
          return minGroupMemberStartDate
        }
      }
      return group.startDate
    }
    return new Date()
  }

  static getMemberJobType = (pk: string, group?: Group): string | undefined => {
    return group?.members[pk]?.jobType
  }

  static getDocRequirementsNotSatisfied = (
    pk: string,
    jobType: string,
    associationReo: AssociationSettingsRepository,
    selectedAssociation: string,
    group?: Group,
  ) => {
    const groupMember = group?.members[pk]
    const docsRequired = this.getDocsRequired(associationReo, selectedAssociation, jobType)
    return difference(docsRequired, groupMember?.validTimeBasedDocs || [])
  }

  static getInitiationGroupKeyByDisplayName = (displayName: string, group?: Group) => {
    const subGroups: SubGroups | undefined = group?.subGroups
    if (subGroups) {
      const subGroupTypes = Object.keys(subGroups)
      for (const subGroupType of subGroupTypes) {
        const subGroupIds = Object.keys(subGroups[subGroupType])
        for (const subGroupId of subGroupIds) {
          if (subGroups[subGroupType][subGroupId].displayName === displayName) {
            return subGroupId
          }
        }
      }
    }
    return ''
  }

  static getSubGroupNames = (subGroups: SubGroups, subGroupType: string): string[] => {
    if (isEmpty(subGroups)) {
      return []
    }
    let subGroupNames = [] as string[]
    Object.keys(subGroups[subGroupType]).forEach((subGroupId) =>
      subGroupNames.push(subGroups[subGroupType][subGroupId].displayName),
    )
    return subGroupNames
  }

  static getSubGroupIdByDisplayName = (subGroups: SubGroups, subGroupType: string, displayName: string): string => {
    if (isEmpty(subGroups)) {
      return ''
    }
    let subGroupId = ''
    Object.keys(subGroups[subGroupType]).forEach((id) => {
      if (subGroups[subGroupType][id].displayName === displayName) {
        subGroupId = id
      }
    })
    return subGroupId
  }

  static getNumberOfRequestedPpe = (requestedPpe: PpeSelections) => {
    let numberOfRequestedPpe = 0
    Object.keys(requestedPpe).forEach((profilePk) => {
      const profilePpeSelections = requestedPpe[profilePk]
      Object.values(profilePpeSelections).forEach((ppeSize) => {
        if (ppeSize) numberOfRequestedPpe += 1
      })
    })
    return numberOfRequestedPpe
  }

  static extractRequestedPpeItems = (selectedPpe: PpeSelections): RequestedPpeIssueItems => {
    let requestedItems: any = {}
    Object.keys(selectedPpe).forEach((profilePk) => {
      const profilePpeSelections = selectedPpe[profilePk]
      Object.keys(selectedPpe[profilePk]).forEach((ppeKey) => {
        const ppeSize = profilePpeSelections[ppeKey]
        if (!ppeSize) {
          return
        }
        if (!(profilePk in requestedItems)) {
          requestedItems[profilePk] = {}
        }
        requestedItems[profilePk][ppeKey] = {
          selectedSizes: [
            {
              ppeSize,
              qty: 1,
            },
          ],
        }
      })
    })
    return requestedItems
  }

  static getAllValidCompetencyDocsPerMember = async (
    pk: string,
    selectedAssociation: string,
    selectedCohort: string,
    token: string,
  ) => {
    const DocService = DocServiceFactory.create()
    const timeBasedDocs = (await DocService.fetchTimeBasedDocData(selectedAssociation, [selectedCohort], token, [
      pk,
    ])) as TimeBasedDocs
    return timeBasedDocs.getDocsInCategory(TimeBasedDocStatusEnum.VALID) || []
  }

  static getPpeSizeSelectionDataFlow = (
    selectedAssociation: string,
    selectedCohort: string,
    associationRepo: AssociationSettingsRepository,
  ) => {
    const processConfig = associationRepo.getProcessConfig(selectedAssociation)
    const sourceData = {
      division: selectedCohort,
      jobType: '',
      jobSubType: '',
    }
    const dataFlowNames = getConfiguredProcessInfo(
      'dataFlowNames',
      processTypes.ppeSizeSelection,
      sourceData,
      processConfig,
    ) as string[]

    return dataFlowNames[0]
  }
}
