import { clear, del, keys, getMany, delMany, get, set } from 'idb-keyval'

export class LocalStorageProvider {
  static async resetDb() {
    return clear()
  }

  static async setData(storageKey: string, value: any) {
    return set(storageKey, value)
  }

  static async getData(storageKeyOrKeys: string) {
    return get(storageKeyOrKeys)
  }

  static async getMany(storageKeyOrKeys: string[]) {
    return getMany(storageKeyOrKeys)
  }

  static listDataKeys = async (storageIdentifier?: string) => {
    let storageKeys = await keys()
    if (storageIdentifier) {
      storageKeys = storageKeys.filter((key) => (key as string).startsWith(storageIdentifier))
    }
    return storageKeys as string[]
  }

  static delete = async (storageKeyOrKeys: string) => {
    return del(storageKeyOrKeys)
  }

  static deleteMany = async (storageKeyOrKeys: string[]) => {
    return delMany(storageKeyOrKeys)
  }

  static async hasStorageKey(storageKey: string) {
    const existingKeys = await keys()
    return existingKeys.includes(storageKey)
  }
}
