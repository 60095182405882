import { ScreenNamesEnum } from '../types'
const {
  IMPORT,
  BULK_ACTIONS,
  BULK_DISCIPLINE_FORMS,
  DISCIPLINE,
  EXPORT,
  EXPORT_DOC_VALIDITY_DATA,
  EXPORT_DOCS,
  EXPORT_EMPLOYEE_CARDS,
  EXPORT_PROFILES,
  REPORTING,
  TERMINATE,
  UNDER_PERFORMANCE,
} = ScreenNamesEnum

export const rootNavPaths = [IMPORT]
export const navButtonsWithCustomActions: string[] = [
  BULK_ACTIONS,
  BULK_DISCIPLINE_FORMS,
  DISCIPLINE,
  UNDER_PERFORMANCE,
  EXPORT,
  EXPORT_DOC_VALIDITY_DATA,
  EXPORT_DOCS,
  EXPORT_EMPLOYEE_CARDS,
  EXPORT_PROFILES,
  REPORTING,
  TERMINATE,
]
