import { useState } from 'react'
import Radium from 'radium'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { mdiAlphaQCircleOutline, mdiSync } from '@mdi/js'

import { ColorPalette } from '../../config/colors'
import { toUpperCaseCustom } from '../../utils'
import TextInputOutlined from '../BaseComponents/Text/TextInputOutlined'
import { SideMenuOptions } from './SideMenuOptions'
import LabelCollector from '../Modals/LabelCollector'
import { Query } from '../../types'
import { SideMenuTitle } from './SideMenuTitle'

dayjs.extend(relativeTime)

interface DataFlowsSideMenuProps {
  queries: Query[]
  selectedQueryId: string
  syncingQueryIds?: string[]
  onQueryAddition: (newQueryName: string) => void
  onQueryClick: (dataField: string) => void
}

const DataFlowsSideMenu = (props: DataFlowsSideMenuProps) => {
  const [searchString, setSearchString] = useState('')
  const [addNewQueryModalOpen, setAddNewQueryModalOpen] = useState(false)

  const closeModal = () => setAddNewQueryModalOpen(false)

  const searchHandler = (e: any) => {
    setSearchString(toUpperCaseCustom(e.target.value))
  }

  const renderOptionLabel = (queryIdentifier: string) => {
    const [queryId, queryName] = queryIdentifier.split('#')
    return toUpperCaseCustom(queryName.replace(/_/g, ' '))
  }

  const getOptionStyle = (queryIdentifier: string) => {
    const [queryId, queryName] = queryIdentifier.split('#')
    return {
      fontSize: 'smaller',
      maxWidth: '100%',
      backgroundColor: selectedQueryId === queryId ? ColorPalette.BUTTON_ACTIVE_GREY : ColorPalette.OFF_WHITE_LIGHT,
    }
  }

  const handleNewQueryAddition = (newQueryName: string) => {
    onQueryAddition(newQueryName)
    closeModal()
  }

  const sortByName = (a: string, b: string) => {
    const [queryIdA, queryNameA] = a.split('#')
    const [queryIdB, queryNameB] = b.split('#')

    if (queryNameA < queryNameB) return -1
    if (queryNameA > queryNameB) return 1

    return 0
  }

  const { selectedQueryId, onQueryAddition, onQueryClick } = props

  let dataQueryIdentifiers = [...props.queries.map((query) => `${query.id}#${query.name}`)]
  if (searchString.length) {
    dataQueryIdentifiers = dataQueryIdentifiers.filter((queryIdentifier: string) => {
      const [queryId, queryName] = queryIdentifier.split('#')
      return queryName.toUpperCase().includes(searchString)
    })
  }
  dataQueryIdentifiers.sort(sortByName)

  return (
    <div style={styles.container}>
      <SideMenuTitle>DATA QUERIES</SideMenuTitle>
      <TextInputOutlined
        style={styles.textStyle}
        label="QUERY SEARCH"
        placeholder="Search for query"
        value={searchString}
        textHandler={searchHandler}
        disabled={false}
      />
      <div style={styles.dividerLine} id="sidemenuOptions" />
      <SideMenuOptions
        addButtonLabel="Add query"
        addButtonClick={() => setAddNewQueryModalOpen(true)}
        addButtonIconStyle={styles.buttonIcon}
        options={dataQueryIdentifiers}
        optionLabel={renderOptionLabel}
        optionClick={onQueryClick}
        optionMdiIconPath={mdiAlphaQCircleOutline}
        optionStyle={getOptionStyle}
      />
      <LabelCollector
        open={addNewQueryModalOpen}
        warning="Enter query name"
        placeholder="Enter query name"
        buttonLabel="Add"
        iconName="setting"
        dismiss={closeModal}
        submit={handleNewQueryAddition}
      />
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    width: '100%',
    overflow: 'auto',
  },
  dividerLine: {
    width: '86%',
    marginTop: 30,
    borderBottom: `1px solid ${ColorPalette.LIGHT_GREY}`,
  },
  textStyle: {
    color: ColorPalette.PRIMARY_TEXT,
    padding: '0px 12px 10px 12px',
    marginTop: 20,
    minWidth: 120,
    width: '80%',
  },
  buttonIcon: {
    width: '1.5rem',
    height: '1.5rem',
    marginRight: 15,
    marginLeft: -2,
  },
}

export default Radium(DataFlowsSideMenu)
