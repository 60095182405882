import { ColorPalette } from '../../config/colors'
import { removeUnderScores, toUpperCaseCustom } from '../../utils'

import React from 'react'
import Radium from 'radium'
import ViewListIcon from '@material-ui/icons/ViewList'
import FaceIcon from '@material-ui/icons/Face'
import DescriptionIcon from '@material-ui/icons/Description'

import DataLogHeader from './DataLogHeader'
import SyncIndicatorDots from './SyncIndicatorDots'

const formatInfo = (data: any) => {
  if (Array.isArray(data)) {
    return data.join(' ')
  }
  return data
}

interface TimelineProps {
  logData: any
  mode: string
  downloadedToDevice?: boolean
  downloadedEverywhere?: boolean
  style?: React.CSSProperties | undefined
}

const timelineBlock: React.FC<TimelineProps> = (props: TimelineProps) => {
  let categoryItems: any[] = []
  const logData = props.logData
  const allCategories = ['rawData', 'docs', 'faceShots']
  allCategories.forEach((category, index) => {
    let categoryItem = null
    let rowItems: JSX.Element[] = []

    if (category === 'rawData') {
      Object.keys(logData[category]).forEach((key) => {
        const itemDetail = logData[category][key]
        const description =
          `${removeUnderScores(key)}:\xa0` +
          toUpperCaseCustom(
            removeUnderScores(` ${formatInfo(itemDetail.old)} \xa0\ =>  \xa0\ ${formatInfo(itemDetail.new)}`),
          )

        const item = (
          <div style={styles.rowItemContainer} key={`category_rawData_${key}`}>
            <ViewListIcon style={styles.rowItemIcon} />
            <p style={styles.rowItemText}>{description}</p>
          </div>
        )
        rowItems.push(item)
      })
    } else if (category === 'faceShots' || category === 'docs') {
      rowItems = [...logData[category]].map((itemDetail) => {
        if (category === 'docs') {
          return (
            <div style={styles.rowItemContainer} key={`category_docs_${itemDetail}`}>
              <DescriptionIcon style={styles.rowItemIcon} />
              <p style={styles.rowItemText}>{toUpperCaseCustom(removeUnderScores(itemDetail))}</p>
            </div>
          )
        } else if (category === 'faceShots') {
          return (
            <div style={styles.rowItemContainer} key={`category_faceShots_${itemDetail}`}>
              <FaceIcon style={styles.rowItemIcon} />
              <p style={styles.rowItemText}>{toUpperCaseCustom(removeUnderScores(itemDetail))}</p>
            </div>
          )
        }
        return <></>
      })
    }
    if (rowItems.length) {
      categoryItem = (
        <div style={{ width: '100%' }} key={`category_${index}`}>
          <p style={{ ...styles.subHeaderStyle }}>{''}</p>
          {rowItems}
        </div>
      )
      categoryItems = [...categoryItems, categoryItem]
    }
  })

  return (
    <div style={{ ...styles.container, ...props.style }}>
      <SyncIndicatorDots
        style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
        local={logData.local}
        downloadedToDevice={logData.downloadedToDevice}
        downloadedEverywhere={logData.downloadedEverywhere}
      />
      <DataLogHeader mode={props.mode} title={logData.title} metaData={logData.metaData} />
      {categoryItems}
    </div>
  )
}

let styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'flex-start',
    borderRadius: 10,
    padding: '20px 20px 20px 30px',
    width: '90%',
    backgroundColor: ColorPalette.BUTTON_HOVER_GREY,
    boxShadow: '0px 2px 6px rgba(0, 0, 30, 0.2)',
    marginTop: 30,
  },
  rowItemContainer: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center',
    marginLeft: window.innerWidth * 0.015,
    paddingVertical: 1,
  },
  rowItemIcon: {
    width: '1rem',
    height: '1rem',
    color: ColorPalette.DARK_GREY,
  },
  rowItemText: {
    color: ColorPalette.SECONDARY_TEXT,
    fontSize: '0.8rem',
    margin: '0 0 0 20px',
  },
  subHeaderStyle: {
    fontSize: '0.85rem',
    fontWeight: '300',
    fontFamily: 'Roboto',
    color: ColorPalette.PRIMARY_TEXT,
    width: '100%',
    textAlign: 'center' as 'center',
  },
}

export default Radium(timelineBlock)
