import React from 'react'
import Radium from 'radium'
import { ColorPalette } from '../../../config/colors'
import Calendar, { CalendarProps } from './Calendar'

const CalendarContained: React.FC<CalendarProps> = (props: CalendarProps) => {
  const { style, ...otherProps } = props
  return (
    <div style={{ ...formControl }}>
      <Calendar {...otherProps} />
    </div>
  )
}

const formControl = {
  padding: 15,
  borderRadius: 12,
  border: `1px solid ${ColorPalette.VERY_LIGHT_GREY}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

export default Radium(CalendarContained)
