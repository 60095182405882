import { TrendConfig, TrendId } from '../../../types'
import TrendButton from './TrendButton'

type TrendListProps = {
  trendConfigList: TrendConfig[]
  selectedTrendId: TrendId
  onClick: (graphId: string) => void
}

export const TrendList = (props: TrendListProps) => {
  const { trendConfigList, selectedTrendId, onClick } = props
  const trendButtons = trendConfigList.map((trend: TrendConfig, index: number) => {
    const { trendName, id = '' } = trend
    const isSelected = selectedTrendId === id
    return (
      <TrendButton
        isFirstButton={index === 0}
        isLastbutton={index === trendConfigList.length - 1}
        label={trendName}
        selected={isSelected}
        onClick={() => onClick(id)}
        key={`graph_${id}`}
      />
    )
  })

  return <div style={styles.horisontalGraphList}>{trendButtons}</div>
}

const styles = {
  horisontalGraphList: {
    display: 'flex',
    gap: '0.3em',
    overflowX: 'scroll' as 'scroll',
    padding: 6,
  },
}
