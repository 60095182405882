import { ColorPalette } from '../../../config/colors'
import { toUpperCaseCustom, ensureStringFormat, toValidPfDate } from '../../../utils'

import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import NameIcon from '@material-ui/icons/Person'
import SurnameIcon from '@material-ui/icons/PersonAdd'
import MobileIcon from '@material-ui/icons/LocalPhone'
import EmailIcon from '@material-ui/icons/Mail'
import IdIcon from '@material-ui/icons/RecentActors'
import PasswordIcon from '@material-ui/icons/Lock'
import FiberPinIcon from '@material-ui/icons/FiberPin'
import DescriptionIcon from '@material-ui/icons/Description'
import ViewListIcon from '@material-ui/icons/ViewList'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import SettingsIcon from '@material-ui/icons/Settings'
import { ITextInputProps } from './text.input'
import { IconButton, InputAdornment, InputLabelProps } from '@material-ui/core'

import dayjs from 'dayjs'
import Calendar from '../Calendar/Calendar'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

export interface ITextInputLabelledProps extends ITextInputProps {
  key?: string
  icon?: string | undefined
  primaryStyle?: React.CSSProperties | undefined
  containerStyle?: React.CSSProperties | undefined
  label: string
  placeholder?: string
  // maxLength: number
  multiline?: boolean
  inputLabelProps?: InputLabelProps | undefined
  inputProps?: object
  dateFormat?: string
  focusOnMount?: boolean
  endAdornmentIcon?: (style: React.CSSProperties) => JSX.Element
  endAdornmentOnClick?: () => void
  textHandler: (event: any, isMount?: boolean) => void
}

const defaultDateFormat = 'YYYY/MM/DD'
class TextInputLabelled extends Component<ITextInputLabelledProps> {
  async componentDidMount() {
    let { value, defaultValue, textHandler, dateFormat } = this.props
    if (value) {
      if (dateFormat) {
        value = toValidPfDate(value)
        if (typeof value === 'number') {
          this.setDate(value, dateFormat, 2000)
        } else if (!dayjs(value, defaultDateFormat).isValid()) {
          this.setDate(new Date().getTime(), dateFormat, 2000)
        }
      } else {
        const initialValue = ensureStringFormat(value)
        textHandler(initialValue, true)
      }
    } else if (defaultValue) {
      textHandler(defaultValue, true)
    } else {
      textHandler('', true)
    }
  }

  setDate = (rawDate: number, dateFormat = defaultDateFormat, delay = 0) => {
    const date = dayjs(rawDate).format(dateFormat)
    this.setState({ calendarModalOpen: false })
    setTimeout(() => this.props.textHandler(date), delay)
  }

  handleDateChange = (date: MaterialUiPickersDate, value?: string | null) => {
    this.props.textHandler(value)
  }

  render() {
    let icon = null

    switch (this.props.icon) {
      case 'id':
        icon = <IdIcon style={styles.iconStyle} />
        break

      case 'password':
        icon = <PasswordIcon style={styles.iconStyle} />
        break

      case 'name':
        icon = <NameIcon style={styles.iconStyle} />
        break

      case 'surname':
        icon = <SurnameIcon style={styles.iconStyle} />
        break

      case 'mobile':
        icon = <MobileIcon style={styles.iconStyle} />
        break

      case 'email':
        icon = <EmailIcon style={styles.iconStyle} />
        break

      case 'otp':
        icon = <FiberPinIcon style={styles.iconStyle} />
        break

      case 'rawData':
        icon = <ViewListIcon style={styles.iconStyle} />
        break

      case 'docs':
        icon = <DescriptionIcon style={styles.iconStyle} />
        break

      case 'job':
        icon = <GroupAddIcon style={styles.iconStyle} />
        break

      case 'setting':
        icon = <SettingsIcon style={styles.iconStyle} />
        break

      default:
        break
    }

    let iconOnRight = null
    if (this.props.endAdornmentIcon) {
      iconOnRight = (
        <InputAdornment position="end">
          <IconButton onClick={this.props.endAdornmentOnClick}>
            {this.props.endAdornmentIcon(styles.iconStyle)}
          </IconButton>
        </InputAdornment>
      )
    }

    let inputComponent = null

    if (this.props.dateFormat) {
      inputComponent = (
        <div style={{ width: '90%', ...this.props.primaryStyle }}>
          <Calendar
            value={this.props.value}
            format={this.props.dateFormat === 'YYYY/MM/DD' ? 'yyyy/MM/dd' : 'dd/MM/yyyy'}
            onChange={this.handleDateChange}
            label={this.props.label}
            inputStyle={styles.inputStyle}
            labelStyle={styles.inputLabelStyle}
            style={{ width: '100%' }}
            key={`dateInput-${this.props.label}-${this.props.value}`}
          />
        </div>
      )
    } else {
      inputComponent = (
        <TextField
          id={this.props.label}
          style={{ width: '90%', ...this.props.primaryStyle }}
          InputLabelProps={{ style: { ...styles.inputLabelStyle, ...this.props.inputLabelProps } }}
          InputProps={{
            // maxLength: this.props.maxLength,
            style: {
              ...styles.inputStyle,
              ...this.props.inputProps,
              marginBottom: this.props.label && this.props.label.length > 25 ? 10 : 0,
            },
            endAdornment: iconOnRight,
          }}
          label={toUpperCaseCustom(this.props.label)}
          value={this.props.value}
          onChange={(e) => this.props.textHandler(e.target.value)}
          type={this.props.type}
          multiline={this.props.multiline}
          disabled={this.props.disabled}
          autoFocus={this.props.focusOnMount}
        />
      )
    }
    return (
      <Grid style={this.props.containerStyle} container direction="row" alignItems="flex-end" justify="center">
        {icon}
        {inputComponent}
      </Grid>
    )
  }
}

const styles = {
  iconStyle: {
    marginRight: 10,
    marginBottom: 8,
    color: ColorPalette.PRIMARY_TEXT,
    width: '1.3rem',
    height: '1.3rem',
  },
  inputLabelStyle: {
    color: ColorPalette.LIGHT_GREY,
    fontSize: '0.8rem',
  },
  inputStyle: {
    fontSize: '0.9rem',
    width: '100%',
    color: ColorPalette.PRIMARY_TEXT,
  },
}

export default TextInputLabelled
