import React, { Component } from 'react'
import Radium from 'radium'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'

import { ColorPalette } from '../../../config/colors'
import './reset.css'

export type PickerHandlerEvent = React.ChangeEvent<{ name?: string; value: any }>
export interface IPickerProps {
  value: string
  items: (string | number)[]
  disabled?: boolean
  placeholder?: string
  label?: string
  fontStyle?: React.CSSProperties | undefined
  labelStyle?: React.CSSProperties | undefined
  style: React.CSSProperties | undefined
  autoItemSelectOnMount?: boolean
  uppercaseItems?: boolean
  variant?: 'outlined' | 'standard' | 'filled'
  noUnderline?: boolean
  onChange: (event: PickerHandlerEvent) => void
}

class Picker extends Component<IPickerProps> {
  componentDidMount() {
    if (this.props.autoItemSelectOnMount && !this.props.value && this.props.items.length) {
      this.props.onChange({ target: { value: this.props.items[0] } } as PickerHandlerEvent)
    }
  }

  render() {
    const formControl: React.CSSProperties = {
      width: '100%',
      minWidth: 120,
      marginTop: 5,
      marginBottom: 10,
      borderBottom: this.props.disabled ? undefined : `1px solid ${ColorPalette.PRIMARY_TEXT}`,
    }

    let itemList = Array.isArray(this.props.items) ? this.props.items : []

    let optionList: React.ReactElement[] = []
    if (this.props.placeholder !== undefined) {
      optionList.push(
        <option key={`default_${this.props.placeholder}`} value={''}>
          {this.props.placeholder}
        </option>,
      )
    }
    optionList = [
      ...optionList,
      ...itemList.map((item, i) => (
        <option key={`${item}_${i}`} value={item}>
          {item}
        </option>
      )),
    ]

    let selectStyle = { fontSize: '0.9rem', color: ColorPalette.PRIMARY_TEXT, ...this.props.fontStyle, border: 'none' }
    let inputProps: Record<string, any> = {
      name: 'pickerValues',
      id: 'picker',
    }
    if (this.props.uppercaseItems) {
      inputProps = { ...inputProps, style: { textTransform: 'uppercase' } }
    }

    return (
      <FormControl style={{ ...formControl, ...this.props.style }}>
        <InputLabel id={this.props.label} style={this.props.labelStyle}>
          {this.props.label}
        </InputLabel>
        <Select
          style={selectStyle}
          native={true}
          value={this.props.value}
          onChange={(event) => this.props.onChange(event)}
          disabled={this.props.disabled}
          inputProps={inputProps}
          variant={this.props.variant}
          className={this.props.noUnderline ? 'MuiInput-underline-override' : undefined}>
          {optionList}
        </Select>
      </FormControl>
    )
  }
}

export default Radium(Picker)
