import { AssociationId } from '../../types'
import { TimeBasedDocStatusEnum } from '../../enums'

class PFDoc {
  private generatedUnixMs
  private docFamily
  private docName
  private association
  private ownerPk
  private fileName
  private fieldData
  private docValidityStartDate
  private docValidityExpiryDate

  constructor(association: AssociationId, ownerPk: string, fileName: string, fieldData: Record<string, any>) {
    const { generatedUnixMs, docFamily, docName } = this.getDocNameMetaData(fileName)
    this.generatedUnixMs = generatedUnixMs
    this.docFamily = docFamily
    this.docName = docName
    this.association = association
    this.ownerPk = ownerPk
    this.fileName = fileName
    this.fieldData = fieldData
    this.docValidityStartDate = fieldData.docValidityStartDate || null
    this.docValidityExpiryDate = fieldData.docValidityExpiryDate || null
  }

  getDocName = () => {
    return this.docName
  }

  getDocFamily = () => {
    return this.docFamily
  }

  getDocGeneratedUnixMs = () => {
    return this.generatedUnixMs
  }

  getDocNameMetaData = (fileName: string) => {
    let fileNameElements = fileName.split('___')
    const generatedUnixMs = fileNameElements[0]
    const docFamily = fileNameElements[1]
    const docName = fileNameElements[2].split('.')[0]
    return { generatedUnixMs, docFamily, docName }
  }

  getValidPeriod = () => {
    if (!this.docValidityStartDate && !this.docValidityExpiryDate) {
      return TimeBasedDocStatusEnum.NO_PERIOD_SET
    }
    const start = this.docValidityStartDate || 'NONE'
    const end = this.docValidityExpiryDate || 'NONE'
    return `${start} - ${end}`
  }

  getValidityStatus = () => {
    const currentDate = new Date().getTime()
    if (!this.docValidityExpiryDate) {
      return TimeBasedDocStatusEnum.NO_EXPIRY_SET
    }
    if (currentDate > new Date(this.docValidityExpiryDate).getTime()) {
      return TimeBasedDocStatusEnum.EXPIRED
    } else if (currentDate > new Date(this.docValidityExpiryDate).getTime() - 3888000000) {
      return TimeBasedDocStatusEnum.EXPIRES_IN_45_DAYS
    }
    return TimeBasedDocStatusEnum.VALID
  }

  getAllDocMetaData = () => {
    const docName = this.getDocName()
    const docFamily = this.getDocFamily()
    const generatedUnixMs = this.getDocGeneratedUnixMs()
    const validPeriod = this.getValidPeriod()
    const validityStatus = this.getValidityStatus()
    return {
      docName,
      docFamily,
      generatedUnixMs,
      fileName: this.fileName,
      association: this.association,
      ownerPk: this.ownerPk,
      validPeriod,
      validityStatus,
    }
  }
}
export default PFDoc
