import { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'

import { ColorPalette } from '../../../config'
import CheckboxTabLabelled from '../../BaseComponents/Checkboxes/CheckboxTabLabelled'
import { FieldValueChooser } from './FieldValueChooser'
import TwoColumnSelector from '../TwoColumnSelector/TwoColumnSelector'
import { addUnderScores, getFieldLabelFromKey, removeUnderScores, toUpperCaseCustom } from '../../../utils'
import InfoCollectorModal from '../../Modals/InfoCollector'
import ModalPortal from '../../Modals/ModalPortal'
import { FieldConfigItemSchema } from '../../../types'

type AutoPopulateFieldsProps = {
  fieldName?: string
  autoPopulate: Record<string, any>
  shouldAutoPopulate: boolean
  dynamicFieldValues?: boolean
  selectorItems?: string[]
  fieldConfig: Record<string, FieldConfigItemSchema>
  onShouldAutoPopulateChange: (values: string[], isMount: boolean) => void
  onFieldValuePairChange: (item: string, field?: string, value?: string, previousField?: string) => void
  onFieldValuesChange?: (fieldValues: string[]) => void
  onFieldValueRemove?: (fieldValue: string) => void
}

export const AutoPopulateFields = (props: AutoPopulateFieldsProps) => {
  const {
    fieldName,
    autoPopulate,
    shouldAutoPopulate,
    selectorItems,
    dynamicFieldValues,
    fieldConfig,
    onShouldAutoPopulateChange,
    onFieldValuePairChange,
    onFieldValuesChange,
    onFieldValueRemove,
  } = props

  const [fieldValueModalOpen, setFieldValueModalOpen] = useState(false)
  const [autoPopulateModalOpen, setAutoPopulateModalOpen] = useState('')

  const toggleFieldValueModal = () => setFieldValueModalOpen((fieldValueModalOpen) => !fieldValueModalOpen)
  const toggleAutoPopulateModal = (selectedFieldToAutoPopulate?: string) => {
    if (selectedFieldToAutoPopulate) {
      setAutoPopulateModalOpen(selectedFieldToAutoPopulate)
      return
    }
    setAutoPopulateModalOpen('')
  }

  const allAvailableFields = Object.keys(fieldConfig)
  const fieldValues = Object.keys(autoPopulate)
    .map((fieldValue) => removeUnderScores(fieldValue))
    .sort()
  const selectedFieldToAutoPopulate = autoPopulateModalOpen.length > 0 ? autoPopulateModalOpen : ''
  return (
    <>
      <CheckboxTabLabelled
        style={styles.sectionInput}
        itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
        label={'SHOULD OTHER FIELDS BE AUTO-POPULATED FROM THE RESULT OF THIS FIELD?'}
        values={shouldAutoPopulate ? ['yes'] : ['no']}
        selectorItems={['no', 'yes']}
        selectionHandler={onShouldAutoPopulateChange}
      />
      {shouldAutoPopulate && fieldName && dynamicFieldValues && onFieldValuesChange ? (
        <div>
          <TwoColumnSelector
            label="FIELD VALUES THAT WILL TRIGGER AUTO-POPULATION OF OTHER FIELDS"
            values={fieldValues}
            onClick={toggleFieldValueModal}
            onValueClick={(fieldValue: string) => {
              const fieldKey = addUnderScores(fieldValue)
              toggleAutoPopulateModal(fieldKey)
            }}
            onRemoveClick={(valueName: string) => {
              if (onFieldValueRemove) {
                onFieldValueRemove(addUnderScores(valueName))
              }
            }}
            outlined={true}
            addOrRemoveLabel="Add another?"
          />
          <ModalPortal>
            <InfoCollectorModal
              open={fieldValueModalOpen}
              defaultItems={fieldValues}
              header="FIELD VALUES"
              subHeader="Field values that will trigger auto-population"
              warningMessage="Add at least one item"
              validateInput={() => true}
              transformInput={toUpperCaseCustom}
              transformOutput={addUnderScores}
              placeholder="Enter value"
              successLabel="Update"
              minimumItems={1}
              dismiss={toggleFieldValueModal}
              onSuccess={(fieldValues) => {
                onFieldValuesChange(fieldValues)
                toggleFieldValueModal()
              }}
              onReject={toggleFieldValueModal}
            />
          </ModalPortal>
          {selectedFieldToAutoPopulate && (
            // TODO: use /components/Modals/Modal.tsx after web factor lands
            <Dialog
              open={!!autoPopulateModalOpen}
              onBackdropClick={() => toggleAutoPopulateModal()}
              PaperProps={{ style: { borderRadius: 8 } }}
              style={{ backgroundColor: ColorPalette.MODAL_BACKGROUND_OVERLAY }}>
              <h1 style={styles.title}>FIELDS TO POPULATE</h1>
              <FieldValueChooser
                fieldConfig={fieldConfig}
                title={
                  <span>
                    WHEN <b>{selectedFieldToAutoPopulate.toUpperCase()}</b> SPECIFIED AS{' '}
                    <b>{getFieldLabelFromKey(fieldConfig, fieldName, true)}</b>, WHICH FIELDS SHOULD BE AUTO-POPULATED?
                  </span>
                }
                fields={allAvailableFields}
                fieldValuePairs={
                  autoPopulate.hasOwnProperty(selectedFieldToAutoPopulate)
                    ? autoPopulate[selectedFieldToAutoPopulate]
                    : {}
                }
                setFieldValuePair={(field: string, value: string, previousField?: string) => {
                  onFieldValuePairChange(selectedFieldToAutoPopulate, field, value, previousField)
                }}
                style={{ margin: '1.5em 3em 3em' }}
                key={`fieldValueChooser_${selectedFieldToAutoPopulate}`}
              />
            </Dialog>
          )}
        </div>
      ) : shouldAutoPopulate ? (
        selectorItems?.map((item) => (
          <FieldValueChooser
            fieldConfig={fieldConfig}
            title={
              <span>
                WHEN <b>{item}</b> SELECTED, WHICH FIELDS SHOULD BE AUTO-POPULATED?
              </span>
            }
            fields={allAvailableFields}
            fieldValuePairs={autoPopulate[item] !== undefined ? autoPopulate[item] : {}}
            setFieldValuePair={(field: string, value: string, previousField?: string) =>
              onFieldValuePairChange(item, field, value, previousField)
            }
            key={`fieldValueChooser_${item}`}
          />
        ))
      ) : null}
    </>
  )
}

const styles = {
  title: {
    margin: '1.5em 0',
    textAlign: 'center' as 'center',
    alignSelf: 'center',
    fontFamily: 'roboto',
    color: ColorPalette.PRIMARY_TEXT,
    paddingLeft: 10,
    paddingRight: 10,
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
  sectionInput: { margin: '40px 1.5em 0px 1.5em' },
}
