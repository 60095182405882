import { useEffect, useState } from 'react'
import Spinner from 'react-spinkit'

import DataTable from '../../Tables/DataTable/DataTable'
import { IColumnConfig } from '../../Tables/DataTable/DataTableHeader'
import LoadingModal from '../../Modals/LoadingModal'
import { QueryResult, QueryResults } from '../../../types'
import { removeUnderScores, timestampToDateString, toUpperCaseCustom } from '../../../utils'
import { ColorPalette } from '../../../config'

type DataQueryFilteredResultsProps = {
  tableColumnConfig: IColumnConfig[]
  queryResults: QueryResults
  fetchingResults?: boolean
  showLoader?: boolean
  loaderMessage?: string
}

export const DataQueryFilteredResults = (props: DataQueryFilteredResultsProps) => {
  const [tableData, setTableData] = useState<Record<string, string>[]>([])
  const [refreshingModalOpen, setRefreshingModalOpen] = useState<boolean>(false)
  const [refreshTimestamp, setRefreshTimestamp] = useState<number>(Date.now())

  useEffect(() => {
    if (!props.queryResults?.results) {
      return
    }
    setRefreshingModalOpen(true)
    refreshProfilesFromCache(props.queryResults.results)
  }, [])

  useEffect(() => {
    prepareTableData()
  }, [refreshTimestamp === 1])

  const refreshProfilesFromCache = async (results: QueryResult[]) => {
    setRefreshingModalOpen(false)
    setRefreshTimestamp(1)
  }

  const generateRowData = (result: QueryResult, tableColumnConfig: IColumnConfig[]) => {
    let data = {} as Record<string, any>
    tableColumnConfig.forEach((column) => {
      if (column.id === 'dateActioned') {
        data[column.id] = timestampToDateString(result.dateActioned, 'YYYY/MM/DD HH:mm') || '-'
      } else {
        data[column.id] = removeUnderScores(toUpperCaseCustom(result[column.id])) || '-'
      }
    })
    return data
  }

  const prepareTableData = async () => {
    if (!props.queryResults?.results) {
      return
    }

    const data = props.queryResults?.results.map((result) => generateRowData(result, props.tableColumnConfig))
    setTableData(data)
    setRefreshTimestamp(Date.now())
  }

  let refreshInProgressModal = null
  if (refreshingModalOpen) {
    refreshInProgressModal = <LoadingModal open={true}>Refreshing profiles...</LoadingModal>
  }

  let tableContent = (
    <DataTable
      tableData={tableData || []}
      columnConfig={props.tableColumnConfig}
      tableWidth={9}
      filterState={{}}
      onRowClick={(rowData: Record<string, any>) => null}
      selectedRowItemId={''}
      selectionEnabled={false}
      disabled={true}
      style={{ flexGrow: 1 }}
      key={`dataQueryFilteredResults_dataTable_${refreshTimestamp}`}
    />
  )
  if (props.showLoader) {
    tableContent = (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Spinner
          style={{ transform: 'scale(0.6)' }}
          fadeIn="quarter"
          name="three-bounce"
          color={ColorPalette.PRIMARY_BLUE}
        />
        <h1 style={{ ...textStyle }}>{props.loaderMessage || 'Fetching results'}</h1>
      </div>
    )
  }
  return (
    <>
      {tableContent}
      {refreshInProgressModal}
    </>
  )
}

const textStyle = {
  textAlign: 'center' as 'center',
  alignSelf: 'center',
  fontFamily: 'roboto',
  fontWeight: 'normal',
  color: ColorPalette.SECONDARY_TEXT,
  fontSize: '0.85rem',
  paddingLeft: 10,
  paddingRight: 10,
  marginTop: 25,
}
