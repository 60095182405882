import React from 'react'
import Radium from 'radium'
import { StyleRules } from '@material-ui/core'
import { ColorPalette } from '../../../config/colors'

interface IButtonGenericProps {
  disabled?: boolean
  label?: string | React.ReactChild[] | Element
  buttonWidth?: number
  buttonIndex?: number
  iconBefore?: JSX.Element
  iconAfter?: JSX.Element
  style?: React.CSSProperties
  labelStyle?: React.CSSProperties
  title?: string
  refCallback?: (element: HTMLDivElement) => void
  onClick: () => void
}

const ButtonGeneric = (props: IButtonGenericProps) => {
  let iconBefore = null
  if (props.iconBefore) {
    iconBefore = props.iconBefore
  }

  let iconAfter = null
  if (props.iconAfter) {
    iconAfter = props.iconAfter
  }

  let labelStyle = {
    display: 'flex',
    ...props.labelStyle,
  }
  if (props.iconAfter) {
    labelStyle = { ...labelStyle, marginRight: 'auto' } // margin-right here ensure that right icon is rendered at the end of the button component width
  }

  let buttonStyle: React.CSSProperties = { ...styles.drawerButton, ...props.style }
  if (props.disabled) {
    buttonStyle.cursor = 'default'
  } else {
    buttonStyle = {
      ...buttonStyle,
      // @ts-ignore
      ':hover':
        props.style && props.style.hasOwnProperty(':hover')
          ? (props.style as StyleRules)[':hover']
          : {
              filter: 'brightness(75%)',
            },
      // @ts-ignore
      ':active':
        props.style && props.style.hasOwnProperty(':active')
          ? (props.style as StyleRules)[':active']
          : {
              filter: 'brightness(30%)',
            },
    }
  }

  return (
    <div ref={props.refCallback} style={{ ...styles.buttonContainer, maxWidth: props.style?.width || 'unset' }}>
      <button style={buttonStyle} onClick={props.onClick} disabled={props.disabled} title={props.title}>
        {iconBefore}
        <p
          style={labelStyle}
          title={props.title === undefined && typeof props.label === 'string' ? props.label : undefined}>
          {props.label}
        </p>
        {iconAfter}
      </button>
    </div>
  )
}

let styles = {
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    width: '100%',
    alignItems: 'center',
  },
  drawerButton: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.9rem',
    fontFamily: 'Roboto',
    fontWeight: '500',
    color: ColorPalette.PRIMARY_LIGHT_TEXT,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    height: '100%',
    width: '100%',
    outline: 0,
    borderWidth: 0,
    borderStyle: 'solid',
    borderColor: 'rgba(255,255,255,0)',
    cursor: 'pointer',
  },
}

export default Radium(ButtonGeneric)
