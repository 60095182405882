import { httpDelete, httpGet, httpPost } from '../remoteDataProvider'

export async function createNewBatch(selectedAssociation: string, payload: any, token: string) {
  try {
    const response = await httpPost('people_flow_core', `/asynctasks/${selectedAssociation}`, payload, token)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function getBatches(selectedAssociation: string, type: string, token: string) {
  try {
    const response = await httpGet('people_flow_core', `/asynctasks/type/${type}/${selectedAssociation}`, token)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function deleteBatch(selectedAssociation: string, taskId: string, token: string) {
  try {
    const response = await httpDelete('people_flow_core', `/asynctasks/taskid/${taskId}/${selectedAssociation}`, token)
    return response.data
  } catch (error) {
    throw error
  }
}
