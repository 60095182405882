import { Profile } from '../models'
import { LocalStorageProvider } from '../providers'
import { profileIdentifier } from '../config/localStorage'

export const persistSingleProfileUpdate = (selectedAssociation: string, profile: Profile) => {
  const associationProfileIdentifier = `${selectedAssociation}#${profileIdentifier}${profile.getPk()}`
  LocalStorageProvider.setData(associationProfileIdentifier, profile)
}

export const getProfilePkFromStorageKey = (storageKey: string) => {
  if (storageKey.includes(profileIdentifier)) {
    const parts = storageKey.split(profileIdentifier)
    const pk = parts[1]
    return pk
  }
  return ''
}

export const getAssociationNameFromProfileStorageKey = (storageKey: string) => {
  if (storageKey.includes(profileIdentifier)) {
    const parts = storageKey.split(profileIdentifier)
    const association = parts[0].replace('#', '')
    return association
  }
  return ''
}
