import { CommonTs } from '../models/common-ts/commonts'

export type ImportRecordQuery = {
  cohort?: string
  trashStatus?: CommonTs.Profile.TrashStatus
  employmentStatus?: CommonTs.Profile.EmploymentStatus
}

export enum AsyncTaskTypesEnum {
  DOC_EXPORT = 'DOC_EXPORT',
  EMPLOYEE_CARDS = 'EMPLOYEE_CARDS',
  BULK_DISCIPLINE = 'BULK_DISCIPLINE',
}
