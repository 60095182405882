import { isEmpty } from 'lodash'

import { AssociationSettings } from '../models/associationSettings/associationSettings'
import { DocConfig, IDisciplineConfig, IRequestInstanceConfig, AssocationSettingsSchema } from '../models'
import { getDivisionJobTypeJobSubType } from '../utils'
import { LocalStorageProvider } from '../providers'
import { DisciplineConfig } from '../models/discipline/idiscipline.config'
import { associationSettingsIdentifier } from '../config/localStorage'
import {
  AllAssociationSettings,
  AssociationId,
  ColumnConfig,
  FieldName,
  PpeSupplierKeyValuePairs,
  RoleName,
  SignatureConfig,
  FieldConfigItemSchema,
  CohortName,
  ProfilePk,
} from '../types'
import { IDataFlow } from '../interfaces'
import { SessionErrorCodesEnum } from '../enums'
import { DEFAULT_PROFILE_TABLE_COLUMN_CONFIG } from '../config/tableColumnConfig'
import { DEFAULT_ACTION_EXPIRY, DEFAULT_ACTION_FLOW } from '../constants/disciplineConstants'

const { MissingAssociationSettings } = SessionErrorCodesEnum
export class AssociationSettingsRepository {
  private allAssociationSettingEntities: AllAssociationSettings | undefined

  async initialise(newAssociationSettingsJson: Record<AssociationId, AssocationSettingsSchema> = {}) {
    for (const association in newAssociationSettingsJson) {
      await this.putLocal(association, newAssociationSettingsJson[association])
    }
    const allLocalAssocationSettings = await this.getAllLocalAssociationSettingsJson()
    let allAssociationSettingEntities = {} as AllAssociationSettings
    Object.keys(allLocalAssocationSettings).forEach((association) => {
      allAssociationSettingEntities[association] = new AssociationSettings({
        ...allLocalAssocationSettings[association],
        association,
      })
    })
    this.allAssociationSettingEntities = allAssociationSettingEntities
  }

  async getAllLocalAssociationSettingsJson() {
    const localAssociationSettingKeys = await LocalStorageProvider.listDataKeys(associationSettingsIdentifier)
    let allLocalAssocationSettings = {} as Record<AssociationId, AssocationSettingsSchema>
    for (const storageKey of localAssociationSettingKeys) {
      try {
        const associationSettings = await LocalStorageProvider.getData(storageKey)
        const assocationName = this.parseAssocationFromStorageKey(storageKey)
        allLocalAssocationSettings[assocationName] = associationSettings
      } catch (error) {
        console.error('getAllLocalAssociationSettingsJson() error: ', error)
      }
    }
    return allLocalAssocationSettings
  }

  parseAssocationFromStorageKey(storageKey: string) {
    return storageKey.replace(associationSettingsIdentifier, '')
  }

  findAssociationsWithoutSettings(requiredAssociations: AssociationId[]) {
    let missingAssociations = new Set(requiredAssociations)
    if (!this.allAssociationSettingEntities) {
      return Array.from(missingAssociations.values())
    }
    Object.keys(this.allAssociationSettingEntities).forEach((association) => missingAssociations.delete(association))
    return Array.from(missingAssociations.values())
  }

  getAllAssociationIds() {
    return Object.keys(this.allAssociationSettingEntities || {})
  }

  getAssociationSettingsEntity(associationId: AssociationId): AssociationSettings {
    if (!this.allAssociationSettingEntities) {
      throw { code: MissingAssociationSettings, associationId }
    }
    return this.allAssociationSettingEntities[associationId]
  }

  async putLocal(association: AssociationId, associationSettings: AssocationSettingsSchema) {
    const storageKey = this.getLocalStorageKey(association)
    await LocalStorageProvider.setData(storageKey, associationSettings)
  }

  getLocalStorageKey(association: AssociationId) {
    return `${associationSettingsIdentifier}${association}`
  }

  getAuthorisedPks(association: string, selectedCohort?: CohortName): ProfilePk[] {
    const profileAccess = this.getAssociationSettingsEntity(association).getProfileAccess()
    if (!selectedCohort) {
      return Object.values(profileAccess).flat()
    }
    return profileAccess[selectedCohort] || []
  }

  getAllDataFlows(association: string): Record<string, IDataFlow> {
    const associationSettingsEntity = this.getAssociationSettingsEntity(association)
    return associationSettingsEntity.getAllDataFlows()
  }

  getAllAssociationRoles(association: string): RoleName[] {
    const associationSettingsEntity = this.getAssociationSettingsEntity(association)
    return associationSettingsEntity.getAllRoleNames()
  }

  getAllCohorts(association: string): string[] {
    const associationSettingsEngtity = this.getAssociationSettingsEntity(association)
    const iam = associationSettingsEngtity.getIam()
    if (iam === undefined || iam.permissions === null) {
      return []
    }
    const cohorts = associationSettingsEngtity.getAllCohorts()
    return cohorts
  }

  getDocConfig(association: string): Record<string, any> {
    const associationSettingsEntity = this.getAssociationSettingsEntity(association)
    return associationSettingsEntity.getDocConfig()
  }

  getPublishedDocTemplates(association: string): Record<string, any> {
    const docConfig = this.getDocConfig(association)
    const isPublished = (docObj: DocConfig) => !isEmpty(docObj.published)

    return Object.keys(docConfig).reduce((acc: Record<string, any>, key) => {
      if (isPublished(docConfig[key])) {
        acc[key] = docConfig[key]
      }
      return acc
    }, {})
  }

  getFieldConfig(association: string): Record<string, FieldConfigItemSchema> {
    const associationSettingsEntity = this.getAssociationSettingsEntity(association)
    return associationSettingsEntity.getFieldConfig()
  }

  getFieldLabelFromConfig = (association: string, fieldKey: string): string => {
    const fieldConfig = this.getFieldConfig(association)
    return fieldConfig[fieldKey]?.label || ''
  }
  getUniqueFieldKeysFromConfig = (association: string) => {
    const fieldConfig = this.getFieldConfig(association)
    const allKeys = Object.keys(fieldConfig)
    const keys = allKeys.filter((fieldName) => fieldConfig[fieldName].isUniquePerProfile)
    return Array.from(new Set([...keys, 'idPassport']))
  }

  getProcessConfig(association: string): Record<string, any> {
    const associationSettingsEntity = this.getAssociationSettingsEntity(association)
    return associationSettingsEntity.getProcessConfig()
  }

  getAllPossibleCompetencies(association: string): string[] {
    const processConfig = this.getProcessConfig(association)
    let allCompetencies: string[] = []

    Object.keys(processConfig.competenciesv2.competencyConfig).forEach((division) => {
      Object.keys(processConfig.competenciesv2.competencyConfig[division]).forEach((jobType) => {
        Object.keys(processConfig.competenciesv2.competencyConfig[division][jobType]).forEach((jobSubType) => {
          const requiredCompetencies: string[] =
            processConfig.competenciesv2.competencyConfig[division][jobType][jobSubType].requiredCompetencies
          allCompetencies = [...new Set([...requiredCompetencies, ...allCompetencies])]
        })
      })
    })

    return allCompetencies
  }

  getDisciplinaryConfig(association: string): IDisciplineConfig {
    const processConfig = this.getProcessConfig(association)
    return new DisciplineConfig(processConfig)
  }

  getActionFlowConfig(
    association: string,
    division: string,
    jobType: string,
    jobSubType: string,
    offence: string,
    action: string,
  ): any {
    const disciplinaryConfig = this.getDisciplinaryConfig(association)
    if (disciplinaryConfig === null) {
      return {}
    }
    return disciplinaryConfig.extractActionFlowConfig(division, jobType, jobSubType, offence, action)
  }

  getPossibleOffences(association: string): string[] {
    const disciplinaryConfig = this.getDisciplinaryConfig(association)
    return disciplinaryConfig.extractPossibleOffences()
  }

  // getPoorPerformanceName(association: string): string {
  //   const disciplinaryConfig = this.getDisciplinaryConfig(association)
  //   return disciplinaryConfig.extractPoorPerformanceName()
  // }

  getAvailableExportConfigs(association: string): string[] {
    const associationSettingsEntity = this.getAssociationSettingsEntity(association)
    const availableExportConfigs = associationSettingsEntity.getAvailableExportConfigs()
    return availableExportConfigs
  }

  getOrganisationConfig(association: string): Record<string, any> {
    return this.getAssociationSettingsEntity(association).getOrganisationConfig()
  }

  populateFilterConfig(
    filterConfig: { key: string; label: string }[],
    fieldConfig: Record<FieldName, FieldConfigItemSchema>,
  ): { key: string; label: string }[] {
    filterConfig = [...filterConfig].filter((configItem) => configItem.key in fieldConfig)
    filterConfig = [...filterConfig].map((configItem) => {
      configItem.label = fieldConfig[configItem.key].label
      return configItem
    })
    return filterConfig
  }

  getProfileFilterConfig(association: string): { key: string; label: string }[] {
    const associationSettingsEntity = this.getAssociationSettingsEntity(association)
    const profileFilterConfig = associationSettingsEntity.getOrganisationConfigItem('profileFilterConfigWeb') as {
      key: string
      label: string
    }[]
    if (profileFilterConfig === undefined) {
      return []
    }
    const fieldConfig = this.getFieldConfig(association)
    return this.populateFilterConfig(profileFilterConfig, fieldConfig)
  }

  getProfileTableColumnConfig(association: string): ColumnConfig[] {
    const defaultColumnConfig: ColumnConfig[] = DEFAULT_PROFILE_TABLE_COLUMN_CONFIG
    const associationSettingsEntity = this.getAssociationSettingsEntity(association)
    const profileTableColumnConfig = associationSettingsEntity.getOrganisationConfigItem(
      'profileTableColumnConfigWeb',
    ) as {
      key: string
      label: string
      sizeFactor: number
    }[]
    if (profileTableColumnConfig === undefined) {
      return defaultColumnConfig
    }
    return profileTableColumnConfig
  }

  getSignatureConfig(association: string): SignatureConfig {
    const defaultSignatureConfig = {
      signatoryTypes: ['EMPLOYEE', 'MANAGER', 'WITNESS'],
    }
    const associationSettingsEntity = this.getAssociationSettingsEntity(association)
    const signatureConfig = associationSettingsEntity.getOrganisationConfigItem('signatureConfig')
    if (signatureConfig === undefined) {
      return defaultSignatureConfig
    }
    return signatureConfig
  }

  getRequestConfig(association: string): Record<string, IRequestInstanceConfig> {
    const associationSettingsEntity = this.getAssociationSettingsEntity(association)
    return associationSettingsEntity.getRequestConfig() || {}
  }

  getSelectedRequestConfig(association: string, requestLabel: string): IRequestInstanceConfig | undefined {
    const allRequestConfig = this.getRequestConfig(association)
    if (allRequestConfig === undefined) {
      return
    }
    for (const requestKey of Object.keys(allRequestConfig)) {
      if (allRequestConfig[requestKey].getRequestName() === requestLabel) {
        return allRequestConfig[requestKey]
      }
    }
    return
  }

  setAllAssocationRoles(association: AssociationId, rolesNames: RoleName[]) {
    this.getAssociationSettingsEntity(association).setRoleNames(rolesNames)
  }

  setRequestConfig(association: string, requestConfig: Record<string, IRequestInstanceConfig>): void {
    this.getAssociationSettingsEntity(association).setRequestConfig(requestConfig)
  }

  setDocConfig(association: string, docConfig: Record<string, DocConfig>): void {
    this.getAssociationSettingsEntity(association).setDocConfig(docConfig)
  }

  setOrganisationConfigPPESuppliers(association: string, suppliers: PpeSupplierKeyValuePairs): void {
    this.getAssociationSettingsEntity(association).getOrganisationConfig().ppeSuppliers = suppliers
  }

  setDataFlowConfig(association: string, dataFlowName: string, config: IDataFlow) {
    const associationSettingsEntity = this.getAssociationSettingsEntity(association)
    associationSettingsEntity.getAllDataFlows()[dataFlowName] = config
  }

  setDivisionConfig(association: string, divisionConfig: Record<string, any>): void {
    const associationSettingsEntity = this.getAssociationSettingsEntity(association)
    associationSettingsEntity.setOrganisationConfigItem('divisionConfig', divisionConfig)
  }

  setFieldConfig(association: string, config: Record<string, FieldConfigItemSchema>): void {
    const associationSettingsEntity = this.getAssociationSettingsEntity(association)
    associationSettingsEntity.setFieldConfig(config)
  }

  setFieldConfigItem(association: string, item: Record<string, any>): void {
    const itemName = Object.keys(item)[0]
    const associationSettingsEntity = this.getAssociationSettingsEntity(association)
    associationSettingsEntity.setFieldConfigItem(itemName, item[itemName])
  }

  setProcessConfigItem(association: string, item: Record<string, any>): void {
    const associationSettingsEntity = this.getAssociationSettingsEntity(association)
    associationSettingsEntity.setProcessConfigItem(item)
  }

  initOffenceConfigDataPath(offenceSpecificConfig: any, division: string, jobType: string, jobSubType: string) {
    const { divisionNode, jobTypeNode, jobSubTypeNode } = getDivisionJobTypeJobSubType(
      offenceSpecificConfig,
      division,
      jobType,
      jobSubType,
      true,
    )

    if (!offenceSpecificConfig.hasOwnProperty(divisionNode)) {
      offenceSpecificConfig[divisionNode] = {
        allJobTypes: {
          allJobSubTypes: {
            actionExpiry: DEFAULT_ACTION_EXPIRY,
            actionFlow: offenceSpecificConfig.allJobTypes.allJobSubTypes.actionFlow,
          },
        },
      }
    }
    if (!offenceSpecificConfig[divisionNode].hasOwnProperty(jobTypeNode)) {
      offenceSpecificConfig[divisionNode][jobTypeNode] = {
        allJobSubTypes: {
          actionExpiry: DEFAULT_ACTION_EXPIRY,
          actionFlow: offenceSpecificConfig[divisionNode].allJobTypes.allJobSubTypes.actionFlow,
        },
      }
    }
    if (!offenceSpecificConfig[divisionNode][jobTypeNode].hasOwnProperty(jobSubTypeNode)) {
      offenceSpecificConfig[divisionNode][jobTypeNode][jobSubTypeNode] = {
        actionExpiry: DEFAULT_ACTION_EXPIRY,
        actionFlow: offenceSpecificConfig[divisionNode][jobTypeNode].allJobSubTypes.actionFlow,
      }
    }

    return offenceSpecificConfig[divisionNode][jobTypeNode][jobSubTypeNode]
  }

  setOffenceConfigActionExpiry(
    association: string,
    division: string,
    jobType: string,
    jobSubType: string,
    offence: string,
    action: string,
    expiryInMilliseconds: number,
  ): void {
    const existingOffenceConfig =
      this.getAssociationSettingsEntity(association).getProcessConfig().discipline.offenceConfig
    if (existingOffenceConfig && existingOffenceConfig.hasOwnProperty(offence)) {
      const path = this.initOffenceConfigDataPath(existingOffenceConfig[offence], division, jobType, jobSubType)
      path.actionExpiry[action] = expiryInMilliseconds
    }
  }

  setOffenceConfigActionFlowItems(
    association: string,
    division: string,
    jobType: string,
    jobSubType: string,
    offence: string,
    actionFlowItems: string[],
  ) {
    const existingOffenceConfig =
      this.getAssociationSettingsEntity(association).getProcessConfig().discipline.offenceConfig

    if (existingOffenceConfig && existingOffenceConfig.hasOwnProperty(offence)) {
      const path = this.initOffenceConfigDataPath(existingOffenceConfig[offence], division, jobType, jobSubType)
      path.actionFlow = [...actionFlowItems]
    }
  }

  addOffenceConfigActionFlowItem(
    association: string,
    division: string,
    jobType: string,
    jobSubType: string,
    offence: string,
    actionFlowName: string,
  ): void {
    const existingOffenceConfig =
      this.getAssociationSettingsEntity(association).getProcessConfig().discipline.offenceConfig

    if (existingOffenceConfig && existingOffenceConfig.hasOwnProperty(offence)) {
      const { divisionNode, jobTypeNode, jobSubTypeNode } = getDivisionJobTypeJobSubType(
        existingOffenceConfig[offence],
        division,
        jobType,
        jobSubType,
        true,
      )

      if (
        !this.getAssociationSettingsEntity(association)
          .getProcessConfig()
          .discipline.offenceConfig[offence][divisionNode].hasOwnProperty(jobTypeNode)
      ) {
        this.getAssociationSettingsEntity(association).getProcessConfig().discipline.offenceConfig[offence][
          divisionNode
        ][jobTypeNode] = {
          allJobSubTypes: {
            actionExpiry: DEFAULT_ACTION_EXPIRY,
            actionFlow: DEFAULT_ACTION_FLOW,
          },
        }
      }

      const existingActionFlowItems =
        this.getAssociationSettingsEntity(association).getProcessConfig().discipline.offenceConfig[offence][
          divisionNode
        ][jobTypeNode][jobSubTypeNode].actionFlow

      if (!existingActionFlowItems.includes(actionFlowName)) {
        const actionFlow =
          this.getAssociationSettingsEntity(association).getProcessConfig().discipline.offenceConfig[offence][
            divisionNode
          ][jobTypeNode][jobSubTypeNode].actionFlow
        const terminateActionIndex = actionFlow.findIndex((action: string) => action === 'TERMINATE')

        if (terminateActionIndex !== -1) {
          this.getAssociationSettingsEntity(association)
            .getProcessConfig()
            .discipline.offenceConfig[offence][divisionNode][jobTypeNode][jobSubTypeNode].actionFlow.splice(
              terminateActionIndex,
              0,
              actionFlowName,
            )
        } else {
          this.getAssociationSettingsEntity(association)
            .getProcessConfig()
            .discipline.offenceConfig[offence][divisionNode][jobTypeNode][jobSubTypeNode].actionFlow.push(
              actionFlowName,
            )
        }
      }
    }
  }

  addOffenceConfigItem(
    association: string,
    division: string,
    jobType: string,
    jobSubType: string,
    offence: string,
  ): void {
    const existingOffenceConfig =
      this.getAssociationSettingsEntity(association).getProcessConfig().discipline.offenceConfig
    if (existingOffenceConfig && !existingOffenceConfig.hasOwnProperty(offence)) {
      const { divisionNode, jobTypeNode, jobSubTypeNode } = getDivisionJobTypeJobSubType(
        existingOffenceConfig[offence],
        division,
        jobType,
        jobSubType,
        true,
      )

      this.getAssociationSettingsEntity(association).getProcessConfig().discipline.offenceConfig[offence] = {
        [divisionNode]: {
          [jobTypeNode]: {
            [jobSubTypeNode]: {
              actionFlow: DEFAULT_ACTION_FLOW,
              actionExpiry: DEFAULT_ACTION_EXPIRY,
            },
          },
        },
      }
    }
  }

  removeOffenceConfigActionFlowItem(
    association: string,
    division: string,
    jobType: string,
    jobSubType: string,
    offence: string,
    actionFlowItemIndex: number,
  ): void {
    const existingOffenceConfig =
      this.getAssociationSettingsEntity(association).getProcessConfig().discipline.offenceConfig

    if (existingOffenceConfig && existingOffenceConfig.hasOwnProperty(offence)) {
      // TODO: use getConfiguredProcessInfo instead of getDivisionJobTypeJobSubType
      const { divisionNode, jobTypeNode, jobSubTypeNode } = getDivisionJobTypeJobSubType(
        existingOffenceConfig[offence],
        division,
        jobType,
        jobSubType,
      )

      const existingActionFlowItems =
        this.getAssociationSettingsEntity(association).getProcessConfig().discipline.offenceConfig[offence][
          divisionNode
        ][jobTypeNode][jobSubTypeNode].actionFlow

      if (existingActionFlowItems.length > actionFlowItemIndex) {
        this.getAssociationSettingsEntity(association)
          .getProcessConfig()
          .discipline.offenceConfig[offence][divisionNode][jobTypeNode][jobSubTypeNode].actionFlow.splice(
            actionFlowItemIndex,
            1,
          )
      }
    }
  }

  setTerminationReasonConfigItem(association: string, item: Record<string, any>): void {
    const itemName = Object.keys(item)[0]
    const existingTerminationReasonConfig =
      this.getAssociationSettingsEntity(association).getProcessConfig().terminate.terminationReasonConfig

    if (existingTerminationReasonConfig && existingTerminationReasonConfig.hasOwnProperty(itemName)) {
      this.getAssociationSettingsEntity(association).getProcessConfig().terminate.terminationReasonConfig[itemName] =
        item[itemName]
    }
  }
}
