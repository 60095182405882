import { Component, RefObject, createRef } from 'react'
import { connect } from 'react-redux'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

import ButtonBlue from '../BaseComponents/Buttons/ButtonBlue'
import CalendarContained from '../BaseComponents/Calendar/CalendarContained'
import {
  CsvProcessIdentifier,
  getCsvFieldHeaderKeyByName,
  writeLocalFileToRemoteStorage,
  dateObjToString,
  jsonToCsv,
  packageAndUploadFile,
  toUpperCaseCustom,
  getDateFormat,
  packageDataAndDownloadFile,
  toValidPfDate,
} from '../../utils'
import { PeopleFlowCombinedReducer } from '../../store'
import CsvFileProcessingModal from './CsvFileProcessingModal'
import CheckboxTabLabelled from '../BaseComponents/Checkboxes/CheckboxTabLabelled'
import { DateFormatConvention } from '../../types'
import { ColorPalette } from '../../config'
import { AssociationSettingsRepository, ProfileRepository } from '../../repositories'
import AlertModalOneButton from './AlertModalOneButton'
import LoadingModal from './LoadingModal'

const csvColumnConfigEnum = {
  idPassport: 'ID/Passport',
  terminationDate: 'Termination Date',
}
const compulsoryDataKeys = ['idPassport']
interface ITerminationDataUploadModalProps {
  open: boolean
  selectedAssociation: string
  profileRepo: ProfileRepository
  associationRepo: AssociationSettingsRepository
  onNextClick: (fileName: string) => void
  onCancelClick: () => void
  forwardedRef?: RefObject<HTMLDivElement>
}

interface ITerminationDataUploadModalState {
  loadingModalOpen: boolean
  datePickerOpen: boolean
  uploadedFileName: string | null
  terminateIdsListed: boolean
  defaultDateStandard: DateFormatConvention
  warningModalOpen: boolean
  warningModalHeader: string
  warningModalMessage: string
  loadingModalMessage: string
}

const today = new Date().toISOString()
const csvProcessName = CsvProcessIdentifier.BULK_TERMINATION
const initialState = {
  loadingModalOpen: false,
  selectedDate: today,
  datePickerOpen: false,
  uploadedFileName: null,
  terminateIdsListed: true,
  warningModalOpen: false,
  warningModalHeader: '',
  warningModalMessage: '',
  loadingModalMessage: '',
  defaultDateStandard: DateFormatConvention.UK,
}

export type TerminationDataUploadModalType = TerminationDataUploadModal

class TerminationDataUploadModal extends Component<ITerminationDataUploadModalProps, ITerminationDataUploadModalState> {
  state = initialState
  csvFileContentIssues = createRef<string[]>()

  constructor(props: ITerminationDataUploadModalProps) {
    super(props)
  }

  toggleDatePickOpen = () => this.setState((prevState) => ({ datePickerOpen: !prevState.datePickerOpen }))

  toggleTerminateIdsListed = () => {
    this.setState({ terminateIdsListed: true })
  }
  toggleTerminateIdsNotListed = () => {
    this.setState({ terminateIdsListed: false })
  }

  uploadCsv = async (fileName: string, filePath: string, fileData: string[], fileType: string) => {
    const file = new File(fileData, fileName, { type: 'fileType' })
    await writeLocalFileToRemoteStorage(filePath, file, fileType)
  }

  validateData = (jsonData: Record<string, string>[] = []) => {
    if (!jsonData.length) {
      throw { code: 'MissingData' }
    }
    const { profileRepo } = this.props
    const { defaultDateStandard } = this.state
    const JSON_FIRST_ROW_NUMBER = 2
    let errorSummary = { messageList: [] as string[], idPassportsNotFound: [] as string[] }
    jsonData.forEach((jsonItem, index) => {
      let missingDataForKeys = []
      for (const key of compulsoryDataKeys) {
        if (!jsonItem[key]) {
          missingDataForKeys.push(key)
        }
      }
      if (missingDataForKeys.length) {
        //@ts-ignore
        const missingColumnLabels = missingDataForKeys.map((key) => csvColumnConfigEnum[key])
        errorSummary.messageList.push(
          `Row ${index + JSON_FIRST_ROW_NUMBER} is missing values for: ${missingColumnLabels.join('; ')}`,
        )
      }
      const maybeDateFormat = getDateFormat(jsonItem.terminationDate, defaultDateStandard)
      if (!maybeDateFormat) {
        errorSummary.messageList.push(
          `Row ${index + JSON_FIRST_ROW_NUMBER} contains invalid date: ${jsonItem.terminationDate}`,
        )
      }
      const idPassport = toUpperCaseCustom(jsonItem.idPassport)
      const profile = profileRepo.getProfileByIdPassport(idPassport)
      if (!profile) {
        errorSummary.idPassportsNotFound.push(idPassport)
      }
    })
    if (Object.keys(errorSummary.messageList).length || errorSummary.idPassportsNotFound.length) {
      throw { code: 'MissingRowData', errorSummary }
    }
  }

  replaceIdPassportsWithProfileIds = (jsonData: Record<string, string>[]) => {
    const { profileRepo } = this.props
    return jsonData.map((jsonItem) => {
      const idPassport = toUpperCaseCustom(jsonItem.idPassport)
      const profile = profileRepo.getProfileByIdPassport(idPassport)
      jsonItem.idPassport = profile?.getPk() || ''
      return jsonItem
    })
  }

  ensurePfDateFormat = (jsonData: Record<string, string>[]) => {
    return jsonData.map((item) => {
      item.terminationDate = toValidPfDate(item.terminationDate, 'YYYY/MM/DD', DateFormatConvention.US)
      return item
    })
  }

  getDefaultHeaderKey = (index: number) => {
    if (index === 0) {
      return 'idPassport'
    }
    if (index === 1) {
      return 'terminationDate'
    }
    return ''
  }

  transformHeader = (header: string, index: number) => {
    const { associationRepo, selectedAssociation } = this.props
    const organisationConfig = associationRepo.getOrganisationConfig(selectedAssociation)
    const defaultHeaderKey = this.getDefaultHeaderKey(index)
    const headerKey = getCsvFieldHeaderKeyByName(organisationConfig, csvProcessName, header, defaultHeaderKey)
    return headerKey
  }

  submissionHandler = async (jsonFileContent: Record<string, string>[] = []) => {
    try {
      this.setState({ loadingModalOpen: true, loadingModalMessage: 'Processing...' })
      this.validateData(jsonFileContent)
      jsonFileContent = this.replaceIdPassportsWithProfileIds(jsonFileContent)
      jsonFileContent = this.ensurePfDateFormat(jsonFileContent)

      const { selectedAssociation } = this.props
      const fileName = `bulktermination_import_${Date.now()}.csv`
      const filePath = `Companies/${selectedAssociation}/Imports/${fileName}`
      const csvFileRows = jsonToCsv(jsonFileContent)

      await packageAndUploadFile([csvFileRows], fileName, filePath, 'text/csv')
      this.setState({ loadingModalOpen: false, uploadedFileName: fileName })
      this.props.onNextClick(fileName)
    } catch (error) {
      this.errorHandler(error)
    }
  }

  handleDateStandardSelection = (values: string[]) => {
    const standard = values[0] as DateFormatConvention
    this.setState({ defaultDateStandard: standard })
  }

  errorHandler = (error: any) => {
    let warningModalHeader = ''
    let warningModalMessage = '' as any
    switch (error.code) {
      case 'MissingData':
        warningModalHeader = 'Missing Data'
        warningModalMessage = "Your file doesn't contain any data"
        break
      case 'MissingRowData':
        warningModalHeader = 'Missing Row Data'
        warningModalMessage = [<p style={{ marginTop: 0, fontWeight: 'bold' }}>Your file contains errors:</p>]
        const errorSummary = error.errorSummary || { messageList: [], idPassportsNotFound: [] }
        Object.values(errorSummary.messageList).forEach((message) => {
          warningModalMessage.push(<p> - {message}</p>)
        })
        if (errorSummary.idPassportsNotFound.length) {
          const message = `There are ID/Passport values which don't match any downloaded profiles. A file containing these missing values is being downloaded now. Ensure that you visit the CANDIDATES/EMPLOYEES screens and click REFRESH to ensure all profiles have been downloaded.`
          warningModalMessage.push(<p> - {message}</p>)
          packageDataAndDownloadFile(errorSummary.idPassportsNotFound.join('\n'), 'UnknownIds_(termination).csv')
        }
        break
    }
    this.setState({ loadingModalOpen: false, warningModalOpen: true, warningModalHeader, warningModalMessage })
  }

  getCustomModalContent = () => {
    return (
      <>
        <CheckboxTabLabelled
          label="Are your dates in UK or US format?"
          values={[this.state.defaultDateStandard]}
          selectorItems={[DateFormatConvention.UK, DateFormatConvention.US]}
          maxItems={1}
          selectionHandler={this.handleDateStandardSelection}
          style={{ marginTop: 40, width: '90%' }}
        />
        <DateFormatStandardExplainer />
      </>
    )
  }

  render() {
    const { open } = this.props
    const { loadingModalOpen, loadingModalMessage, warningModalOpen, warningModalHeader, warningModalMessage } =
      this.state
    let modal = null
    if (open) {
      if (warningModalOpen) {
        modal = (
          <AlertModalOneButton
            open={true}
            header={warningModalHeader}
            subHeader={warningModalMessage}
            buttonLabel={'Ok'}
            opaqueBackground={true}
            onClick={() => this.setState({ warningModalOpen: false })}
          />
        )
      } else if (loadingModalOpen) {
        modal = <LoadingModal open={true}>{loadingModalMessage}</LoadingModal>
      } else {
        modal = (
          <CsvFileProcessingModal
            open={true}
            title="Import Termination Data"
            instructions="Download and complete the CSV template. "
            csvColumnConfig={csvColumnConfigEnum}
            onCancel={this.props.onCancelClick}
            onSubmit={this.submissionHandler}
            customUi={this.getCustomModalContent()}
          />
        )
      }
    }
    return modal
  }
}

const mapStateToProps = (state: PeopleFlowCombinedReducer) => {
  return {
    associationRepo: state.sessionManager.associationRepo as AssociationSettingsRepository,
    selectedAssociation: state.sessionManager.selectedAssociation,
    profileRepo: state.sessionManager.profileRepo as ProfileRepository,
  }
}

export default connect(mapStateToProps)(TerminationDataUploadModal)

const DateFormatStandardExplainer = () => (
  <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
    <div
      style={{
        fontSize: '0.8rem',
        paddingTop: '0.25em',
        textAlign: 'center',
        color: ColorPalette.TERTIARY_TEXT,
      }}>
      <b>UK</b>&nbsp;&nbsp;-&nbsp;&nbsp;day/month/year or year/month/day
      <br />
      <b>US</b>&nbsp;&nbsp;-&nbsp;&nbsp;month/day/year or year/day/month
    </div>
  </div>
)
