import cloneDeep from 'lodash/cloneDeep'

export function copyObjectImmutibly<T>(data: T): T {
  try {
    const copy = cloneDeep(data)
    return copy
  } catch (error) {
    return { ...data }
  }
}

export function flattenEntries<T>(obj: Record<string, T>): ({ key: string } & T)[] {
  return Object.entries(obj).map((x: [string, T]) => ({
    key: x[0],
    ...x[1],
  }))
}

export const toArrayOfObjects = (objectOfObjects: {}): {}[] =>
  Object.entries(objectOfObjects).map(([key, value]) => ({ [key]: value }))

export const flipObjKeysAndValues = (obj: Record<string, string | number>): Record<string, string> => {
  const flipped = Object.entries(obj).map(([key, value]) => [value, key])
  return Object.fromEntries(flipped)
}

export const mapToObject = (map: Map<string, any>, convertMapValues?: boolean): Record<string, any> => {
  const obj: Record<string, any> = {}
  for (const [key, value] of map) {
    obj[key] = convertMapValues && value instanceof Map ? mapToObject(value) : value
  }
  return obj
}

export const isObject = (value: any) => {
  return typeof value === 'object' && value !== null && !Array.isArray(value)
}

export const classToObject = (clss: any) => {
  // based on answer in https://stackoverflow.com/questions/34699529/convert-javascript-class-instance-to-plain-object-preserving-methods
  const keys = (obj: Record<string, any>) =>
    Object.getOwnPropertyNames(obj).concat(Object.getOwnPropertyNames(obj?.__proto__))

  return keys(clss ?? {}).reduce((object: Record<string, any>, key) => {
    const [val, arr, obj] = [clss[key], Array.isArray(clss[key]), isObject(clss[key])]
    object[key] = arr ? val.map(classToObject) : obj ? classToObject(val) : val
    return object
  }, {})
}

export const getObjectKeyByValue = (obj: Record<string, string>, value: string) => {
  return Object.keys(obj).find((key) => obj[key] === value)
}

export const removeObjectLayers = (config: Record<string, any>, layersToRemove: string[]) => {
  let newConfig = {} as Record<string, any>
  Object.keys(config).forEach((currentLevelKey) => {
    let layerConfig = {}
    layersToRemove.forEach((keyToRemove) => {
      try {
        if (keyToRemove in config[currentLevelKey]) {
          layerConfig = removeObjectLayers(config[currentLevelKey][keyToRemove], layersToRemove)
        }
      } catch (error) {
        console.log('removeObjectLayers: ', { error })
      }
    })
    newConfig[currentLevelKey] = layerConfig
  })
  return newConfig
}

export const groupObjectsByKey = (allItems: Record<string, any>[], key: string) => {
  return allItems.reduce((acc, item) => {
    if (!acc[item[key]]) {
      acc[item[key]] = []
    }
    acc[item[key]].push(item)
    return acc
  }, {} as Record<string, any>)
}
