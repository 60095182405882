export enum PpeActions {
  CREATE_INSTANCES = 'CREATE_INSTANCES',
  SELECT_SIZE = 'SELECT_SIZE',
  CONFIRM_REQUEST = 'CONFIRM_REQUEST',
  RESERVE_PPE_ITEM = 'RESERVE_PPE_ITEM',
  INITIATE_ISSUE = 'INITIATE_ISSUE',
  ISSUE_PPE_ITEM = 'ISSUE_PPE_ITEM',
  INITIATE_RETURN = 'INITIATE_RETURN',
  INITIATE_RETURN_INSTANCE = 'INITIATE_RETURN_INSTANCE',
  RETURN_TO_STOCK = 'RETURN_TO_STOCK',
  RETURN_TO_STOCK_INSTANCE = 'RETURN_TO_STOCK_INSTANCE',
  WRITEOFF = 'WRITEOFF',
  WRITEOFF_INSTANCE = 'WRITEOFF_INSTANCE',
  TRANSFER = 'TRANSFER',
  TRANSFER_INSTANCE = 'TRANSFER_INSTANCE',
  COMPLETE = 'COMPLETE',
}

export enum PpeActionReason {
  RESERVE = 'RESERVE',
  LOST = 'LOST',
  DAMAGED = 'DAMAGED',
  STOLEN = 'STOLEN',
  RETURNED = 'RETURNED',
}

export enum PpeStatus {
  READY_FOR_ISSUE = 'READY_FOR_ISSUE',
  ISSUING = 'ISSUING',
  RETURN_IN_PROGRESS = 'RETURN_IN_PROGRESS',
  COMPLETE = 'COMPLETE',
}
