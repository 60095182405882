import _ from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import Icon from '@mdi/react'
import { mdiInformationOutline, mdiContentDuplicate, mdiTrashCan, mdiRefresh, mdiExport } from '@mdi/js'
import { cloneDeep, flatten, set } from 'lodash'
import Radium from 'radium'

import NavBar from '../../components/Navigation/NavBar'
import SectionHeaderPrimary from '../../components/Headings/SectionHeaderPrimary'
import SideMenu from '../../components/Navigation/SideMenu'
import { ActionButtonType, Toolbar } from '../../components/GeneralUI/Toolbar'
import LoadingModal from '../../components/Modals/LoadingModal'
import LabelCollector from '../../components/Modals/LabelCollector'
import AlertModalTwoButton from '../../components/Modals/AlertModalTwoButton'
import AlertModalOneButton from '../../components/Modals/AlertModalOneButton'
import { ColorPalette } from '../../config/colors'
import { PeopleFlowCombinedReducer } from '../../store'
import DataQueriesSideMenu from '../../components/SideMenus/DataQueriesSideMenu'
import TabNavigator from '../../components/Navigation/TabNavigator'
import { DataQueryFilteredResults, DataQuerySettings } from '../../components/Configurator/DataQueries'
import { QueryService } from '../../services'
import { DataQueryTrends } from '../../components/Configurator/DataQueries/DataQueryTrends'
import { IColumnConfig } from '../../components/Tables/DataTable/DataTableHeader'

import {
  AllQueryResults,
  CustomTimePeriod,
  DocFilterParams,
  FieldFilterParams,
  Query,
  QueryFilter,
  QueryId,
  QueryResult,
  QueryResults,
  ResultExportTargetEmails,
  TimePeriod,
  TrendConfig,
} from '../../types'
import { CommonUnixMsEnum, DataQueryErrorCodesEnum } from '../../enums/index'
import { FilterTypeEnum } from '../../enums'
import InfoCollectorModal from '../../components/Modals/InfoCollector'
import {
  checkEmailFormat,
  normaliseStringForComparison,
  toLowerCaseCustom,
  csvToJson,
  removeUnderScores,
  sentenceCase,
} from '../../utils'
import { AssociationSettingsRepository, UsersRepository } from '../../repositories'
import { getRemoteFile } from '../../providers/remoteDataProvider'

type DataQueriesProps = {
  match: any
  location: any
  history: any
}

type ModalState = {
  [key: string]: boolean
}

const TABS = {
  SETTINGS: 'SETTINGS',
  RESULTS: 'RESULTS',
  TRENDS: 'TRENDS',
}
const initialModalState: ModalState = {
  deleteConfirmModalOpen: false,
  addModalOpen: false,
  renameModalOpen: false,
  loadingModalOpen: false,
  savingModalOpen: false,
  alertOneButtonModalOpen: false,
  graphConfiguratorModalOpen: false,
  emailCollectorModalOpen: false,
}

const DataQueries = (props: DataQueriesProps) => {
  const tabbedNavigatorRef = useRef()
  const resultsIntervalRef = useRef<Record<QueryId, number>>({})

  const [selectedQuery, setSelectedQuery] = useState<Query | undefined>(undefined)
  const [currentTabName, setCurrentTabName] = useState(TABS.SETTINGS)
  const [modalState, setModalState] = useState<ModalState>(initialModalState)
  const [queries, setQueries] = useState<Query[]>([])
  const [allQueryResults, setAllQueryResults] = useState<AllQueryResults>({})
  const [triggerUnixMs, setTriggerUnixMs] = useState<number>(0)
  const [uiUpdateTrigger, setUiUpdateTrigger] = useState<number>(0)
  const [queryNamesRefreshing, setQueryNamesRefreshing] = useState<string[]>([])
  const [maxAllowedTimeRange, setMaxAllowedTimeRange] = useState<number>(CommonUnixMsEnum.ONE_MONTH * 6)
  const [warningModalContent, setWarningModalContent] = useState({ warningModalHeader: '', warningModalBody: '' })
  const [profileFilterConfig, setProfileFilterConfig] = useState<{ key: string; label: string }[]>([])
  const [profileTableColumnConfig, setProfileTableColumnConfig] = useState<IColumnConfig[]>([])
  const [fieldAggregationOptions, setFieldAggregationOptions] = useState<string[]>([])

  const associationRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.associationRepo,
  ) as AssociationSettingsRepository
  const selectedAssociation = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation,
  )
  const username = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.idPassport)
  const password = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.password)
  const userRepo = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.userRepo) as UsersRepository
  const navMenuAccess = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.navMenuAccess)

  useEffect(() => {
    initialise()
  }, [])

  const initialise = async () => {
    setModalState({ ...initialModalState, loadingModalOpen: true })
    const { filterConfig, columnConfig } = getTrendGraphConfiguratorTableConfig(associationRepo)
    const activeQueries = await loadQueries()
    setQueries(activeQueries)
    setProfileFilterConfig(filterConfig)
    setProfileTableColumnConfig(columnConfig)
    setModalState({ ...initialModalState, loadingModalOpen: false })
  }

  const getTrendGraphConfiguratorTableConfig = (associationRepo: AssociationSettingsRepository) => {
    const filterConfig = associationRepo.getProfileFilterConfig(selectedAssociation)
    const profileTableColumnConfig = associationRepo.getProfileTableColumnConfig(selectedAssociation)
    let columnConfigClone = cloneDeep(profileTableColumnConfig)
    let columnConfig = [...columnConfigClone].map((config: any) => {
      config.id = config.key
      delete config.key
      return config
    })
    // @ts-ignore
    columnConfig = [...columnConfig.slice(0, 5)]
    return { filterConfig, columnConfig }
  }

  const loadQueries = async () => {
    const queries = (await QueryService.getQueries(selectedAssociation, username, password)) as Query[]
    return queries.filter((query) => query.status === 'active')
  }

  const changeModalState = (newState: any) => setModalState((modalState) => ({ ...modalState, ...newState }))
  const closeModals = () => setModalState(initialModalState)
  const toggleModal = (identifier: string) => {
    const stateVariable = `${identifier}ModalOpen`
    changeModalState({ [stateVariable]: !modalState[stateVariable] })
  }

  const triggerResultsRecalculation = async (query: Query) => {
    try {
      setWarningModalContent({
        warningModalHeader: 'Refresh Started',
        warningModalBody:
          'Your query results are being refreshed and will display once loaded. It may take a short while.',
      })
      setModalState({
        ...initialModalState,
        alertOneButtonModalOpen: true,
      })
      const queryId = query.id || ''
      setQueryNamesRefreshing([query.name])
      await QueryService.triggerResults(selectedAssociation, queryId, username, password)
      const triggerUnixMs = Date.now()
      pollForResults(queryId, triggerUnixMs)
    } catch (error) {
      console.log('error: ', error)
    }
  }

  const pollForResults = async (queryId: string, triggerUnixMs: number) => {
    if (queryId !== selectedQuery?.id) {
      setQueryNamesRefreshing([])
      return
    }
    const maybeQueryResults = await getQueryResults(queryId, triggerUnixMs)
    if (maybeQueryResults) {
      setAllQueryResults((results: AllQueryResults) => {
        results[queryId] = maybeQueryResults
        return results
      })
      setQueryNamesRefreshing([])
      setUiUpdateTrigger(Date.now())
    } else {
      setTimeout(() => pollForResults(queryId, triggerUnixMs), 5000)
    }
  }

  const getQueryResults = async (queryId: string, returnLaterThanUnixMs: number): Promise<QueryResults | null> => {
    try {
      const fieldConfig = associationRepo.getFieldConfig(selectedAssociation)
      const queryResults: QueryResults = await QueryService.getQueryResults(
        fieldConfig,
        selectedAssociation,
        queryId,
        returnLaterThanUnixMs,
        username,
        password,
      )
      if (queryResults.hasUpdates) {
        const { resultsFileLocation } = queryResults
        queryResults.results = await getResultsFromFile(resultsFileLocation)
        return queryResults
      }
    } catch (error) {
      console.log(error)
    }
    return null
  }

  const getResultsFromFile = async (resultsFileLocation: any): Promise<QueryResult[] | undefined> => {
    const removeDuplicates = (queryResults: QueryResult[]) => {
      let uniqueResults = [] as QueryResult[]
      queryResults.forEach((result) => {
        let isDuplicate = false
        for (const processedResult of uniqueResults) {
          if (_.isEqual(result, processedResult)) {
            isDuplicate = true
            break
          }
        }
        if (!isDuplicate) {
          uniqueResults.push(result)
        }
      })
      return uniqueResults
    }

    const { fileDir, fileName, fileFormat } = resultsFileLocation
    const filePath = `${fileDir.replace(/public\//, '')}/${fileName}.${fileFormat}`
    const data = await getRemoteFile(filePath, { download: true })

    if (data?.Body) {
      const blob = data.Body
      const file = new File([blob], 'results_export.csv')
      let results = (await csvToJson(file)) as QueryResult[]
      return removeDuplicates(results) as QueryResult[]
    }
    return undefined
  }

  const deleteSelectedQuery = async () => {
    try {
      setModalState({ ...initialModalState, deleteConfirmModalOpen: false, loadingModalOpen: true })
      let queryToDelete: Query | undefined
      const filteredQueries = [...queries].filter((query) => {
        if (query.id === selectedQuery?.id) {
          queryToDelete = query
          return false
        }
        return true
      })
      if (!queryToDelete) {
        return
      }
      queryToDelete.status = 'deactivated'
      if (queryToDelete.hasOwnProperty('blobStoreDir')) {
        delete queryToDelete.blobStoreDir
      }
      setQueries(filteredQueries)
      await QueryService.updateQuery(selectedAssociation, username, password, queryToDelete)
      setSelectedQuery(undefined)
      closeModals()
    } catch (error: any) {
      displayWarning(error)
    }
  }

  const renameSelectedQuery = async (newName: string) => {
    const indexOfQueryToRename = queries.findIndex((query) => query.id === selectedQuery?.id)
    const queryId = queries[indexOfQueryToRename].id
    if (indexOfQueryToRename > -1 && queryId) {
      const queryToRename = queries[indexOfQueryToRename]
      queryToRename.name = newName
      await QueryService.updateQuery(selectedAssociation, username, password, queryToRename as Query)
      const updatedQueries = [...queries]
      updatedQueries[indexOfQueryToRename].name = newName
      setQueries(updatedQueries)
    }
    closeModals()
  }

  const saveQueryFilterSettings = async (
    filters: QueryFilter[][],
    timePeriod?: TimePeriod | {},
    customTimeRange?: CustomTimePeriod | {},
  ) => {
    if (!selectedQuery) {
      displayWarning({ code: DataQueryErrorCodesEnum.MissingQueryId })
      return
    }
    setModalState({ ...initialModalState, savingModalOpen: true })
    let query = cloneDeep(selectedQuery)
    query.filterParams = { ...query.filterParams, filters }
    if (timePeriod) {
      query.filterParams.period = timePeriod
    }
    if (customTimeRange) {
      const { startUnixMs, endUnixMs } = customTimeRange as CustomTimePeriod
      if (Math.abs(endUnixMs - startUnixMs) > maxAllowedTimeRange) {
        displayWarning({ code: DataQueryErrorCodesEnum.MaxTimeRangeExceeded })
        return
      }
      query.filterParams.timeRange = customTimeRange
    }
    const updatedQueries = [...queries].map((existingQuery) => (existingQuery.id === query.id ? query : existingQuery))
    setQueries(updatedQueries)
    setSelectedQuery(query)
    await QueryService.updateQuery(selectedAssociation, username, password, query)
    setModalState({ ...initialModalState, savingModalOpen: false })
  }

  const handleQueryAddition = async (newQueryName: string) => {
    setModalState({ ...initialModalState, loadingModalOpen: true })
    const query = await QueryService.createQuery(selectedAssociation, newQueryName, username, password)
    const newQueries = [...queries, query]
    setQueries(newQueries)
    setSelectedQuery(query)
    if (query.id) {
      await triggerResultsRecalculation(query)
    }
    setModalState({ ...initialModalState, loadingModalOpen: false })
  }

  const handleQuerySelection = async (queryIdentifier: string, allQueries: Query[]) => {
    function getQueryById(queryId: string, queryList: Query[]) {
      return queryList.find((query) => query.id === queryId)
    }

    function getFieldAggregationOptions(targetQuery: Query) {
      const queryFilters = targetQuery.filterParams.filters || []
      const aggregationOptions = queryFilters.flatMap((filter) =>
        filter.map((f) => {
          if (f.filterType === FilterTypeEnum.FIELD) {
            return (f.filterParams as FieldFilterParams).targetPath
          } else {
            return (f.filterParams as DocFilterParams).targetDoc
          }
        }),
      )
      return Array.from(new Set(flatten(aggregationOptions)))
    }

    const [queryId, queryName] = queryIdentifier.split('#')
    const targetQuery = getQueryById(queryId, allQueries)
    if (!targetQuery) {
      return
    }
    setSelectedQuery(targetQuery)
    setQueryNamesRefreshing([queryId])
    setCurrentTabName(TABS.SETTINGS)
    setFieldAggregationOptions(getFieldAggregationOptions(targetQuery))
    const maybeQueryResults = await getQueryResults(queryId, 0)
    if (maybeQueryResults) {
      setAllQueryResults((results: AllQueryResults) => {
        results[queryId] = maybeQueryResults
        return results
      })
      setUiUpdateTrigger(Date.now())
    }
    setQueryNamesRefreshing([])
  }

  const saveTrendConfig = async (trendConfig: TrendConfig, queryToUpdate: Query | undefined) => {
    try {
      if (!queryToUpdate || !queryToUpdate.id) {
        throw { code: DataQueryErrorCodesEnum.MissingQueryId }
      }
      const isNewTrend = !trendConfig.id
      if (isNewTrend) {
        for (const trend of queryToUpdate.trends) {
          if (normaliseStringForComparison(trend.trendName) === normaliseStringForComparison(trendConfig.trendName)) {
            displayWarning({ code: DataQueryErrorCodesEnum.DuplicateTrendName })
            return
          }
        }
        queryToUpdate.trends.push(trendConfig)
      } else {
        const trendToUpdate = queryToUpdate.trends.find((trend) => trend.id === trendConfig.id)
        if (trendToUpdate) {
          const trendIndex = queryToUpdate.trends.indexOf(trendToUpdate)
          queryToUpdate.trends[trendIndex] = trendConfig
        }
      }
      setModalState({ ...initialModalState, savingModalOpen: true })
      const updatedQuery = await QueryService.updateQuery(selectedAssociation, username, password, queryToUpdate)
      const updatedQueries = [...queries].map((existingQuery) =>
        existingQuery.id === updatedQuery.id ? updatedQuery : existingQuery,
      )
      setQueries(updatedQueries)
      setSelectedQuery(updatedQuery)
      closeModals()
      await triggerResultsRecalculation(updatedQuery)
    } catch (error: any) {
      displayWarning(error)
    }
  }

  const exportResultsToEmail = (emailAddresses: string[]) => {
    try {
      QueryService.exportResults(
        selectedAssociation,
        selectedQuery?.id || '',
        username,
        password,
        emailAddresses as ResultExportTargetEmails,
      )
      setTriggerUnixMs(Date.now())
    } catch (error) {
      console.log('error', error)
    }
    setModalState({ ...initialModalState, exportConfirmationModalOpen: true })
  }

  const displayWarning = (error: { code: string }) => {
    let warningModalHeader = 'Problem'
    let warningModalBody = 'We ran into a problem. Refresh the page and try again.'
    let modalsToOpen = { alertOneButtonModalOpen: true } as Record<string, boolean>
    if (error.code === DataQueryErrorCodesEnum.MaxTimeRangeExceeded) {
      warningModalHeader = 'Max range exceeded'
      warningModalBody = 'The maximum allowed time range is 6 months'
    } else if (error.code === DataQueryErrorCodesEnum.MissingQueryId) {
      warningModalHeader = 'Missing query ID'
      warningModalBody =
        'The query ID is missing. Refresh the page and try again, and if the problem persists, contact tech support.'
    } else if (error.code === DataQueryErrorCodesEnum.DuplicateTrendName) {
      warningModalHeader = 'Already Exists'
      warningModalBody = 'There is already a trend with that name.'
      modalsToOpen.graphConfiguratorModalOpen = true
    }
    setWarningModalContent({ warningModalHeader, warningModalBody })
    setModalState(modalsToOpen)
  }

  const getTabRelatedActionButtons = (tabName: string, targetQuery: Query | undefined) => {
    const queryId = targetQuery?.id || ''
    if (!queryId) {
      return []
    }
    let actionButtons: ActionButtonType[] = []
    if (tabName === TABS.SETTINGS) {
      actionButtons = [
        {
          iconPath: mdiTrashCan,
          onClick: () => toggleModal('deleteConfirm'),
          label: 'DELETE',
          title: 'Delete currently selected query',
        },
        {
          iconPath: mdiContentDuplicate,
          onClick: () => toggleModal('rename'),
          label: 'RENAME',
          title: 'Rename currently selected query',
        },
      ]
    }
    const queryResults = allQueryResults[queryId]
    const queryHasResults = queryResults?.results && queryResults.results.length > 0
    if (tabName === TABS.RESULTS && queryHasResults) {
      actionButtons = [
        {
          iconPath: mdiExport,
          onClick: () => toggleModal('emailCollector'),
          label: 'EXPORT',
          title: 'Export query results',
        },
      ]
    }

    if ([TABS.RESULTS, TABS.TRENDS].includes(tabName)) {
      if (targetQuery) {
        actionButtons.push({
          iconPath: mdiRefresh,
          onClick: () => triggerResultsRecalculation(targetQuery),
          label: 'REFRESH RESULTS',
          title: 'Refresh results matching query settings',
        })
      }
    }
    return actionButtons
  }

  const getOneResultPerProfilePk = (queryResults: QueryResult[] | undefined) => {
    if (!queryResults) {
      return []
    }
    let uniqueProfiles = {} as Record<string, any>
    queryResults.forEach((result) => {
      const { profilePk } = result
      if (!profilePk) {
        return
      }
      if (!uniqueProfiles[profilePk]) {
        uniqueProfiles[profilePk] = result
      }
    })
    return Object.values(uniqueProfiles)
  }

  const generateWorkAreaContent = (
    tabName: string,
    targetQuery: Query | undefined,
    selectedQueryResults: QueryResults | undefined,
  ) => {
    if (!targetQuery) {
      return (
        <div style={styles.selectedFieldInstruction}>
          <Icon
            path={mdiInformationOutline}
            size={1}
            color={ColorPalette.PRIMARY_BLUE}
            style={{ marginRight: '.75em' }}
          />
          Select a query to manage
        </div>
      )
    }

    const generateFilteredResultsTableConfig = (queryResults: QueryResults | undefined) => {
      const fieldConfig = associationRepo.getFieldConfig(selectedAssociation)
      const columnsToExclude = ['profilePk']
      let allKeys = ['name', 'surname', 'idPassport', 'dateActioned']
      if (queryResults?.results && queryResults?.results.length) {
        const firstResultKeys = Object.keys(queryResults.results[0])
        firstResultKeys.forEach((key) =>
          !allKeys.includes(key) && !columnsToExclude.includes(key) ? allKeys.push(key) : null,
        )
      }
      return allKeys.map((key) => {
        let label = removeUnderScores(sentenceCase(key))
        if (key === 'dateActioned') {
          label = 'Event Date'
        } else if (fieldConfig[key]) {
          label = sentenceCase(fieldConfig[key].label) || label
        }
        return { id: key, sizeFactor: 0.75, label } as IColumnConfig
      })
    }

    const selectedQueryId = targetQuery?.id || ''
    if (tabName === TABS.SETTINGS) {
      const { filterParams } = targetQuery
      return (
        <DataQuerySettings
          filterParams={filterParams || {}}
          saveChanges={saveQueryFilterSettings}
          key={`queryFilterSettings_${selectedQueryId}`}
        />
      )
    }
    if (tabName === TABS.RESULTS) {
      return (
        <DataQueryFilteredResults
          tableColumnConfig={generateFilteredResultsTableConfig(selectedQueryResults)}
          showLoader={queryNamesRefreshing.includes(targetQuery.name)}
          queryResults={selectedQueryResults || { trends: [] }}
          key={`filteredResults_${uiUpdateTrigger}`}
        />
      )
    }
    if (tabName === TABS.TRENDS) {
      let profileTableRowData = []
      if (selectedQueryResults) {
        profileTableRowData = getOneResultPerProfilePk(selectedQueryResults?.results)
      }
      return (
        <DataQueryTrends
          profileFilterConfig={profileFilterConfig}
          profileTableColumnConfig={profileTableColumnConfig}
          trendConfigList={targetQuery?.trends || []}
          allTrendData={selectedQueryResults?.trends || []}
          aggregationOptions={fieldAggregationOptions}
          profileTableRowData={profileTableRowData}
          saveTrendConfig={(updatedTrendConfig) => saveTrendConfig(updatedTrendConfig, selectedQuery)}
          key={`dataQueryTrends_${uiUpdateTrigger}`}
        />
      )
    }
    return null
  }

  const generateWorkArea = (targetQuery: Query | undefined, tabName: string, queryResults: AllQueryResults) => {
    const queryId = targetQuery?.id || ''
    return (
      <div style={styles.workAreaContainer}>
        <Toolbar actionButtons={getTabRelatedActionButtons(tabName, targetQuery)} />
        <TabNavigator
          ref={tabbedNavigatorRef}
          tabs={[
            { tabName: TABS.SETTINGS, iconName: 'cog' },
            { tabName: TABS.RESULTS, iconName: 'table-account' },
            { tabName: TABS.TRENDS, iconName: 'chart-bar' },
          ]}
          currentTabName={tabName}
          onTabClick={(tabName) => {
            setCurrentTabName(tabName)
            // setSelectedTrendId('')
          }}
          key={`tabbedNavigator_${Object.keys(resultsIntervalRef.current).join(',')}`}
        />
        <div style={styles.workAreaContent}>{generateWorkAreaContent(tabName, targetQuery, queryResults[queryId])}</div>
      </div>
    )
  }

  let resultsExportEmailCollector = null
  if (modalState.emailCollectorModalOpen) {
    const user = userRepo.getCurrentUserEntity()
    const userEmailAddress = user.getEmail()
    resultsExportEmailCollector = (
      <InfoCollectorModal
        open={true}
        defaultItems={[userEmailAddress]}
        header="EMAIL TO"
        subHeader="Which email addresses should we send to?"
        warningMessage="Add at least one email address"
        validateInput={checkEmailFormat}
        transformInput={toLowerCaseCustom}
        placeholder="Email address"
        minimumItems={1}
        dismiss={closeModals}
        onSuccess={exportResultsToEmail}
        onReject={closeModals}
      />
    )
  }

  let exportConfirmModal = null
  if (modalState.exportConfirmationModalOpen) {
    exportConfirmModal = (
      <AlertModalOneButton
        open={true}
        header="Export Initiated"
        body={
          <p>
            Your export is being generated and will be sent out shortly.
            <br />
            Check your inbox in a few minutes.
          </p>
        }
        buttonLabel="Ok"
        onClick={closeModals}
      />
    )
  }

  let actionInProgressModal = null
  if (modalState.loadingModalOpen || modalState.savingModalOpen) {
    const message = modalState.loadingModalOpen ? 'Loading...' : 'Saving...'
    actionInProgressModal = <LoadingModal open={true}>{message}</LoadingModal>
  }

  return (
    <div style={styles.container}>
      <NavBar match={props.match} location={props.location} reloadPageData={() => {}} history={props.history} />
      <SectionHeaderPrimary
        style={styles.sectionHeader}
        disabled={true}
        searchString={''}
        textHandler={() => ({})}
        onClick={() => ({})}>
        Data Queries
      </SectionHeaderPrimary>

      <div style={styles.contentContainer}>
        <SideMenu
          visible={true}
          menuComponents={
            <DataQueriesSideMenu
              queries={queries}
              selectedQueryId={selectedQuery?.id || ''}
              onQueryAddition={handleQueryAddition}
              onQueryClick={(queryIdentifier) => handleQuerySelection(queryIdentifier, queries)}
              syncingQueryIds={Object.keys(resultsIntervalRef.current)}
              key={`sideMenu_${triggerUnixMs}_${Object.keys(resultsIntervalRef.current).join(',')}`}
            />
          }
        />
        {generateWorkArea(selectedQuery, currentTabName, allQueryResults)}
      </div>
      {resultsExportEmailCollector}
      {exportConfirmModal}

      <AlertModalTwoButton
        open={modalState.deleteConfirmModalOpen}
        dismiss={closeModals}
        onClick1={closeModals}
        onClick2={deleteSelectedQuery}
        buttonLabel1="No"
        buttonLabel2="Yes"
        header="Confirm"
        buttonStyle={{ marginTop: 30 }}
        body={<div>{`Are you sure you want to delete query?`}</div>}
      />
      <AlertModalOneButton
        // TODO: There should only be one AlertModalOneButton component per screen with the required headers, body, and functions being set accordingly by a handler.
        open={!navMenuAccess.hasAdvancedDataQueryAccess}
        header={'Not Authorised'}
        body={"You don't have permission to view adavanced data queries."}
        buttonLabel={'Ok'}
        opaqueBackground={true}
        onClick={() => props.history.goBack()}
      />
      <AlertModalOneButton
        open={modalState.alertOneButtonModalOpen}
        header={warningModalContent.warningModalHeader}
        body={warningModalContent.warningModalBody}
        buttonLabel={'Ok'}
        containerStyle={{ top: window.innerHeight * 0.3 }}
        onClick={() => toggleModal('alertOneButton')}
      />
      {actionInProgressModal}
      <LabelCollector
        open={modalState.addModalOpen}
        warning="Enter query name"
        placeholder="Enter query name"
        buttonLabel="Add"
        iconName="setting"
        dismiss={() => toggleModal('add')}
        submit={() => null}
      />
      <LabelCollector
        open={modalState.renameModalOpen}
        warning={`Rename "${selectedQuery?.name || '?'}"`}
        placeholder="Rename"
        buttonLabel="Rename"
        initialValue={selectedQuery?.name}
        iconName="setting"
        dismiss={() => toggleModal('rename')}
        submit={renameSelectedQuery}
      />
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundImage: `linear-gradient(to bottom, ${ColorPalette.SCREEN_TOP_GRADIENT}, ${ColorPalette.SCREEN_BOTTOM_GRADIENT})`,
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 1%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflowY: 'scroll' as 'scroll',
  },
  workAreaContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    paddingInline: 'max(2em, 2%)',
    width: '100%',
    overflow: 'hidden',
  },
  workAreaContent: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    display: 'flex',
    flex: 1,
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    overflowY: 'scroll' as 'scroll',
    backgroundColor: ColorPalette.CARD_WHITE,
  },
  queryDetails: {
    width: '94%',
    margin: '0 auto',
  },
  section: {
    width: '100%',
    margin: '1em 0',
    paddingBottom: '20px',
  },
  graphView: {
    display: 'flex',
    alignItems: 'center',
    gap: '1em',
    margin: '2.5em 0 0.75em',
  },
  selectedFieldInstruction: {
    paddingTop: '25%',
    fontSize: '0.85rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: ColorPalette.SECONDARY_TEXT,
  },
}

export default Radium(DataQueries)
