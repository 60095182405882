import { ColorPalette } from '../../../config/colors'
import { toUpperCaseCustom } from '../../../utils'
import TextInputBasic from '../Text/TextInputBasic'

import React, { Component } from 'react'
import Radium from 'radium'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { PickerHandlerEvent } from './Picker'

interface PickerContainedProps {
  value: string
  items: string[]
  searchEnabled?: boolean
  disabled?: boolean
  label?: string
  style?: React.CSSProperties | undefined
  transformToUpperCase?: boolean
  labelStyle?: React.CSSProperties | undefined
  placeholder?: string
  onChange: (e: PickerHandlerEvent) => void
}

interface PickerContainedState {
  searchString: string
  filteredItems: string[]
}

class PickerContained extends Component<PickerContainedProps, PickerContainedState> {
  state: PickerContainedState = {
    searchString: '',
    filteredItems: Array.isArray(this.props.items) ? [...this.props.items] : [],
  }

  componentDidMount() {
    if (!this.props.value && this.props.items.length) {
      this.props.onChange({ target: { value: this.props.items[0] } } as PickerHandlerEvent)
    }
  }

  componentDidUpdate(
    prevProps: Readonly<PickerContainedProps>,
    prevState: Readonly<PickerContainedState>,
    snapshot?: any,
  ): void {
    if (prevState.searchString !== this.state.searchString) {
      const filteredItems = this.props.items.filter((item) => toUpperCaseCustom(item).includes(this.state.searchString))
      this.setState({ filteredItems })
      if (prevState.filteredItems.length > 0) {
        if (filteredItems.length > 0 && prevState.filteredItems[0] !== filteredItems[0]) {
          this.props.onChange({ target: { value: filteredItems[0] } } as PickerHandlerEvent)
        }
      } else if (filteredItems.length > 0) {
        this.props.onChange({ target: { value: filteredItems[0] } } as PickerHandlerEvent)
      }
    }
  }

  searchHandler(event: any) {
    this.setState({ searchString: toUpperCaseCustom(event.target.value) })
  }

  render() {
    const formControl = {
      minWidth: 120,
      width: '80%',
      marginTop: 5,
      paddingTop: 15,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 10,
      border: this.props.disabled ? undefined : `1px solid rgba(195,205,225,0.6)`,
      borderRadius: 12,
      display: 'flex',
      justifyContent: 'center',
      ...this.props.style,
    }
    const label = {
      zIndex: 2,
      marginTop: -22,
      alignSelf: 'center',
      fontSize: '0.65rem',
      color: ColorPalette.LIGHT_GREY,
      backgroundColor: ColorPalette.CARD_WHITE,
      paddingLeft: 10,
      paddingRight: 10,
    }

    let labelComponent = null
    if (this.props.label) {
      labelComponent = <p style={{ ...label, ...this.props.labelStyle }}>{toUpperCaseCustom(this.props.label)}</p>
    }

    let optionList: React.ReactElement[] = []
    if (this.props.placeholder) {
      optionList.push(
        <option key={`default_${this.props.placeholder}`} value={''}>
          {this.props.placeholder}
        </option>,
      )
    }
    optionList = [
      ...optionList,
      ...this.state.filteredItems.map((item, i) => (
        <option key={`${item}_${i}`} value={item}>
          {!this.props.transformToUpperCase ? item : item.toUpperCase()}
        </option>
      )),
    ]

    let searchbox = null
    if (this.props.searchEnabled) {
      searchbox = (
        <TextInputBasic
          style={styles.textStyle}
          value={this.state.searchString}
          placeholder={'Search'}
          onChange={(event) => this.searchHandler(event)}
        />
      )
    }

    return (
      <FormControl style={formControl}>
        {labelComponent}
        {searchbox}
        <Select
          style={{ fontSize: '0.8rem', color: ColorPalette.PRIMARY_TEXT }}
          native
          value={this.props.value}
          onChange={this.props.onChange}
          disabled={this.props.disabled}
          inputProps={{
            name: 'pickerValues',
            id: 'picker',
          }}>
          {optionList}
        </Select>
      </FormControl>
    )
  }
}

const styles = {
  textStyle: {
    color: ColorPalette.PRIMARY_TEXT,
    paddingBottom: 8,
    borderBottomWeight: 1,
    borderBottomStyle: 'solid' as 'solid',
    borderBottomColor: ColorPalette.PRIMARY_TEXT,
    marginBottom: 20,
    marginTop: 10,
    width: '100%',
  },
}

export default Radium(PickerContained)
