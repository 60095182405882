import { ColorPalette } from '../config'
import { updateConfig } from '../providers'
import { SessionService } from './sessionService'
import {
  AuthCredentials,
  ConfigChanges,
  ConfigType,
  DocTableColumnConfig,
  EmploymentStatus,
  ProfileNavMenuAccess,
  SystemFeaturesEnum,
  SystemOperationsEnum,
  AssociationId,
  CohortName,
  SelectorConfig,
  SelectorItemsConfig,
  PpeInventoryTransferTableRowItem,
  FieldConfigCompType,
} from '../types'
import { docsSectionConfig } from '../config'
import { Iam } from '../models'
import { AssociationSettingsRepository } from '../repositories'

const { EMPLOYEE } = EmploymentStatus
const { documents, workforce } = SystemFeaturesEnum
const {
  deleteProfileDocumets,
  downloadProfileDocuments,
  viewEmployees,
  viewCandidates,
  viewFaceshots,
  viewProfileDocuments,
  viewPerformance,
  viewDiscipline,
  viewProfileLogs,
} = SystemOperationsEnum

export class ConfigService {
  static async updateConfig(
    selectedAssociation: string,
    configType: ConfigType,
    changesToApply: ConfigChanges,
    authCredentials: AuthCredentials,
    dataFlowName?: string,
  ): Promise<{ result: any; updatedConfig: any } | { result: any; updatedConfig?: undefined }> {
    const { username, password } = authCredentials
    const token = await SessionService.prepareAuthTokens(username, password)
    let payload = {
      selectedAssociation: selectedAssociation,
      configType,
      changes: changesToApply,
      dataFlowName: configType === 'dataFlow' ? dataFlowName : undefined,
    }
    const response = await updateConfig(payload, token)
    return response
  }

  generateProfileNavMenuConfig(iamEntity: Iam, employmentStatus: EmploymentStatus) {
    const dataAccessOperationType = employmentStatus === EMPLOYEE ? viewEmployees : viewCandidates
    const permissionsToCheckConfig = [
      { operation: dataAccessOperationType, variable: 'hasDataManagerAccess' },
      { operation: viewFaceshots, variable: 'hasFaceshotAccess' },
      { operation: viewProfileDocuments, variable: 'hasDocPortalAccess' },
      { operation: viewPerformance, variable: 'hasPerformanceAccess' },
      { operation: viewDiscipline, variable: 'hasDisciplineAccess' },
      { operation: viewProfileLogs, variable: 'hasLogsAccess' },
    ]
    const profileNavMenuAccessFlags = permissionsToCheckConfig.reduce((acc, { operation, variable }) => {
      // @ts-ignore
      acc[variable] = iamEntity.checkAccess(workforce, operation)
      return acc
    }, {})
    return profileNavMenuAccessFlags as ProfileNavMenuAccess
  }

  generateDataFlowSectionConfig(iamEntity: Iam) {
    return [...docsSectionConfig].filter((sectionConfigItem) => {
      const { sectionName } = sectionConfigItem
      return iamEntity.checkAccess(documents, viewProfileDocuments, sectionName)
    })
  }

  generateDocTableColumnConfig(iamEntity: Iam, iconComponentMap: Record<string, JSX.Element>) {
    let columnConfig = [
      { id: 'expand', label: 'Expand' },
      { id: 'collapse', label: 'Collapse' },
    ]
    if (iamEntity.checkAccess(documents, downloadProfileDocuments)) {
      columnConfig.push({ id: 'download', label: 'Download' })
    }
    if (iamEntity.checkAccess(documents, deleteProfileDocumets)) {
      columnConfig.push({ id: 'delete', label: 'Delete' })
    }
    columnConfig.map((configItem) => {
      return { ...configItem, iconComponent: iconComponentMap[configItem.id] }
    })
    return columnConfig as DocTableColumnConfig
  }

  generateHierarchialAccountPickerConfig(
    associationRepo: AssociationSettingsRepository,
    currentSelections: {
      selectedAssociation: AssociationId
      selectedCohort: CohortName
    },
  ): { selectorConfig: SelectorConfig; selectorItemsConfig: SelectorItemsConfig } {
    const { selectedAssociation, selectedCohort } = currentSelections
    const authedAssociations = associationRepo.getAllAssociationIds()

    let selectorItemsConfig = {} as Record<string, Record<string, any>>
    authedAssociations.forEach((association) => {
      const associationEntity = associationRepo.getAssociationSettingsEntity(association)
      const displayName = associationEntity.getDisplayName()
      const cohorts = associationEntity.getAllCohorts()
      selectorItemsConfig[displayName] = {}
      cohorts.forEach((cohortName) => (selectorItemsConfig[displayName][cohortName] = {}))
    })
    const selectorConfig = [
      {
        key: 'association',
        label: 'Selected employer',
        value: associationRepo.getAssociationSettingsEntity(selectedAssociation).getDisplayName(),
        selectorItems: [],
      },
      {
        key: 'cohort',
        label: 'Selected cohort',
        value: selectedCohort,
        selectorItems: [],
      },
    ]
    return { selectorConfig, selectorItemsConfig }
  }

  buildInventoryTransferTableRowConfig(ppeItemOptions: string[]): PpeInventoryTransferTableRowItem {
    const uuid = window.crypto.randomUUID()
    return {
      id: uuid,
      label: {
        key: `item-${uuid}`,
        compType: FieldConfigCompType.SELECTOR,
        value: '',
        label: '',
        selectorItems: ppeItemOptions,
        props: {
          style: { borderBottom: 'none', marginTop: 0, marginBottom: 0 },
          fontStyle: { marginTop: 0, border: 'none', fontWeight: '500' },
        },
      },
      ppeSize: {
        key: `size-${uuid}`,
        compType: FieldConfigCompType.SELECTOR,
        value: '',
        label: '',
        disabled: true,
        props: {
          style: { borderBottom: 'none', marginTop: 0, marginBottom: 0 },
          fontStyle: { marginTop: 0, border: 'none', fontWeight: '500' },
        },
      },
      noOfUnits: {
        key: `noOfUnits-${uuid}`,
        compType: FieldConfigCompType.TEXT,
        value: '',
        label: '',
        props: {
          placeholder: 'Enter quantity',
          fontStyle: { color: ColorPalette.SECONDARY_TEXT },
        },
      },
      availableToTransfer: '-',
    }
  }
}
