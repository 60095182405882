import React, { Component, createRef } from 'react'
import { RouteComponentProps } from 'react-router'
import Radium from 'radium'
import { connect } from 'react-redux'

import { GridSize } from '@material-ui/core/Grid'
import dayjs from 'dayjs'
import { chunk, cloneDeep, flatten, isEmpty, isEqual, pick } from 'lodash'
import relativeTime from 'dayjs/plugin/relativeTime'
import { mdiFileDocumentMultiple, mdiMessageAlert, mdiPlaylistPlus, mdiRefresh } from '@mdi/js'

import DataTable, { DataTableFilterState } from '../../components/Tables/DataTable/DataTable'
import NavBar from '../../components/Navigation/NavBar'
import SectionHeader, { SectionHeaderComponent } from '../../components/Headings/SectionHeaderPrimary'
import LoadingModal from '../../components/Modals/LoadingModal'
import AlertModalOneButton from '../../components/Modals/AlertModalOneButton'
import SideMenu from '../../components/Navigation/SideMenu'
import { DocValiditySideMenu, ViewModes } from '../../components/SideMenus/DocValiditySideMenu'
import DownloadQueueModal from '../../components/Modals/DownloadQueueModal'
import { removeUnderScores, sentenceCase, flipObjKeysAndValues, toUpperCaseCustom } from '../../utils'
import { ColorPalette } from '../../config/colors'
import { ActionType } from '../../store/actions/actions'
import { DataCaptureMode, DocumentCategories, ProcessModes } from '../../config'
import { TimeBasedDocInstance, TimeBasedDocs } from '../../models/docValidity'
import { DocServiceFactory, SessionService } from '../../services'
import { PeopleFlowCombinedReducer } from '../../store'
import { TimeBasedDocFactory, Profile, ProfilePk } from '../../models'
import {
  getAllCachedTimeBasedDocs,
  getTimeBasedDocsCacheTimestamp,
  initTimeBasedDocsCache,
  replaceAllCachedTimeBasedDocs,
} from '../../utils/idbTimeBasedDocs'
import { Toolbar, ActionButtonType } from '../../components/GeneralUI/Toolbar'
import { SimpleTable } from '../../components/Tables/SimpleTable/SimpleTable'
import { AssociationSettingsRepository, ProfileRepository, UsersRepository } from '../../repositories'
import { IColumnConfig } from '../../components/Tables/DataTable/DataTableHeader'
import {
  AsyncTaskDocExport,
  TimeBasedDocStatus,
  ValidEmploymentStatus,
  ScreenNamesEnum,
  NavMenuAccess,
} from '../../types'
import { ProfileService } from '../../services'
import { GeneralErrorCodesEnum, TimeBasedDocStatusEnum } from '../../enums'
import { TimeBasedDocStatuses } from '../../constants'
import { fetchProfileData, getRemoteFile } from '../../providers/remoteDataProvider'
import { LocalStorageProvider } from '../../providers'

dayjs.extend(relativeTime)
const profileService = new ProfileService()
const sessionService = new SessionService()
const columnConfig = [
  { id: 'idPassport', label: 'ID/Passport', sizeFactor: 0.9 },
  { id: 'competency', label: 'Document', sizeFactor: 1.15 },
  { id: 'status', label: 'Status', sizeFactor: 0.75 },
  { id: 'expiryDate', label: 'Expiry Date', sizeFactor: 0.95 },
  { id: 'workSite', label: 'Work Site', sizeFactor: 1.15 },
  { id: 'employmentStatus', label: 'Employment' },
]

const perDocConfig = [
  { id: 'docType', label: 'Document' },
  { id: TimeBasedDocStatusEnum.NO_DOC, label: 'No Doc' },
  { id: TimeBasedDocStatusEnum.DOCS_OUTSTANDING, label: 'Documents outstanding' },
  { id: TimeBasedDocStatusEnum.EXPIRED, label: 'Expired' },
  { id: TimeBasedDocStatusEnum.VALID, label: 'Valid' },
  { id: TimeBasedDocStatusEnum.EXPIRES_IN_45_DAYS, label: 'Expires (45 days)' },
]

interface DocValidityProps extends RouteComponentProps {
  comingFromDocValidity: boolean
  docValidityViewMode: ViewModes
  asyncTaskQueueDocExport: AsyncTaskDocExport[]
  itemsDownloading: string[]
  allTimeBasedDocs: TimeBasedDocs
  associationRepo: AssociationSettingsRepository
  profileRepo: ProfileRepository
  userRepo: UsersRepository
  docValidityFilterState: Record<string, Record<string, boolean>>
  selectedAssociation: string
  selectedCohort: string
  selectedRole: string
  currentScreen: ScreenNamesEnum
  currentSection: ScreenNamesEnum
  previousScreen: ScreenNamesEnum
  navMenuAccess: NavMenuAccess
  selectedEmploymentStatus: ValidEmploymentStatus
  profilePic: string
  idPassport: string
  password: string
  updateState: (data: any) => void
}

interface DocValidityState {
  authorisedDocIds: string[]
  authorisedPersonIds: string[]
  downloadQueueModalOpen: boolean
  loadingModalOpen: boolean
  warningModalOpen: boolean
  loadingModalMessage: string | React.ReactElement
  loadingModalAuxMessage: string
  warningModalHeader: string
  warningModalMessage: string
  isUpdatingDocExportQueue: boolean
  downloading: boolean
  perDocTableData: Record<string, any>[]
  perPersonTableData: Record<string, any>[]
  perPersonColumnConfig: IColumnConfig[]
  tableWidth: GridSize
  selectedRowItemId: string
  searchString: string
  tableData: Record<string, string>[]
  viewMode: ViewModes
  sideMenuVisible: boolean
  chartConfig: { title: string; value: number; color: string }[]
  mounted: boolean
  cacheTimestamp: number
  checkTimestamp: number
  refreshAllTableTimestamp: number
  allTableVisited: boolean
  allTableMountedFilterState: DataTableFilterState
  profilesFetched: number
  fetchingProfiles: boolean
  profileRefetchInProgress: boolean
}

type DocValidityColumnConfig = { key: string; label?: string; sizeFactor?: number }

const sectionHeaderRef: React.RefObject<SectionHeaderComponent> = createRef()
const allRecordsTableRef: React.RefObject<DataTable> = createRef()
const perDocTableRef: React.RefObject<DataTable> = createRef()

let checkTimestampIntervalId: NodeJS.Timer

class DocValidity extends Component<DocValidityProps, DocValidityState> {
  labelMap: Record<string, string> = {
    [TimeBasedDocStatusEnum.NO_DOC]: 'No Doc',
    [TimeBasedDocStatusEnum.DOCS_OUTSTANDING]: 'Documents outstanding',
    [TimeBasedDocStatusEnum.EXPIRED]: 'Expired',
    [TimeBasedDocStatusEnum.VALID]: 'Valid',
    [TimeBasedDocStatusEnum.EXPIRES_IN_45_DAYS]: 'Expires in 45 days',
  }

  DocService = DocServiceFactory.create()

  initialModalState = {
    loadingModalOpen: false,
    warningModalOpen: false,
    downloadQueueModalOpen: false,
  }

  state: DocValidityState = {
    ...this.initialModalState,
    loadingModalMessage: '',
    warningModalHeader: '',
    warningModalMessage: '',
    selectedRowItemId: '',
    loadingModalAuxMessage: '',
    searchString: '',
    chartConfig: [],
    tableData: [],
    perDocTableData: [],
    perPersonTableData: [],
    perPersonColumnConfig: [],
    tableWidth: 9,
    authorisedDocIds: [],
    authorisedPersonIds: [],
    isUpdatingDocExportQueue: false,
    downloading: false,
    sideMenuVisible: true,
    viewMode: ViewModes.PER_DOCUMENT,
    mounted: false,
    cacheTimestamp: 0,
    checkTimestamp: Date.now(),
    allTableVisited: false,
    allTableMountedFilterState: {},
    refreshAllTableTimestamp: 0,
    profilesFetched: 0,
    fetchingProfiles: false,
    profileRefetchInProgress: false,
  }

  componentDidMount() {
    this.initialise()
  }

  componentWillUnmount(): void {
    if (checkTimestampIntervalId) {
      clearInterval(checkTimestampIntervalId)
    }
    if (!this.props.comingFromDocValidity) {
      this.props.updateState({ docValidityViewMode: ViewModes.PER_DOCUMENT, docValidityFilterState: {} })
    }
  }

  // componentDidUpdate(prevProps: DocValidityProps, prevState: DocValidityState) {
  //   const { mounted } = this.state
  //   const { currentScreen, selectedAssociation, selectedCohort } = this.props
  //   const screenChanged =
  //     prevProps.currentScreen !== currentScreen ||
  //     prevProps.selectedAssociation !== selectedAssociation ||
  //     prevProps.selectedCohort !== selectedCohort
  //   if (mounted && screenChanged) {
  //     this.refreshDocs()
  //   }
  // }

  initialise = async () => {
    try {
      const { selectedAssociation, selectedCohort, allTimeBasedDocs, docValidityViewMode, previousScreen } = this.props
      // const stateSaved = await LocalStorageProvider.hasStorageKey('docValidityState')
      // if (previousScreen === ScreenNamesEnum.PROFILES && stateSaved) {
      //   this.setState({ loadingModalOpen: true, loadingModalMessage: 'Loading...' })
      //   const savedState = (await LocalStorageProvider.getData('docValidityState')) || {}
      //   const { perPersonTableData, perPersonColumnConfig } = this.generateTablePerPerson(this.props.allTimeBasedDocs)
      //   this.setState({ ...savedState, perPersonTableData, perPersonColumnConfig, loadingModalOpen: false })
      //   await LocalStorageProvider.delete('docValidityState')
      //   return
      // }
      this.updateAndRender(allTimeBasedDocs, false, async () => {
        await this.refreshDocs(true)
        this.changeViewMode(docValidityViewMode)
      })
      const cacheTimestamp = await getTimeBasedDocsCacheTimestamp(selectedAssociation, selectedCohort)
      this.setState({ cacheTimestamp })

      checkTimestampIntervalId = setInterval(() => {
        this.setState({ checkTimestamp: Date.now() })
      }, 60000)
      this.setState({ mounted: true })
    } catch (error: any) {
      this.displayWarning(error)
    }
  }

  closeModals() {
    this.setState({ ...this.initialModalState })
  }

  updateAndRender = (
    allTimeBasedDocs: TimeBasedDocs,
    comingFromDocValidity: boolean = false,
    callback?: () => void,
  ) => {
    const allDocuments = allTimeBasedDocs.getAllDocs()
    const tableData = this.generateTableDataAll(allDocuments)
    const { perDocTableData, chartConfig } = this.generateTableDataPerDoc(allTimeBasedDocs)
    const { perPersonTableData, perPersonColumnConfig } = this.generateTablePerPerson(allTimeBasedDocs)
    const authorisedDocIds = [...perDocTableData].map((item) => item.id)
    const authorisedPersonIds = [...perPersonTableData].map((item) => item.id)
    this.props.updateState({ allTimeBasedDocs, comingFromDocValidity })
    this.setState(
      {
        ...this.initialModalState,
        downloading: false,
        chartConfig,
        tableData,
        perDocTableData,
        perPersonTableData,
        perPersonColumnConfig,
        authorisedDocIds,
        authorisedPersonIds,
      },
      callback,
    )
  }

  refreshDocs = async (useCachedDocs: boolean = false) => {
    const {
      idPassport: userIdPassport,
      password,
      selectedAssociation,
      selectedCohort,
      associationRepo,
      comingFromDocValidity,
      docValidityViewMode,
    } = this.props

    this.setState({
      ...this.initialModalState,
      downloading: !comingFromDocValidity,
      loadingModalOpen: !comingFromDocValidity,
      loadingModalMessage: 'Fetching latest data...',
    })

    if (comingFromDocValidity) {
      this.changeViewMode(docValidityViewMode)
      return
    }

    const authorisedPks = associationRepo.getAuthorisedPks(selectedAssociation, selectedCohort)
    console.log('selectedAssociation: ', selectedAssociation)
    console.log('selectedCohort: ', selectedCohort)

    const idPassportChunks = chunk(authorisedPks, 50)
    const numberOfChunks = idPassportChunks.length
    let allDocRecords: TimeBasedDocs[] = []
    let counter = 0
    let allTimeBasedDocs: TimeBasedDocs
    if (useCachedDocs) {
      allDocRecords = await getAllCachedTimeBasedDocs(selectedAssociation, selectedCohort)
      if (allDocRecords.length === 0) {
        await initTimeBasedDocsCache(selectedAssociation, selectedCohort)
      }
    }
    const token = await SessionService.prepareAuthTokens(userIdPassport, password)
    if (allDocRecords.length === 0) {
      await Promise.all(
        idPassportChunks.map(async (chunk) => {
          const chunkOfDocs: TimeBasedDocs = (await this.DocService.fetchTimeBasedDocData(
            selectedAssociation,
            [selectedCohort],
            token,
            chunk,
            true,
          )) as TimeBasedDocs
          this.setState({
            loadingModalMessage: (
              <div>
                Fetching latest data...
                <div style={{ fontSize: 'smaller', fontWeight: 500, marginTop: '0.25em' }}>
                  {`${Math.trunc((counter / numberOfChunks) * 100)}%`}
                </div>
              </div>
            ),
          })
          counter += 1
          allDocRecords.push(chunkOfDocs)
        }),
      )
      allDocRecords = flatten(allDocRecords)
      await replaceAllCachedTimeBasedDocs(selectedAssociation, selectedCohort, allDocRecords)
    }
    if (!isEmpty(allDocRecords)) {
      allTimeBasedDocs = TimeBasedDocFactory.create(allDocRecords)
      this.updateAndRender(allTimeBasedDocs, comingFromDocValidity, this.reRender)
    } else {
      this.setState(this.initialModalState)
    }
  }

  reRender = () => {
    this.reloadTable()
  }

  changeViewMode = (selectedViewMode: ViewModes, callback?: () => void) => {
    const allTableMountedBeforeWithSameFilterState = isEqual(
      this.props.docValidityFilterState,
      this.state.allTableMountedFilterState,
    )
    this.setState({ viewMode: selectedViewMode, loadingModalOpen: true, loadingModalMessage: 'Loading...' }, () => {
      if (
        selectedViewMode === ViewModes.ALL &&
        this.state.allTableVisited &&
        allTableMountedBeforeWithSameFilterState
      ) {
        if (callback) {
          callback()
        }
        this.closeModals()
        return
      }
      setTimeout(() => {
        this.reloadTable()
      }, 100)

      if (selectedViewMode === ViewModes.ALL && !this.state.allTableVisited) {
        this.setState({ allTableVisited: true })
      }

      if (callback) {
        callback()
      }
      this.closeModals()
    })
  }

  reloadTable = () => {
    const { viewMode } = this.state
    if (viewMode === ViewModes.ALL && allRecordsTableRef?.current) {
      allRecordsTableRef?.current?.reload(true, (allFilterState: DataTableFilterState) => {
        this.props.updateState({ docValidityFilterState: allFilterState })
      })
      return
    }
    if (viewMode === ViewModes.PER_DOCUMENT && perDocTableRef?.current) {
      perDocTableRef?.current?.reload()
      return
    }
  }

  generateRingChartConfig(
    allDocStatuses: TimeBasedDocStatus[],
    perDocData: Record<string, any>[],
  ): { title: string; value: number; color: string }[] {
    const chartConfig = allDocStatuses.map((status) => {
      const colourMap: Record<string, string> = {
        [TimeBasedDocStatusEnum.VALID]: ColorPalette.PRIMARY_BLUE,
        [TimeBasedDocStatusEnum.EXPIRES_IN_45_DAYS]: ColorPalette.DARK_GREY,
        [TimeBasedDocStatusEnum.EXPIRED]: ColorPalette.WARNING_RED,
        [TimeBasedDocStatusEnum.NO_DOC]: ColorPalette.VERY_LIGHT_GREY,
        [TimeBasedDocStatusEnum.DOCS_OUTSTANDING]: ColorPalette.VERY_LIGHT_GREY,
      }
      let configItem = {
        title: status in this.labelMap ? this.labelMap[status] : status,
        value: 0,
        color: colourMap[status],
      }
      perDocData.forEach((summaryItem) => (configItem.value += parseInt(summaryItem[status])))
      return configItem
    })
    return chartConfig
  }

  generateTableDataAll(allDocData: TimeBasedDocInstance[]): Record<string, string>[] {
    const tableData = allDocData.map((docInstance: TimeBasedDocInstance) => {
      let metaData = docInstance.getMetaData()

      metaData.status = removeUnderScores(metaData.status)
      if (toUpperCaseCustom(metaData.status) === toUpperCaseCustom(TimeBasedDocStatusEnum["VALID_(CAN'T_EXPIRE)"])) {
        // VALID (CAN'T EXPIRE) status gets translated to VALID
        metaData.status = 'VALID'
      }

      return {
        ...metaData,
        id: `${metaData.idPassport}___${metaData.competency}___${metaData.expiryDate}`,
      }
    })
    return tableData
  }

  generateTableDataPerDoc(allTimeBasedDocs: TimeBasedDocs): {
    perDocTableData: Record<string, any>[]
    chartConfig: { title: string; value: number; color: string }[]
  } {
    let perDocData = [] as Record<string, any>[]
    const allDocStatuses = TimeBasedDocStatuses.filter(
      (status) => status !== TimeBasedDocStatusEnum["VALID_(CAN'T_EXPIRE)"],
    )
    const statsPerDoc = allTimeBasedDocs.getCategorisedDocStats()
    Object.keys(statsPerDoc).forEach((docType) => {
      let tableRecord = { docType, id: docType } as Record<string, any>
      allDocStatuses.forEach((status) => {
        tableRecord[status] = status in statsPerDoc[docType] ? statsPerDoc[docType][status].toString() : '0'
      })
      perDocData.push(tableRecord)
    })
    const chartConfig = this.generateRingChartConfig(allDocStatuses, perDocData)
    return { perDocTableData: perDocData, chartConfig }
  }

  generateTablePerPerson(allTimeBasedDocs: TimeBasedDocs) {
    const { profileRepo, selectedAssociation, associationRepo } = this.props

    function prependNonDocColumns(perPersonColumnConfig: IColumnConfig[]): IColumnConfig[] {
      let nonDocColumns = [{ id: 'idPassport', label: 'ID/Passport', sizeFactor: 1.2 }]
      // check docValidityColumnConfigPerPerson for any additional columns
      const organisationConfig = associationRepo.getOrganisationConfig(selectedAssociation)
      if (organisationConfig.docValidityColumnConfigPerPerson) {
        const additionalColumnIds = organisationConfig.docValidityColumnConfigPerPerson.map(
          (column: DocValidityColumnConfig) => {
            let label = column.label
            if (!label) {
              const fieldConfig = associationRepo.getFieldConfig(selectedAssociation)
              const field = fieldConfig[column.key]
              if (!field) {
                return
              }
              label = field.label
            }
            return { id: column.key, label, sizeFactor: column.sizeFactor || 1.2 }
          },
        )
        nonDocColumns = nonDocColumns.concat(additionalColumnIds)
      }
      perPersonColumnConfig.unshift(...nonDocColumns)
      return nonDocColumns
    }

    function addNonDocColumnTableData(
      idPassport: string,
      tableRecord: Record<string, any>,
      nonDocColumns: IColumnConfig[],
    ) {
      nonDocColumns.forEach((column) => {
        const field = column.id
        if (field !== 'idPassport') {
          const profile = profileRepo.getProfileByIdPassport(idPassport)
          if (profile) {
            const profileGeneralData = profile.getGeneralData()
            tableRecord[field] = profileGeneralData[field] || ''
          }
        }
      })
    }

    const filteredDocTypes = allTimeBasedDocs
      .getAllDocsTypes()
      .filter((docType: string) => !(docType === TimeBasedDocStatusEnum.NO_DOC || docType === 'NONE'))
    let perPersonColumnConfig = filteredDocTypes.map((docType: string) => ({
      id: docType,
      label: sentenceCase(removeUnderScores(docType)),
    })) as IColumnConfig[]

    const nonDocColumns = prependNonDocColumns(perPersonColumnConfig)

    let perPersonTableData = [] as Record<string, any>[]
    const statsPerPerson = allTimeBasedDocs.getCategorisedDocsByPerson()
    const getStatusDotColor = (validityStatus: string) => {
      const statusDotColorMap = {
        [TimeBasedDocStatusEnum.NO_EXPIRY_SET]: ColorPalette.MEDIUM_GREY,
        [TimeBasedDocStatusEnum.VALID]: ColorPalette.PRIMARY_BLUE,
        [TimeBasedDocStatusEnum.EXPIRES_IN_45_DAYS]: ColorPalette.DARK_GREY,
        [TimeBasedDocStatusEnum.EXPIRED]: ColorPalette.WARNING_RED,
      } as Record<string, string>
      if (validityStatus in statusDotColorMap) {
        return statusDotColorMap[validityStatus]
      }
      return ColorPalette.VERY_LIGHT_GREY
    }
    Object.keys(statsPerPerson).forEach((idPassport) => {
      let tableRecord = { idPassport, id: idPassport } as Record<string, any>
      addNonDocColumnTableData(idPassport, tableRecord, nonDocColumns)

      statsPerPerson[idPassport].forEach((docInstance: TimeBasedDocInstance) => {
        const docType = docInstance.getCompetency()
        let status = docInstance.getStatus()
        const expiryDate = docInstance.getExpiryDate()
        if (expiryDate === '1970-01-01') {
          status = TimeBasedDocStatusEnum.NO_EXPIRY_SET
        }
        tableRecord[docType] = (
          <div style={styles.statusDotContainer}>
            <div style={{ ...styles.statusDot, backgroundColor: getStatusDotColor(status) }} />
          </div>
        )
      })
      filteredDocTypes.forEach((docType: string) =>
        tableRecord[docType]
          ? null
          : (tableRecord[docType] = (
              <div style={styles.statusDotContainer}>
                <div style={{ ...styles.statusDot, backgroundColor: ColorPalette.VERY_LIGHT_GREY }} />
              </div>
            )),
      )
      perPersonTableData.push(tableRecord)
    })
    return { perPersonTableData, perPersonColumnConfig }
  }

  generateFile = async () => {
    let selected = [] as string[]
    const { tableData } = this.state
    const { idPassport: userIdPassport, password, userRepo, selectedAssociation } = this.props
    if (allRecordsTableRef && allRecordsTableRef.current) {
      selected = Object.keys(allRecordsTableRef.current.getSelectedRows())
    }
    if (!selected.length) {
      this.setState({
        ...this.initialModalState,
        warningModalOpen: true,
        warningModalHeader: 'Make a selection',
        warningModalMessage: "You haven't selected anything to export. Select at least one item to continue",
      })
      return
    }
    let exportPayload = {} as Record<string, string[]>
    tableData.forEach((item) => {
      if (selected.includes(item.id)) {
        if (!exportPayload[item.idPassport]) {
          exportPayload[item.idPassport] = []
        }
        exportPayload[item.idPassport].push(item.filename)
      }
    })
    const user = userRepo.getCurrentUserEntity()
    const { name, surname } = user.getPersonalUserInfo()
    const downloadBatchLabel = this.DocService.getDownloadBatchLabel(1, name, surname)
    const token = await SessionService.prepareAuthTokens(userIdPassport, password)
    await this.DocService.triggerDocBatchGeneration(exportPayload, selectedAssociation, downloadBatchLabel, token)
    this.setState({
      warningModalOpen: true,
      warningModalHeader: 'Queued',
      warningModalMessage: 'Your files have been added to the download queue',
    })
  }

  async downloadFile(filePath: string, queueId: string): Promise<any> {
    let { idPassport: userIdPassport, password, itemsDownloading = [], selectedAssociation } = this.props
    itemsDownloading = [...new Set([...itemsDownloading, queueId])]
    this.props.updateState({ itemsDownloading })
    if (filePath.includes('public')) {
      filePath = filePath.replace('public/', '')
    }
    await this.DocService.downloadDocBatch(filePath)
    const token = await SessionService.prepareAuthTokens(userIdPassport, password)
    const asyncTaskQueueDocExport = await this.DocService.clearDownloadQueue(selectedAssociation, queueId, token)
    itemsDownloading = itemsDownloading.filter((existingQueueId) => existingQueueId !== queueId)
    this.props.updateState({ asyncTaskQueueDocExport, itemsDownloading })
  }

  async updateQueueData(showModal: boolean = true) {
    if (showModal) {
      this.setState({ downloadQueueModalOpen: true, isUpdatingDocExportQueue: true })
    }
    const { idPassport: userIdPassport, password, selectedAssociation } = this.props
    const token = await SessionService.prepareAuthTokens(userIdPassport, password)
    const asyncTaskQueueDocExport = await this.DocService.fetchDocDownloadQueue(selectedAssociation, token)
    this.props.updateState({ asyncTaskQueueDocExport })
    this.setState({ isUpdatingDocExportQueue: false })
  }

  updateChartConfig(filteredTableData: any[]) {
    this.props.updateState({ docValidityFilterState: allRecordsTableRef?.current?.state.allFilterState || {} })
    const allDocData = TimeBasedDocFactory.create(filteredTableData)
    const { chartConfig } = this.generateTableDataPerDoc(allDocData)
    this.setState({ chartConfig })
  }

  searchHandler(event: React.ChangeEvent<{ value: string }>) {
    this.setState({ searchString: event.target.value })
    const { viewMode } = this.state
    if (viewMode === ViewModes.ALL) {
      allRecordsTableRef?.current?.search(event.target.value)
    }
  }

  setFilter = (rowItemId: string, category: void | string) => {
    const updateFilterStateAndReload = () => {
      if (allRecordsTableRef?.current) {
        let allFilterState = cloneDeep(allRecordsTableRef.current.state.allFilterState)
        const reverseLabelMap = flipObjKeysAndValues(this.labelMap)
        if (category === 'status') {
          Object.keys(allFilterState[category]).forEach((filterKey) => {
            allFilterState[category][filterKey] = filterKey === reverseLabelMap[rowItemId]
          })
        } else {
          Object.keys(allFilterState).forEach((category) => {
            Object.keys(allFilterState[category]).forEach((filterKey) => {
              allFilterState[category][filterKey] = filterKey === rowItemId
            })
          })
        }
        this.props.updateState({ docValidityFilterState: allFilterState })
        // // is it really necessary to call setState and reload via the ref..?
        // allRecordsTableRef?.current?.setState({ allFilterState }, () => {
        //   allRecordsTableRef?.current?.reload(true, () => this.closeModals())
        // })
      }
      this.closeModals()
    }

    this.setState(
      {
        ...this.initialModalState,
        loadingModalOpen: true,
        loadingModalMessage: 'Filtering...',
        viewMode: ViewModes.ALL,
      },
      () => setTimeout(updateFilterStateAndReload, 150),
    )
  }

  async selectProfile(rowItemId: string) {
    try {
      this.setState(
        {
          ...this.initialModalState,
          loadingModalOpen: true,
          loadingModalMessage: 'Preparing profile info...',
        },
        async () => {
          const { selectedEmploymentStatus, profileRepo } = this.props
          const profileIdPassport = rowItemId.split('___')[0]
          const profile = profileRepo.getProfileByIdPassport(profileIdPassport)
          if (profile === undefined) {
            this.setState({
              ...this.initialModalState,
              warningModalOpen: true,
              warningModalHeader: 'Profile Not Found',
              warningModalMessage: "This profile hasn't been downloaded. Click REFRESH and try again.",
            })
            return
          }

          const pk = profile.getPk()
          const profilePic = await getRemoteFile(`People/${pk}/FaceShots/center`)
          let allFilterState = {}
          if (allRecordsTableRef && allRecordsTableRef.current) {
            allFilterState = allRecordsTableRef.current.state.allFilterState
          }
          this.props.updateState({
            docValidityFilterState: allFilterState,
            profilePic,
            profile,
            comingFromDocValidity: true,
            docValidityViewMode: this.state.viewMode,
            activeDocumentPortalSection: DocumentCategories.OTHER,
            dataCaptureMode: DataCaptureMode.STANDARD,
            processMode:
              selectedEmploymentStatus === 'EMPLOYEE' ? ProcessModes.GENERAL_EMPLOYEE : ProcessModes.GENERAL_CANDIDATE,
          })
          setTimeout(async () => {
            this.closeModals()
            // @ts-ignore (_radiumStyleState)
            const { _radiumStyleState, perPersonTableData, perPersonColumnConfig, ...stateToPersist } = this.state // per person table data has React elements that cannot be persisted so will need to be re-created
            await LocalStorageProvider.setData('docValidityState', stateToPersist) // saves current state to restore on back nav from /people/documents
            this.props.history.push(`/people/documents`)
          }, 500)
        },
      )
    } catch (error) {
      this.displayWarning(error)
    }
  }

  displayWarning(error: any) {
    let warning = ''
    try {
      if (error.code === 'NetworkError') {
        warning = 'Seems like your internet connection is down. Reconnect to the network, then try again.'
      } else if (error.code === 'LocalRequestInstanceCreationError') {
        warning =
          'There was a problem initiating this request. Close PeopleFlow, then login again. If there is still a problem then contact tech support for assistance.'
      } else if (
        error.code === 'NoClientSettings' ||
        error.code === 'NoClientSettingsForSelectedClient' ||
        error.code === 'NoClientSettingsInitialised'
      ) {
        warning = 'You will need to login again to continue'
      } else if ('message' in error) {
        warning =
          "The following error message was returned:\n\n'" +
          error.message +
          "'. \n\nRefresh the page and try again. If unsuccessful, then contact tech support"
      } else {
        warning =
          'We encountered a problem. Refresh the page and try again. If unsuccessful logout and login, then contact tech support if still unsuccessful'
      }
    } catch (error) {
      warning =
        'We encountered a problem. Refresh the page and try again. If unsuccessful logout and login, then contact tech support if still unsuccessful'
    }

    this.setState({
      ...this.initialModalState,
      warningModalOpen: true,
      warningModalHeader: 'Warning',
      warningModalMessage: warning,
    })
  }

  getTableToDisplay(filterState?: DataTableFilterState) {
    function getPerPersonDataToDisplay(searchString: string, perPersonTableData: Record<string, any>[]) {
      if (searchString.length === 0) {
        return perPersonTableData
      }
      return perPersonTableData.filter((row) => row.idPassport.includes(searchString.toLowerCase()))
    }

    let perDocumentTable = null
    let perPersonTable = null
    let allTable = null

    if (this.state.viewMode === ViewModes.PER_DOCUMENT) {
      perDocumentTable = (
        <DataTable
          key={'perDocument'}
          ref={perDocTableRef}
          tableData={this.state.perDocTableData}
          columnConfig={perDocConfig}
          tableWidth={this.state.tableWidth}
          onRowClick={(rowItem: Record<string, any>) => this.setFilter(rowItem.id, 'competency')}
          selectedRowItemId={this.state.selectedRowItemId}
          trimText={true}
        />
      )
    } else if (this.state.viewMode === ViewModes.PER_PERSON) {
      const rowData = getPerPersonDataToDisplay(this.state.searchString, this.state.perPersonTableData)
      perPersonTable = (
        <SimpleTable
          columns={this.state.perPersonColumnConfig.map((column) => column.label)}
          rows={rowData}
          onRowClick={(rowData: Record<string, any>) => this.selectProfile(rowData.id)}
          key={`perPersonTable_${this.state.searchString}`}
        />
      )
    }

    const handleAllTableMounted = () => {
      if (filterState) {
        this.setState({ allTableMountedFilterState: cloneDeep(filterState) })
      }
    }

    const allTableMountedBeforeWithSameFilterState = isEqual(filterState, this.state.allTableMountedFilterState)
    let tableRefreshTimestamp = this.state.refreshAllTableTimestamp
    if (!allTableMountedBeforeWithSameFilterState) {
      tableRefreshTimestamp = Date.now()
      this.setState({ refreshAllTableTimestamp: tableRefreshTimestamp })
    }
    allTable = (
      <DataTable
        ref={allRecordsTableRef}
        tableData={this.state.tableData}
        columnConfig={columnConfig}
        tableWidth={this.state.tableWidth}
        filterState={filterState}
        onRowClick={(rowData: Record<string, any>) => this.selectProfile(rowData.id)}
        selectedRowItemId={this.state.selectedRowItemId}
        onFilterChange={(tableData: any[]) => this.updateChartConfig(tableData)}
        filterModeEnabled={true}
        selectionEnabled={true}
        onMounted={handleAllTableMounted}
        style={{ visibility: perDocumentTable || perPersonTable ? 'hidden' : 'visible' }}
        key={`allDocs_${tableRefreshTimestamp}`}
      />
    )
    return (
      <>
        {perDocumentTable}
        {perPersonTable}
        {allTable}
      </>
    )
  }

  getCacheTimestampMessage() {
    const cacheTimestamp = this.state.cacheTimestamp
    const cacheAgeInMillisecondes = this.state.checkTimestamp - cacheTimestamp

    const millisecondsPerMinute = 1000 * 60
    const millisecondsPerHour = millisecondsPerMinute * 60
    const millisecondsPerDay = millisecondsPerHour * 24
    const daysSinceLastCache = Math.floor(cacheAgeInMillisecondes / millisecondsPerDay)

    if (daysSinceLastCache >= 1) {
      return `Based on data at least ${daysSinceLastCache} day${daysSinceLastCache > 1 ? 's' : ''} old`
    }
    const hoursSinceLastCache = Math.floor(cacheAgeInMillisecondes / millisecondsPerHour)
    if (hoursSinceLastCache >= 1) {
      return `Based on data at least ${hoursSinceLastCache} hour${hoursSinceLastCache > 1 ? 's' : ''} old`
    }
    const minutesSinceLastCache = Math.floor(cacheAgeInMillisecondes / millisecondsPerMinute)
    if (minutesSinceLastCache >= 1) {
      return `Based on data at least ${minutesSinceLastCache} minute${minutesSinceLastCache > 1 ? 's' : ''} old`
    }
    return
  }

  async refreshAllProfiles() {
    const { associationRepo, profileRepo, idPassport, password, selectedAssociation, selectedCohort } = this.props
    const loadingModalMessage = 'Refreshing profiles...'
    try {
      this.setState({
        ...this.initialModalState,
        loadingModalOpen: true,
        loadingModalMessage,
        loadingModalAuxMessage: '',
      })

      await profileService.downloadAllAssociationProfiles(
        sessionService,
        associationRepo,
        profileRepo,
        { username: idPassport, password },
        selectedAssociation,
        selectedCohort,
        (progress: string) => {
          console.log('progress: ', progress)
          this.setState({
            ...this.initialModalState,
            loadingModalOpen: true,
            loadingModalMessage,
            loadingModalAuxMessage: progress,
          })
        },
      )
      this.props.updateState({ docValidityFilterState: {} })
      await this.refreshDocs(false)
      const cacheTimestamp = await getTimeBasedDocsCacheTimestamp(selectedAssociation, selectedCohort)
      this.setState({ ...this.initialModalState, cacheTimestamp, checkTimestamp: 0, profileRefetchInProgress: false })
    } catch (error: any) {
      this.displayWarning(error)
    }
  }

  render() {
    const { navMenuAccess } = this.props
    const { hasDocValidityAccess } = navMenuAccess
    const {
      cacheTimestamp,
      downloading,
      downloadQueueModalOpen,
      viewMode,
      isUpdatingDocExportQueue,
      loadingModalOpen,
      loadingModalMessage,
      loadingModalAuxMessage,
      searchString,
      warningModalOpen,
      warningModalHeader,
      warningModalMessage,
    } = this.state
    const toolbarActionButtons: ActionButtonType[] = [
      {
        iconPath: mdiRefresh,
        iconColor: ColorPalette.PRIMARY_BLUE,
        onClick: () => this.refreshAllProfiles(),
        label: 'REFRESH',
        title: 'Refetch data, overwriting existing cache',
        disabled: false,
      },
    ]

    if (viewMode === ViewModes.ALL) {
      toolbarActionButtons.unshift(
        {
          iconPath: mdiPlaylistPlus,
          iconColor: ColorPalette.PRIMARY_BLUE,
          onClick: () => this.updateQueueData(),
          label: 'VIEW QUEUE',
          disabled: false,
        },
        {
          iconPath: mdiFileDocumentMultiple,
          iconColor: ColorPalette.PRIMARY_BLUE,
          onClick: () => this.generateFile(),
          label: 'EXPORT DOCS',
          disabled: false,
        },
      )
    }

    if (cacheTimestamp !== 0) {
      const label = this.getCacheTimestampMessage()
      if (label) {
        toolbarActionButtons.unshift({
          iconPath: mdiMessageAlert,
          iconColor: ColorPalette.SECONDARY_TEXT,
          onClick: () => undefined,
          disabled: true,
          label: label,
          title: 'Click REFRESH to refetch and update',
        })
      }
    }

    const table = this.getTableToDisplay(this.props.docValidityFilterState)

    let downloadQueueModal = null
    if (downloadQueueModalOpen) {
      downloadQueueModal = (
        <DownloadQueueModal
          open={downloadQueueModalOpen}
          queuedTasks={this.props.asyncTaskQueueDocExport || []}
          itemsBeingGenerated={[]}
          itemsDownloading={this.props.itemsDownloading || []}
          header={'Download Queue'}
          body={''}
          isUpdatingDocExportQueue={isUpdatingDocExportQueue}
          updateQueueData={() => this.updateQueueData(false)}
          download={(filePath: string, queueId: string) => this.downloadFile(filePath, queueId)}
          dismiss={() => this.closeModals()}
        />
      )
    }

    return (
      <div style={styles.container}>
        <NavBar
          history={this.props.history}
          match={this.props.match}
          location={this.props.location}
          reloadPageData={this.refreshDocs}
        />
        <SectionHeader
          ref={sectionHeaderRef}
          style={styles.sectionHeader}
          downloading={downloading}
          searchString={searchString}
          textHandler={(e) => this.searchHandler(e)}
          onClick={() => this.setState({ tableWidth: 10 })}>
          {this.props.currentSection}
        </SectionHeader>

        <div style={styles.contentContainer}>
          <SideMenu
            visible={this.state.sideMenuVisible}
            menuComponents={
              <DocValiditySideMenu
                setFilter={(rowItemId: string) => this.setFilter(rowItemId, 'status')}
                chartConfig={this.state.chartConfig}
                numberOfDocsTotal={this.state.tableData.length}
                // @ts-ignore
                numberOfDocs={allRecordsTableRef?.current?.state.allSortedData.length}
                selectedViewMode={this.state.viewMode}
                onClick={(selectedViewMode) => this.changeViewMode(selectedViewMode)}
              />
            }
          />
          <div style={styles.rightSide}>
            <Toolbar actionButtons={toolbarActionButtons} key={`actionToolbar_${toolbarActionButtons.length}`} />
            <div style={styles.rightSideContent}>{table}</div>
          </div>
        </div>
        {downloadQueueModal}
        <AlertModalOneButton
          open={warningModalOpen}
          header={warningModalHeader}
          body={warningModalMessage}
          buttonLabel={'Ok'}
          onClick={() => this.closeModals()}
        />
        <AlertModalOneButton
          open={!hasDocValidityAccess}
          header={'Not Authorised'}
          body={"You don't have permission to view time-sensitive documents / competencies."}
          buttonLabel={'Ok'}
          opaqueBackground={true}
          onClick={() => this.props.history.goBack()}
        />
        <LoadingModal open={loadingModalOpen} auxilliaryMessage={loadingModalAuxMessage}>
          {loadingModalMessage}
        </LoadingModal>
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    backgroundImage: `linear-gradient(to bottom, ${ColorPalette.SCREEN_TOP_GRADIENT}, ${ColorPalette.SCREEN_BOTTOM_GRADIENT})`,
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 1.5%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    paddingInline: 'max(2em, 2%)',
    overflow: 'hidden',
    width: '100%',
  },
  rightSideContent: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    display: 'flex',
    flex: 1,
    backgroundColor: ColorPalette.CARD_WHITE,
  },
  tableName: {
    marginTop: window.innerHeight * 0.055,
    height: window.innerHeight * 0.7,
    width: '94%',
  },
  newFormButton: {
    marginTop: window.innerHeight * 0.045,
    fontWeight: 'bolder',
    fontSize: '0.8rem',
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
    },
    ':active': {
      color: ColorPalette.DARK_GREY,
    },
  },
  tableNavButtonContainer: {
    // margingit Top: window.innerHeight * 0.07,
    marginBottom: window.innerHeight * 0.005,
    display: 'flex',
    alignItems: 'center',
    width: (window.innerWidth * 10) / 12,
    // justifyContent: "space-between",
  },
  toolbarButton: {
    marginTop: window.innerHeight * 0,
    width: 220,
    fontWeight: 'bolder' as 'bolder',
    fontSize: '0.8rem',
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
    },
    ':active': {
      color: ColorPalette.DARK_GREY,
    },
  },
  statusDotContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start' as 'flex-start',
    alignItems: 'center' as 'center',
    paddingLeft: 10,
  },
  statusDot: {
    width: 10,
    height: 10,
    borderRadius: 30,
  },
}

const mapStateToProps = (state: PeopleFlowCombinedReducer) => {
  return {
    idPassport: state.sessionManager.idPassport,
    password: state.sessionManager.password,
    associationRepo: state.sessionManager.associationRepo as AssociationSettingsRepository,
    profileRepo: state.sessionManager.profileRepo as ProfileRepository,
    userRepo: state.sessionManager.userRepo as UsersRepository,
    // allCohorts: state.sessionManager.allCohorts,
    // allProfiles: state.sessionManager.allProfiles,
    activeDocumentPortalSection: state.sessionManager.activeDocumentPortalSection,
    allTimeBasedDocs: state.sessionManager.allTimeBasedDocs,
    selectedAssociation: state.sessionManager.selectedAssociation,
    selectedCohort: state.sessionManager.selectedCohort,
    selectedRole: state.sessionManager.selectedRole,
    selectedEmploymentStatus: state.sessionManager.selectedEmploymentStatus,
    currentSection: state.sessionManager.currentSection,
    currentScreen: state.sessionManager.currentScreen,
    previousScreen: state.sessionManager.previousScreen,
    // hasDocValidityAccess: state.sessionManager.hasDocValidityAccess,
    navMenuAccess: state.sessionManager.navMenuAccess,
    processMode: state.sessionManager.processMode,
    profile: state.sessionManager.profile,
    profilePic: state.sessionManager.profilePic,
    dataCaptureMode: state.sessionManager.dataCaptureMode,
    comingFromDocValidity: state.sessionManager.comingFromDocValidity,
    docValidityViewMode: state.sessionManager.docValidityViewMode,
    docValidityFilterState: state.sessionManager.docValidityFilterState,
    asyncTaskQueueDocExport: state.sessionManager.asyncTaskQueueDocExport,
    itemsDownloading: state.sessionManager.itemsDownloading,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateState: (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Radium(DocValidity))
