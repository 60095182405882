import { useState, useEffect, memo } from 'react'
import { useSelector } from 'react-redux'

import NavBar from '../../components/Navigation/NavBar'
import SectionHeaderPrimary from '../../components/Headings/SectionHeaderPrimary'

import { ColorPalette } from '../../config/colors'
import SideMenu from '../../components/Navigation/SideMenu'
import BillingSideMenu from '../../components/SideMenus/BillingSideMenu'
import AlertModalOneButton from '../../components/Modals/AlertModalOneButton'

import { PeopleFlowCombinedReducer } from '../../store'
import { getBillingVerifications, getBillingProfiles } from '../../providers'
import { BillTrendEntry, BillingUiState, CountsBillingDataObject, VerificationsBillingCategoryItem } from '../../types'
import { SessionService } from '../../services'
import { BillingDetails } from '../../components/Billing/BillingDetails'
import LoadingModal from '../../components/Modals/LoadingModal'

type BillingProps = {
  match: any
  location: any
  history: any
  selectedAssociation: string
}

const Billing = (props: BillingProps) => {
  const selectedAssociation = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation,
  )
  const idPassport = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.idPassport)
  const password = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.password)
  const navMenuAccess = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.navMenuAccess)

  const [selectedCategory, setSelectedCategory] = useState('ALL')
  const [selectedDateRange, setSelectedDateRange] = useState<Record<string, Date>>({
    startDate: new Date(new Date().getTime() - 2408000000),
    endDate: new Date(),
  })
  const [data, setData] = useState<BillingUiState>({
    people: [],
    bankVerifications: [],
    idVerifications: [],
  })
  const [loadingModalOpen, setLoadingModalOpen] = useState(false)

  useEffect(() => {
    initialise()
  }, [])

  const initialise = async () => {
    setLoadingModalOpen(true)
    const token = await SessionService.prepareAuthTokens(idPassport, password)
    const people = await getPeopleCounts(token)
    const { bankVerifications, idVerifications } = await getVerifications(token)
    setData({ people, bankVerifications, idVerifications })
    setLoadingModalOpen(false)
  }

  const getPeopleCounts = async (token: string) => {
    const data = await getBillingProfiles(selectedAssociation, token)
    const sortedResponseData = data.sort((a: any, b: any) => a.unixMs - b.unixMs)
    const peopleData = sortedResponseData.map((item: any) => ({
      date: new Date(item.unixMs).toISOString(),
      employees: item.employees,
      candidates: item.candidates,
    }))
    // setData({ ...data, people: peopleData })
    return peopleData
  }

  const getVerifications = async (token: string) => {
    const response = await getBillingVerifications(selectedAssociation, token)

    let bankVerifications = response.bankverification || []
    if (bankVerifications) {
      bankVerifications = packageVerificationData(bankVerifications)
    }
    let idVerifications = response.idverification || []
    if (idVerifications) {
      idVerifications = packageVerificationData(idVerifications)
    }
    return { bankVerifications, idVerifications }
  }

  const packageVerificationData = (verificationData: BillTrendEntry<number>[]) => {
    const sortedData = verificationData.sort((a: any, b: any) => {
      return a.unixMs - b.unixMs
    })
    const verifications = sortedData.map((item: any) => ({
      date: new Date(item.unixMs).toISOString(),
      price: item.billValue?.price,
    }))
    return verifications
  }

  const getDataMatchingDataRange = (data: Record<string, any>[]) => {
    return data.filter((item) => {
      const itemDate = new Date(item.date)
      return itemDate >= selectedDateRange.startDate && itemDate <= selectedDateRange.endDate
    })
  }

  const getBillingAmountTotal = (
    peopleDataMatchingDateRange: CountsBillingDataObject[],
    bankVerificationsMatchingDateRange: VerificationsBillingCategoryItem[],
    idVerificationsMatchingDateRange: VerificationsBillingCategoryItem[],
    maxCandidatesInPeriod: number,
    maxEmployeesInPeriod: number,
  ) => {
    let countsPriceTotal = 0
    let bankVerificationsPriceTotal = 0
    let idVerificationsPriceTotal = 0

    if (peopleDataMatchingDateRange.length) {
      const lastItem = peopleDataMatchingDateRange[peopleDataMatchingDateRange.length - 1]
      countsPriceTotal =
        lastItem.candidates.price * maxCandidatesInPeriod + lastItem.employees.price * maxEmployeesInPeriod
    }

    if (bankVerificationsMatchingDateRange.length) {
      const targetData = getDataMatchingDataRange(
        bankVerificationsMatchingDateRange,
      ) as VerificationsBillingCategoryItem[]
      bankVerificationsPriceTotal = targetData.reduce((acc: number, item) => acc + item.price, 0)
    }

    if (idVerificationsMatchingDateRange.length) {
      const targetData = getDataMatchingDataRange(
        idVerificationsMatchingDateRange,
      ) as VerificationsBillingCategoryItem[]
      idVerificationsPriceTotal = targetData.reduce((acc: number, item) => acc + item.price, 0)
    }
    return countsPriceTotal.toFixed(2)
  }

  const peopleDataMatchingDateRange = getDataMatchingDataRange(data.people) as CountsBillingDataObject[]
  let billingCategories = ['ALL']
  if (peopleDataMatchingDateRange.length) {
    billingCategories = [...billingCategories, 'CANDIDATES', 'EMPLOYEES']
  }
  const bankVerificationsMatchingDateRange = getDataMatchingDataRange(
    data.bankVerifications,
  ) as VerificationsBillingCategoryItem[]
  if (bankVerificationsMatchingDateRange.length) {
    billingCategories = [...billingCategories, 'BANK VERIFICATIONS']
  }
  const idVerificationsMatchingDateRange = getDataMatchingDataRange(
    data.idVerifications,
  ) as VerificationsBillingCategoryItem[]
  if (idVerificationsMatchingDateRange.length) {
    billingCategories = [...billingCategories, 'ID VERIFICATIONS']
  }

  const maxCandidatesInPeriod = Math.max.apply(
    Math,
    peopleDataMatchingDateRange.map((item: any) => item.candidates.quantity),
  )
  const maxEmployeesInPeriod = Math.max.apply(
    Math,
    peopleDataMatchingDateRange.map((item: any) => item.employees.quantity),
  )
  const billingAmountTotal = getBillingAmountTotal(
    peopleDataMatchingDateRange,
    bankVerificationsMatchingDateRange,
    idVerificationsMatchingDateRange,
    maxCandidatesInPeriod,
    maxEmployeesInPeriod,
  )

  const candidateBar = {
    label: 'Candidates',
    data: peopleDataMatchingDateRange.map((item: any) => item.candidates.quantity),
    backgroundColor: ColorPalette.PRIMARY_BLUE,
  }
  const employeeBar = {
    label: 'Employees',
    data: peopleDataMatchingDateRange.map((item: any) => item.employees.quantity),
    backgroundColor: ColorPalette.DARK_GREY,
  }

  const lineStyle = { backgroundColor: 'rgba(0,0,0,0)', borderWidth: 3, radius: 0 }
  const candidateLine = {
    type: 'line',
    label: 'Max Candidates',
    // @ts-ignore
    data: [...peopleDataMatchingDateRange].fill(maxCandidatesInPeriod),
    borderColor: ColorPalette.PRIMARY_BLUE_TRANSPARENT,
    ...lineStyle,
  }
  const employeeLine = {
    type: 'line',
    label: 'Max Employees',
    // @ts-ignore
    data: [...peopleDataMatchingDateRange].fill(maxEmployeesInPeriod),
    borderColor: ColorPalette.DARK_GREY_TRANSPARENT,
    ...lineStyle,
  }

  let graphDataSets = [candidateBar, employeeBar, candidateLine, employeeLine]
  if (selectedCategory === 'CANDIDATES') {
    graphDataSets = [candidateBar, candidateLine]
  }
  if (selectedCategory === 'EMPLOYEES') {
    graphDataSets = [employeeBar, employeeLine]
  }

  return (
    <div style={styles.container}>
      <NavBar match={props.match} location={props.location} history={props.history} />
      <SectionHeaderPrimary style={styles.sectionHeader} disabled={true} searchString={''} onClick={() => ({})}>
        Billing
      </SectionHeaderPrimary>

      <div style={styles.contentContainer}>
        <SideMenu
          visible={true}
          menuComponents={
            <BillingSideMenu
              billingAmountTotal={billingAmountTotal}
              billingCategories={billingCategories}
              selectedCategory={selectedCategory}
              selectedDateRange={selectedDateRange}
              onBillingCategoryClick={(category: string) => setSelectedCategory(category)}
              onDateRangeChange={(dateRange: Record<string, Date>) => setSelectedDateRange(dateRange)}
            />
          }
        />
        <BillingDetails
          data={peopleDataMatchingDateRange}
          dataSets={graphDataSets}
          maxCandidatesInPeriod={
            selectedCategory === 'ALL' || selectedCategory === 'CANDIDATES' ? maxCandidatesInPeriod : undefined
          }
          maxEmployeesInPeriod={
            selectedCategory === 'ALL' || selectedCategory === 'EMPLOYEES' ? maxEmployeesInPeriod : undefined
          }
          key={`billingDetails_${selectedCategory}`}
        />

        <AlertModalOneButton
          // TODO: There should only be one AlertModalOneButton component per screen with the required headers, body, and functions being set accordingly by a handler.
          open={!navMenuAccess.hasBillingAccess}
          header={'Not Authorised'}
          body={"You don't have permission to view billing information."}
          buttonLabel={'Ok'}
          opaqueBackground={true}
          onClick={() => props.history.goBack()}
        />
      </div>
      <LoadingModal open={loadingModalOpen}>Loading billing data...</LoadingModal>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundImage: `linear-gradient(to bottom, ${ColorPalette.SCREEN_TOP_GRADIENT}, ${ColorPalette.SCREEN_BOTTOM_GRADIENT})`,
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 1.5%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
}

export default memo(Billing)
