import { Component } from 'react'
import Radium from 'radium'
import { mdiWrap } from '@mdi/js'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import { ColorPalette } from '../../config/colors'
import { removeUnderScores, toUpperCaseCustom } from '../../utils'
import TextInputOutlined from '../BaseComponents/Text/TextInputOutlined'
import { SideMenuOptions } from './SideMenuOptions'
import { IDataFlow } from '../../interfaces'
import { SideMenuTitle } from './SideMenuTitle'

dayjs.extend(relativeTime)

interface IDataFlowsSideMenuProps {
  selectedFlow: string
  selectedAssociation: string
  dataflowConfig: any
  organisationConfig: any
  onFlowClick: (dataField: string) => void
  onDivisionChange: (division: string) => void
  onFlowAdded: (dataFlowName: string, updatedData: IDataFlow) => void
}

interface IDataFlowsSideMenuState {
  addPositionModalOpen: boolean
  searchString: string
}

class DataFlowsSideMenu extends Component<IDataFlowsSideMenuProps, IDataFlowsSideMenuState> {
  state = {
    addPositionModalOpen: false,
    searchString: '',
  }

  closeModal = () => this.setState({ addPositionModalOpen: false })

  searchHandler = (e: any) => {
    this.setState({ searchString: toUpperCaseCustom(e.target.value) })
  }

  render() {
    const { dataflowConfig, selectedFlow, onFlowClick } = this.props
    let dataFlows = Object.keys(dataflowConfig).sort()

    if (this.state.searchString.length) {
      dataFlows = dataFlows.filter((flow) => flow.toUpperCase().includes(this.state.searchString))
    }

    return (
      <div style={styles.container}>
        <SideMenuTitle>Data Flows</SideMenuTitle>
        <TextInputOutlined
          style={styles.textStyle}
          label="FLOW SEARCH"
          placeholder="Search for flow"
          value={this.state.searchString}
          textHandler={this.searchHandler}
          disabled={false}
        />
        <div style={{ backgroundColor: ColorPalette.OFF_WHITE_LIGHT, height: '80vh', width: '100%' }}>
          <SideMenuOptions
            options={dataFlows}
            optionLabel={(flowName: string) => removeUnderScores(flowName)}
            optionClick={onFlowClick}
            optionMdiIconPath={mdiWrap}
            optionStyle={(flowName: string) => ({
              fontSize: 'smaller',
              maxWidth: '100%',
              backgroundColor:
                selectedFlow === flowName ? ColorPalette.BUTTON_ACTIVE_GREY : ColorPalette.OFF_WHITE_LIGHT,
            })}
          />
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    width: '100%',
    overflow: 'auto',
  },
  dividerLine: {
    width: '86%',
    marginTop: 30,
    marginBottom: 20,
    borderBottom: `1px solid ${ColorPalette.LIGHT_GREY}`,
  },
  pickerStyle: {
    margin: '0 auto',
    minWidth: 120,
    width: '72%',
    backgroundColor: ColorPalette.CARD_WHITE,
  },
  textStyle: {
    color: ColorPalette.PRIMARY_TEXT,
    padding: '0px 12px 10px 12px',
    marginTop: 20,
    minWidth: 120,
    width: '80%',
  },
}

export default Radium(DataFlowsSideMenu)
