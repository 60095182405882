import React, { Component, createRef } from 'react'
import { RouteComponentProps } from 'react-router'
import Radium from 'radium'
import get from 'lodash/get'
import { connect } from 'react-redux'
import { GridSize } from '@material-ui/core/Grid'
import Icon from '@mdi/react'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { mdiAlertCircle, mdiCamera, mdiFileDocument } from '@mdi/js'

import { ColorPalette } from '../../config/colors'
import { ActionType } from '../../store/actions/actions'
import completedScreens from '../completedScreens'
import {
  toLowerCaseCustom,
  removeUnderScores,
  addUnderScores,
  sortArrayOfObjects,
  toUpperCaseCustom,
} from '../../utils'
import { defaultActionFlowConfig, disciplineSectionConfig } from '../../config'
import { ScreenNamesEnum, EmploymentStatus, NavMenuAccess, ProfileNavMenuAccess } from '../../types'

import DisciplineService from '../../services/disciplineService'
import NavBar from '../../components/Navigation/NavBar'
import SectionHeader from '../../components/Headings/SectionHeaderPrimary'
import LoadingModal from '../../components/Modals/LoadingModal'
import AlertModalOneButton from '../../components/Modals/AlertModalOneButton'
import FileViewer from '../../components/Modals/FileViewer'
import OptionsSelectorModal from '../../components/Modals/OptionsSelectorModal'
import SideMenu from '../../components/Navigation/SideMenu'
import ProfileNavMenu from '../../components/GeneralUI/ProfileNavMenu/ProfileNavMenu'
import DataFlowNavigator from '../../components/Navigation/DataFlowNavigator'
import DataTable from '../../components/Tables/DataTable/DataTable'
import ActionFlow from '../../components/GeneralUI/ActionFlows/ActionFlows'
import DisciplineActionTile from '../../components/BaseComponents/Buttons/DisciplineTile'
import InfoCollectorModal from '../../components/Modals/InfoCollector'
import ButtonGeneric from '../../components/BaseComponents/Buttons/ButtonGeneric'
import { PickerHandlerEvent } from '../../components/BaseComponents/Pickers/Picker'
import { DisciplineAction, Profile } from '../../models'
import { PeopleFlowCombinedReducer } from '../../store'
import { IDisciplineHistoryItem } from '../../models/discipline/discipline.history.item'
import { ConfigService, SessionService } from '../../services'
import { AssociationSettingsRepository, UsersRepository } from '../../repositories'
import { ISectionConfigItem } from '../../interfaces'
import { getRemoteFile } from '../../providers/remoteDataProvider'
import { IamServiceErrorCodesEnum } from '../../enums'

const { Unauthorised } = IamServiceErrorCodesEnum
const configService = new ConfigService()
const sessionService = new SessionService()

const columnConfig = [
  { id: 'date', label: 'Date' },
  { id: 'action', label: 'Action' },
  { id: 'offence', label: 'For', sizeFactor: 2 },
]

interface DisciplineProps extends RouteComponentProps {
  // allRoles: string[]
  idPassport: string
  password: string
  associationRepo: AssociationSettingsRepository
  userRepo: UsersRepository
  profile: Profile
  selectedAssociation: string
  profilePic: string
  disciplineItemUidToLoad: string
  currentScreen: ScreenNamesEnum
  previousScreen: ScreenNamesEnum
  isReadOnly: boolean
  // hasWorkforceAccess: boolean
  profileNavMenuAccess: ProfileNavMenuAccess
  selectedEmploymentStatus: string

  updateState: (obj: any) => void
  changeScreen: (screen: string) => void
}

interface DisciplineState {
  currentScreen: ScreenNamesEnum
  loadingModalOpen: boolean
  warningModalOpen: boolean
  accountModalOpen: boolean
  forgotPasswordModalOpen: boolean
  settingsModalOpen: boolean
  fileViewerModalOpen: boolean
  roleSelectorModalOpen: boolean
  optionsSelectorModalOpen: boolean
  loadingModalMessage: string
  warningModalHeader: string
  warningModalMessage: string
  sideMenuComponents: JSX.Element
  sideMenuVisible: boolean
  tableWidth: GridSize
  actionFlowData: any
  disciplineData: any[]
  possibleOffences: string[]
  selectedRowItemId: string
  sectionConfig: ISectionConfigItem[]
  currentSectionName: string
  authedRoles: string[]
  bulkActions: any[]
  columnConfig: any
  photosUris: string[]
  selectedRoles: string[]
  selectedOffence: string
  customComponentConfig: {
    leftComponentWidth: number
    rightComponentWidth: number
  }
  [index: string]: any
}

class Discipline extends Component<DisciplineProps, DisciplineState> {
  tableRef?: React.RefObject<DataTable> = createRef()

  initialModalState = {
    loadingModalOpen: false,
    warningModalOpen: false,
    accountModalOpen: false,
    forgotPasswordModalOpen: false,
    settingsModalOpen: false,
    fileViewerModalOpen: false,
    roleSelectorModalOpen: false,
    optionsSelectorModalOpen: false,
  }

  state: DisciplineState = {
    ...this.initialModalState,
    loadingModalMessage: '',
    warningModalHeader: '',
    warningModalMessage: '',
    sideMenuComponents: <div />,
    sideMenuVisible: true,
    tableWidth: 9,
    actionFlowData: [],
    disciplineData: [],
    possibleOffences: [],
    selectedRowItemId: '',
    sectionConfig: disciplineSectionConfig,
    currentSectionName: 'history',
    authedRoles: [],
    bulkActions: [],
    columnConfig,
    photosUris: [],
    selectedRoles: [],
    selectedOffence: '',
    customComponentConfig: {
      leftComponentWidth: 50,
      rightComponentWidth: 100,
    },
    currentScreen: ScreenNamesEnum.DISCIPLINE,
  }

  async componentDidMount() {
    this.setState({
      loadingModalOpen: true,
      loadingModalMessage: 'Loading discipline data...',
    })
    this.props.updateState({
      currentScreen: ScreenNamesEnum.DISCIPLINE,
    })
    const { name, surname, idPassport } = this.props.profile.getPersonalInfo()

    if (!idPassport) {
      sessionService.logout()
      this.props.history.push('/login')
      return
    }

    this.initialiseSideMenu(name, surname, idPassport)

    const { selectedAssociation } = this.props
    const { activeActionFlows, disciplineHistory } = this.props.profile.getDisciplineData(selectedAssociation)
    const { disciplineData, actionFlowData } = this.initialiseUI(disciplineHistory, activeActionFlows)

    this.setState(
      {
        ...this.initialModalState,
        actionFlowData,
        disciplineData,
        columnConfig,
        // bulkActions
      },
      () => this.tableRef?.current?.reload(),
    )

    if (this.props.disciplineItemUidToLoad) {
      this.viewDisciplinaryItemData(this.props.disciplineItemUidToLoad)
      this.props.updateState({ disciplineItemUidToLoad: '' })
    }
  }

  initialiseUI(disciplineHistory: IDisciplineHistoryItem[], activeActionFlows: any) {
    const preparedDisciplinaryData = this.prepareDisciplinaryData(disciplineHistory)
    if (preparedDisciplinaryData === null) {
      throw new Error('Disciplinary data could not be found')
    }

    const { disciplineData } = preparedDisciplinaryData
    const { associationRepo, selectedAssociation, profile } = this.props
    const { divison, jobType, jobSubType, offence, action } = profile.getGeneralData()
    const actionFlowConfig = associationRepo.getActionFlowConfig(
      selectedAssociation,
      divison,
      jobType,
      jobSubType,
      offence,
      action,
    )
    const actionFlowData = this.prepareActiveActionFlows(activeActionFlows, actionFlowConfig)
    return { disciplineData, actionFlowData }
  }

  initialiseSideMenu(name: string, surname: string, idPassport: string) {
    const { userRepo, selectedAssociation, selectedEmploymentStatus } = this.props
    const iamEntity = userRepo.getCurrentUserEntity().getAssocationIamEntity(selectedAssociation)
    if (!iamEntity) {
      throw { code: Unauthorised }
    }
    const profileNavMenuAccess = configService.generateProfileNavMenuConfig(
      iamEntity,
      selectedEmploymentStatus as EmploymentStatus,
    )
    const sideMenuComponents = (
      <ProfileNavMenu
        profilePic={this.props.profilePic}
        name={`${name} ${surname}`}
        idPassport={idPassport}
        onClick={(screen) => this.sideMenuHandler(screen)}
        currentScreen={this.props.currentScreen}
        accessFlags={profileNavMenuAccess}
      />
    )
    this.props.updateState({ profileNavMenuAccess })
    this.setState({ sideMenuComponents })
  }

  onChange(event: PickerHandlerEvent, type: string) {
    this.setState({ [type]: event.target.value })
  }

  async addActionFlow() {
    try {
      const offence = addUnderScores(this.state.selectedOffence)
      let actionFlowData = this.state.actionFlowData
      if (offence in actionFlowData) {
        this.displayWarning({ code: 'Exists' })
      } else {
        this.setState({
          ...this.initialModalState,
          loadingModalOpen: true,
          loadingModalMessage: 'Adding new action flow...',
        })
        const { idPassport, password, selectedAssociation, profile, associationRepo } = this.props
        const { divison, jobType, jobSubType, offence, action } = profile.getGeneralData()
        actionFlowData[offence] = []
        actionFlowData = this.prepareActiveActionFlows(
          actionFlowData,
          associationRepo.getActionFlowConfig(selectedAssociation, divison, jobType, jobSubType, offence, action),
        )

        await DisciplineService.uploadDisciplinaryActions(
          selectedAssociation,
          {
            [profile.getPk()]: { activeActionFlows: actionFlowData },
          },
          { username: idPassport, password },
        )
        this.setState({ ...this.initialModalState, actionFlowData, currentSectionName: 'activeCases' })
      }
    } catch (error) {
      this.displayWarning({ code: 'Typo' })
    }
  }

  generateActionComponent(item: DisciplineAction): JSX.Element {
    let authedRoles: any[] = []
    const { action, offence, idPassport, expiry, uid, custom, actionLabel } = item
    const x = this.state.bulkActions.find(
      (x) => x.offence === offence && x.action === action && x.idPassport === idPassport,
    )
    if (x !== undefined) {
      authedRoles = x.selectedRoles
    } else {
      authedRoles = []
    }

    return (
      <DisciplineActionTile
        key={`${action}_${offence}`}
        active={!!uid}
        action={action}
        expiry={expiry}
        customAction={custom}
        actionLabel={actionLabel}
        authedRoles={authedRoles}
        onClick={() => this.viewDisciplinaryItemData(uid)}
      />
    )
  }

  generateRowComponent(config: {
    side: string
    index: string | number
    photos: string[]
    docs?: boolean
    date: string | number
  }): JSX.Element | null {
    let component = null
    if (config.side === 'left') {
      const width = this.state.customComponentConfig.leftComponentWidth
      component = (
        <div style={{ ...styles.leftComponentContainer, width }}>
          <Icon path={mdiAlertCircle} size={1.1} color={ColorPalette.LIGHT_GREY} />
        </div>
      )
    } else if (config.side === 'right') {
      const photosAvailable = Object.keys(get(config, 'photos', [])).length ? true : false
      const width = this.state.customComponentConfig.rightComponentWidth

      component = (
        <div style={{ ...styles.rightComponentContainer, width }}>
          <button
            key={`${config.date}_1_${config.index}`}
            style={{ ...styles.buttonContainer, width: width / 2 - 10 }}
            disabled={!photosAvailable}
            onClick={() => this.viewPhotos(config.photos)}>
            <Icon
              path={mdiCamera}
              size={0.8}
              color={photosAvailable ? ColorPalette.PRIMARY_BLUE : ColorPalette.LIGHT_GREY}
            />
          </button>
          <button
            key={`${config.date}_2_${config.index}`}
            style={{ ...styles.buttonContainer, width: width / 2 - 10 }}
            disabled={!config.docs}
            onClick={() => this.goToDocumentPortal()}>
            <Icon
              path={mdiFileDocument}
              size={0.8}
              color={config.docs ? ColorPalette.PRIMARY_BLUE : ColorPalette.LIGHT_GREY}
            />
          </button>
        </div>
      )
    }
    return component
  }

  prepareDisciplinaryData(disciplineHistory: IDisciplineHistoryItem[]): { disciplineData: DisciplineAction[] } | null {
    let disciplineData = disciplineHistory.map((disciplineItem, i) => {
      // Need 'id' key for UI table
      const date = (disciplineItem.getRawDataField('discActionIssuedDate') as string)
        ? (disciplineItem.getRawDataField('discActionIssuedDate') as string)
        : '-'
      const action: DisciplineAction = {
        id: disciplineItem.getUid(),
        uid: disciplineItem.getUid(),
        docs: disciplineItem.getDocs(),
        photos: disciplineItem.getPhotos(),
        rawData: disciplineItem.getRawData(),
        // type: disciplineItem.getType(),
        date,
        unixDate: new Date(date).getTime(),
        custom: !!disciplineItem.getRawDataField('custom'),
        action: removeUnderScores(disciplineItem.getRawDataField('action') as string),
        offence: removeUnderScores(disciplineItem.getRawDataField('offence') as string),
        actionLabel: disciplineItem.getRawDataField('actionLabel') as string,
        expiry: disciplineItem.getRawDataField('expiry') as string,
        leftComponent: this.generateRowComponent({
          side: 'left',
          date: disciplineItem.getRawDataField('discOffenceDate') as string,
          index: i,
          photos: [],
        }),
        rightComponent: this.generateRowComponent({
          side: 'right',
          docs: Object.keys(disciplineItem.getDocs()).length > 0,
          photos: disciplineItem.getPhotos(),
          date: disciplineItem.getRawDataField('discOffenceDate') as string,
          index: i,
        }),
      }
      return action
    })
    disciplineData = sortArrayOfObjects('unixDate', 'descending', disciplineData)
    return { disciplineData }
  }

  generateActionFlowLabels(data: DisciplineAction[]): DisciplineAction[] {
    let itemNumber = 0
    let foundNextValidStep = false
    return data.map((disciplineItem) => {
      if (disciplineItem.custom) {
        itemNumber += 1
      }

      let actionLabel = 'Optional'
      if (disciplineItem.custom && !disciplineItem.expiry && !foundNextValidStep) {
        actionLabel = `${itemNumber}. Recommended`
        foundNextValidStep = true
      } else if (disciplineItem.custom) {
        actionLabel = `${itemNumber}. Standard`
      }
      return {
        ...disciplineItem,
        actionLabel,
      }
    })
  }

  prepareActiveActionFlows(activeActionFlows: any, actionFlowConfig: Record<string, any>) {
    let activeActionFlow: any = {}

    for (const offence of Object.keys(activeActionFlows)) {
      let completeActionFlow = defaultActionFlowConfig.map((action) => {
        const custom = actionFlowConfig[offence]?.includes(action) ? true : false
        const exists = activeActionFlows[offence]?.filter((offenceItem: any) => {
          if (
            toUpperCaseCustom(addUnderScores(offenceItem.rawData.action)) === toUpperCaseCustom(addUnderScores(action))
          ) {
            return true
          } else {
            return false
          }
        })
        let returnItem = { action, offence, expiry: '0', custom }
        if (exists.length) {
          returnItem = { action, offence, expiry: '0', ...exists[0].rawData, custom }
        }
        return returnItem
      })
      completeActionFlow = this.generateActionFlowLabels(completeActionFlow as DisciplineAction[])
      activeActionFlow[offence] = completeActionFlow
    }
    return activeActionFlow
  }

  closeModals() {
    this.setState({ ...this.initialModalState })
  }

  loadSection(currentSectionName: string) {
    this.setState({ currentSectionName })
  }

  sideMenuHandler(screen: string) {
    if (completedScreens.includes(screen)) {
      this.props.updateState({ currentScreen: screen, previousScreen: ScreenNamesEnum.DISCIPLINE })
      this.props.history.push(`/people/${toLowerCaseCustom(screen.split(' ').join(''))}`)
    } else {
      this.props.history.push('/comingsoon')
    }
  }

  goToDocumentPortal() {
    this.navigate(ScreenNamesEnum.DOCUMENTS)
  }

  goToProfiles() {
    this.closeModals()
    window.scrollTo(0, 0)
    const prevRouteState = this.props.location.state || {}
    setTimeout(() => this.props.history.push('/people', prevRouteState), 30)
  }

  async viewPhotos(photoPaths: string[]) {
    this.setState({
      loadingModalOpen: true,
      loadingModalMessage: 'Fetching photos...',
    })
    const pk = this.props.profile.getPk()
    const photosUris: string[] = []
    for (let i = 0; i < photoPaths.length; i++) {
      const path = photoPaths[i].includes('public/')
        ? photoPaths[i].substring(7)
        : `People/${pk}/Photos/${this.props.selectedAssociation}/${photoPaths[i]}`
      const uri = await getRemoteFile(path)
      photosUris.push(uri)
    }

    this.setState({
      ...this.initialModalState,
      fileViewerModalOpen: true,
      photosUris,
    })
  }

  private viewDisciplinaryItemData(disciplineItemId: string) {
    if (disciplineItemId) {
      let currentDisciplinaryItem: DisciplineAction = {} as DisciplineAction
      this.state.disciplineData.forEach((disciplineItem) =>
        disciplineItem.uid === disciplineItemId ? (currentDisciplinaryItem = disciplineItem) : null,
      )

      if (Object.keys(currentDisciplinaryItem).length) {
        this.props.updateState({
          sourceData: currentDisciplinaryItem.rawData,
          isReadOnly: true,
        })
        this.navigate(ScreenNamesEnum.DATA_MANAGER)
      }
    }
  }

  async updateBulkDisciplineList(selectedRoles: string[]) {
    const { userRepo, profile, selectedAssociation, password } = this.props
    try {
      this.setState({
        ...this.initialModalState,
        loadingModalOpen: true,
        loadingModalMessage: 'Saving action...',
      })
      const user = userRepo.getCurrentUserEntity()
      const { username } = user.getPersonalUserInfo()
      const pk = profile.getPk()
      await DisciplineService.updateBulkDisciplineList(
        pk,
        this.state.selectedOffence,
        this.state.selectedAction,
        selectedAssociation,
        selectedRoles,
        { username, password },
      )
      let { bulkActions, selectedAction, selectedOffence } = this.state
      bulkActions.push({
        action: selectedAction,
        idPassport: pk,
        offence: selectedOffence,
        selectedRoles,
        timestamp: new Date().getTime(),
      })
      this.setState({
        ...this.initialModalState,
        bulkActions,
      })
    } catch (error) {
      // console.log("error: ", error)
      this.displayWarning(error)
    }
  }

  navigate(pathname: string) {
    this.closeModals()
    window.scrollTo(0, 0)
    setTimeout(() => this.props.history.push(`/people/${toLowerCaseCustom(pathname.split(' ').join(''))}`), 30)
  }

  displayWarning(error: any) {
    let warning = ''

    try {
      if (error.code === 'NetworkError') {
        warning = 'Seems like your internet connection is down. Reconnect to the network, then try again.'
      } else if (error.code === 'Exists') {
        warning = 'There is already an active case for this offence.'
      } else if ('message' in error) {
        warning =
          "The following error message was returned when logging in:\n\n'" +
          error.message +
          "'. \n\nRefresh the page and try again. If unsuccessful, then contact tech support"
      } else {
        warning = 'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
      }
    } catch (error) {
      warning = 'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
    }
    this.setState({
      ...this.initialModalState,
      warningModalOpen: true,
      warningModalHeader: 'Warning',
      warningModalMessage: warning,
    })
  }

  render() {
    const { hasDisciplineAccess } = this.props.profileNavMenuAccess

    let screenContent = null
    if (this.state.currentSectionName === 'history') {
      screenContent = (
        <DataTable
          ref={this.tableRef}
          tableData={this.state.disciplineData}
          columnConfig={this.state.columnConfig}
          customComponentConfig={this.state.customComponentConfig}
          tableWidth={this.state.tableWidth}
          onRowClick={(rowData: Record<string, any>) => this.viewDisciplinaryItemData(rowData.id)}
          selectedRowItemId={this.state.selectedRowItemId}
        />
      )
    } else if (this.state.currentSectionName === 'activeCases') {
      screenContent = (
        <ActionFlow
          data={this.state.actionFlowData}
          generateActionComponent={(action: DisciplineAction) => this.generateActionComponent(action)}
        />
      )
    }

    let roleSelectorModal = null
    if (this.state.roleSelectorModalOpen) {
      roleSelectorModal = (
        <InfoCollectorModal
          open={this.state.roleSelectorModalOpen}
          type={'picker'}
          // pickerItems={this.props.allRoles}
          pickerItems={[]}
          header={'AUTHORISED ROLES'}
          subHeader={'Which user roles are authorised to complete this action?'}
          warningMessage={'Select at least one role'}
          successLabel={'Save'}
          validateInput={() => true}
          placeholder={'Role'}
          // defaultItems={this.props.allRoles}
          defaultItems={[]}
          onSuccess={(selectedRoles) => this.updateBulkDisciplineList(selectedRoles)}
          onReject={() => this.closeModals()}
          dismiss={() => this.closeModals()}
          minimumItems={1}
        />
      )
    }

    return (
      <div style={styles.container}>
        <NavBar history={this.props.history} match={this.props.match} location={this.props.location} />

        <SectionHeader
          style={styles.sectionHeader}
          searchString={this.state.searchString}
          disabled={true}
          onClick={() => this.setState({ sideMenuVisible: false, tableWidth: 10 })}>
          {this.props.currentScreen}
        </SectionHeader>

        <div style={styles.contentContainer}>
          <SideMenu visible={this.state.sideMenuVisible} menuComponents={this.state.sideMenuComponents} />
          <div style={styles.rightSide}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ButtonGeneric
                style={{ ...styles.backButton, width: 80 }}
                iconBefore={<ChevronLeftIcon style={styles.buttonIconStyle} />}
                label={'BACK'}
                onClick={() => this.goToProfiles()}
              />
              <ButtonGeneric
                style={{ ...styles.newActionFlow, width: 150 }}
                label={'+ NEW ACTION FLOW'}
                onClick={() => this.setState({ optionsSelectorModalOpen: true })}
              />
            </div>

            <DataFlowNavigator
              buttonStyle={{ width: 260 }}
              sectionConfig={this.state.sectionConfig}
              currentSectionName={this.state.currentSectionName}
              onSectionClick={(section: string) => this.loadSection(section)}
            />
            <div style={styles.rightSideContent}>{screenContent}</div>
          </div>
        </div>

        <FileViewer
          open={this.state.fileViewerModalOpen}
          files={this.state.photosUris}
          dismiss={() => this.closeModals()}
        />
        {roleSelectorModal}
        <OptionsSelectorModal
          open={this.state.optionsSelectorModalOpen}
          onSuccess={() => this.addActionFlow()}
          successLabel={'Save'}
          label1={'What was the offence?'}
          options1={this.props.associationRepo.getPossibleOffences(this.props.selectedAssociation)}
          optionsName1={'selectedOffence'}
          selectedOption1={this.state.selectedOffence}
          onChange={(e, name) => this.onChange(e, name)}
        />
        <AlertModalOneButton
          // TODO: There should only be one AlertModalOneButton component per screen with the required headers, body, and functions being set accordingly by a handler.
          open={!hasDisciplineAccess}
          header={'Not Authorised'}
          body={"You don't have permission to view disciplinary data."}
          buttonLabel={'Ok'}
          opaqueBackground={true}
          onClick={() => this.props.history.goBack()}
        />
        <AlertModalOneButton
          open={this.state.warningModalOpen}
          header={this.state.warningModalHeader}
          body={this.state.warningModalMessage}
          buttonLabel={'Ok'}
          onClick={() => this.closeModals()}
        />
        <LoadingModal open={this.state.loadingModalOpen}>{this.state.loadingModalMessage}</LoadingModal>
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundImage: `linear-gradient(to bottom, ${ColorPalette.SCREEN_TOP_GRADIENT}, ${ColorPalette.SCREEN_BOTTOM_GRADIENT})`,
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 1.5%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    paddingInline: 'max(2em, 2%)',
    overflow: 'hidden',
  },
  rightSideContent: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    display: 'flex',
    flex: 1,
    backgroundColor: ColorPalette.CARD_WHITE,
  },
  iconStyle: {
    color: ColorPalette.DARK_GREY,
    width: '1.1rem',
    height: '1.1rem',
    marginRight: 10,
    marginLeft: 10,
  },
  leftComponentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rightComponentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    padding: '0px 10px',
    backgroundColor: ColorPalette.CARD_WHITE,
  },
  buttonContainer: {
    height: 40,
    borderRadius: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    outline: 0,
    backgroundColor: ColorPalette.CARD_WHITE,
    ':hover': {
      backgroundColor: ColorPalette.BUTTON_HOVER_GREY,
    },
    ':active': {
      backgroundColor: ColorPalette.BUTTON_ACTIVE_GREY,
    },
  },
  newActionFlow: {
    fontWeight: '550',
    fontSize: '0.8rem',
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
    },
    ':active': {
      color: ColorPalette.DARK_GREY,
    },
  },
  buttonIconStyle: {
    color: ColorPalette.PRIMARY_TEXT,
    width: '1.2rem',
    height: '1.2rem',
  },
  backButton: {
    fontWeight: '550',
    fontSize: '0.8rem',
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
    },
    ':active': {
      color: ColorPalette.DARK_GREY,
    },
  },
}

const mapStateToProps = (state: PeopleFlowCombinedReducer) => {
  return {
    idPassport: state.sessionManager.idPassport,
    password: state.sessionManager.password,
    associationRepo: state.sessionManager.associationRepo as AssociationSettingsRepository,
    userRepo: state.sessionManager.userRepo as UsersRepository,
    // allRoles: state.sessionManager.allRoles,
    currentScreen: state.sessionManager.currentScreen,
    isReadOnly: state.sessionManager.isReadOnly,
    previousScreen: state.sessionManager.previousScreen,
    profile: state.sessionManager.profile,
    profilePic: state.sessionManager.profilePic,
    selectedAssociation: state.sessionManager.selectedAssociation,
    profileNavMenuAccess: state.sessionManager.profileNavMenuAccess,
    // hasWorkforceAccess: state.sessionManager.hasWorkforceAccess,
    // currentDisciplinaryItem: state.sessionManager.currentDisciplinaryItem,
    disciplineItemUidToLoad: state.sessionManager.disciplineItemUidToLoad,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    changeScreen: (screen: string) => dispatch({ type: ActionType.CHANGE_SCREEN, data: { currentScreen: screen } }),
    updateState: (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Radium(Discipline))
