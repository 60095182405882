import Radium from 'radium'
import { ColorPalette } from '../../../config/colors'
import CalendarRange, { CalendarRangeProps } from './CalendarRange'

export type CalendarRangeContainedProps = CalendarRangeProps & { containerStyle?: React.CSSProperties }

const CalenderRangeContained = (props: CalendarRangeContainedProps) => (
  <div style={{ ...styles.formControl, ...props.containerStyle }}>
    <p style={styles.label}>{props.label?.toUpperCase()}</p>
    <CalendarRange {...props} />
  </div>
)

const styles = {
  formControl: {
    fontSize: '1rem',
    border: `1px solid ${ColorPalette.VERY_LIGHT_GREY}`,
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    width: '100%',
    padding: '15px 20px 10px 20px',
    height: 80,
  },
  label: {
    display: 'absolute',
    zIndex: 2,
    marginTop: -26,
    alignSelf: 'center',
    fontSize: '0.65rem',
    color: ColorPalette.LIGHT_GREY,
    backgroundColor: ColorPalette.CARD_WHITE,
    paddingLeft: 10,
    paddingRight: 10,
  },
}

export default Radium(CalenderRangeContained)
