export enum AggregationTypesEnum {
  COUNT = 'COUNT',
  VALUE = 'VALUE',
}

export enum OperationsEnum {
  BEGINSWITH = 'beginsWith',
  CONTAINS = 'contains',
  EQUALS = 'equals',
  EXISTS = 'exists',
  DOESNTEXIST = 'doesntExist',
  GREATTHANEQUALTO = 'greatThanEqualTo',
  HASCHANGED = 'hasChanged',
  LESSTHANEQUALTO = 'lessThanEqualTo',
}

export enum FilterTypeEnum {
  FIELD = 'field',
  DOCUMENT = 'document',
}

export enum PeriodTypeEnum {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export enum PeriodTypeLabelsEnum {
  TODAY = 'TODAY',
  LAST_WEEK = 'LAST WEEK',
  LAST_MONTH = 'LAST MONTH',
  LAST_3_MONTHS = 'LAST 3 MONTHS',
  LAST_6_MONTHS = 'LAST 6 MONTHS',
  LAST_12_MONTHS = 'LAST 12 MONTHS',
  CUSTOM_RANGE = 'CUSTOM RANGE',
}
