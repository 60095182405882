import React, { Component } from 'react'
import Radium from 'radium'
import { connect } from 'react-redux'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

import { GridSize } from '@material-ui/core/Grid'

import { ColorPalette } from '../../config/colors'
import { ActionType } from '../../store/actions/actions'
import { toLowerCaseCustom } from '../../utils'
import { NavMenuAccess, ScreenNamesEnum } from '../../types'
import completedScreens from '../completedScreens'
import { ConfigService, SessionService } from '../../services'
import NavBar from '../../components/Navigation/NavBar'
import SectionHeader from '../../components/Headings/SectionHeaderPrimary'
import LoadingModal from '../../components/Modals/LoadingModal'
import AlertModalOneButton from '../../components/Modals/AlertModalOneButton'
import SideMenu from '../../components/Navigation/SideMenu'
import ProfileNavMenu from '../../components/GeneralUI/ProfileNavMenu/ProfileNavMenu'
import Timeline from '../../components/GeneralUI/Timeline/Timeline'
import DataLogBlock from '../../components/Logs/DataLogBlock'
import ButtonGeneric from '../../components/BaseComponents/Buttons/ButtonGeneric'
import { PeopleFlowCombinedReducer } from '../../store'
import { Profile } from '../../models'
import { RouteComponentProps } from 'react-router'
import { AssociationSettingsRepository, UsersRepository } from '../../repositories'
import { PeopleRouteState, EmploymentStatus } from '../../types'
import { fetchChangeLogs } from '../../providers/remoteDataProvider'
import { IamServiceErrorCodesEnum } from '../../enums'

const { Unauthorised } = IamServiceErrorCodesEnum
const configService = new ConfigService()
const sessionService = new SessionService()

interface IndividualLogsProps extends RouteComponentProps {
  selectedAssociation: string
  selectedEmploymentStatus: string
  idPassport: string
  password: string
  associationRepo: AssociationSettingsRepository
  profile: Profile
  currentScreen: ScreenNamesEnum
  profilePic: string
  // hasWorkforceAccess: boolean
  navMenuAccess: NavMenuAccess
  userRepo: UsersRepository

  updateState: (data: any) => void
  changeScreen: (screen: string) => void
}

interface IndividualLogsState {
  loadingModalOpen: boolean
  warningModalOpen: boolean
  accountModalOpen: boolean
  forgotPasswordModalOpen: boolean
  settingsModalOpen: boolean
  loadingModalMessage: string
  warningModalHeader: string
  warningModalMessage: string
  sideMenuComponents: JSX.Element
  sideMenuVisible: boolean
  tableWidth: GridSize
  logs: any[]
  currentBatchNumber: number
  totalBatches: number
  isFirstPage: boolean
}

class IndividualLogs extends Component<IndividualLogsProps, IndividualLogsState> {
  initialModalState = {
    loadingModalOpen: false,
    warningModalOpen: false,
    accountModalOpen: false,
    forgotPasswordModalOpen: false,
    settingsModalOpen: false,
  }

  state: IndividualLogsState = {
    ...this.initialModalState,
    loadingModalMessage: '',
    warningModalHeader: '',
    warningModalMessage: '',
    sideMenuComponents: <div />,
    sideMenuVisible: true,
    tableWidth: 9,
    logs: [],
    currentBatchNumber: 0,
    totalBatches: 0,
    isFirstPage: true,
  }

  componentDidMount() {
    try {
      this.props.updateState({
        currentSection: ScreenNamesEnum.PEOPLE,
        currentScreen: ScreenNamesEnum.LOGS,
      })
      const { name, surname, idPassport } = this.props.profile.getPersonalInfo()

      if (!idPassport) {
        sessionService.logout()
        this.props.history.push('/login')
        return
      }

      this.initialiseSideMenu(name, surname, idPassport)
      this.refreshLogs(true)
    } catch (error) {
      this.closeModals()
    }
  }

  closeModals = () => {
    this.setState({ ...this.initialModalState })
  }

  initialiseSideMenu = (name: string, surname: string, idPassport: string) => {
    const { userRepo, selectedAssociation, selectedEmploymentStatus } = this.props
    const iamEntity = userRepo.getCurrentUserEntity().getAssocationIamEntity(selectedAssociation)
    if (!iamEntity) {
      throw { code: Unauthorised }
    }
    const profileNavMenuAccess = configService.generateProfileNavMenuConfig(
      iamEntity,
      selectedEmploymentStatus as EmploymentStatus,
    )
    const sideMenuComponents = (
      <ProfileNavMenu
        profilePic={this.props.profilePic}
        name={`${name} ${surname}`}
        idPassport={idPassport}
        onClick={(screen: string) => this.sideMenuHandler(screen)}
        currentScreen={this.props.currentScreen}
        accessFlags={profileNavMenuAccess}
      />
    )
    this.props.updateState({ profileNavMenuAccess })
    this.setState({ sideMenuComponents })
  }

  sideMenuHandler(screen: string) {
    if (completedScreens.includes(screen)) {
      this.props.updateState({ currentScreen: screen })
      this.props.history.push(`/people/${toLowerCaseCustom(screen.split(' ').join(''))}`)
    } else {
      this.props.history.push('/comingsoon')
    }
  }

  createLogsComponents(logs: any[]): JSX.Element[] {
    return logs.map((logItem, index: number) => {
      return (
        <DataLogBlock
          mode={'individual'}
          downloadedToDevice={logItem.downloadedToDevice}
          downloadedEverywhere={logItem.downloadedEverywhere}
          logData={logItem}
          key={`individual_${index}`}
        />
      )
    })
  }

  async refreshLogs(showLoadingModal: boolean) {
    try {
      if (showLoadingModal) {
        this.setState({
          loadingModalOpen: true,
          loadingModalMessage: 'Refreshing change logs',
        })
      }
      const { selectedAssociation, idPassport, password, associationRepo, profile } = this.props
      const fieldConfig = associationRepo.getFieldConfig(selectedAssociation)
      const token = await SessionService.prepareAuthTokens(idPassport, password)
      const obj: { logs: any[]; currentBatchNumber: number } = await fetchChangeLogs(
        [],
        selectedAssociation,
        idPassport,
        fieldConfig,
        null,
        profile.getIdPassport(),
        profile.getPk(),
        profile.getUid(),
        token,
      )
      const logComponents = this.createLogsComponents(obj.logs)
      this.setState({
        ...this.initialModalState,
        logs: logComponents,
        currentBatchNumber: obj.currentBatchNumber,
        totalBatches: obj.currentBatchNumber,
      })
    } catch (error) {
      this.displayWarning(error)
    }
  }

  async loadMoreLogs(direction: string) {
    try {
      if (this.state.currentBatchNumber > 0 || direction === 'previous') {
        this.setState({
          loadingModalOpen: true,
          loadingModalMessage: 'Loading more logs...',
        })

        const { selectedAssociation, idPassport, password, associationRepo, profile } = this.props
        const fieldConfig = associationRepo.getFieldConfig(selectedAssociation)
        const token = await SessionService.prepareAuthTokens(idPassport, password)
        let { logs, currentBatchNumber } = await fetchChangeLogs(
          [],
          selectedAssociation,
          idPassport,
          fieldConfig,
          this.state.currentBatchNumber + (direction === 'next' ? -1 : 1),
          profile.getIdPassport(),
          profile.getPk(),
          profile.getUid(),
          token,
        )
        logs = this.createLogsComponents(logs)
        this.setState(
          {
            ...this.initialModalState,
            logs,
            currentBatchNumber,
            isFirstPage: false,
          },
          this.forceUpdate,
        )
      }
    } catch (error) {
      this.displayWarning(error)
    }
  }

  goToProfiles = () => {
    this.closeModals()
    window.scrollTo(0, 0)
    const prevRouteState = this.props.location.state || {}
    setTimeout(() => this.props.history.push('/people', prevRouteState), 30)
  }

  displayWarning(error: any) {
    let warning = ''

    try {
      if (error.code === 'NetworkError') {
        warning = 'Seems like your internet connection is down. Reconnect to the network, then try again.'
      } else if ('message' in error) {
        warning =
          "The following error message was returned when logging in:\n\n'" +
          error.message +
          "'. \n\nRefresh the page and try again. If unsuccessful, then contact tech support"
      } else {
        warning = 'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
      }
    } catch (error) {
      warning = 'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
    }

    this.setState({
      ...this.initialModalState,
      warningModalOpen: true,
      warningModalHeader: 'Warning',
      warningModalMessage: warning,
    })
  }

  render() {
    const { hasDataLogsAccess } = this.props.navMenuAccess
    return (
      <div style={styles.container}>
        <NavBar match={this.props.match} location={this.props.location} history={this.props.history} />

        <SectionHeader
          style={styles.sectionHeader}
          disabled={true}
          onClick={() => this.setState({ sideMenuVisible: false, tableWidth: 10 })}>
          {this.props.currentScreen}
        </SectionHeader>

        <div style={styles.contentContainer}>
          <SideMenu visible={this.state.sideMenuVisible} menuComponents={this.state.sideMenuComponents} />
          <div style={styles.rightSide}>
            <ButtonGeneric
              style={{
                ...styles.backButton,
                width: (window.innerWidth * (this.state.tableWidth as number)) / 12,
              }}
              iconBefore={<ChevronLeftIcon style={styles.buttonIconStyle} />}
              label={'BACK'}
              onClick={this.goToProfiles}
            />
            <div style={styles.rightSideContent}>
              <Timeline
                logs={this.state.logs}
                shouldPaginate={this.state.currentBatchNumber === 0 ? false : true}
                isFirstPage={this.state.currentBatchNumber === this.state.totalBatches ? true : false}
                isLastPage={this.state.currentBatchNumber === 1 ? true : false}
                loadPrevious={() => this.loadMoreLogs('previous')}
                loadNext={() => this.loadMoreLogs('next')}
              />
            </div>
          </div>
        </div>

        <AlertModalOneButton
          open={this.state.warningModalOpen}
          header={this.state.warningModalHeader}
          body={this.state.warningModalMessage}
          buttonLabel={'Ok'}
          onClick={this.closeModals}
        />
        <LoadingModal open={this.state.loadingModalOpen}>{this.state.loadingModalMessage}</LoadingModal>
        <AlertModalOneButton
          open={!hasDataLogsAccess}
          header={'Not Authorised'}
          body={"You don't have permission to view profile logs."}
          buttonLabel={'Ok'}
          opaqueBackground={true}
          onClick={() => this.props.history.goBack()}
        />
      </div>
    )
  }
}

const styles: Record<string, React.CSSProperties | Record<string, React.CSSProperties>> = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundImage: `linear-gradient(to bottom, ${ColorPalette.SCREEN_TOP_GRADIENT}, ${ColorPalette.SCREEN_BOTTOM_GRADIENT})`,
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 1.5%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column',
    paddingInline: 'max(2em, 2%)',
    overflow: 'hidden',
  },
  rightSideContent: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    display: 'flex',
    flex: 1,
    backgroundColor: ColorPalette.CARD_WHITE,
  },
  buttonIconStyle: {
    color: ColorPalette.PRIMARY_TEXT,
    width: '1.2rem',
    height: '1.2rem',
  },
  backButton: {
    fontWeight: '550',
    fontSize: '0.8rem',
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
    },
    ':active': {
      color: ColorPalette.DARK_GREY,
    },
  },
}

const mapStateToProps = (state: PeopleFlowCombinedReducer) => {
  return {
    idPassport: state.sessionManager.idPassport,
    password: state.sessionManager.password,
    associationRepo: state.sessionManager.associationRepo as AssociationSettingsRepository,
    selectedAssociation: state.sessionManager.selectedAssociation,
    currentScreen: state.sessionManager.currentScreen,
    profile: state.sessionManager.profile,
    profilePic: state.sessionManager.profilePic,
    navMenuAccess: state.sessionManager.navMenuAccess,
    userRepo: state.sessionManager.userRepo as UsersRepository,

    // hasWorkforceAccess: state.sessionManager.hasWorkforceAccess,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateState: (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data }),
    changeScreen: (screen: string) => dispatch({ type: ActionType.CHANGE_SCREEN, data: { currentScreen: screen } }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Radium(IndividualLogs))
