import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import Radium from 'radium'
import Icon from '@mdi/react'
import { mdiArrowRightBoldCircle, mdiInformation, mdiArchiveCheck, mdiDownload } from '@mdi/js'

import DataTable from '../../components/Tables/DataTable/DataTable'
import NavBar from '../../components/Navigation/NavBar'
import SectionHeader from '../../components/Headings/SectionHeaderPrimary'
import LoadingModal from '../../components/Modals/LoadingModal'
import { ColorPalette } from '../../config/colors'
import { ActionType } from '../../store/actions/actions'
import { PeopleFlowCombinedReducer } from '../../store'
import { CommonTs } from '../../models/common-ts/commonts'
import { Toolbar } from '../../components/GeneralUI/Toolbar/Toolbar'
import { ImportExportService } from '../../services'
import { removeUnderScores, toSentenceCase } from '../../utils'
import AlertModalOneButton from '../../components/Modals/AlertModalOneButton'
import { AssociationId, AuthCredentials, NavMenuAccess } from '../../types'
import { ProfileRepository } from '../../repositories'
import { ProfileService } from '../../services'

const columnConfig = [
  { id: 'id', label: 'ID / Passport', sizeFactor: 1 },
  { id: 'firstName', label: 'Name', sizeFactor: 1 },
  { id: 'surname', label: 'Surname', sizeFactor: 1 },
  { id: 'intendedChange', label: 'Intended Change', sizeFactor: 1 },
  { id: 'terminationDate', label: 'Date', sizeFactor: 1 },
]

const importExportService = new ImportExportService()
const profileService = new ProfileService()

interface BulkTerminationsProps extends RouteComponentProps {
  selectedAssociation: string
  navMenuAccess: NavMenuAccess
  idPassport: string
  password: string
  profileRepo: ProfileRepository
}

type RouteState = {
  records: CommonTs.Import.IImportPlan[]
  csvFileName: string
}
enum ProgressStateEnum {
  INITIALISING = 'INITIALISING',
  REVIEW_PLAN = 'REVIEW_PLAN',
  // PROCESSING_COMPLETE = 'PROCESSING_COMPLETE',
}
const { INITIALISING, REVIEW_PLAN } = ProgressStateEnum

interface BulkTerminationsState {
  progressState: ProgressStateEnum
  loadingModalOpen: boolean
  loadingModalMessage: string
  warningModalOpen: boolean
  warningModalHeader: string
  warningModalMessage: string
  records: CommonTs.Import.IImportPlan[]
  applyResults: any[]
  csvFileName: string
  searchString: string
  selectedRowItemId: string
  isFinishedProcessing: boolean
  planResultTableData: Record<string, any>[]
  validSelectionIds: string[]
  selectedResultsTableData: Record<string, any>[]
}

class BulkTerminations extends Component<BulkTerminationsProps, BulkTerminationsState> {
  sectionHeaderRef: React.RefObject<any>
  primaryTableRef: React.RefObject<DataTable>

  constructor(props: BulkTerminationsProps) {
    super(props)
    this.sectionHeaderRef = React.createRef()
    this.primaryTableRef = React.createRef()
  }

  initialModalState = {
    loadingModalOpen: false,
    warningModalOpen: false,
  }

  state: BulkTerminationsState = {
    ...this.initialModalState,
    progressState: INITIALISING,
    loadingModalMessage: '',
    records: [],
    applyResults: [],
    searchString: '',
    selectedRowItemId: '',
    csvFileName: (this.props.location.state as RouteState).csvFileName,
    isFinishedProcessing: false,
    planResultTableData: [],
    validSelectionIds: [],
    selectedResultsTableData: [],
    warningModalHeader: '',
    warningModalMessage: '',
  }

  componentDidMount() {
    const routeState = this.props.location.state as RouteState
    const { records } = routeState
    const planResultTableData = this.packagePlanResultTableData(records)
    const validSelectionIds = planResultTableData.filter((row) => row.disableSelect === false).map((row) => row.id)
    this.setState({ progressState: REVIEW_PLAN, records, planResultTableData, validSelectionIds })
  }

  closeModals() {
    this.setState({ ...this.initialModalState })
  }

  searchHandler(event: React.ChangeEvent<{ value: string }>) {
    this.setState({ searchString: event.target.value })
    if (!this.primaryTableRef?.current) {
      return
    }
    this.primaryTableRef.current.search(event.target.value)
  }

  packagePlanResultTableData(records: CommonTs.Import.IImportPlan[]) {
    return records.map(({ current, problems, record }: CommonTs.Import.IImportPlan) => {
      let blockingProblemsFound = problems.length > 0
      let warningMessage = ''
      if (blockingProblemsFound) {
        warningMessage = `${toSentenceCase(problems[0].key)}; ${removeUnderScores(problems[0].reason)}`
      }

      const problemWithExistingTerminationDateIndex = problems.findIndex(
        (problem: any) => problem.key === 'employmentStatus' && problem.reason === 'CORRUPT_EXISTING_TERMINATION_STATE',
      )
      if (problemWithExistingTerminationDateIndex > -1) {
        blockingProblemsFound = problems.length > 1 // ignoring the existing termination date problem
        if (!blockingProblemsFound) {
          warningMessage = 'Record with existing termination date which will be overwritten.'
        } else {
          warningMessage = `${toSentenceCase(problems[1].key)}; ${removeUnderScores(problems[1].reason)}`
        }
      }

      const idPassportProblem = problems.find((problem: any) => problem.key === 'idPassport')
      if (!current || idPassportProblem) {
        if (idPassportProblem && idPassportProblem.reason === 'PROFILE_DOES_NOT_EXIST') {
          warningMessage = 'Unknown ID/Passport; profile does not exist in the system.'
        } else {
          warningMessage = `${problems[0].key}; ${removeUnderScores(problems[0].reason)}`
        }
        return {
          id: record.generalData.idPassport,
          firstName: '',
          surname: '',
          disableSelect: blockingProblemsFound,
          onHoverMessage: warningMessage || null,
        }
      }

      let rightComponent = <div style={styles.noInfoIcon}></div>
      if (problems.length > 0) {
        rightComponent = (
          <div style={styles.infoIcon}>
            <Icon path={mdiInformation} color={ColorPalette.OFF_WHITE} size={0.75} />
          </div>
        )
      }
      return {
        id: current.generalData.idPassport,
        firstName: current.generalData.name,
        surname: current.generalData.surname,
        intendedChange: 'Employee > Candidate',
        terminationDate: record.generalData.terminationDate,
        disableSelect: blockingProblemsFound,
        onHoverMessage: problems.length > 0 ? warningMessage : null,
        rightComponent,
      }
    })
  }

  getTerminationChangesToApply(records: CommonTs.Import.IImportPlan[], validSelectionIds: string[]) {
    return validSelectionIds.flatMap((idPassport: string) => {
      return records
        .filter((r) => {
          return r.current && r.current.generalData.idPassport === idPassport
        })
        .map((r) => {
          return {
            idPassport,
            id: r.current?.idPassport,
            firstName: r.current?.generalData.name || '',
            surname: r.current?.generalData.surname || '',
            intendedChange: 'Employee > Candidate',
            terminationDate: r.current?.generalData.terminationDate || '',
            uid: r.current?.uid || '',
            patchWithBefore: r.patchWithBefore || [],
          }
        })
    })
  }

  processTerminations = async (
    profileRepo: ProfileRepository,
    records: CommonTs.Import.IImportPlan[],
    validSelectionIds: string[],
    selectedAssociation: AssociationId,
    authCredentials: AuthCredentials,
  ) => {
    try {
      this.setState({ loadingModalOpen: true, loadingModalMessage: 'Processing terminations...' })
      const selectedResultsTableData = this.getTerminationChangesToApply(records, validSelectionIds)
      await Promise.all(
        [...selectedResultsTableData].map(async (change) => {
          return await importExportService.applyProfileChange(
            selectedAssociation,
            change as CommonTs.Import.ProfileChange,
            authCredentials,
          )
        }),
      )
      this.setState({
        // selectedResultsTableData,
        loadingModalMessage: 'Fetching updated profiles...',
      })
      const idPassportsToFetch = selectedResultsTableData.map((change) => change.id || '')
      await profileService.downloadProfiles(profileRepo, selectedAssociation, idPassportsToFetch, authCredentials)
      this.setState({
        loadingModalOpen: false,
        warningModalOpen: true,
        warningModalHeader: 'Done',
        warningModalMessage: 'Terminations processed successfully.',
      })
    } catch (error) {
      this.errorHandler(error)
    }
  }

  errorHandler = (error: any) => {
    let warningModalHeader = 'Problem'
    let warningModalMessage =
      'We experienced a problem. Reload all profiles and try again. If the problem persists, then reach out to tech support.'
    this.setState({ loadingModalOpen: false, warningModalOpen: true, warningModalHeader, warningModalMessage })
  }

  getToolbarNavButtons = (
    profileRepo: ProfileRepository,
    records: CommonTs.Import.IImportPlan[],
    validSelectionIds: string[],
    progressState: ProgressStateEnum,
    authCredentials: AuthCredentials,
    selectedAssociation: AssociationId,
    isFinishedProcessing: boolean,
  ) => {
    let toolbarNavButtonConfig = {} as Record<string, any>
    if (progressState === REVIEW_PLAN) {
      toolbarNavButtonConfig = {
        left: { onClick: this.handleBackNavButtonClick },
        right: {
          onClick: () =>
            this.processTerminations(profileRepo, records, validSelectionIds, selectedAssociation, authCredentials),
        },
      }
    }
    // if (progressState === PROCESSING_COMPLETE) {
    //   if (isFinishedProcessing) {
    //     toolbarNavButtonConfig = {
    //       right: { onClick: () => this.goBackToProfiles() },
    //     }
    //   } else {
    //     toolbarNavButtonConfig = {
    //       left: { onClick: this.handleBackNavButtonClick },
    //     }
    //   }
    // }
    return toolbarNavButtonConfig
  }

  goBackToProfiles = () => {
    this.props.history.push('/people/employees')
  }

  handleBackNavButtonClick = () => {
    // this.setState({ progressState: REVIEW_PLAN })
    this.props.history.push('/people/employees', {
      // @ts-ignore
      openTerminationModal: this.props.history.location.state?.via === 'bulkActions', // trigger termination file upload if we came via bulk actions
    })
  }

  render() {
    const {
      progressState,
      planResultTableData,
      selectedRowItemId,
      validSelectionIds,
      records,
      selectedResultsTableData,
      isFinishedProcessing,
      loadingModalOpen,
      loadingModalMessage,
      warningModalOpen,
      warningModalHeader,
      warningModalMessage,
    } = this.state
    const { password, idPassport, navMenuAccess, selectedAssociation, profileRepo } = this.props
    const { hasBulkTerminationAccess } = navMenuAccess
    const authCredentials = { username: idPassport, password }
    const toolbarNavButtons = this.getToolbarNavButtons(
      profileRepo,
      records,
      validSelectionIds,
      progressState,
      authCredentials,
      selectedAssociation,
      isFinishedProcessing,
    )

    let contentArea = null
    if (progressState === REVIEW_PLAN) {
      contentArea = (
        <DataTable
          key={'scan_results_table'}
          ref={this.primaryTableRef}
          tableData={planResultTableData}
          columnConfig={columnConfig}
          tableWidth={10}
          filterState={undefined}
          onRowClick={(rowData: Record<string, any>) => this.setState({ selectedRowItemId: rowData.id })}
          selectedRowItemId={selectedRowItemId}
          customFilterConfig={[]}
          filterModeEnabled={false}
          selectionEnabled={false}
          disabled={true}
          customComponentConfig={{ rightComponentWidth: 40 }}
          initialSelectedRows={validSelectionIds}
        />
      )
    }
    // else if (progressState === PROCESSING_COMPLETE) {
    //   contentArea = (
    //     <DataTable
    //       key={'processing_results_table'}
    //       tableData={selectedResultsTableData}
    //       columnConfig={columnConfig}
    //       tableWidth={10}
    //       filterState={undefined}
    //       onRowClick={() => null}
    //       customFilterConfig={[]}
    //       filterModeEnabled={false}
    //       selectionEnabled={false}
    //       selectedRowItemId=""
    //       customComponentConfig={{
    //         rightComponentWidth: 40,
    //       }}
    //       applyCustomRowStyle={(rowId: string) => ({
    //         backgroundColor: ColorPalette.PRIMARY_BLUE_TRANSPARENT,
    //       })}
    //       disabled={true}
    //     />
    //   )
    // }

    return (
      <div style={{ ...styles.container }}>
        <NavBar
          match={this.props.match}
          location={this.props.location}
          history={this.props.history}
          primaryTableRef={this.primaryTableRef} // destined for SubMenu via prop drilling
        />
        <SectionHeader
          ref={this.sectionHeaderRef}
          style={styles.sectionHeader}
          downloading={false}
          searchString={this.state.searchString}
          textHandler={(e) => this.searchHandler(e)}
          onClick={() => null}>
          Bulk Terminations
        </SectionHeader>

        <div style={styles.contentContainer}>
          <div style={{ ...styles.main, paddingInline: 'min(5em, 5%)' }}>
            <Toolbar navButtons={toolbarNavButtons} />
            <div style={styles.mainContent}>{contentArea}</div>
          </div>
        </div>
        <AlertModalOneButton
          // TODO: There should only be one AlertModalOneButton component per screen with the required headers, body, and functions being set accordingly by a handler.
          open={!hasBulkTerminationAccess}
          header={'Not Authorised'}
          body={"You don't have permission to access the bulk termination module."}
          buttonLabel={'Ok'}
          opaqueBackground={true}
          onClick={() => this.props.history.goBack()}
        />
        <AlertModalOneButton
          open={warningModalOpen}
          header={warningModalHeader}
          subHeader={warningModalMessage}
          buttonLabel={'Ok'}
          opaqueBackground={true}
          onClick={() => this.goBackToProfiles()}
        />
        <LoadingModal open={loadingModalOpen}>{loadingModalMessage}</LoadingModal>
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundImage: `linear-gradient(to bottom, ${ColorPalette.SCREEN_TOP_GRADIENT}, ${ColorPalette.SCREEN_BOTTOM_GRADIENT})`,
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 1.5%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  main: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    width: '100%',
    overflow: 'hidden',
  },
  mainContent: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    display: 'flex',
    flex: 1,
    backgroundColor: ColorPalette.CARD_WHITE,
    overflow: 'auto',
  },
  tableNavButtonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  toolbarButton: {
    width: 180,
    fontWeight: 'bolder' as 'bolder',
    fontSize: '0.8rem',
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
    },
    ':active': {
      color: ColorPalette.DARK_GREY,
    },
  },
  infoIcon: {
    backgroundColor: 'rgba(220, 20, 60, 0.25)',
    width: 40,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noInfoIcon: {
    borderBottom: `1px solid ${ColorPalette.VERY_LIGHT_GREY}`,
    width: 40,
    height: '100%',
  },
}

const mapStateToProps = (state: PeopleFlowCombinedReducer) => {
  return {
    idPassport: state.sessionManager.idPassport,
    password: state.sessionManager.password,
    selectedAssociation: state.sessionManager.selectedAssociation,
    navMenuAccess: state.sessionManager.navMenuAccess,
    profileRepo: state.sessionManager.profileRepo as ProfileRepository,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateState: (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Radium(BulkTerminations))
