import { isEmpty } from 'lodash'
import { execLoadHooks, PeopleFlowCombinedReducer } from '../store'

export class ReduxRepository {
  saveStateSnapshot(stateObj: PeopleFlowCombinedReducer) {
    if (!stateObj || !stateObj.sessionManager || !stateObj.sessionManager.currentSection) {
      return
    }
    localStorage.setItem('stateSnapshot', JSON.stringify(stateObj))
  }

  loadStateSnapshot() {
    const state = localStorage.getItem('stateSnapshot')
    if (!state) {
      return {}
    }
    const stateObj = JSON.parse(state)
    if (isEmpty(state)) {
      return {}
    }
    localStorage.removeItem('stateSnapshot')
    return execLoadHooks(stateObj)
  }
}
