import React from 'react'
import { GridSize } from '@material-ui/core'

import { ColorPalette } from '../../../config/colors'
import DocumentRowItem from './DocumentRowItem'
import { UserFeedback } from '../../GeneralUI/Feedback/UserFeedback'
import { IFileMeta } from '../../../interfaces'

interface DocumentListProps {
  folderContents: Record<string, IFileMeta[]>
  tableWidth: GridSize
  expandedFolders: string[]
  folderSelectionEnabled?: boolean
  selectedFolders?: string[]
  emptyFolderIconColor?: string
  hideMetaData?: boolean
  onDocClicked: (docname: string) => void
  expandCollapseFolder: (folder: string) => void
  folderSelectionHandler?: (categoryName: string) => void
}

const DocumentList: React.FC<DocumentListProps> = (props: DocumentListProps) => {
  const folderSelectionHandler = (folder: string) => {
    if (props.folderSelectionHandler === undefined) {
      return
    }
    props.folderSelectionHandler(folder)
  }

  const getDocItemsToRender = () =>
    folders.map((folder, i) => {
      let metaData: string = 'None to view'
      let selectedFolderContents = props.folderContents[folder]
      if (selectedFolderContents.length > 0) {
        metaData = selectedFolderContents[0].metaData || ''
      }
      if (props.hideMetaData) {
        metaData = ''
      }
      return (
        <DocumentRowItem
          key={`documenttablebody_rowitem_${i}`}
          tableWidth={props.tableWidth}
          style={{ marginRight: '0px' }}
          folder={folder}
          metaData={metaData}
          docConfig={props.folderContents[folder]}
          expandedFolders={props.expandedFolders}
          folderSelectionEnabled={props.folderSelectionEnabled}
          folderSelected={props.selectedFolders?.includes(folder)}
          folderSelectionHandler={() => folderSelectionHandler(folder)}
          expandCollapseFolder={() => props.expandCollapseFolder(folder)}
          emptyFolderIconColor={props.emptyFolderIconColor}
          onDocClicked={(docname: string) => props.onDocClicked(docname)}
        />
      )
    })

  let folders = Object.keys(props.folderContents).sort()
  if (!folders.length) {
    return (
      <div style={{ ...styles.promptContainer }}>
        <UserFeedback message="No configured documents in category" />
      </div>
    )
  }

  const docItems = getDocItemsToRender()

  return <div style={{ margin: '0px' }}>{docItems}</div>
}

const styles = {
  promptContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    shadowOpacity: 0.16,
    shadowRadius: 4,
    shadowOffset: {
      width: 0,
      height: 6,
    },
  },
  text: {
    color: ColorPalette.PRIMARY_LIGHT_TEXT,
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    fontSize: 15,
  },
}

export default DocumentList
