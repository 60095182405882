export enum FormComponentType {
  TEXT = 'text',
  TEXTAREA = 'textArea',
  SELECTOR = 'selector',
  CHECKBOX = 'checkbox',
  CHECKBOX_GROUP = 'checkboxGroup',
  RADIO_GROUP = 'radioGroup',
  DATE = 'date',
}

export type FormComponent = {
  type: FormComponentType
  label: string
  value: string
  onChange: (value: string) => void
}
