export interface IErrorContent {
  header: string
  body: string
}

export function extractWarning(error: any, action?: string): IErrorContent {
  let body = ''
  let head = 'Warning'
  if ('code' in error) {
    switch (error.code) {
      case 'InvalidOtp':
        body = 'Your OTP must be 6 digits'
        break

      case 'InvalidIdPassport':
        body = 'Enter your ID / Passport number'
        break

      case 'InvalidPassword':
        body = 'Your new password is invalid'
        break

      case 'CodeMismatchException':
        body = 'The OTP you entered is invalid'
        break

      case 'LimitExceededException':
        body = 'Retry limit reached. Try again in a while.'
        break

      case 'NetworkError':
        body = 'Seems like your internet connection is down. Reconnect to the network, then try again.'
        break

      case 'InvalidDocConfig':
        body = "This document isn't configured correctly. Reach out to PeopleFlow tech support for assistance."
        break

      case 'FetchDocConfigsError':
        body =
          'There was a problem fetching your document templates from the database. Reach out to PeopleFlow tech support for assistance.'
        break

      case 'UpdateDocConfigError':
        body = 'There was a problem updating your document template. Refresh that page and try again.'
        break

      case 'ComingSoon':
        head = 'Coming soon'
        body = 'This feature will be available soon.'
        break

      case 'Expired':
        head = 'Expired'
        body =
          'This item has expired. You may only take disciplinary action on the latest unactioned offence. \n\n(This ensures that your employees are timeously warned and that they are given an opportunity to rectify their behaviour between warnings).'
        break

      case 'ProfileFormatError':
        body = 'It looks like there is a problem with this profile. Contact tech support to look into this for you.'
        break

      case 'NoDocumentSelected':
        head = 'Select a document'
        body = 'Select a document'
        break

      case 'Exists':
        body = 'There is already an active case for this offence.'
        break

      default:
        body = 'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
    }
  }

  if ('message' in error) {
    body =
      `The following error message was returned${action ? ' while ' + action : ''}:\n\n'` +
      error.message +
      "'. \n\nRefresh the page and try again. If unsuccessful, then contact tech support"
  }

  return { body: body, header: head }
}

export function extractErrorCode(error: any): string {
  try {
    const errorCode = error.response.data.code
    return errorCode
  } catch (err) {}
  return ''
}

export function getErrorMessage(error: unknown) {
  if (error instanceof Error) return error.message
  return String(error)
}
