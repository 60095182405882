import Radium from 'radium'
import Icon from '@mdi/react'
import { Property } from 'csstype'

import { ColorPalette } from '../../config'
import { ScreenNamesEnum, SubSubMenuSchema } from '../../types'

interface Props {
  parentScreen: ScreenNamesEnum
  menuOptions: SubSubMenuSchema
  onSubSubMenuClick: (subSubMenuName: ScreenNamesEnum, hasAccess?: boolean) => void
}

const SubSubMenu = (props: Props) => {
  const { parentScreen, menuOptions } = props
  return (
    <ul style={{ marginTop: 4, marginBottom: 0, paddingLeft: 15, listStyleType: 'none' }}>
      {menuOptions[parentScreen].map(({ iconName, label, screenName, hasAccess }) => {
        const listItemKey = `${parentScreen}_${label}`
        return (
          <li style={{ paddingBottom: 5, paddingTop: 3, fontSize: '0.85em', fontWeight: 'bolder' }} key={listItemKey}>
            <div
              style={{ ...styles.subSubMenuItem, opacity: hasAccess ? 0.9 : 0.4 }}
              onClick={() => props.onSubSubMenuClick(screenName, hasAccess)}
              key={`listItemDiv_${listItemKey}`}>
              {iconName && <Icon size={0.7} path={iconName} color={ColorPalette.PRIMARY_BLUE} />}
              <div
                style={{
                  paddingLeft: 8,
                  color: ColorPalette.PRIMARY_TEXT,
                  opacity: 0.9,
                  fontWeight: '400',
                  fontSize: '0.75rem',
                }}>
                {label}
              </div>
            </div>
          </li>
        )
      })}
    </ul>
  )
}

const styles = {
  subSubMenuItem: {
    display: 'flex',
    alignItems: 'center',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: 'rgba(255,255,255,0)',
    borderBottomWidth: 2,
    borderBottomStyle: 'solid' as 'solid',
    borderBottomColor: 'rgba(255,255,255,0)',
    paddingBottom: 5,
    ':hover': {
      borderBottomColor: ColorPalette.PRIMARY_BLUE,
    },
    cursor: 'pointer',
  },
}

export default Radium(SubSubMenu)
