import { useState } from 'react'

import NewGraphButton from './NewGraphButton'
import { TrendList } from './TrendList'
import { TrendGraph } from './TrendGraph'
import { UserFeedback } from '../../GeneralUI/Feedback/UserFeedback'
import { DataQueryGraphConfigurator } from '../../Modals/DataQuery'
import { QueryTrend, TrendConfig, QueryResult, FieldConfigKey, TrendId } from '../../../types'
import { IColumnConfig } from '../../Tables/DataTable/DataTableHeader'

type DataQueryTrendsProps = {
  profileTableColumnConfig: IColumnConfig[]
  profileFilterConfig: { key: string; label: string }[]
  trendConfigList: TrendConfig[]
  allTrendData: QueryTrend[]
  aggregationOptions: FieldConfigKey[]
  profileTableRowData: QueryResult[]
  saveTrendConfig: (trendConfig: TrendConfig) => void
}

export const DataQueryTrends = (props: DataQueryTrendsProps) => {
  const [selectedTrendConfig, setSelectedTrendConfig] = useState({} as TrendConfig | undefined)
  const [graphConfiguratorModalOpen, setGraphConfiguratorModalOpen] = useState(false)

  const {
    trendConfigList,
    profileTableColumnConfig,
    profileFilterConfig,
    profileTableRowData,
    aggregationOptions,
    saveTrendConfig,
  } = props

  const setTrendConfig = (trendId: TrendId = '') => {
    const trendConfig = trendConfigList.find((trend) => trend.id === trendId)
    setSelectedTrendConfig(trendConfig)
  }

  function packageTrendData(targetTrendDataItem: QueryTrend) {
    let xValues = [] as any[]
    let yValues = [] as any[]
    targetTrendDataItem.trendData.forEach((item: any) => {
      xValues.push(item.x)
      yValues.push(item.y)
    })
    if (targetTrendDataItem.warningFieldLabels && targetTrendDataItem.warningFieldLabels.length) {
      return { xValues, yValues, warningFieldLabels: targetTrendDataItem.warningFieldLabels }
    }
    return { xValues, yValues }
  }

  let feebackMessage = null
  let trendGraph = null
  if (selectedTrendConfig) {
    const selectedTrendId = selectedTrendConfig.id
    const targetTrendDataItem = props.allTrendData.find((trend) => trend.id === selectedTrendId)
    if (targetTrendDataItem) {
      const { xValues, yValues, warningFieldLabels } = packageTrendData(targetTrendDataItem)
      trendGraph = (
        <TrendGraph
          selectedTrendId={selectedTrendConfig?.id || ''}
          selectedTrendName={selectedTrendConfig.trendName}
          xValues={xValues}
          yValues={yValues}
          warningFieldLabels={warningFieldLabels}
          openTrendConfigurator={() => setGraphConfiguratorModalOpen(true)}
        />
      )
    }
  } else {
    if (trendConfigList.length) {
      feebackMessage = <UserFeedback message="Select a trend to view" style={{ paddingBottom: '4em' }} />
    } else {
      feebackMessage = <UserFeedback message="No trends configured" style={{ paddingBottom: '4em' }} />
    }
  }

  let graphConfiguratorModal = null
  if (graphConfiguratorModalOpen) {
    graphConfiguratorModal = (
      <DataQueryGraphConfigurator
        profileTableColumnConfig={profileTableColumnConfig}
        profileFilterConfig={profileFilterConfig}
        aggregationOptions={aggregationOptions}
        trendConfig={selectedTrendConfig}
        profileTableRowData={profileTableRowData}
        onCancelClick={() => setGraphConfiguratorModalOpen(false)}
        onDoneClick={(trendConfig) => saveTrendConfig(trendConfig)}
        key={`graphConfig_${selectedTrendConfig?.id}`}
      />
    )
  }

  return (
    <div
      // @ts-ignore
      style={styles.containerStyle}
      key={`graph_container`}>
      <div style={{ height: '40px' }} />
      <div style={styles.graphView}>
        <NewGraphButton
          onClick={() => {
            setTrendConfig()
            setGraphConfiguratorModalOpen(true)
          }}
        />
        <TrendList
          trendConfigList={props.trendConfigList}
          selectedTrendId={selectedTrendConfig?.id || ''}
          onClick={(trendId) => setTrendConfig(trendId)}
        />
      </div>
      {feebackMessage}
      {trendGraph}
      {graphConfiguratorModal}
    </div>
  )
}

const styles = {
  containerStyle: {
    height: '100%',
    width: '88%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column' as 'column',
  },
  section: {
    width: '100%',
    height: '100%',
    margin: '1em 0',
    paddingBottom: '20px',
  },
  graphView: {
    display: 'flex',
    alignItems: 'center',
    gap: 20,
  },
  noGraphsContainer: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}
