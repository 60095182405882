import { CommonTs } from '../../models/common-ts/commonts'
import { httpPatch } from '../remoteDataProvider'

export async function updateProfile(association: string, change: CommonTs.Import.ProfileChange, token: string) {
  const payload = { patchWithBefore: change.patchWithBefore }
  const { data } = await httpPatch(
    'people_flow_core',
    `/employees/association/${association}/profile/${change.id}/uid/${change.uid}`,
    payload,
    token,
  )
  console.log('data: ', data)
  return data
}
