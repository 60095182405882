import { AssociationId, FieldConfigKey, PpeSupplierName } from '../../types'
import { PpeRequestRecordSk } from './PpeRequestRecord'

export type PpeInstanceRecordPk = string // group#<association>#<cohortId>
export type PpeInstanceRecordSk = string // id
export type PpeType = string
export type PpeSize = string
export type BatchId = string
export enum PpeAvailability {
  AVAILABLE = 'AVAILABLE',
  RESERVED = 'RESERVED',
  WRITEOFF = 'WRITEOFF',
}
export type PurchaseOrderId = string
export type PpeSku = string
export type PpeQuantity = number
export type PpeInstanceBaseKeys = {
  sku: PpeSku
  ppeSize: PpeSize
  ppeKey: string
  label: string
  association: AssociationId
  purchaseOrderId: PurchaseOrderId
  batchId: BatchId
  supplier: PpeSupplierName
  dateActioned: string
}
export type NewPpeInstanceParams = PpeInstanceBaseKeys & { qty: PpeQuantity }

export type PpeInstanceRecordSchema = {
  pk?: PpeInstanceRecordPk
  sk?: PpeInstanceRecordSk
  availability: PpeAvailability
  batchId: BatchId
  sku: PpeSku
  ppeKey: FieldConfigKey
  ppeSize: PpeSize
  requestHistory: PpeRequestRecordSk[]
}
export class PpeInstanceRecord {
  pk?: PpeInstanceRecordPk
  sk?: PpeInstanceRecordSk
  availability: PpeAvailability
  batchId: BatchId
  sku: PpeSku
  ppeKey: FieldConfigKey
  ppeSize: PpeSize
  requestHistory: PpeRequestRecordSk[]

  constructor(data: Record<string, any>) {
    this.pk = data.pk
    this.sk = data.sk
    this.availability = data.availability
    this.batchId = data.batchId
    this.sku = data.ppeSku
    this.ppeKey = data.ppeKey
    this.ppeSize = data.ppeSize
    this.requestHistory = data.requestHistory
  }

  getPk = () => {
    return this.pk || ''
  }

  getSk = () => {
    return this.sk || ''
  }

  getAvailability = () => {
    return this.availability || ''
  }

  getBatchId = () => {
    return this.batchId || ''
  }

  getSku = () => {
    return this.sku || ''
  }

  getPpeKey = () => {
    return this.ppeKey || ''
  }

  getPpeSize = () => {
    return this.ppeSize || ''
  }

  getRequestHistory = () => {
    return this.requestHistory || []
  }
}
