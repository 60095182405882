import { removeUnderScores, toUpperCaseCustom } from '../../utils'
import { TimeBasedDocStatus } from '../../types'
import { TimeBasedDocStatusEnum } from '../../enums'
import { TimeBasedDocInstance, TimeBasedDocInstanceFactory } from './index'

export class TimeBasedDocs {
  private docData: TimeBasedDocInstance[] = []
  constructor(json: any) {
    if (json === undefined) {
      return
    }
    this.docData = this.initialise(json)
  }

  initialise(json: Record<string, any>[]): TimeBasedDocInstance[] {
    return json.map((jsonItem) => TimeBasedDocInstanceFactory.create(jsonItem))
  }

  getAllDocs(): TimeBasedDocInstance[] {
    return this.docData
  }

  getAllDocsTypes(): string[] {
    let allDocTypes = [] as string[]
    this.getAllDocs().forEach((docInstance) => {
      const docType = docInstance.getCompetency()
      if (!allDocTypes.includes(docType)) {
        allDocTypes.push(docType)
      }
    })
    allDocTypes.sort()
    return allDocTypes
  }

  getDocsInCategory(category: TimeBasedDocStatus): TimeBasedDocInstance[] {
    const categorisedDocs = this.getCategorisedDocsByCategory()
    return categorisedDocs[category]
  }

  getCategorisedDocsByCategory(): Record<string, TimeBasedDocInstance[]> {
    let categories = {} as Record<string, TimeBasedDocInstance[]>
    this.getAllDocs().forEach((docInstance) => {
      const status = docInstance.getStatus()
      if (!categories[status]) {
        categories[status] = [] as TimeBasedDocInstance[]
      }
      categories[status].push(docInstance)
    })
    return categories
  }

  getCategorisedDocsByDocType(): Record<string, TimeBasedDocInstance[]> {
    let categories = {} as Record<string, TimeBasedDocInstance[]>
    this.getAllDocs().forEach((docInstance) => {
      const docType = docInstance.getCompetency()
      if (!categories[docType]) {
        categories[docType] = [] as TimeBasedDocInstance[]
      }
      categories[docType].push(docInstance)
    })
    return categories
  }

  getCategorisedDocStats(): Record<string, any> {
    const categorisedDocs = this.getCategorisedDocsByDocType()
    let docStats = {} as Record<string, any>
    Object.keys(categorisedDocs).forEach((docType) => {
      if (!docStats[docType]) {
        docStats[docType] = {}
      }
      categorisedDocs[docType].forEach((docInstance) => {
        let status = removeUnderScores(docInstance.getStatus())
        if (toUpperCaseCustom(status) === toUpperCaseCustom(TimeBasedDocStatusEnum["VALID_(CAN'T_EXPIRE)"])) {
          // VALID (CAN'T EXPIRE) status gets translated to VALID
          status = 'VALID'
        }
        if (!docStats[docType][status]) {
          docStats[docType][status] = 0
        }
        docStats[docType][status] += 1
      })
    })
    return docStats
  }

  getCategorisedDocsByPerson(): Record<string, TimeBasedDocInstance[]> {
    let categories = {} as Record<string, TimeBasedDocInstance[]>
    this.getAllDocs().forEach((docInstance) => {
      const { idPassport } = docInstance.getMetaData()
      if (!categories[idPassport]) {
        categories[idPassport] = [] as TimeBasedDocInstance[]
      }
      categories[idPassport].push(docInstance)
    })
    return categories
  }

  cloneSubset(filterFunc: (doc: TimeBasedDocInstance) => boolean) {
    const docData = []
    for (const doc of this.docData) {
      if (filterFunc !== undefined && !filterFunc(doc)) {
        continue
      }
      docData.push(doc)
    }
    return TimeBasedDocFactory.create(docData)
  }
}

export class TimeBasedDocFactory {
  static create(json: any) {
    if (json === undefined) {
      json = []
    }
    return new TimeBasedDocs(json)
  }
}
