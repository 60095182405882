export class ColorPalette {
  public static HEADER_TOP: string = 'rgba(220,225,230,1)'
  public static HEADER_BOTTOM: string = 'rgba(196,205,235,1)'

  public static DARK_GREY: string = 'rgba(115,135,195,1)'
  public static DARK_GREY_TRANSPARENT: string = 'rgba(115,135,195,0.3)'
  public static DARK_MEDIUM_GREY: string = 'rgba(150,165,205,1)'
  public static DARK_MEDIUM_GREY_TRANSPARENT: string = 'rgba(150,165,205,0.3)'

  public static MEDIUM_GREY: string = 'rgba(175,190,225,1)'
  public static LIGHT_GREY: string = 'rgba(195,205,225,1)'
  public static VERY_LIGHT_GREY: string = 'rgba(225,225,240,1)'

  public static PRIMARY_TEXT: string = 'rgba(35,20,140,1)'
  public static SECONDARY_TEXT: string = 'rgba(150,150,175,1)'
  public static TERTIARY_TEXT: string = 'rgba(180,180,195,1)'
  public static DISABLED_TEXT: string = 'rgba(35,20,140,0.25)'
  public static PRIMARY_LIGHT_TEXT: string = 'rgba(255,255,255,1)'
  public static SECONDARY_LIGHT_TEXT: string = 'rgba(250,250,250,1)'

  public static WARNING_RED: string = 'rgba(255, 30, 120, 1)'
  public static WARNING_RED_50PERCENT: string = 'rgba(255, 30, 100, 0.5)'
  public static WARNING_RED_TRANSPARENT: string = 'rgba(255, 30, 100, 0.1)'
  public static CARD_WHITE: string = 'rgba(253,253,255,1)'
  public static BUTTON_WHITE: string = 'rgba(250,250,250,1)'
  public static DRAWER_WHITE: string = 'rgba(250,250,252,1)'
  public static OFF_WHITE: string = 'rgba(245,245,250,1)'
  public static OFF_WHITE_LIGHT: string = 'rgba(248,248,252,1)'
  public static PRIMARY_BLUE: string = 'rgba(0,200,255,1)'
  public static PRIMARY_BLUE_GRADIENT_LIGHT: string = 'rgba(0,200,255,0.95)'
  public static PRIMARY_BLUE_TRANSPARENT: string = 'rgba(0,200,255,0.3)'
  public static SECONDARY_BLUE: string = 'rgba(0,180,235,1)'
  public static SECONDARY_BLUE_GRADIENT_LIGHT: string = 'rgba(0,180,235,0.95)'

  public static SCREEN_BACKGROUND: string = 'rgba(245,245,250,1)'

  public static DISABLED_SCREEN_OVERLAY: string = 'rgba(220,220,240, 0.35)'

  public static MODAL_BACKGROUND_OVERLAY: string = 'rgba(220,220,235, 0.8)'

  public static BUTTON_HOVER_GREY: string = 'rgba(242,242,250,1)'
  public static BUTTON_ACTIVE_GREY: string = 'rgba(222,222,230,1)'

  public static SCREEN_TOP_GRADIENT: string = 'rgba(255,255,255,1)'
  public static SCREEN_BOTTOM_GRADIENT: string = 'rgba(209,210,230,1)'
}
