import { IdPassport } from './common'
import { FieldConfig } from './fieldConfigTypes'
import { PpeTypeUpsert } from './ppe'
import { Username } from './ubiquitousTypes'

type CognitoAttribute = {
  Name: string
  Value: string
}

export type CognitoUser = {
  Username: Username
  UserAttributes?: CognitoAttribute[]
  UserCreateDate?: string
  UserLastModifiedDate?: string
  Enabled?: boolean
  UserStatus?: string
}

export enum CognitoMessageDeliveryMedium {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}

export type ConfigType = 'processConfig' | 'fieldConfig' | 'organisationConfig' | 'dataFlow'

export type ConfigChanges = {
  editedBy: IdPassport
  updatePath: string[]
  updatedData: boolean | string[] | {}[] | Record<string, any> | PpeTypeUpsert | {}
  updatedMs: number
}[]

export type ConfigPayload = {
  selectedAssociation: string
  configType: ConfigType
  changes: ConfigChanges
  dataFlowName?: string
}

export type ConfigUpdateResponse = {
  data: {
    result: 'success' | 'unsuccessful' | 'error'
    updatedConfig: FieldConfig
  }
}

export type AsyncTaskResponse = {
  data: {
    result: string
    payload: any
  }
}

export type DocConfigUpdateResponse = {
  data: {
    result: string
    newDoc: any
  }
}

export type DocVerificationTrackerUpdateResponse = {
  data: {
    result: string
    trackers: any[]
  }
}

export type DocExportTriggerResponse = {
  data: {
    result: string
    asyncTask: any
  }
}

export type DocAuditReportResponse = {
  data: {
    result: string
    records?: any[]
  }
}

export type LoginResponse = {
  result: string
  userProfile: any
  clientSettings: Record<string, any>
}
