import { Component } from 'react'
import Radium from 'radium'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { mdiAlertCircle } from '@mdi/js'

import { removeUnderScores, toUpperCaseCustom } from '../../utils'
import LabelCollector from '../Modals/LabelCollector'
import TextInputOutlined from '../BaseComponents/Text/TextInputOutlined'
import { SideMenuOptions } from './SideMenuOptions'
import { ColorPalette } from '../../config/colors'
import { SideMenuTitle } from './SideMenuTitle'

dayjs.extend(relativeTime)

interface IDisciplinaryOffencesSideMenuProps {
  selectedOffence: string
  selectedDivision: string
  selectedJobType: string
  selectedJobSubType: string
  offenceConfig: any
  organisationConfig: any
  onOffenceClick: (dataField: string) => void
  onFieldChange: (stateChangeObj: {}) => void
  onNewOffenceSave: (newOffenceName: string) => void
}

interface IDisciplinaryOffencesSideMenuState {
  addPositionModalOpen: boolean
  selectedJobType: string
  selectedJobSubType: string
  searchString: string
}

class DisciplinaryOffencesSideMenu extends Component<
  IDisciplinaryOffencesSideMenuProps,
  IDisciplinaryOffencesSideMenuState
> {
  state = {
    addPositionModalOpen: false,
    selectedJobType: '',
    selectedJobSubType: '',
    searchString: '',
  }

  saveNewField = (newFieldName: string) => {
    const key = newFieldName.split(' ').join('_').toUpperCase()
    this.props.onNewOffenceSave(key)
    this.closeModal()
  }

  closeModal = () => this.setState({ addPositionModalOpen: false })

  searchHandler = (e: any) => {
    this.setState({ searchString: toUpperCaseCustom(e.target.value) })
  }

  render() {
    const { offenceConfig, selectedJobType, selectedOffence, onOffenceClick } = this.props

    const defaultOffence = 'All Offences'
    let offences = Object.keys(offenceConfig).sort()

    if (this.state.searchString.length) {
      offences = offences.filter((offence) => offence.toUpperCase().includes(this.state.searchString))
    }
    offences.unshift(defaultOffence)

    return (
      <div style={{ ...styles.container, height: '100%' }}>
        <div style={styles.container}>
          <SideMenuTitle>Disciplinary Offences</SideMenuTitle>
          <TextInputOutlined
            style={styles.textStyle}
            label="SELECTED JOB POSITION"
            placeholder="Selected job position"
            value={selectedJobType}
            textHandler={() => null}
            disabled={true}
          />
          <TextInputOutlined
            style={{ ...styles.textStyle, marginTop: 30 }}
            label="OFFENCE SEARCH"
            placeholder="Search for offence"
            value={this.state.searchString}
            textHandler={this.searchHandler}
            disabled={false}
          />
          <div style={styles.dividerLine} />
        </div>
        <SideMenuOptions
          addButtonLabel="Add offence"
          addButtonClick={() => this.setState({ addPositionModalOpen: true })}
          options={offences}
          optionLabel={(offence: string) => (
            <div
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              title={offence}>
              {removeUnderScores(offence)}
            </div>
          )}
          optionMdiIconPath={mdiAlertCircle}
          optionClick={onOffenceClick}
          optionStyle={(offence: string) => ({
            fontSize: 'smaller',
            maxWidth: '100%',
            backgroundColor:
              selectedOffence === offence ? ColorPalette.BUTTON_ACTIVE_GREY : ColorPalette.OFF_WHITE_LIGHT,
          })}
          optionIconStyle={{ flexShrink: 0 }}
        />
        <LabelCollector
          open={this.state.addPositionModalOpen}
          warning="Enter offence name"
          placeholder="Enter offence name"
          buttonLabel="Add"
          iconName="setting"
          dismiss={this.closeModal}
          submit={this.saveNewField}
        />
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    width: '100%',
  },
  dividerLine: {
    width: '86%',
    marginTop: 30,
    borderBottom: `1px solid ${ColorPalette.LIGHT_GREY}`,
  },
  pickerStyle: {
    margin: '0 auto',
    minWidth: 120,
    width: '72%',
    backgroundColor: ColorPalette.CARD_WHITE,
    color: ColorPalette.PRIMARY_TEXT,
  },
  textStyle: {
    color: ColorPalette.PRIMARY_TEXT,
    padding: '0px 12px 10px 12px',
    marginTop: 20,
    minWidth: 120,
    width: '80%',
  },
}

export default Radium(DisciplinaryOffencesSideMenu)
