import React, { CSSProperties, Component } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import { GridSize } from '@material-ui/core/Grid'
import Radium from 'radium'
import { isEmpty, get } from 'lodash'

import { DataTableRow } from './DataTableRow'
import { ColorPalette } from '../../../config/colors'

interface PrimaryTableProps {
  data: any[]
  disableRowClick?: boolean
  selectionEnabled?: boolean
  customComponentConfig: any
  tableWidth: GridSize
  selectedRows: string[]
  selectedRowItemId: string
  combinedSizeFactor: number
  columnConfig: any
  customFilterConfig?: { key: string; label: string }[]
  trimText?: boolean
  disabled?: boolean
  rowHeight?: number
  currentCellInputFocus?: string[]
  style?: React.CSSProperties
  applyCustomRowStyle?: (rowId: string) => React.CSSProperties | { ':hover': React.CSSProperties }
  onRowSelect: (rowData: Record<string, any>) => void
  onClick: (selectedrow: Record<string, any>) => void
  onCellInputChange?: (rowId: string, itemId: string, value: string) => void
  onCellInputFocus?: (rowId: string, itemId: string) => void
}

const selectedRowColour = 'rgba(232,232,240,1)'
const hoverRowColour = 'rgb(220, 20, 60, 0.125)'

class PrimaryTableBody extends Component<PrimaryTableProps> {
  buildCheckBox = (checkboxWidth: number, isSelected: boolean, rowId: string, onChange: () => any) => {
    return (
      <div
        key={`dataTable_checkbox_filter_container_${rowId}`}
        style={{ ...styles.addonContainer, width: checkboxWidth, flexShrink: 0 }}>
        <Checkbox
          style={{
            color: isSelected ? ColorPalette.PRIMARY_BLUE : ColorPalette.TERTIARY_TEXT,
          }}
          checked={isSelected}
          onChange={onChange}
          inputProps={{ 'aria-label': 'Select all' }}
          key={`dataTable_checkbox_filter_${rowId}`}
        />
      </div>
    )
  }

  getRowColor = (isSelected: boolean, onHoverMessage?: string, disableRowClick?: boolean) => {
    if (onHoverMessage) {
      return hoverRowColour
    }
    if (isSelected) {
      return selectedRowColour
    }
    if (!disableRowClick) {
      return ColorPalette.BUTTON_HOVER_GREY
    }
    return ColorPalette.CARD_WHITE
  }

  buildRowStyle = (
    isSelected: boolean,
    rowHeight: number = 42,
    onHoverMessage?: string,
    disableRowClick?: boolean,
    disabled?: boolean,
  ) => {
    let rowStyle = {
      ...styles.rowStyle,
      backgroundColor: isSelected ? selectedRowColour : ColorPalette.CARD_WHITE,
      height: rowHeight || 42,
    }
    if (!disabled) {
      rowStyle = {
        ...rowStyle,
        color: ColorPalette.SECONDARY_TEXT,
        // @ts-ignore
        ':hover': {
          backgroundColor: this.getRowColor(isSelected, onHoverMessage, disableRowClick),
        },
      }
    }
    return rowStyle
  }

  buildRow = (rowData: any, i: number, props: PrimaryTableProps) => {
    const checkboxWidth = props.selectionEnabled ? 45 : 0
    const leftContainerWidth = get(props.customComponentConfig, 'leftComponentWidth', 0)
    const rightContainerWidth = get(props.customComponentConfig, 'rightComponentWidth', 0)
    const customFilterWidth = props.customFilterConfig && props.customFilterConfig.length ? 40 : 0
    const buttonWidth =
      (window.innerWidth * (props.tableWidth as number)) / 12 -
      checkboxWidth -
      customFilterWidth -
      leftContainerWidth -
      rightContainerWidth

    let checkbox = null
    if (props.selectionEnabled) {
      const selectedRow = props.selectedRows.hasOwnProperty(rowData.id)
      checkbox = this.buildCheckBox(checkboxWidth, selectedRow, rowData.id, () => props.onRowSelect(rowData))
    }

    let customFilter = null
    if (props.customFilterConfig && props.customFilterConfig.length) {
      customFilter = <div key="customFilter" style={{ ...styles.addonContainer, width: customFilterWidth }} />
    }

    const isRowActive = rowData.id === props.selectedRowItemId
    let rowStyle = this.buildRowStyle(
      isRowActive,
      props.rowHeight,
      rowData.onHoverMessage,
      props.disableRowClick,
      props.disabled,
    )

    if (props.applyCustomRowStyle) {
      const customRowStyle = props.applyCustomRowStyle(rowData.id)
      if (!isEmpty(customRowStyle)) {
        // @ts-ignore
        rowStyle = {
          ...rowStyle,
          ...customRowStyle,
        }
      }
    }
    let rowContainerStyle: CSSProperties = {
      ...styles.rowContainer,
      height: props.rowHeight || 42,
    }
    if (props.selectionEnabled) {
      rowContainerStyle.borderBottom = `1px solid ${ColorPalette.VERY_LIGHT_GREY}`
    } else {
      rowContainerStyle.borderBottom = rowStyle.borderBottom
    }

    return (
      <DataTableRow
        buttonWidth={buttonWidth}
        checkbox={checkbox}
        columnConfig={props.columnConfig}
        combinedSizeFactor={props.combinedSizeFactor}
        customFilter={customFilter}
        disabled={props.disabled}
        leftComponent={
          <div key={'leftComponent'} style={{ ...styles.addonContainer, width: leftContainerWidth }}>
            {get(rowData, 'leftComponent', null)}
          </div>
        }
        rightComponent={
          <div
            key={'rightComponent'}
            style={{
              ...styles.addonContainer,
              width: rightContainerWidth,
            }}>
            {get(rowData, 'rightComponent', null)}
          </div>
        }
        rowData={rowData}
        currentCellInputFocus={props.currentCellInputFocus}
        onCellInputChange={props.onCellInputChange}
        onCellInputFocus={props.onCellInputFocus}
        onClick={props.onClick}
        rowStyle={rowStyle}
        style={rowContainerStyle}
        key={`${i}_${rowData.id}`}
      />
    )
  }

  buildTableRows = (props: PrimaryTableProps) => {
    return props.data.map((rowData, i) => this.buildRow(rowData, i, props))
  }

  render() {
    const tableRows = this.buildTableRows(this.props)
    return <div style={{ height: 80, ...this.props.style }}>{tableRows}</div>
  }
}

const rowheight = 42
const styles = {
  rowContainer: {
    display: 'flex',
    height: rowheight,
    backgroundColor: ColorPalette.CARD_WHITE,
  },
  rowStyle: {
    height: rowheight,
    padding: 0,
    backgroundColor: ColorPalette.CARD_WHITE,
    color: ColorPalette.SECONDARY_TEXT,
    borderBottom: `1px solid ${ColorPalette.VERY_LIGHT_GREY}`,
    ':active': {
      backgroundColor: ColorPalette.BUTTON_ACTIVE_GREY,
    },
  },
  innerRowContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  addonContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

export default Radium(PrimaryTableBody)
