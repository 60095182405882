import { AssociationId, FieldConfigKey } from '../../types'
import { toValidPfDate } from '../../utils'
import { EmploymentStatus, TrashStatus } from '../common-ts/ProfileDocument'
import PFDoc from '../pfDoc/pfDoc'

export type ProfilePk = string

export class Profile {
  private pk: ProfilePk = ''
  private uid: string = ''
  private idPassport: string = ''
  private docs: Record<string, string[]> = {}
  private faceShots: Record<string, any>
  private cohorts: string[]
  private trashStatus: TrashStatus = TrashStatus.NONE
  private adHocDocs: Record<string, any>
  private adHocData: Record<string, any>
  private competencies: Record<string, any>
  private employment: any
  private ppe: any
  private discipline: Record<string, any>
  private generalData: Record<string, any>
  private updatedMs: Record<string, number>

  constructor(obj: Record<string, any>, pk?: string) {
    this.pk = pk || obj.idPassport
    this.uid = obj.uid
    this.idPassport = obj.idPassport
    this.docs = obj.docs || {}
    this.faceShots = obj.faceShots || {}
    this.cohorts = obj.cohorts
    this.trashStatus = obj.hasOwnProperty('_obj') ? obj._obj.trashStatus : obj.trashStatus
    this.adHocDocs = obj.adHocDocs || {}
    this.adHocData = obj.adHocData || {}
    this.generalData = obj.generalData || {}
    this.discipline = obj.discipline || {}
    this.competencies = obj.competencies || {}
    this.ppe = obj.ppe || {}
    this.updatedMs = obj.updatedMs || {}
  }

  updateGeneralData(newData: Record<string, any>) {
    for (const [k, v] of Object.entries(newData)) {
      this.generalData[k] = v
    }
  }

  deleteKeys(...keys: string[]): void {
    for (const key of keys) {
      delete (this as any)[key]
    }
  }

  getDisciplineData(selectedAssociation: string, inflateToEventBasedProfile?: boolean): Record<string, any> {
    return this.discipline.getSelectedDisciplineData(selectedAssociation)
  }

  getFaceShot(): any /*FaceShot*/ {
    return this.faceShots
  }

  getPersonalInfo(): { name: string; surname: string; idPassport: string } {
    return {
      name: this.generalData.name,
      surname: this.generalData.surname,
      idPassport: this.generalData.idPassport,
    }
  }

  getGeneralData(): any {
    return this.generalData || {}
  }

  getGeneralDataValue(fieldKey: FieldConfigKey, fallBackValue: any = ''): any {
    const generalData = this.getGeneralData()
    if (generalData.hasOwnProperty(fieldKey)) {
      return generalData[fieldKey]
    } else {
      return fallBackValue
    }
  }

  getPk(): ProfilePk {
    return this.pk
  }

  getUid(): string {
    return this.uid
  }

  getIdPassport(): string {
    return this.generalData.idPassport || ''
  }

  getCompetencies(): Record<string, any> {
    return this.competencies
  }

  getFileNameRecord(fileName: string, association: AssociationId) {
    // TODO: Convert these records to model entities
    if (this.adHocDocs?.[association]) {
      for (const record of this.adHocDocs[association]) {
        if (record.docs.includes(fileName)) {
          const recordType = 'adHocDocs'
          return { record: this.recordNormaliser(record, recordType), recordType }
        }
      }
    }
    if (this.competencies?.history) {
      for (const record of this.competencies?.history) {
        if (record.docs.includes(fileName)) {
          const recordType = 'competencies'
          return { record: this.recordNormaliser(record, recordType), recordType }
        }
      }
    }
    if (this.employment?.history) {
      for (const record of this.employment.history) {
        if (record.docs.includes(fileName)) {
          const recordType = 'employment'
          return { record: this.recordNormaliser(record, recordType), recordType }
        }
      }
    }
    if (this.discipline?.[association]?.disciplineHistory) {
      for (const record of this.discipline[association].disciplineHistory) {
        if (record.docs.includes(fileName)) {
          const recordType = 'discipline'
          return { record: this.recordNormaliser(record, recordType), recordType }
        }
      }
    }
    return { record: null, recordType: '' }
  }

  recordNormaliser(record: Record<string, any>, recordType: string) {
    let { rawData = {} } = record
    if (recordType === 'competencies') {
      if (rawData.hasOwnProperty('competencyIssuedDate') && !rawData.docValidityStartDate) {
        rawData = { ...rawData, docValidityStartDate: toValidPfDate(rawData.competencyIssuedDate || 0) }
      }
      if (rawData.hasOwnProperty('competencyExpiryDate') && !rawData.docValidityExpiryDate) {
        rawData = { ...rawData, docValidityExpiryDate: toValidPfDate(rawData.competencyExpiryDate || 0) }
      }
    }
    if (recordType === 'discipline') {
      if (rawData.hasOwnProperty('discActionIssuedDate') && !rawData.docValidityStartDate) {
        rawData = {
          ...rawData,
          docValidityStartDate: toValidPfDate(rawData.discActionIssuedDate || 0),
          discActionIssuedDate: toValidPfDate(rawData.discActionIssuedDate || 0),
        }
      }
      if (rawData.hasOwnProperty('expiry') && !rawData.docValidityExpiryDate) {
        rawData = {
          docValidityExpiryDate: toValidPfDate(record.expiry),
          expiry: toValidPfDate(record.expiry),
        }
      }
      if (rawData.hasOwnProperty('discOffenceDate')) {
        rawData = { discOffenceDate: toValidPfDate(record.discOffenceDate) }
      }
    }
    return { ...record, rawData }
  }

  getAssociationFileNames(association: AssociationId) {
    if (!this.docs[association]) {
      return []
    }
    return this.docs[association]
  }

  getAssociationDocRecords(association: AssociationId) {
    const fileNames = this.getAssociationFileNames(association)
    return fileNames.map((fileName) => {
      const { record = { rawData: {} }, recordType } = this.getFileNameRecord(fileName, association)
      const rawData = record ? record.rawData : ({} as Record<string, any>)
      const docEntity = new PFDoc(association, this.getPk(), fileName, rawData)
      return docEntity
    })
  }

  getDocFileNameStrings(selectedAssociation: string = '', startTimestamp?: number, endTimestamp?: number): string[] {
    if (!selectedAssociation) {
      throw { code: 'MissingAssociationName' }
    }
    let clientDocs = this.docs[selectedAssociation] || [] // this.clientFileNameMap.get(selectedAssociation) || []
    clientDocs = clientDocs.filter((fileName: string) => {
      const timestamp = parseInt(fileName.split('___')[0])
      let startCriteriaMet = true
      let endCriteriaMet = true
      if (startTimestamp && timestamp < startTimestamp) {
        startCriteriaMet = false
      }
      if (endTimestamp && timestamp > endTimestamp) {
        endCriteriaMet = false
      }
      return startCriteriaMet && endCriteriaMet
    })
    return clientDocs
  }

  getEmploymentStatus() {
    return this.generalData.employmentStatus ?? EmploymentStatus.CANDIDATE
  }

  getOriginalField(key: string): string | string[] | undefined | number {
    return this.generalData.getOriginalField(key)
  }

  getTrashStatus() {
    return this.trashStatus
  }

  getPpe() {
    return this.ppe
  }

  getCohorts(): string[] {
    return this.cohorts
  }

  isPartOfCohort(cohortName: string): boolean {
    return this.cohorts?.includes(cohortName)
  }

  unlinkDocuments(selectedAssociation: string, selectedDocuments: string[]): void {
    let docs = this.docs[selectedAssociation] // this.clientFileNameMap.get(selectedAssociation)
    if (docs === undefined) {
      return
    }

    docs = docs.filter((fileName) => !selectedDocuments.includes(fileName))
    this.docs[selectedAssociation] = docs
  }

  toJson() {
    return {
      pk: this.pk,
      uid: this.uid,
      idPassport: this.idPassport,
      docs: this.docs,
      faceShots: this.faceShots,
      cohorts: this.cohorts,
      trashStatus: this.trashStatus,
      adHocDocs: this.adHocDocs,
      adHocData: this.adHocData,
      generalData: this.generalData,
      discipline: this.discipline,
      competencies: this.competencies,
      ppe: this.ppe,
      updatedMs: this.updatedMs,
    }
  }
}
