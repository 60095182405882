import { toUpperCaseCustom } from './stringUtils'

export namespace Regex {
  export const DIGITS_ONLY = /^[0-9]+$/
  export const EMAIL_FORMAT = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
  export const PASSWORD_FORMAT = /(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)[0-9a-zA-Z!@#$%^&*()]*$/
  export const REMOVE_SPACES = /\s\s+/g
  export const REMOVE_FORWARD_SLASH = /\//g
}

export function reformatUsername(idPassport: string): string {
  idPassport = toUpperCaseCustom(idPassport).replace(Regex.REMOVE_SPACES, '')
  idPassport = idPassport.replace(Regex.REMOVE_FORWARD_SLASH, '')
  return idPassport
}

export function checkPasswordFormat(password: string): boolean {
  return Regex.PASSWORD_FORMAT.test(password)
}

export function checkEmailFormat(email: string): boolean {
  return Regex.EMAIL_FORMAT.test(email)
}

export function checkDigitsOnly(digits: string): boolean {
  return Regex.DIGITS_ONLY.test(digits)
}
