import { CSSProperties } from 'react'
import ButtonGeneric from '../BaseComponents/Buttons/ButtonGeneric'
import { randomIdString } from '../../utils'
import { ColorPalette } from '../../config/colors'

import Icon from '@mdi/react'
import { mdiPlusCircle, mdiCog, mdiChevronDown, mdiHardHat, mdiSync } from '@mdi/js'

interface ISideMenuOptionsProps {
  addButtonLabel?: string
  addButtonMdiIconPath?: string
  addButtonIconStyle?: CSSProperties
  options: string[]
  optionMdiIconPath?: string | ((optionName: string) => string)
  optionButtonLabelStyle?: CSSProperties
  optionIconStyle?: CSSProperties
  expandableOptionData?: Record<string, string[]>
  optionCustomIcon?: (optionName: string) => JSX.Element | JSX.Element
  addButtonClick?: () => void
  optionLabel: (optionName: string) => string | JSX.Element
  optionStyle: (optionName: string) => CSSProperties
  optionClick: (optionName: string) => void
  optionButtonRefCallBack?: (optionName: string, el: HTMLDivElement) => void
}

export const SideMenuOptions = (props: ISideMenuOptionsProps) => {
  const {
    addButtonLabel,
    addButtonMdiIconPath = mdiPlusCircle,
    addButtonIconStyle = {},
    options,
    optionMdiIconPath = mdiCog,
    optionIconStyle = {},
    addButtonClick,
    optionLabel,
    optionCustomIcon,
    optionClick,
    optionStyle,
    optionButtonRefCallBack,
  } = props

  const optionButtonLabelStyle = {
    overflow: 'hidden',
    marginRight: 15,
    whiteSpace: 'nowrap' as 'nowrap',
    textOverflow: 'ellipsis',
    ...props.optionButtonLabelStyle,
  }

  const handleRefCallback = (el: HTMLDivElement, optionName: string) => {
    if (optionButtonRefCallBack) {
      optionButtonRefCallBack(optionName, el)
    }
  }

  const renderOption = (optionName: string) => {
    const buttonStyle = {
      ...styles.button,
      ...optionStyle(optionName),
    }

    let iconBefore = null
    if (optionCustomIcon) {
      iconBefore = optionCustomIcon(optionName)
    } else {
      iconBefore = (
        <Icon
          style={{ ...styles.buttonIcon, ...optionIconStyle }}
          path={typeof optionMdiIconPath === 'string' ? optionMdiIconPath : optionMdiIconPath(optionName)}
          color={ColorPalette.PRIMARY_BLUE}
        />
      )
    }

    return (
      <ButtonGeneric
        key={randomIdString()}
        style={buttonStyle}
        iconBefore={iconBefore}
        label={optionLabel(optionName) as string}
        labelStyle={optionButtonLabelStyle}
        onClick={() => optionClick(optionName)}
        refCallback={(el: HTMLDivElement) => handleRefCallback(el, optionName)}
      />
    )
  }

  const renderSubOption = (optionName: string) => (
    <ButtonGeneric
      key={randomIdString()}
      style={{
        ...styles.button,
        ...optionStyle(optionName),
      }}
      iconBefore={<div style={{ marginLeft: '2em', marginRight: '1em' }}>-</div>}
      label={optionLabel(optionName) as string}
      labelStyle={optionButtonLabelStyle}
      onClick={() => optionClick(optionName)}
      refCallback={(el: HTMLDivElement) => handleRefCallback(el, optionName)}
    />
  )

  const renderOptions = () => {
    let optionsToRender: any[] = []
    if (!!props.expandableOptionData) {
      optionsToRender = options.map((optionName: string) => {
        // @ts-ignore
        const expandableOptions = props.expandableOptionData[optionName]
        return (
          <div key={randomIdString()}>
            <ButtonGeneric
              style={{
                ...styles.button,
                ...optionStyle(optionName),
              }}
              iconBefore={
                <Icon
                  style={{ ...styles.buttonIcon, ...optionIconStyle }}
                  path={mdiHardHat}
                  color={ColorPalette.PRIMARY_BLUE}
                />
              }
              iconAfter={
                <Icon
                  style={{ ...styles.buttonIcon, ...optionIconStyle }}
                  path={mdiChevronDown}
                  color={ColorPalette.PRIMARY_BLUE}
                />
              }
              // @ts-ignore
              label={optionLabel(optionName)}
              labelStyle={optionButtonLabelStyle}
              onClick={() => optionClick(optionName)}
            />
            <div style={{ paddingLeft: 20 }}>
              {expandableOptions.map((expandableOptionName: string) => renderSubOption(expandableOptionName))}
            </div>
          </div>
        )
      })
    } else {
      optionsToRender = options.map((option) => renderOption(option))
    }
    return optionsToRender
  }

  let addButton = null
  if (addButtonClick) {
    addButton = (
      <ButtonGeneric
        key={randomIdString()}
        style={{
          ...styles.button,
          backgroundColor: 'transparent',
          color: ColorPalette.LIGHT_GREY,
        }}
        iconBefore={
          <Icon
            style={{ ...styles.buttonIcon, ...addButtonIconStyle }}
            path={addButtonMdiIconPath}
            color={ColorPalette.PRIMARY_BLUE}
          />
        }
        label={addButtonLabel}
        onClick={addButtonClick}
      />
    )
  }

  const optionsRender = renderOptions()

  return (
    <div
      style={{
        width: '100%',
        overflow: 'auto',
      }}>
      <div style={styles.addButtonContainer}>{addButton}</div>
      {optionsRender}
    </div>
  )
}

const styles = {
  button: {
    fontWeight: '550',
    fontSize: '0.8rem',
    backgroundColor: ColorPalette.CARD_WHITE,
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    paddingLeft: 30,
    marginTop: 0,
    ':hover': {
      backgroundColor: 'rgba(242,242,250,1)',
    },
    ':active': {
      backgroundColor: 'rgba(222,222,230,1)',
    },
  },
  buttonIcon: {
    minWidth: '1.5rem',
    height: '1.5rem',
    color: ColorPalette.PRIMARY_BLUE,
    marginRight: 15,
  },
  addButtonContainer: {
    paddingBottom: '0.5em',
    marginBottom: '0.5em',
    position: 'sticky' as 'sticky',
    top: 0,
    minHeight: 30,
    paddingTop: 15,
    backgroundColor: ColorPalette.CARD_WHITE,
    boxShadow: `0 6px 6px -4px ${ColorPalette.LIGHT_GREY}`,
  },
}
