import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { mdiCollapseAllOutline, mdiExport, mdiRestartAlert } from '@mdi/js'
import { isEmpty, pick, set } from 'lodash'

import NavBar from '../../components/Navigation/NavBar'
import SectionHeaderPrimary from '../../components/Headings/SectionHeaderPrimary'
import SideMenu from '../../components/Navigation/SideMenu'
import { Toolbar, ActionButtonType } from '../../components/GeneralUI/Toolbar'
import RouteLeavingGuard from '../../components/Navigation/RouteLeavingGuard'
import LoadingModal from '../../components/Modals/LoadingModal'
import AlertModalTwoButton from '../../components/Modals/AlertModalTwoButton'
import AlertModalOneButton from '../../components/Modals/AlertModalOneButton'
import { ColorPalette } from '../../config/colors'
import { PeopleFlowCombinedReducer } from '../../store'
import PpeRequestsSideMenu, { PpeRequestsSideMenuOption } from '../../components/SideMenus/PpeRequestsSideMenu'
import PpeRequestListing, { PpeRequestListingData } from '../../components/Requests/Ppe/PpeRequestListing'
import PpeRequestDetails, { PPERequestDetailsDataSchema } from '../../components/Requests/Ppe/PpeRequestDetails'
import { PpeService, SessionService } from '../../services'
import PpeRequestReturns from '../../components/Requests/Ppe/PpeRequestReturns'
import { StockConfirmationModalBody } from '../../components/Modals/Ppe/StockConfirmationModalBody'
import {
  checkEmailFormat,
  createActionRecord,
  getActionRelatedToInstance,
  getActionsRelatedToRequests,
  getInstancesRelatedToRequests,
  getPpeTypeLabelFromKey,
  getPpeTypesFromFieldConfig,
  toLowerCaseCustom,
} from '../../utils'
import { UserFeedback } from '../../components/GeneralUI/Feedback/UserFeedback'
import { AssociationSettingsRepository, UsersRepository } from '../../repositories'
import {
  PpeActionReasonType,
  PpeInstanceRecordSk,
  PpeRecordSk,
  PpeRequest,
  PpeRequestItem,
  PpeStatusType,
} from '../../types'
import { PpeActionRecordSchema, PpeInstanceRecordSchema } from '../../models/ppe'
import { PpeActionReason, PpeActions, PpeStatus } from '../../enums'
import InfoCollectorModal from '../../components/Modals/InfoCollector'

const initialModalState = {
  deleteConfirmModalOpen: false,
  saveFailedModalOpen: false,
}

type RequestsPpeProps = {
  match: any
  location: any
  history: any
}

const ppeService = new PpeService()

export const RequestsPpe = (props: RequestsPpeProps) => {
  const selectedAssociation = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation,
  )
  const userIdPassport = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.idPassport)
  const password = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.password)
  const associationRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.associationRepo,
  ) as AssociationSettingsRepository
  const userRepo = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.userRepo) as UsersRepository
  const navMenuAccess = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.navMenuAccess)

  const [selectedStatus, setSelectedStatus] = useState<PpeStatusType>(PpeStatus.READY_FOR_ISSUE)
  const [modalState, setModalState] = useState(initialModalState)
  const [allActions, setAllActions] = useState<Record<PpeRecordSk, PpeActionRecordSchema>>({})
  const [allInstances, setAllInstances] = useState<Record<PpeRecordSk, PpeInstanceRecordSchema>>({})
  const [allRequests, setAllRequests] = useState<Record<PpeRecordSk, PpeRequest>>({})
  const [selectedRequestDetailsToDisplay, setSelectedRequestDetailsToDisplay] = useState<PPERequestDetailsDataSchema[]>(
    [],
  )
  const [changesMade, setChangesMade] = useState(false)
  const [viewDetails, setViewDetails] = useState(false)
  const [selectedReturnItemIds, setSelectedReturnItemIds] = useState<string[]>([])
  const [returnToStockConfirmationModalOpen, setReturnToStockConfirmationModalOpen] = useState(false)
  const [removalFromStockConfirmationModalOpen, setRemovalFromStockConfirmationModalOpen] = useState(false)
  const [stockReturnSuccessModalOpen, setStockReturnSuccessModalOpen] = useState(false)
  const [stockRemovalSuccessModalOpen, setStockRemovalSuccessModalOpen] = useState(false)
  const [selectItemToReturnModalOpen, setSelectItemToReturnModalOpen] = useState(false)
  const [selectedReturnReason, setSelectedReturnReason] = useState('')
  const [otherReturnReason, setOtherReturnReason] = useState('')
  const [savingModalOpen, setSavingModalOpen] = useState(false)
  const [loadingModalOpen, setLoadingModalOpen] = useState(false)
  const [exportEmailCollectorModalOpen, setExportEmailCollectorModalOpen] = useState(false)
  const [exportEmailPendingModalOpen, setExportEmailPendingModalOpen] = useState(false)

  const user = userRepo.getCurrentUserEntity()

  useEffect(() => {
    getRequests()
  }, [])

  const toggleStockRemovalConfirmationModal = () => {
    if (removalFromStockConfirmationModalOpen === false && selectedReturnItemIds.length === 0) {
      setSelectItemToReturnModalOpen(true)
      return
    }
    setRemovalFromStockConfirmationModalOpen((currentValue) => !currentValue)
  }
  const toggleStockReturnConfirmationModal = () => {
    if (returnToStockConfirmationModalOpen === false && selectedReturnItemIds.length === 0) {
      setSelectItemToReturnModalOpen(true)
      return
    }
    setReturnToStockConfirmationModalOpen((currentValue) => !currentValue)
  }
  const toggleStockRemovalSuccessModal = () => {
    setStockRemovalSuccessModalOpen((currentValue) => !currentValue)
  }
  const toggleStockReturnSuccessModal = () => {
    setStockReturnSuccessModalOpen((currentValue) => !currentValue)
  }

  const getRequests = async () => {
    setLoadingModalOpen(true)
    const requests = await ppeService.getRequests(selectedAssociation, { username: userIdPassport, password })
    setAllActions(requests.actionRecordDtos)
    setAllInstances(requests.instanceRecordDtos)
    setAllRequests(requests.requestRecordDtos)
    setLoadingModalOpen(false)
  }

  const changeModalState = (newState: any) => setModalState((modalState) => ({ ...modalState, ...newState }))
  const closeModals = () => setModalState(initialModalState)
  const toggleSaveFailedModal = () => changeModalState({ saveFailedModalOpen: !modalState.saveFailedModalOpen })
  const toggleDeleteConfirmModal = () =>
    changeModalState({ deleteConfirmModalOpen: !modalState.deleteConfirmModalOpen })
  const toggleViewDetails = () => setViewDetails((viewDetails) => !viewDetails)
  const deleteSelectedQueryName = () => {
    toggleDeleteConfirmModal()
  }

  const getSideMenuSelectedStatus = (status: PpeStatusType): PpeRequestsSideMenuOption => {
    return {
      [PpeStatus.READY_FOR_ISSUE]: 'READY FOR ISSUE',
      [PpeStatus.ISSUING]: 'ISSUING',
      [PpeStatus.RETURN_IN_PROGRESS]: 'RETURN IN PROGRESS',
      [PpeStatus.COMPLETE]: 'COMPLETE',
    }[status] as PpeRequestsSideMenuOption
  }

  const getTranslatedSideMenuStatusSelection = (statusSelection: PpeRequestsSideMenuOption) => {
    return {
      'READY FOR ISSUE': PpeStatus.READY_FOR_ISSUE,
      ISSUING: PpeStatus.ISSUING,
      'RETURN IN PROGRESS': PpeStatus.RETURN_IN_PROGRESS,
      COMPLETE: PpeStatus.COMPLETE,
    }[statusSelection]
  }

  const getActionButtons = (processReturns: boolean) => {
    let actionButtons: ActionButtonType[] = [
      {
        label: 'EXPORT',
        iconPath: mdiExport,
        onClick: () => setExportEmailCollectorModalOpen(true),
        disabled: false,
        title: 'Export request data to email address(es)',
      },
    ]

    if (viewDetails && processReturns) {
      actionButtons.push({
        label: 'REMOVE FROM STOCK',
        onClick: toggleStockRemovalConfirmationModal,
        iconPath: mdiCollapseAllOutline,
        iconColor: ColorPalette.PRIMARY_BLUE,
        title: 'Remove selected items from stock',
      })
      actionButtons.push({
        label: 'RETURN TO STOCK',
        onClick: toggleStockReturnConfirmationModal,
        iconPath: mdiRestartAlert,
        iconColor: ColorPalette.PRIMARY_BLUE,
        title: 'Return selected items to stock',
      })
      // actionButtons.push({
      //   label: 'CLOSE REQUEST',
      //   onClick: toggleStockReturnConfirmationModal,
      //   iconPath: mdiRestartAlert,
      //   iconColor: ColorPalette.PRIMARY_BLUE,
      //   title: 'Return selected items to stock',
      // })
      return actionButtons
    }

    return actionButtons
  }

  const prepareRequestItemsForDisplay = (processReturns?: boolean) => {
    const data: PpeRequestItem[] = []
    selectedRequestDetailsToDisplay.forEach((record: any, index: number) => {
      const { sk, item, sku, size } = record
      data.push({
        // @ts-ignore  TODO: extend PpeRequestItem in new type
        id: sk, // id is needed for selectability on PPERequestReturns table
        item,
        sku,
        size,
        // qty,
      })
    })

    return data
  }

  const compileRequestDetailsToDisplay = (instance: PpeInstanceRecordSchema): PPERequestDetailsDataSchema => {
    const fieldConfig = associationRepo.getFieldConfig(selectedAssociation)
    const action = getActionRelatedToInstance(instance.sk || '', allActions)
    const ppeItemKeyLabelPairs = getPpeTypesFromFieldConfig(fieldConfig, ['key', 'label'])
    const itemLabel = getPpeTypeLabelFromKey(ppeItemKeyLabelPairs, instance.ppeKey)
    const requestedFor = `${action?.rawData.targetName} ${action?.rawData.targetSurname}` || '?'
    return {
      sk: instance.sk,
      item: itemLabel,
      sku: instance.sku,
      size: instance.ppeSize,
      // qty: instance.qty,
      requestedFor: requestedFor.toUpperCase(),
      available: instance.availability || '?',
    }
  }

  const viewRequestDetails = async (requestId: string) => {
    const requestDetails: any = []
    const relatedActions = getActionsRelatedToRequests([requestId], allActions, PpeActions.INITIATE_RETURN_INSTANCE)
    const actions = Object.values(relatedActions)
    const relatedInstances: PpeInstanceRecordSchema[] = Object.values(allInstances).filter((instance) =>
      actions.some((action) => action.rawData.ppeInstanceId === instance.sk),
    )
    Object.values(relatedInstances).forEach((instance: any) => {
      const detailsToDisplay = compileRequestDetailsToDisplay(instance)
      requestDetails.push(detailsToDisplay)
    })
    setSelectedRequestDetailsToDisplay(requestDetails)
    toggleViewDetails()
  }

  const displayRequestListing = () => {
    const requestsWithSelectedStatus = Object.values(allRequests).filter((request) => {
      return request.status === selectedStatus
    })
    if (requestsWithSelectedStatus.length === 0) {
      return <UserFeedback message="No requests found" />
    }
    const requestSks = requestsWithSelectedStatus.map((request) => request.sk)
    const relatedActions = getActionsRelatedToRequests(requestSks, allActions)
    const relatedInstances = getInstancesRelatedToRequests(requestSks, allInstances)
    const data: PpeRequestListingData = {
      actions: relatedActions,
      instances: relatedInstances,
      requests: pick(allRequests, requestSks),
    }
    return (
      <div key={`ppeRequestListing_${selectedStatus}`}>
        <PpeRequestListing data={data} selectedStatus={selectedStatus} onRowClick={viewRequestDetails} />
      </div>
    )
  }

  const handleReturnItemSelection = (selectedItemSks: string[]) => {
    setSelectedReturnItemIds(selectedItemSks)
  }

  const displayRequestReturns = () => {
    const selectedReturnDetailsToDisplay = prepareRequestItemsForDisplay(processReturns)
    return <PpeRequestReturns data={selectedReturnDetailsToDisplay} onItemSelection={handleReturnItemSelection} />
  }

  const getRightSideContentToDisplay = (processReturns: boolean) => {
    if (loadingModalOpen) {
      return null
    }
    if (isEmpty(allRequests)) {
      return <UserFeedback message="No requests found" />
    }
    if (viewDetails) {
      if (processReturns) {
        const requestReturns = displayRequestReturns()
        return requestReturns
      }
      return <PpeRequestDetails data={selectedRequestDetailsToDisplay} />
    } else if (selectedStatus) {
      const requestListing = displayRequestListing()
      return requestListing
    } else {
      return null
    }
  }

  const getPageTitle = (processReturns: boolean) => {
    if (viewDetails) {
      if (processReturns) {
        return 'PPE | Return'
      }
      return 'PPE Request Details'
    } else {
      return 'PPE'
    }
  }

  const getSourceInfoFromReturnInstance = (instanceSk: string) => {
    const relatedAction = getActionRelatedToInstance(instanceSk, allActions, PpeActions.INITIATE_RETURN_INSTANCE)
    const sourceName = relatedAction?.rawData.targetName || ''
    const sourceSurname = relatedAction?.rawData.targetSurname || ''
    const sourcePk = relatedAction?.rawData.targetPk || ''

    return { sourceName, sourceSurname, sourcePk }
  }

  const initiateStockRemoval = async () => {
    const ppeActionReasons: Record<PpeInstanceRecordSk, PpeActionReasonType> = {}
    selectedReturnItemIds.forEach((sk) => (ppeActionReasons[sk] = PpeActionReason.RETURNED))
    const { sourceName, sourceSurname, sourcePk } = getSourceInfoFromReturnInstance(selectedReturnItemIds[0])

    const record = createActionRecord({
      ppeAction: PpeActions.WRITEOFF,
      association: selectedAssociation,
      dateActioned: Date.now(),
      requestedReturnInstanceIds: selectedReturnItemIds,
      ppeActionReasons,
      sourceName,
      sourceSurname,
      sourcePk,
      targetName: user.getName(),
      targetSurname: user.getSurname(),
      targetPk: userIdPassport,
    })
    return await ppeService.uploadActionRecord(selectedAssociation, [record], { username: userIdPassport, password })
  }

  const initiateStockReturn = async () => {
    const ppeActionReasons: Record<PpeInstanceRecordSk, PpeActionReasonType> = {}
    selectedReturnItemIds.forEach((sk) => (ppeActionReasons[sk] = PpeActionReason.RETURNED))
    const { sourceName, sourceSurname, sourcePk } = getSourceInfoFromReturnInstance(selectedReturnItemIds[0])

    const record = createActionRecord({
      ppeAction: PpeActions.RETURN_TO_STOCK,
      association: selectedAssociation,
      dateActioned: Date.now(),
      requestedReturnInstanceIds: selectedReturnItemIds,
      ppeActionReasons,
      sourceName,
      sourceSurname,
      sourcePk,
      targetName: user.getName(),
      targetSurname: user.getSurname(),
      targetPk: userIdPassport,
    })
    return await ppeService.uploadActionRecord(selectedAssociation, [record], { username: userIdPassport, password })
  }

  const resetAfterReturnOrRemove = () => {
    // NOTE: having a function like this justifies considering using useReducer instead of useState
    setSelectedStatus(PpeStatus.READY_FOR_ISSUE)
    setAllActions({})
    setAllInstances({})
    setAllRequests({})
    setSelectedRequestDetailsToDisplay([])
    setChangesMade(false)
    setViewDetails(false)
    setSelectedReturnItemIds([])
    setSelectedReturnReason('')
    setOtherReturnReason('')
    getRequests()
  }

  const handleRemoveFromStock = async () => {
    toggleStockRemovalConfirmationModal()
    setSavingModalOpen(true)
    await initiateStockRemoval()
    setSavingModalOpen(false)
    toggleStockRemovalSuccessModal()
    resetAfterReturnOrRemove()
  }

  const handleReturnToStock = async () => {
    toggleStockReturnConfirmationModal()
    setSavingModalOpen(true)
    await initiateStockReturn()
    setSavingModalOpen(false)
    toggleStockReturnSuccessModal()
    resetAfterReturnOrRemove()
  }

  const sideMenuSelectionHandler = (statusSelection: PpeRequestsSideMenuOption) => {
    if (viewDetails) {
      setViewDetails(false)
    }
    const status = getTranslatedSideMenuStatusSelection(statusSelection)
    setSelectedStatus(status)
  }

  const triggerExportEmailSend = async (emailAddresses: string[]) => {
    // TODO: prepareAuthTokens inside exportPpeInventory, passing authCredentials
    const token = await SessionService.prepareAuthTokens(userIdPassport, password)
    ppeService.ppeRequestExport(selectedAssociation, emailAddresses, token)
    setExportEmailCollectorModalOpen(false)
    setExportEmailPendingModalOpen(true)
  }

  const getAlertModalToShow = () => {
    let exportEmailCollectorModal = null
    if (exportEmailCollectorModalOpen) {
      const user = userRepo.getCurrentUserEntity()
      const userEmailAddress = user.getEmail()
      exportEmailCollectorModal = (
        <InfoCollectorModal
          open={true}
          defaultItems={[userEmailAddress]}
          header="EMAIL TO"
          subHeader="Which email addresses should we send to?"
          warningMessage="Add at least one email address"
          validateInput={checkEmailFormat}
          transformInput={toLowerCaseCustom}
          placeholder="Email address"
          minimumItems={1}
          dismiss={() => setExportEmailCollectorModalOpen(false)}
          onSuccess={triggerExportEmailSend}
          onReject={() => setExportEmailCollectorModalOpen(false)}
        />
      )
    }

    let exportEmailPendingModal = null
    if (exportEmailPendingModalOpen) {
      exportEmailPendingModal = (
        <AlertModalOneButton
          open={exportEmailPendingModalOpen}
          header="Request export triggered"
          body="Request data export has been triggered and will be sent to the specified email address(es)"
          buttonLabel="Ok"
          onClick={() => setExportEmailPendingModalOpen(false)}
        />
      )
    }

    return (
      <>
        <AlertModalTwoButton
          open={modalState.deleteConfirmModalOpen}
          dismiss={closeModals}
          onClick1={closeModals}
          onClick2={deleteSelectedQueryName}
          buttonLabel1="No"
          buttonLabel2="Yes"
          header="Confirm"
          buttonStyle={{ marginTop: 30 }}
          body={<div>{`Are you sure you want to delete query?`}</div>}
          key="deleteConfirmModal"
        />
        <AlertModalTwoButton
          open={removalFromStockConfirmationModalOpen}
          dismiss={toggleStockRemovalConfirmationModal}
          onClick1={toggleStockRemovalConfirmationModal}
          onClick2={handleRemoveFromStock}
          buttonLabel1="Cancel"
          buttonLabel2="Remove"
          header="Remove stock items?"
          buttonStyle={{ marginTop: 30 }}
          body={
            <StockConfirmationModalBody
              selectedReason={selectedReturnReason}
              otherReason={otherReturnReason}
              setSelectedReason={setSelectedReturnReason}
              setOtherReason={setOtherReturnReason}
            />
          }
          key="stockRemovalConfirmationModal"
        />
        <AlertModalTwoButton
          open={returnToStockConfirmationModalOpen}
          dismiss={toggleStockReturnConfirmationModal}
          onClick1={toggleStockReturnConfirmationModal}
          onClick2={handleReturnToStock}
          buttonLabel1="Cancel"
          buttonLabel2="Return"
          header="Are you sure?"
          buttonStyle={{ marginTop: 30 }}
          body={<div>Items will be returned into stock</div>}
          key="stockReturnConfirmationModal"
        />
        {/* <AlertModalTwoButton
          open={closeConfirmationModalOpen}
          dismiss={toggleStockReturnConfirmationModal}
          onClick1={toggleStockReturnConfirmationModal}
          onClick2={handleRequestClosure}
          buttonLabel1="Cancel"
          buttonLabel2="Return"
          header="Are you sure?"
          buttonStyle={{ marginTop: 30 }}
          body={<div>The return request will be cancelled and closed.</div>}
          key="stockReturnConfirmationModal"
        /> */}
        <AlertModalOneButton
          open={selectItemToReturnModalOpen}
          header="Select item(s)"
          body={<div>Please select the item(s) to remove from or return to stock</div>}
          buttonLabel="Ok"
          onClick={() => setSelectItemToReturnModalOpen(false)}
          key="selectItemToReturnModal"
        />
        <AlertModalOneButton
          open={stockReturnSuccessModalOpen}
          header="Stock returned successfully"
          body={<div>Items returned to stock.</div>}
          buttonLabel="Ok"
          onClick={toggleStockReturnSuccessModal}
          key="stockReturnSuccessModal"
        />
        <AlertModalOneButton
          open={stockRemovalSuccessModalOpen}
          header="Stock removed successfully"
          body={<div>Items removed from stock.</div>}
          buttonLabel="Ok"
          onClick={toggleStockRemovalSuccessModal}
          key="stockRemovalSuccessModal"
        />
        <AlertModalOneButton
          open={modalState.saveFailedModalOpen}
          header="Failed to save changes"
          body={
            <div>
              Changes won't be permanently applied. Please try again.
              <p>Contact customer support if the problem persists.</p>
            </div>
          }
          buttonLabel="Ok"
          onClick={toggleSaveFailedModal}
          key="saveFailedModal"
        />
        <AlertModalOneButton
          // TODO: There should only be one AlertModalOneButton component per screen with the required headers, body, and functions being set accordingly by a handler.
          open={!navMenuAccess.hasProcessConfigAccess}
          header={'Not Authorised'}
          body={"You don't have permission to view/manage PPE requests."}
          buttonLabel={'Ok'}
          opaqueBackground={true}
          onClick={() => props.history.goBack()}
        />
        {exportEmailCollectorModal}
        {exportEmailPendingModal}
      </>
    )
  }

  const processReturns = selectedStatus === PpeStatus.RETURN_IN_PROGRESS
  const rightSideContent = getRightSideContentToDisplay(processReturns)
  const title = getPageTitle(processReturns)
  const actionButtons = getActionButtons(processReturns)
  const alertModal = getAlertModalToShow()

  return (
    <div style={styles.container}>
      <NavBar match={props.match} location={props.location} history={props.history} />
      <SectionHeaderPrimary style={styles.sectionHeader} disabled={true} searchString={''} onClick={() => ({})}>
        {title}
      </SectionHeaderPrimary>

      <div style={styles.contentContainer}>
        <SideMenu
          visible={true}
          menuComponents={
            <PpeRequestsSideMenu
              selectedStatus={getSideMenuSelectedStatus(selectedStatus)}
              onStatusSelection={sideMenuSelectionHandler}
            />
          }
        />
        <div style={styles.rightSide}>
          <Toolbar
            actionButtons={actionButtons}
            navButtons={{ left: { onClick: toggleViewDetails } }}
            key={`${selectedStatus}-${JSON.stringify(selectedReturnItemIds)}`}
          />
          <div style={styles.rightSideContent}>{rightSideContent}</div>
        </div>
      </div>
      {alertModal}
      <LoadingModal open={loadingModalOpen} key="loadingModal">
        Loading...
      </LoadingModal>
      <LoadingModal open={savingModalOpen} key="savingModal">
        Saving changes...
      </LoadingModal>
      <RouteLeavingGuard
        when={changesMade} // should have true value if there are any changes unsaved
        navigate={(path) => props.history.push(path)}
        shouldBlockNavigation={(location) => true}
        alertHeader="Discard changes"
        alertBody={
          <div>
            You have unsaved changes.
            <br />
            Are you sure you want to leave this page without saving?
          </div>
        }
      />
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundImage: `linear-gradient(to bottom, ${ColorPalette.SCREEN_TOP_GRADIENT}, ${ColorPalette.SCREEN_BOTTOM_GRADIENT})`,
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 1%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    paddingInline: 'max(2em, 2%)',
    width: '100%',
    // overflow: 'hidden',
  },
  rightSideContent: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    display: 'flex',
    flex: 1,
    overflow: 'auto',
    backgroundColor: ColorPalette.CARD_WHITE,
  },
  details: {
    margin: '0 auto',
  },
}
