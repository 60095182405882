import { ColorPalette } from '../../../config/colors'

import { CSSProperties, Component } from 'react'
import Radium from 'radium'
import Icon from '@mdi/react'
import { mdiDragHorizontal, mdiTrashCan, mdiCancel, mdiContentSave } from '@mdi/js'

import Picker from '../../BaseComponents/Pickers/PlainPicker'
import ButtonBlue from '../../BaseComponents/Buttons/ButtonBlue'
import ButtonGrey from '../../BaseComponents/Buttons/ButtonGrey'
import { FieldConfigItemSchema } from '../../../types'

interface IFieldProps {
  allFieldOptions: any[]
  showMissingFieldWarning: boolean
  compType?: string
  dateFormat?: string
  disabled: boolean
  label?: string
  adding?: boolean
  fieldOverridesItem?: FieldConfigItemSchema
  style?: CSSProperties
  number?: number
  fieldSelectionHandler: (args: string) => void
  onLeftButtonClick?: () => void
  onRightButtonClick?: () => void
  onFieldOverrideClick?: (fieldOverridesItem?: FieldConfigItemSchema) => void
}

interface IFieldState {
  isDragging: boolean
  hasInteracted: boolean
}

class Field extends Component<IFieldProps, IFieldState> {
  componentTypeMap: Record<string, string> = {
    text: 'TEXT',
    paragraph: 'TEXT (MULTI)',
    selector: 'SELECTOR',
    date: 'DATE',
  }

  state = {
    isDragging: false,
    hasInteracted: false,
  }

  render() {
    const {
      adding,
      allFieldOptions,
      compType,
      dateFormat,
      disabled,
      fieldOverridesItem,
      label,
      showMissingFieldWarning,
      fieldSelectionHandler,
      onLeftButtonClick,
      onRightButtonClick,
      onFieldOverrideClick,
    } = this.props

    const textStyle = {
      fontFamily: 'roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      color: ColorPalette.PRIMARY_TEXT,
      paddingLeft: '1.5em',
    }

    return (
      <div
        style={{
          ...styles.container,
          backgroundColor: this.state.isDragging ? 'rgba(255,255,255,0.4)' : ColorPalette.OFF_WHITE_LIGHT,
          boxShadow: `0px 3px 3px ${this.state.hasInteracted ? 'rgba(0, 0, 100, 0.1)' : ColorPalette.LIGHT_GREY}`,
          ...this.props.style,
        }}>
        <div
          style={{
            width: 70,
            height: '100%',
            backgroundColor: showMissingFieldWarning
              ? ColorPalette.WARNING_RED
              : adding
              ? ColorPalette.DARK_GREY
              : ColorPalette.PRIMARY_BLUE,
            color: ColorPalette.BUTTON_WHITE,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: 25,
          }}>
          <div
            style={{
              fontSize: '1.8em',
              fontWeight: 'bold',
            }}
            title={compType ? (compType === 'text' && dateFormat ? 'TEXT (DATE)' : compType.toUpperCase()) : ''}>
            {compType ? (compType[0] === 't' && dateFormat ? 'D' : compType[0].toUpperCase()) : '?'}
          </div>
        </div>
        <div style={{ width: '100%', height: '100%' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              // borderBottom: `1px solid ${ColorPalette.LIGHT_GREY}`,
            }}>
            <div
              style={{
                width: '86%',
                paddingBottom: 10,
                paddingTop: 25,
                borderBottom: `1px solid ${ColorPalette.LIGHT_GREY}`,
              }}>
              <Picker
                style={{
                  ...textStyle,
                  minWidth: 160,
                  width: '100%',
                  backgroundColor: this.state.isDragging ? 'rgba(255,255,255,0.4)' : ColorPalette.CARD_WHITE,
                  color: ColorPalette.PRIMARY_TEXT,
                  outline: 'none',
                  borderBottom: 'none',
                }}
                value={label}
                items={allFieldOptions}
                disabled={disabled}
                onChange={(fieldLabel) => fieldSelectionHandler(fieldLabel)}
              />
            </div>

            <div style={{ display: 'flex', alignItems: 'center', height: 25 }}>
              <div style={{ paddingTop: '0.35em', marginRight: '1em', height: '100%' }} onClick={onLeftButtonClick}>
                <Icon
                  path={adding ? mdiCancel : mdiTrashCan}
                  size={0.9}
                  color={ColorPalette.WARNING_RED}
                  style={{ cursor: 'pointer' }}
                  title={adding ? 'Cancel new field addition' : 'Remove field from flow'}
                />
              </div>
              <div onClick={onRightButtonClick} style={{ height: '100%' }}>
                <Icon
                  path={adding ? mdiContentSave : mdiDragHorizontal}
                  size={1.4}
                  color={ColorPalette.DARK_GREY}
                  style={{
                    cursor: adding ? 'pointer' : 'grab',
                    marginRight: adding ? '0.5em' : 0,
                  }}
                  title={adding ? 'Save new field' : 'Move to change order'}
                />
              </div>
            </div>
          </div>
          {!adding && onFieldOverrideClick && (
            <div style={{ padding: '1em 0' }}>
              {fieldOverridesItem ? (
                <ButtonBlue
                  style={{ width: '82%', marginLeft: 20 }}
                  onClick={() => onFieldOverrideClick(fieldOverridesItem)}>
                  Edit field overrides
                </ButtonBlue>
              ) : (
                <ButtonGrey
                  style={{ width: '82%', marginLeft: 20 }}
                  onClick={() => onFieldOverrideClick(fieldOverridesItem)}>
                  Add field override
                </ButtonGrey>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
}

let styles = {
  container: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center' as 'center',
    paddingRight: 20,
    marginBottom: 35,
    width: '94%',
    borderRadius: 15,
    overflow: 'hidden',
    height: 135,
  },
}

export default Radium(Field)
