import React from 'react'
import Spinner from 'react-spinkit'
import Dialog from '@material-ui/core/Dialog'
import { ColorPalette } from '../../config/colors'

interface LoadingModalProps {
  open: boolean
  children?: React.ReactNode
  loadingMessage?: string
  auxilliaryMessage?: string
  style?: React.CSSProperties
}

const LoadingModal = (props: LoadingModalProps) => {
  const { open, children, loadingMessage = '', auxilliaryMessage = '' } = props
  const primaryMessage = loadingMessage || children
  let secondaryMessage = null
  if (auxilliaryMessage) {
    secondaryMessage = <p style={{ ...auxilliaryTextStyle }}>{auxilliaryMessage}</p>
  }
  return (
    <Dialog
      open={open}
      BackdropProps={{ invisible: true }}
      PaperProps={{ style: { borderRadius: 8 } }}
      style={{ backgroundColor: ColorPalette.MODAL_BACKGROUND_OVERLAY }}>
      <div style={{ width: window.innerWidth * 0.25, display: 'flex', flexDirection: 'column' }}>
        <h1 style={{ ...primaryTextStyle }}>{primaryMessage}</h1>
        {secondaryMessage}
        <Spinner style={spinnerStyle} fadeIn="quarter" name="three-bounce" color={ColorPalette.PRIMARY_TEXT} />
      </div>
    </Dialog>
  )
}

const primaryTextStyle = {
  textAlign: 'center' as 'center',
  alignSelf: 'center',
  fontFamily: 'roboto',
  fontWeight: 'bold',
  color: ColorPalette.PRIMARY_TEXT,
  fontSize: '1.2rem',
  padding: '0px 10px 0px 10px',
  marginTop: 25,
}
const auxilliaryTextStyle = {
  textAlign: 'center' as 'center',
  alignSelf: 'center',
  fontFamily: 'roboto',
  fontWeight: 400,
  color: ColorPalette.PRIMARY_TEXT,
  fontSize: '1rem',
  paddingLeft: 10,
  paddingRight: 10,
  marginTop: 0,
  marginBottom: 0,
}
const spinnerStyle = {
  transform: 'scale(0.8)',
  alignSelf: 'center',
  marginBottom: '18%',
  marginTop: '10%',
}

export default LoadingModal
