import { useState, useEffect } from 'react'
import { mdiCog } from '@mdi/js'
import Icon from '@mdi/react'

import { ColorPalette } from '../../../config'
import BarChart from '../../DataVisualisation/BarChart'
import ButtonGeneric from '../../BaseComponents/Buttons/ButtonGeneric'
import AlertModalOneButton from '../../Modals/AlertModalOneButton'
import { TrendId } from '../../../types'

type TrendGraphProps = {
  selectedTrendId: TrendId
  selectedTrendName: string
  xValues: any[]
  yValues: any[]
  warningFieldLabels?: string[]
  openTrendConfigurator: () => void
}

export const TrendGraph = (props: TrendGraphProps) => {
  const [warningModalOpen, setWarningModalOpen] = useState(false)
  const [warningModalConfig, setWarningModalConfig] = useState({ header: '', subHeader: '' })

  const { selectedTrendId, xValues, yValues, warningFieldLabels, openTrendConfigurator } = props

  useEffect(() => {
    if (warningFieldLabels) {
      const subHeader = `The following trend fields are not compatible with the aggregation method you chose and have been excluded from the graph: ${warningFieldLabels.join(
        ', ',
      )}`
      setWarningModalConfig({ header: 'Invalid Trend', subHeader })
      setWarningModalOpen(true)
    }
  }, [])

  let warningModal = null
  if (warningModalOpen) {
    warningModal = (
      <AlertModalOneButton
        open={warningModalOpen}
        header={warningModalConfig.header}
        subHeader={warningModalConfig.subHeader}
        onClick={() => {
          setWarningModalOpen(false)
        }}
        buttonLabel={'Ok'}
      />
    )
  }

  let content = null
  if (selectedTrendId && yValues.length > 0) {
    const barData = {
      label: 'COUNT',
      data: yValues,
      backgroundColor: ColorPalette.PRIMARY_BLUE,
    }

    const graphDataSets = [barData]
    const title = (
      <ButtonGeneric
        style={{ ...styles.sectionHeader }}
        iconBefore={
          <Icon path={mdiCog} size={0.8} style={{ color: ColorPalette.PRIMARY_BLUE, marginRight: '0.75em' }} />
        }
        // label={`EDIT SETTINGS \u00A0 | \u00A0 ${sentenceCase(props.selectedTrendName)}`}
        label={`EDIT SETTINGS`}
        onClick={openTrendConfigurator}
      />
    )

    content = (
      <div style={styles.section}>
        {title}
        <BarChart
          data={graphDataSets}
          labels={xValues.map((item: any) => item)}
          showLegend={false}
          style={styles.barChart}
          key={yValues.join('')}
        />
        {warningModal}
      </div>
    )
  }

  return content
}

const styles = {
  barChart: {
    padding: '1em 0 0',
    width: '100%',
  },
  section: {
    width: '100%',
    margin: '1em 0',
    paddingBottom: '20px',
    cursor: 'pointer',
  },
  sectionHeader: {
    fontWeight: 550,
    marginTop: 5,
    fontSize: '1rem',
    fontFamily: 'Roboto',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid' as 'solid',
    borderBottomColor: ColorPalette.PRIMARY_BLUE,
    color: ColorPalette.PRIMARY_TEXT,
    height: 40,
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
    },
    ':active': {
      color: ColorPalette.DARK_GREY,
    },
  },
}
