import React, { Component } from 'react'
import { withStyles, createStyles } from '@material-ui/core/styles'
import Radium from 'radium'
import Grid from '@material-ui/core/Grid'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

import { ColorPalette } from '../../config/colors'
import CalendarContained from '../BaseComponents/Calendar/CalendarContained'
import PickerContained from '../BaseComponents/Pickers/PickerContained'
import ButtonBlue from '../BaseComponents/Buttons/ButtonBlue'
import ButtonGrey from '../BaseComponents/Buttons/ButtonGrey'
import { PickerHandlerEvent } from '../BaseComponents/Pickers/Picker'

interface OptionsSelectorModalProps {
  dateLabel?: string
  showCalendar?: boolean
  selectedDate?: string | Date
  selectedDateRange?: Record<string, Date>
  successLabel?: string
  cancelLabel?: string
  options1?: string[]
  options2?: string[]
  optionsName1?: string
  optionsName2?: string
  selectedOption1?: string
  selectedOption2?: string
  label1?: string
  label2?: string
  searchEnabled1?: boolean
  searchEnabled2?: boolean
  warningMessage?: string
  open?: boolean
  style?: React.CSSProperties | undefined
  dateRangeStyle?: React.CSSProperties | undefined
  handleDateChange?: ((date: Date, value?: string | null) => void) | undefined
  onChange?: (e: PickerHandlerEvent, name: string) => void
  onReject?: () => void
  onSuccess: () => void
}

class OptionsSelectorModal extends Component<OptionsSelectorModalProps> {
  state = {
    date: new Date(),
    searchValue1: '',
    searchValue2: '',
  }

  handleDateChangeHandler(date: MaterialUiPickersDate, value?: string | null): void {
    // console.log("date: ", date?.getTime())
    if (typeof this.props?.handleDateChange === 'undefined') {
      return
    }
    this.props.handleDateChange(date as Date, value)
  }

  onRejectWrapper() {
    if (typeof this.props.onReject === 'undefined') {
      return
    }
    this.props.onReject()
  }

  onChange = (event: PickerHandlerEvent, pickerNumber: string) => {
    if (this.props?.onChange === undefined) {
      return
    }
    this.props?.onChange(
      event,
      pickerNumber === '1' ? this.props[`optionsName${pickerNumber}`] || '' : this.props.optionsName2 || '',
    )
  }

  render() {
    let dateLabel = null
    let datePicker = null

    if (this.props.showCalendar) {
      dateLabel = <h1 style={{ ...getStyle('header'), marginTop: 30 }}>{this.props.dateLabel}</h1>
      datePicker = (
        <CalendarContained
          format={'dd/MM/yyyy'}
          label={'Selected Date'}
          value={this.props.selectedDate}
          onChange={(date: MaterialUiPickersDate, value?: string | null) => this.handleDateChangeHandler(date)}
        />
      )
    }
    let label1 = null
    let picker1 = null
    if (this.props.options1 && this.props.options1.length) {
      label1 = <h1 style={getStyle('header')}>{this.props.label1}</h1>
      picker1 = (
        <PickerContained
          style={{ marginTop: 5 }}
          items={this.props.options1}
          value={this.props.selectedOption1 || ''}
          searchEnabled={this.props.searchEnabled1}
          onChange={(e) => this.onChange(e, '1')}
        />
      )
    }

    let label2 = null
    let picker2 = null

    if (this.props.options2 && this.props.options2.length) {
      label2 = <h1 style={{ ...getStyle('header'), marginTop: 30 }}>{this.props.label2}</h1>
      picker2 = (
        <PickerContained
          style={{ marginTop: 5 }}
          items={this.props.options2}
          value={this.props.selectedOption2 || ''}
          searchEnabled={this.props.searchEnabled2}
          onChange={(e) => this.onChange(e, '2')}
        />
      )
    }

    let cancelButton: JSX.Element | null = null
    if (this.props.cancelLabel) {
      cancelButton = (
        <ButtonGrey
          style={getStyle('labelledButton', !!this.props.successLabel)}
          onClick={() => this.onRejectWrapper()}>
          {this.props.cancelLabel}
        </ButtonGrey>
      )
    }

    let successButton = null
    if (this.props.successLabel) {
      successButton = (
        <ButtonBlue
          style={{ ...getStyle('labelledButton', !!this.props.cancelLabel), marginTop: 10 }}
          onClick={() => this.props?.onSuccess()}>
          {this.props.successLabel}
        </ButtonBlue>
      )
    }

    let warning = null
    if (this.props.warningMessage) {
      warning = <h1 style={getStyle('warningTextStyle')}> {this.props.warningMessage} </h1>
    }

    if (!this.props.open) {
      return null
    }

    return (
      <div style={{ ...getStyle('container'), ...this.props.style }}>
        <div style={getStyle('cardContainer')}>
          <Grid item container direction="column" justify="center" alignItems="flex-start" xs={12} sm={6} md={4} lg={4}>
            <div style={getStyle('cardStyle')}>
              {label1}
              {picker1}
              {label2}
              {picker2}
              {dateLabel}
              {datePicker}
              {warning}
              <Grid style={{ ...getStyle('buttonContainer') }} item container lg={12}>
                {cancelButton}
                {successButton}
              </Grid>
            </div>
          </Grid>
        </div>
      </div>
    )
  }
}

const styles = createStyles({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 200,
    paddingTop: 120,
    backgroundColor: ColorPalette.MODAL_BACKGROUND_OVERLAY,
    height: window.innerHeight,
    width: window.innerWidth,
    overflow: 'hidden',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    overflow: 'hidden',
  },
  cardStyle: {
    width: '75%',
    backgroundColor: ColorPalette.CARD_WHITE,
    borderRadius: 8,
    boxShadow: '0rem 0.5rem 0.8rem rgba(0, 0, 0, 0.25)',
    marginBottom: 30,
    paddingBottom: 30,
    paddingLeft: '6%',
    paddingRight: '6%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'center',
  },
  header: {
    color: ColorPalette.PRIMARY_TEXT,
    fontSize: '1rem',
    fontFamily: 'roboto',
    fontWeight: 'bold',
    width: '80%',
    marginTop: 40,
    textAlign: 'center' as 'center',
  },
  buttonContainer: {
    marginTop: 40,
    display: 'flex',
    justifyContent: 'space-between',
  },
  warningTextStyle: {
    marginTop: 25,
    marginBottom: 0,
    textAlign: 'center' as 'center',
    alignSelf: 'center',
    fontFamily: 'roboto',
    fontWeight: 'normal',
    color: ColorPalette.WARNING_RED,
    fontSize: '0.95rem',
    paddingLeft: 20,
    paddingRight: 20,
  },
  labelledButton: { marginHorizontal: 5, width: '100%' },
})

function getStyle(
  k: 'container' | 'cardContainer' | 'cardStyle' | 'header' | 'buttonContainer' | 'labelledButton' | 'warningTextStyle',
  cancelLabel: boolean = false,
): React.CSSProperties | undefined {
  const x = styles[k]
  if (!x) {
    return undefined
  }
  if (k === 'labelledButton' && cancelLabel && typeof (x as any).width !== 'undefined') {
    ;(x as any)['width'] = '100%'
  }
  return x as React.CSSProperties
}

export default withStyles(styles, { withTheme: true })(Radium(OptionsSelectorModal))
