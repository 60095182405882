import { FormControl, FormControlLabel, FormGroup, Radio, RadioGroup } from '@material-ui/core'
import { ColorPalette } from '../../../config'
import { toUpperCaseCustom } from '../../../utils'

type RadioGroupContainedProps = {
  label: string
  value: string
  options: { key: string; label: string; value?: string }[]
  rowDisplay?: boolean
  disabled?: boolean
  style?: React.CSSProperties
  optionsStyle?: React.CSSProperties
  onChange: (e: any) => void
}

export const RadioGroupContained = (props: RadioGroupContainedProps) => {
  const { label, value, rowDisplay, style, optionsStyle, onChange } = props

  const defaultValue = props.options.length > 0 ? props.options[0].value ?? props.options[0].key : undefined

  const options = props.options.map((option) => {
    if (option.key) {
      return (
        <FormControlLabel
          key={option.key}
          value={option.value ?? option.key}
          control={<Radio />}
          label={<div style={{ fontSize: '0.9rem ' }}>{option.label}</div>}
        />
      )
    }
    return null
  })

  return (
    <FormControl style={{ ...styles.container, ...style }}>
      <p style={styles.label}>{toUpperCaseCustom(label)}</p>
      <RadioGroup value={value} onChange={onChange} defaultValue={defaultValue} style={style}>
        <FormGroup row={rowDisplay} style={{ ...styles.inputs, ...optionsStyle }}>
          {options}
        </FormGroup>
      </RadioGroup>
    </FormControl>
  )
}

const styles = {
  container: {
    minWidth: 120,
    width: '100%',
    marginTop: 5,
    padding: '15px 20px 10px',
    border: '1px solid rgba(195,205,225,0.6)',
    borderRadius: 12,
    display: 'flex',
    justifyContent: 'center',
  },
  label: {
    zIndex: 2,
    marginTop: -22,
    alignSelf: 'center',
    fontSize: '0.65rem',
    color: ColorPalette.LIGHT_GREY,
    backgroundColor: ColorPalette.CARD_WHITE,
    paddingLeft: 10,
    paddingRight: 10,
  },
  inputs: {
    color: ColorPalette.PRIMARY_TEXT,
  },
}
