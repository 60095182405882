import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import {
  formatDateAndTime,
  removeUnderScores,
  sortArrayOfObjects,
  toUpperCaseCustom,
  writeRemoteFilesToLocalStorage,
} from '../utils'
import { addUnderScores } from '../utils/stringUtils'
import {
  getAsyncTasksByType,
  getTimeBasedDocRecords,
  removeFromAsyncQueue,
  triggerDocExport,
  updateDocConfig,
  updateDocVerificationTracker,
} from '../providers'
import { IDocVerificationItem, TimeBasedDocFactory, TimeBasedDocs } from '../models'
import { DocConfig } from '../models/forms/doc.config'
import { DocDownloadQueueItem } from '../screens/Exports/DocExport'
import { DocStatus, DocVerificationActions, TimeBasedDocStatusEnum } from '../enums'
import { IFileMeta } from '../interfaces'
import {
  AsyncTaskDocExport,
  DocumentCategory,
  DownloadBatchLabel,
  DownloadBatchNumber,
  UpdateDocConfigParams,
} from '../types'
import PFDoc from '../models/pfDoc/pfDoc'

dayjs.extend(relativeTime)

export class DocServiceFactory {
  static create() {
    return new DocsService()
  }
}

export class DocsService {
  loadDocumentViewer(
    pfDocRecords: PFDoc[],
    docVerificationTracker: IDocVerificationItem,
  ): Record<DocumentCategory, IFileMeta[]> {
    const labelConfig: Record<string, string> = {
      Contract: 'SIGNED ON:',
      PhotoCopy: 'GENERATED ON:',
      Import: 'IMPORTED ON:',
    }
    let packagedDocs: Record<DocumentCategory, IFileMeta[]> = {}
    for (const pfDocRecord of pfDocRecords) {
      const { docName, docFamily, generatedUnixMs, fileName, association, ownerPk, validPeriod, validityStatus } =
        pfDocRecord.getAllDocMetaData()
      const { day, month, year, hour, minutes } = formatDateAndTime(new Date(parseInt(generatedUnixMs)))
      const formattedDate = `${day} ${month} ${year}, ${hour}:${minutes}`
      if (!packagedDocs[docName]) {
        packagedDocs[docName] = []
      }
      packagedDocs[docName].push({
        docNameDisplay: `${labelConfig[docFamily]}   ${formattedDate}`,
        fileInfo: {
          association,
          pk: ownerPk,
          fileName,
          validityStatus,
          isVerified: docVerificationTracker.getVerificationStatus(fileName),
          validPeriod,
        },
      })
    }
    return packagedDocs
  }

  extractDocGroup(
    selectedDocConfig: Record<string, any>,
    groupName: string,
    targetDocStatuses: DocStatus[] = [DocStatus.PUBLISHED],
    docsOnProfile?: string[],
  ): string[] {
    let groupedDocs: string[] = []
    Object.keys(selectedDocConfig).forEach((docName: string) => {
      targetDocStatuses.forEach((status) => {
        const versions = Object.keys(selectedDocConfig[docName][status])
        if (status === DocStatus.DEPUBLISHED && docsOnProfile && !docsOnProfile.includes(docName)) {
          return
        }
        if (versions.length) {
          const validCategories = [...selectedDocConfig[docName].displaySettings.category].map((category) =>
            addUnderScores(toUpperCaseCustom(category)),
          )

          if (validCategories.includes(toUpperCaseCustom(groupName))) {
            groupedDocs = [...groupedDocs, docName]
          }
        }
      })
    })
    return groupedDocs
  }

  getUniqueDocNames(docFileNameStrings: string[]): string[] {
    return [
      ...new Set([
        ...docFileNameStrings.map((doc) => {
          const parts = doc.split('___')
          const docNameWithFileExtension = parts[parts.length - 1].split('.')
          const docName = docNameWithFileExtension[0]
          return docName
        }),
      ]),
    ]
  }

  async downloadDocuments(company: string, pk: string, documentList: string[]): Promise<any> {
    let docsToDownload: { docPath: string; filename: string }[] = []
    for (const docname of documentList) {
      let fileNameElements = docname.split('_')
      const timestamp = fileNameElements.shift()
      fileNameElements.shift()
      const selectedFolder = fileNameElements.join('_').split('.')[0]
      const filename = `${pk} - ${removeUnderScores(selectedFolder)} - ${timestamp}.pdf`
      const docPath = `People/${pk}/Documents/${company}/${docname}`
      docsToDownload.push({ filename, docPath })
    }
    return writeRemoteFilesToLocalStorage(docsToDownload)
  }

  async downloadDocBatch(filePath: string): Promise<any> {
    const filePathElements = filePath.split('/')
    const filename = filePathElements[filePathElements.length - 1]
    await writeRemoteFilesToLocalStorage([{ docPath: filePath, filename }])
  }

  async fetchDocDownloadQueue(selectedAssociation: string, token: string): Promise<AsyncTaskDocExport[]> {
    const data = await getAsyncTasksByType(selectedAssociation, token)
    const asyncTasks = data.payload
    const sortedTasks: AsyncTaskDocExport[] = sortArrayOfObjects('createdAtUnixMs', 'descending', asyncTasks)
    const filteredTasks = sortedTasks.filter((item) => item.payload.content)
    return filteredTasks as AsyncTaskDocExport[]
  }

  async clearDownloadQueue(selectedAssociation: string, queueId: string, token: string): Promise<AsyncTaskDocExport[]> {
    await removeFromAsyncQueue(selectedAssociation, queueId, token)
    return this.fetchDocDownloadQueue(selectedAssociation, token)
  }

  static asyncTasksToDownloadQueueItems = (asyncTasks: AsyncTaskDocExport[]) => {
    return asyncTasks.map((item) => {
      const { batchLabel = 'UNKNOWN USER', status, content } = item.payload
      const date = `${dayjs.unix(item.createdAtUnixMs / 1000).format('YYYY-MM-DD,  HH:mm')}`
      const divider = `\xa0 | \xa0`
      const suffix = `${divider}${batchLabel}`
      const label = `${date} ${suffix}`
      return {
        label,
        queueId: item.uid,
        status,
        fileLocation: content,
      } as DocDownloadQueueItem
    })
  }

  async updateDocConfig(params: UpdateDocConfigParams, token: string): Promise<Record<string, DocConfig>> {
    let {
      author,
      allFieldConfig,
      action,
      selectedAssociation,
      docConfig,
      docName,
      docVersion,
      docStatus,
      sourceDocName,
      sourceDocVersion,
      targetDocStatus,
      newVersion,
      processConfig,
    } = params
    let payload: any = {
      action,
      selectedEmployer: selectedAssociation,
      docName,
      docVersion,
    }
    switch (action) {
      case 'CREATE_NEW_DOC':
        payload = {
          ...payload,
          docConfig: docConfig[docName],
        }
        break

      case 'CLONE_DOC':
        payload = {
          ...payload,
          author,
          newVersion,
          sourceDocName,
          sourceDocVersion,
        }
        break

      case 'UPDATE_DISPLAY_SETTINGS':
        payload = {
          ...payload,
          displaySettings: docConfig[docName].displaySettings,
          processConfig,
        }
        break

      case 'CREATE_NEW_VERSION':
        payload = {
          ...payload,
          versionConfig: docConfig[docName].drafts[docVersion],
        }
        break

      case 'UPDATE_FIELD_CONFIG':
        payload = {
          ...payload,
          fieldConfig: docConfig[docName][docStatus][docVersion].fieldConfig,
          allFieldConfig,
        }
        break

      case 'UPDATE_DOC_STATUS':
        payload = {
          ...payload,
          sourceDocStatus: docStatus,
          targetDocStatus,
        }
        break

      case 'COPY_TO_DRAFTS':
        payload = {
          ...payload,
          sourceDocStatus: docStatus,
          newVersion,
        }
        break

      default:
        break
    }
    const data = await updateDocConfig(payload, token)
    const { newDoc } = data
    if (action === 'CLONE_DOC') {
      docConfig[docName] = newDoc
    }
    return docConfig
  }

  async fetchTimeBasedDocData(
    selectedAssociation: string,
    cohorts: string[],
    token: string,
    profilePkList?: string[],
    returnRawRecords?: boolean,
  ): Promise<TimeBasedDocs | any[]> {
    const params = {
      cohorts,
      onlyReturnRecords: true,
      profilePkList,
    }
    const data = await getTimeBasedDocRecords(selectedAssociation, params, token)
    const records = []
    const recordData = data.records
    if (recordData) {
      for (const item of [...recordData]) {
        if (item.Name) {
          item.name = item.Name
        }
        if (item.Surname) {
          item.surname = item.Surname
        }
        records.push(item)
      }

      if (returnRawRecords) {
        return records
      }

      return TimeBasedDocFactory.create(records)
    }
    return []
  }

  async triggerDocBatchGeneration(
    targetDocs: Record<string, string[]>,
    selectedAssociation: string,
    batchLabel: DownloadBatchLabel,
    token: string,
  ): Promise<AsyncTaskDocExport> {
    const { asyncTask } = await triggerDocExport(selectedAssociation, batchLabel, targetDocs, token)
    return asyncTask
  }

  async updateDocVerificationTrackers(
    selectedAssociation: string,
    action: DocVerificationActions,
    idPassport: string,
    docNames: string[],
    token: string,
    userDetails?: Record<string, any>,
  ): Promise<any> {
    try {
      const params = { selectedEmployer: selectedAssociation, action, idPassport, docNames, userDetails }
      const { trackers } = await updateDocVerificationTracker(params, token)
      return trackers
    } catch (error) {
      console.error('error: ', error)
      return {}
    }
  }

  getDownloadBatchLabel(
    batchNumber: DownloadBatchNumber,
    name: string = 'UNKNOWN',
    surname: string = 'UNKNOWN',
  ): DownloadBatchLabel {
    return `Set ${batchNumber} \xa0 | \xa0 ${name[0]}. ${surname}`
  }
}
