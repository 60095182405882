import { ColorPalette } from '../../../config'
import CalendarRange from './CalendarRange'
import { DateRange, DateRangeConfig, DateRangePoint } from '../../Modals/DateRanges/DateRangesModal'

type CalenderRangesProps = {
  dateRangeConfig: DateRangeConfig
  selections: Record<string, DateRange>
  orientation?: 'horizontal' | 'vertical'
  style?: React.CSSProperties
  onDateRangeChange: (date?: number, rangePoint?: DateRangePoint, id?: string) => void
  validateDateRange: (selectionLabel: string) => JSX.Element | null
}

export const CalendarRanges = (props: CalenderRangesProps) => {
  const { orientation = 'horizontal', selections, style = {}, onDateRangeChange, validateDateRange } = props

  const renderRanges = () =>
    props.dateRangeConfig.map((configItem, index) => {
      const label = configItem.label

      return (
        <div key={index} style={style}>
          <h3 style={styles.textSubHeaderStyle}>{configItem.label}</h3>
          <CalendarRange
            id={label}
            orientation={orientation}
            dateFormat="yyyy/MM/dd"
            dateRange={selections[label] as Pick<DateRange, 'startDate' | 'endDate'>}
            // @ts-expect-error
            onDateChange={onDateRangeChange}
            dialogStyle={{ zIndex: 30000 }}
            minEndDate={selections[label].startDate}
          />
          {validateDateRange(label)}
        </div>
      )
    })

  const dateRanges = renderRanges()
  return <div>{dateRanges}</div>
}

const styles = {
  textSubHeaderStyle: {
    textAlign: 'center' as 'center',
    alignSelf: 'center',
    fontFamily: 'roboto',
    fontWeight: 'normal',
    fontSize: '0.9rem',
    color: ColorPalette.PRIMARY_TEXT,
    paddingLeft: 20,
    paddingRight: 20,
  },
  additionalInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '1em',
  },
}
