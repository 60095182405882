import React, { Component } from 'react'
import ButtonBlue from '../BaseComponents/Buttons/ButtonBlue'
import { ColorPalette } from '../../config/colors'

interface AlertModalOneButtonProps {
  open: boolean
  containerStyle?: React.CSSProperties | undefined
  header: string
  subHeader?: any
  buttonLabel: string
  body?: string | React.ReactChild[] | React.ReactElement
  opaqueBackground?: boolean
  dismiss?: () => void
  onClick: () => void
}

class AlertModalOneButton extends Component<AlertModalOneButtonProps> {
  render() {
    const { open, containerStyle, buttonLabel, header, body = '', dismiss = () => {}, onClick = () => {} } = this.props
    let content = null

    if (open) {
      let subHeader = null
      if (this.props.subHeader) {
        subHeader = <div style={{ ...styles.textStyle }}>{this.props.subHeader}</div>
      }
      content = (
        <>
          <button style={styles.screenContainer} onClick={() => dismiss()} />
          <div style={{ ...styles.cardContainer, ...containerStyle }}>
            <h1 style={{ ...styles.textStyle, fontWeight: 'bold', fontSize: '1.2rem', marginTop: 0 }}>{header}</h1>
            {subHeader}
            <div style={styles.textStyle}>{body}</div>
            <ButtonBlue style={{ marginTop: '8%', width: '100%', alignSelf: 'center' }} onClick={() => onClick()}>
              {buttonLabel}
            </ButtonBlue>
          </div>
        </>
      )
    }
    return content
  }
}

export default AlertModalOneButton

const styles: Record<string, React.CSSProperties> = {
  screenContainer: {
    zIndex: 10000,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: ColorPalette.MODAL_BACKGROUND_OVERLAY,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 0,
    border: 'none',
    overflow: 'hidden',
  },
  cardContainer: {
    zIndex: 10000,
    position: 'absolute',
    top: window.innerHeight * 0.16,
    left: window.innerWidth * 0.38,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
    backgroundColor: ColorPalette.CARD_WHITE,
    padding: 30,
    borderRadius: 8,
    width: window.innerWidth * 0.26,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 30,
  },
  textStyle: {
    marginTop: 5,
    textAlign: 'center',
    alignSelf: 'center',
    fontFamily: 'roboto',
    fontWeight: 'normal',
    color: ColorPalette.PRIMARY_TEXT,
    fontSize: 14,
    paddingLeft: 10,
    paddingRight: 10,
  },
}
