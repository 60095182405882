import { PpeInstanceRecordSk, FieldConfigKey, AssociationId } from '../../types'
import { PpeActions } from '../../enums'
import { PpeRequestRecordSk } from './PpeRequestRecord'
import { ProfilePk } from '../profile'
import { NewPpeInstanceParams, PpeInstanceRecordSchema, PpeQuantity, PpeSize, PpeSku } from './PpeInstanceRecord'

export type PpeActionRecordPk = string
export type PpeActionRecordSk = string
export type PpeRequestItem = {
  selectedSizes: Record<string, any>[]
}
export type RequestedPpeItems = Record<ProfilePk, Record<FieldConfigKey, PpeRequestItem>>

type PpeActionRawData = {
  requestId: PpeRequestRecordSk
  ppeAction: PpeActions
  ppeActionReasons?: Record<PpeInstanceRecordSk, string>
  dateActioned: string
  association: string
  targetAssociation?: string
  division?: string
  jobType?: string
  seedGroupId?: string
  ppeInstanceId?: PpeInstanceRecordSk
  ppeInstance?: PpeInstanceRecordSchema
  expiryDate?: string
  issueValue?: string
  issueValueCurrency?: string
  requestedCreationInstanceParams?: NewPpeInstanceParams[]
  requestedTransferInstanceParams?: Record<
    AssociationId,
    Record<FieldConfigKey, Record<PpeSize, { qty: PpeQuantity; skus?: PpeSku[] }[]>>
  >
  requestedReturnInstanceIds?: PpeInstanceRecordSk[]
  requestedIssueInstanceIds?: PpeInstanceRecordSk[]
  requestedPpeItems?: RequestedPpeItems
  sourceName?: string
  sourceSurname?: string
  sourcePk?: ProfilePk
  targetName?: string
  targetSurname?: string
  targetPk?: ProfilePk
}
export type PpeDocs = string[]
export type PpePhotos = string[]

export type PpeActionRecordSchema = {
  pk?: PpeActionRecordPk
  sk?: PpeActionRecordSk
  ppeAction?: PpeActions
  rawData: PpeActionRawData
  docs?: PpeDocs
  photos?: PpePhotos
}
export class PpeActionRecord {
  pk?: string
  sk?: string
  rawData: PpeActionRawData
  docs: []
  photos: []

  constructor(data: Record<string, any>) {
    if (!data) {
      data = {}
    }
    this.pk = data.pk || ''
    this.sk = data.sk || ''
    this.rawData = {
      ppeAction: data.rawData?.ppeAction ? data.rawData.ppeAction : '',
      ppeActionReasons: data.rawData?.ppeActionReasons,
      association: data.rawData?.association ? data.rawData.association : '',
      division: data.rawData?.division ? data.rawData.division : '',
      jobType: data.rawData?.jobType ? data.rawData.jobType : '',
      dateActioned: data.rawData?.dateActioned ? data.rawData.dateActioned : 0,
      expiryDate: data.rawData?.expiryDate ? data.rawData.expiryDate : '',
      issueValue: data.rawData?.issueValue ? data.rawData.issueValue : '',
      issueValueCurrency: data.rawData?.issueValueCurrency ? data.rawData.issueValueCurrency : '',
      ppeInstanceId: data.rawData?.ppeInstanceId ? data.rawData.ppeInstanceId : '',
      requestedTransferInstanceParams: data.rawData?.requestedTransferInstanceParams
        ? data.rawData.requestedTransferInstanceParams
        : {},
      requestedCreationInstanceParams: data.rawData?.requestedCreationInstanceParams
        ? data.rawData.requestedCreationInstanceParams
        : [],
      requestedIssueInstanceIds: data.rawData?.requestedIssueInstanceIds ? data.rawData.requestedIssueInstanceIds : [],
      requestedReturnInstanceIds: data.rawData?.requestedReturnInstanceIds
        ? data.rawData.requestedReturnInstanceIds
        : [],
      requestId: data.rawData?.requestId ? data.rawData.requestId : '',
      sourceName: data.rawData?.sourceName ? data.rawData.sourceName : '',
      sourceSurname: data.rawData?.sourceSurname ? data.rawData.sourceSurname : '',
      sourcePk: data.rawData?.sourcePk ? data.rawData.sourcePk : '',
      targetName: data.rawData?.targetName ? data.rawData.targetName : '',
      targetSurname: data.rawData?.targetSurname ? data.rawData.targetSurname : '',
      targetPk: data.rawData?.targetPk ? data.rawData.targetPk : '',
    }
    this.docs = data.docs || []
    this.photos = data.photos || []
  }

  getPk = () => {
    return this.pk || ''
  }

  getSk = () => {
    return this.sk || ''
  }

  getRawData(): Record<string, any> {
    return { ...this.rawData }
  }

  getRawDataField = (fieldName: string) => {
    return this.getRawData()[fieldName]
  }

  getDocs(): string[] {
    return [...this.docs]
  }

  getPhotos(): string[] {
    return [...this.photos]
  }
}
