import React, { useEffect, useState } from 'react'

import { ColorPalette } from '../../../config/colors'
import LabelCollector from '../../Modals/LabelCollector'
import ButtonBlue from '../../BaseComponents/Buttons/ButtonBlue'
import { ensureSingleSpaceInsideString } from '../../../utils/stringUtils'
import { Description } from './PpeItemDescription'
import { Sizing } from './PpeItemSizing'
import { camelCase, isEqual } from 'lodash'
import { PpeService } from '../../../services'
import AlertModalOneButton from '../../Modals/AlertModalOneButton'
import { AssociationSettingsRepository } from '../../../repositories'
import { PpeSupplierKeyValuePairs, PpeTypeConfig, PpeTypeUpsert } from '../../../types'

export type ItemSize = {
  name: string
  sku: string
  sizes: string[]
}

type ItemKeyLabelPair = {
  key: string
  label: string
}

interface PpeItemDetailsProps {
  selectedAssociation: string
  associationRepo: AssociationSettingsRepository
  selectedItem?: string
  allItems?: ItemKeyLabelPair[]
  itemSizes: ItemSize[]
  itemDetails?: PpeTypeConfig
  additionMode: boolean
  selectedSku: string
  style?: React.CSSProperties
  onSaveChangesClick: (item: PpeTypeUpsert & { sku: string }) => void
  updateUserProfileState: (suppliers: PpeSupplierKeyValuePairs) => void
}

const ppeService = new PpeService()

const getItemNameWithoutSkuPart = (itemDescription?: string) => {
  if (!itemDescription) {
    return ''
  }
  return itemDescription.split(' -')[0]
}

const PpeItemDetails = (props: PpeItemDetailsProps) => {
  const [selectedItemDescription, setSelectedItemDescription] = useState(props.selectedItem || '')
  const [skuCode, setSkuCode] = useState(props.selectedSku || '')
  const [issuedPrice, setIssuedPrice] = useState(
    props.itemDetails?.suppliers && props.itemDetails.suppliers[props.selectedSku]
      ? props.itemDetails.suppliers[props.selectedSku].bookValue
      : '',
  )
  const [selectedSupplier, setSelectedSupplier] = useState(
    props.itemDetails?.suppliers && props.itemDetails.suppliers[props.selectedSku]
      ? props.itemDetails.suppliers[props.selectedSku].supplier
      : '',
  )
  const [selectedSizingType, setSelectedSizingType] = useState(props.itemDetails?.label || '')
  const [sizeOptions, setSizeOptions] = useState<string[]>(
    props.itemDetails?.suppliers && props.itemDetails.suppliers[props.selectedSku]
      ? props.itemDetails.suppliers[props.selectedSku].supplierSizeOptions
      : [],
  )
  const [itemSizes, setItemSizes] = useState<ItemSize[]>(props.itemSizes || [])

  const uniqueInitialItemDescriptions = props.allItems ? [...new Set(props.allItems?.map((item) => item.label))] : []
  const [itemDescriptionOptions, setItemDescriptionOptions] = useState<string[]>(uniqueInitialItemDescriptions)
  const [newItemDescription, setNewItemDescription] = useState('')
  const [supplierOptions, setSupplierOptions] = useState<PpeSupplierKeyValuePairs>({})

  const [addingCategory, setAddingCategory] = useState('')
  const [requiredInfoMissingModalOpen, setRequiredInfoMissingModalOpen] = useState(false)

  const { additionMode, selectedAssociation, selectedItem, selectedSku } = props

  useEffect(() => {
    const suppliers = ppeService.getSuppliers(selectedAssociation, props.associationRepo)
    setSupplierOptions(suppliers)
  }, [])

  useEffect(() => {
    const options = getSizes()
    setSizeOptions(options)
  }, [itemSizes, selectedSizingType, selectedItemDescription])

  const getSizes = () => {
    if (additionMode) {
      if (newItemDescription) {
        return itemSizes.find((item) => item.name === selectedSizingType)?.sizes || []
      }
      return itemSizes.find((item) => item.name === selectedItemDescription)?.sizes || []
    }
    return itemSizes.find((item) => item.name === selectedSizingType && item.sku === selectedSku)?.sizes || []
  }

  const getSizingType = () => {
    if (additionMode) {
      if (newItemDescription) {
        return selectedSizingType
      }
    }
    return selectedItemDescription
  }

  const handleDescriptionSectionChange = (field: string, value: string) => {
    switch (field) {
      case 'itemDescription':
        setSelectedItemDescription((previousValue) => {
          if (previousValue === newItemDescription) {
            setNewItemDescription('')
          }
          return value
        })
        break
      case 'skuCode':
        setSkuCode(value.toUpperCase())
        break
      case 'issuedPrice':
        setIssuedPrice(value)
        break
      case 'supplier':
        setSelectedSupplier(value)
        break
      default:
        break
    }
  }

  // const addItemDescriptionOption = (option: string) => {
  //   setItemDescriptionOptions([...itemDescriptionOptions, option])
  //   setSelectedItemDescription(option)
  // }

  const addSupplierOption = (option: string) => {
    const supplierNameTrimmed = ensureSingleSpaceInsideString(option.trim())
    const supplierKey = camelCase(supplierNameTrimmed)

    if (supplierOptions[supplierKey]) {
      return
    }

    const updatedSupplierOptions = { ...supplierOptions }
    updatedSupplierOptions[supplierKey] = supplierNameTrimmed
    setSupplierOptions(updatedSupplierOptions)
    props.updateUserProfileState(updatedSupplierOptions)
  }

  const resetCategoryItemAddition = () => {
    setAddingCategory('')
  }

  const handleCategoryItemAddition = (name: string) => {
    const itemName = name.toUpperCase()
    if (addingCategory === 'item description') {
      setNewItemDescription(itemName)
      setSelectedItemDescription(itemName)
      // addItemDescriptionOption(itemName);
    }
    if (addingCategory === 'supplier') {
      addSupplierOption(itemName)
      setSelectedSupplier(itemName)
    }
    if (addingCategory === 'size') {
      const updatedUniqueSizes = [...new Set([...sizeOptions, itemName])]
      if (newItemDescription) {
        setItemDescriptionOptions((itemDescriptionOptions) => [...itemDescriptionOptions, newItemDescription])
        setSelectedSizingType(newItemDescription)
        setItemSizes((itemSizes: ItemSize[]) => [
          ...itemSizes,
          { name: newItemDescription, sizes: updatedUniqueSizes, sku: selectedSku },
        ])
      } else {
        setItemSizes((itemSizes: ItemSize[]) => [
          ...itemSizes,
          { name: selectedSizingType, sizes: updatedUniqueSizes, sku: selectedSku },
        ])
      }
      setSizeOptions(updatedUniqueSizes)
    }
    if (addingCategory === 'sizing type') {
      setSelectedSizingType(itemName)
    }
    setAddingCategory('')
  }

  const handleSizesChange = (sizeValues: string[]) => {
    let itemSizesArray: ItemSize[] = [...itemSizes]
    const itemIndex = itemSizesArray.findIndex((item) => item.name === selectedSizingType)
    if (itemIndex > -1) {
      itemSizesArray[itemIndex] = { name: selectedSizingType, sizes: sizeValues, sku: selectedSku }
    } else {
      itemSizesArray = [...itemSizesArray, { name: selectedSizingType, sizes: sizeValues, sku: selectedSku }]
    }
    setItemSizes(itemSizesArray)
  }

  const anyChangesMade = () => {
    if (
      !props.itemDetails?.skus?.includes(skuCode) ||
      issuedPrice !== props.itemDetails?.bookValue ||
      selectedSupplier !== props.itemDetails?.supplier ||
      selectedItemDescription !== props.itemDetails?.label ||
      !isEqual(sizeOptions, props.itemDetails?.selectorItems)
    ) {
      return true
    }
    return false
  }

  const haveRequiredInfoToSave = () => {
    if (!skuCode || !issuedPrice || !selectedSupplier || !selectedItemDescription || sizeOptions.length === 0) {
      return false
    }
    return true
  }

  const saveChanges = () => {
    if (!haveRequiredInfoToSave()) {
      setRequiredInfoMissingModalOpen(true)
      return
    }

    const item: Partial<PpeTypeConfig & { sku: string }> = {
      bookValue: issuedPrice,
      label: selectedItemDescription.split(' - ')[0], // effective resulting in uppercased PPE type label
      compType: 'selector',
      selectorItems: sizeOptions,
      supplier: selectedSupplier,
      isPpe: true,
    }

    if (props.itemDetails?.key) {
      // editing an existing item type
      item.key = props.itemDetails.key
    } else {
      const itemDescriptionLowerCased = selectedItemDescription.toLowerCase()
      const keyName = camelCase(itemDescriptionLowerCased)
      item.key = keyName
    }
    item.sku = skuCode
    props.onSaveChangesClick(item as PpeTypeUpsert & { sku: string })
  }

  let title = selectedItem?.toUpperCase() || '???'
  if (additionMode) {
    title = 'ADD PPE STOCK KEEPING UNIT (SKU)'
  }

  const changesMade = anyChangesMade()
  const selectedItemName = getItemNameWithoutSkuPart(selectedItemDescription)
  let sizingSection = null
  if (selectedItemDescription) {
    const sizingOptionsToChooseFrom =
      additionMode && !newItemDescription ? [selectedItemDescription] : itemDescriptionOptions
    const sizingTypeValue = getSizingType()
    sizingSection = (
      <Sizing
        currentSizingTypes={sizingOptionsToChooseFrom}
        sizeOptions={sizeOptions}
        selectedSizingType={sizingTypeValue}
        additionMode={additionMode}
        onSizingTypeChange={(value: string) => setSelectedSizingType(value)}
        onSizesChange={handleSizesChange}
        // onSizeAdd={() => setAddingCategory('size')}
        key={`sizingSection_${selectedSizingType}_${selectedItemDescription}_${JSON.stringify(sizeOptions)}`}
      />
    )
  }

  return (
    <div style={{ ...styles.section, ...props.style }}>
      <h1 style={styles.sectionHeader}>{title}</h1>
      <div style={styles.sectionInput}>
        <Description
          issuedPrice={issuedPrice}
          skuCode={skuCode}
          selectedItemDescription={selectedItemName}
          selectedSupplier={selectedSupplier}
          currentItemDescriptions={itemDescriptionOptions.sort()}
          newItemDescription={newItemDescription}
          suppliers={Object.values(supplierOptions)}
          additionMode={additionMode}
          onInputChange={handleDescriptionSectionChange}
          onItemDescriptionAdd={() => setAddingCategory('item description')}
          onSupplierAdd={() => setAddingCategory('supplier')}
        />
      </div>
      <div style={styles.sectionInput}>{sizingSection}</div>
      <div style={{ ...styles.sectionInput, marginTop: 60 }}>
        <ButtonBlue disabled={!changesMade} onClick={saveChanges}>
          Save Changes
        </ButtonBlue>
      </div>
      <AlertModalOneButton
        open={requiredInfoMissingModalOpen}
        header="Required information missing"
        body={
          <div>
            Could not save changes.
            <br />
            <br />
            Please fill in all necessary information.
          </div>
        }
        buttonLabel="Ok"
        onClick={() => setRequiredInfoMissingModalOpen(false)}
      />
      <LabelCollector
        open={addingCategory.length > 0}
        warning={`${addingCategory} name/value`}
        placeholder={`${addingCategory} name/value`}
        buttonLabel="Save"
        iconName="setting"
        dismiss={resetCategoryItemAddition}
        submit={handleCategoryItemAddition}
      />
    </div>
  )
}

const styles = {
  section: {
    width: '100%',
    margin: '4% 0',
    paddingBottom: '20px',
  },
  sectionHeader: {
    marginTop: 0,
    fontSize: '1rem',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    color: ColorPalette.PRIMARY_TEXT,
    textAlign: 'left' as 'left',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid' as 'solid',
    borderBottomColor: ColorPalette.PRIMARY_BLUE,
    padding: '0em 0.5em 0.5em 0.5em',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sectionInput: {
    margin: '40px 1.5em 0px 1.5em',
  },
  inputContainer: {
    minWidth: 120,
    marginTop: 5,
    paddingTop: 15,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 40,
    border: `1px solid rgba(195,205,225,0.6)`,
    borderRadius: 12,
    display: 'flex',
    justifyContent: 'center',
  },
  inputContainerLabel: {
    zIndex: 2,
    marginTop: -22,
    alignSelf: 'center',
    fontSize: '0.65rem',
    color: ColorPalette.LIGHT_GREY,
    backgroundColor: ColorPalette.CARD_WHITE,
    paddingLeft: 10,
    paddingRight: 10,
  },
  button: {
    fontWeight: '550',
    fontSize: '0.9rem',
    backgroundColor: ColorPalette.CARD_WHITE,
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    paddingLeft: '2.5%',
    marginTop: 0,
    ':hover': {
      backgroundColor: 'rgba(242,242,250,1)',
    },
    ':active': {
      backgroundColor: 'rgba(222,222,230,1)',
    },
  },
}

export default PpeItemDetails
