export enum ScreenNamesEnum {
  BILLING = 'Billing',
  BULK_ACTIONS = 'Bulk Actions',
  BULK_DISCIPLINE_FORMS = 'Forms: Bulk Discipline',
  CLOSED_REQUESTS = 'Closed Requests',
  CANDIDATES = 'Candidates',
  COMING_SOON = 'Coming soon',
  DOCUMENT_VALIDITY = 'Document Validity',
  CONFIGURATOR = 'Configurator',
  DATA_FIELDS = 'Data Fields',
  DATA_FLOWS = 'Data Flows',
  DATA_LOGS = 'Data logs',
  DATA_QUERIES = 'Data Queries',
  DATA_MANAGER = 'Data Manager',
  DEVICE_LOGS = 'Device logs',
  DISCIPLINE = 'Discipline',
  DISCIPLINE_CONFIG = 'Discipline Config',
  DOCUMENTS = 'Documents',
  EMPLOYEES = 'Employees',
  EXPORT = 'Export',
  EXPORT_PROFILES = 'Export Profiles',
  EXPORT_DOC_VALIDITY_DATA = 'Export doc validity data',
  EXPORT_DOCS = 'Export Docs',
  EXPORT_EMPLOYEE_CARDS = 'Export Employee Cards',
  FACE_SHOTS = 'Faceshots',
  FORMS = 'Forms',
  GLOBAL_LOGS = 'Global Logs',
  GROUPS = 'Groups',
  GROUPMEMBERS = 'Group Members',
  IMPORT = 'Import',
  JSON_CONFIG_FILES = 'Config Files',
  LOGIN = 'Login',
  LOGS = 'Logs',
  OPEN_REQUESTS = 'Open Requests',
  PEOPLE = 'People',
  PERFORMANCE = 'Performance',
  PPE_CONFIG = 'PPE',
  PPE_INVENTORY = 'PPE Inventory',
  PROCESSES = 'Processes',
  PROFILES = 'Profiles',
  REQUESTS = 'Requests',
  REQUESTS_PPE = 'PPE Requests',
  REQUEST_HANDLER = 'Requests',
  REPORTING = 'Reporting',
  TERMINATE = 'Terminate',
  TERMINATION_REASONS = 'Termination',
  TRANSFER = 'Transfer',
  TRASH = 'Trash',
  USERS = 'Users',
  UNDER_PERFORMANCE = 'Under Performance',
}

export type SubMenuSchema = {
  label: string
  iconName?: string
  screenName: ScreenNamesEnum
  hasAccess: boolean
}
export type SubSubMenuSchema = Record<ScreenNamesEnum, SubMenuSchema[]>

export interface MenuSchema {
  label: string
  screenName: ScreenNamesEnum
  subMenus: SubMenuSchema[] | []
  subSubMenus?: SubSubMenuSchema
}

export type NavMenuAccess = {
  hasDataLogsAccess?: boolean
  hasDeviceLogsAccess?: boolean
  hasDocExportAccess?: boolean
  hasDocValidityAccess?: boolean
  hasDocumentLibraryAccess: boolean
  hasImportAccess?: boolean
  hasRequestHandlerAccess?: boolean
  hasUserManagementAccess?: boolean
  hasCandidatesAccess?: boolean
  hasEmployeesAccess?: boolean
  hasTrashAccess?: boolean
  hasBulkTerminationAccess?: boolean
  hasBulkDisciplineAccess?: boolean
  hasDataFlowConfigAccess?: boolean
  hasFieldConfigAccess?: boolean
  hasProcessConfigAccess?: boolean
  hasBillingAccess?: boolean
  hasJsonConfigFileAccess?: boolean
  hasGroupAccess?: boolean
  hasPpeStockAccess?: boolean
  hasPpeStockConfigAccess?: boolean
  hasPpeRequestAccess?: boolean
  hasAdvancedDataQueryAccess?: boolean
  hasExportAccess?: boolean
}

export type ProfileNavMenuAccess = {
  hasDataManagerAccess: boolean
  hasFaceshotAccess: boolean
  hasDocPortalAccess: boolean
  hasPerformanceAccess: boolean
  hasDisciplineAccess: boolean
  hasLogsAccess: boolean
}
