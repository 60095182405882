import { cloneDeep, intersection, isEmpty, omit, pick } from 'lodash'
import { isNumericString, removeUnderScores } from './stringUtils'
import { ProfilePk } from '../models'
import { AssociationSettingsRepository, ProfileRepository } from '../repositories'
import { PpeAction, PpeInstanceRecordSk, PpeSuppliers, PpeTypeConfig, PpeTypeUpsert } from '../types'
import { PpeActionRecordSk, PpeActionRecordSchema, PpeInstanceRecordSchema } from '../models/ppe'
import { PpeActions } from '../enums'

export const getUniqueTypeLabelsInclSku = (types: PpeTypeConfig[]) => {
  const typesWithSkus: string[] = []
  types.forEach((type) => {
    const label = removeUnderScores(type.label)
    type.skus?.forEach((sku) => {
      typesWithSkus.push(`${label} - ${sku}`)
    })
  })
  return typesWithSkus
}

export const sortSizeValues = (values: string[]) => {
  if (values && values.length > 0) {
    const firstValue = values[0]
    const clothingSizesMap: Record<string, number> = {
      XS: 1,
      S: 2,
      M: 3,
      L: 4,
      XL: 5,
      '1XL': 6,
      XXL: 7,
      '2XL': 8,
      XXXL: 9,
      '3XL': 10,
    }
    if (isNumericString(firstValue) || !Object.keys(clothingSizesMap).includes(firstValue)) {
      const numericValues = values.map((value) => parseInt(value))
      numericValues.sort((a, b) => a - b)
      const stringValues = numericValues.map((value) => value.toString())
      return stringValues
    }
    // at this point assuming clothing sizes have been entered and needs sorting
    const sortClothingSizes = (a: string, b: string) => {
      const aOrderValue = clothingSizesMap[a]
      const bOrderValue = clothingSizesMap[b]
      if (aOrderValue < bOrderValue) {
        return -1
      }
      if (aOrderValue > bOrderValue) {
        return 1
      }
      return 0
    }
    const sortedSizes = values.sort(sortClothingSizes)
    return sortedSizes
  }
  return values
}

export const getPpeTypesFromFieldConfig = (fieldConfig: Record<string, any>, keysToExtract: string[]) => {
  const ppeTypes: PpeTypeConfig[] = []
  Object.keys(fieldConfig).forEach((key) => {
    const field = fieldConfig[key]
    if (field.isPpe) {
      const type: PpeTypeConfig = pick(field, keysToExtract) as PpeTypeConfig
      ppeTypes.push(type)
    }
  })
  return ppeTypes
}

export const getPpeTypeLabelFromKey = (types: Partial<PpeTypeConfig>[], key: string) => {
  const type = types.find((type) => type.key === key)
  return type?.label || ''
}

export const getInstanceRelatedtoAction = (
  actionRecord: PpeActionRecordSchema,
  allInstances: Record<PpeActionRecordSk, PpeInstanceRecordSchema>,
) => {
  if (!isEmpty(actionRecord) && !isEmpty(allInstances) && actionRecord.rawData.ppeInstanceId) {
    const instance = allInstances[actionRecord.rawData.ppeInstanceId]
    return instance
  }
  return {}
}

export const getLatestAction = (allRelatedActions: Record<PpeActionRecordSk, PpeActionRecordSchema>) => {
  const actions = Object.values(allRelatedActions) as PpeActionRecordSchema[]
  //@ts-ignore
  actions.sort((a, b) => b.rawData.dateActioned - a.rawData.dateActioned)
  const latestAction = actions[0]
  return latestAction
}

export const getActionsRelatedToRequests = (
  requestSks: string[],
  allActions: Record<PpeActionRecordSk, PpeActionRecordSchema>,
  status?: PpeAction,
) => {
  const actions = Object.values(allActions)
  const actionsMatchingRequests = actions.filter(
    (action) => action.rawData.requestId && requestSks.includes(action.rawData.requestId),
  )
  if (status) {
    const relatedActionsSks = actionsMatchingRequests.map((action) => action.sk) as PpeActionRecordSk[]
    const actionsRelatedToRequests = pick(allActions, relatedActionsSks)
    return actionsRelatedToRequests
  }
  const relatedActionsSks = actionsMatchingRequests.map((action) => action.sk) as PpeActionRecordSk[]
  const actionsRelatedToRequests = pick(allActions, relatedActionsSks)
  return actionsRelatedToRequests
}

export const getInstancesRelatedToRequests = (
  requestSks: string[],
  allInstances: Record<PpeActionRecordSk, PpeInstanceRecordSchema>,
) => {
  const instances = Object.values(allInstances)
  const instancesMatchingRequests = instances.filter((instance) => {
    return intersection(requestSks, instance.requestHistory).length > 0
  })
  const relatedInstancesSks = instancesMatchingRequests.map((instance) => instance.sk) as PpeInstanceRecordSk[]
  const instancesRelatedToRequests = pick(allInstances, relatedInstancesSks)
  return instancesRelatedToRequests
}

export const getActionRelatedToInstance = (
  instanceSk: PpeInstanceRecordSk,
  allActions: Record<PpeActionRecordSk, PpeActionRecordSchema>,
  status?: PpeAction,
) => {
  const actions = Object.values(allActions)
  const actionsMatchingInstance = actions.filter((action) => action.rawData.ppeInstanceId === instanceSk)
  if (status) {
    const actionsMatchingStatus = actionsMatchingInstance.filter((action) => action.rawData.ppeAction === status)
    return actionsMatchingStatus[0]
  }
  return actionsMatchingInstance[0]
}

export const createActionRecord = (obj: any) => {
  return { rawData: obj }
}

export const getLatestActionByInstanceId = (actions: PpeActionRecordSchema[]) => {
  const grouped = groupActionsByInstanceId(actions)
  const latestActionByInstanceId = Object.keys(grouped).reduce((acc, instanceId) => {
    const instanceActions = grouped[instanceId]
    const latestAction = getLatestAction(instanceActions)
    acc[instanceId] = latestAction
    return acc
  }, {} as any)
  return latestActionByInstanceId
}

export const groupActionsByInstanceId = (actionsWithInstanceId: PpeActionRecordSchema[]) => {
  const actions = actionsWithInstanceId
  const grouped = actions.reduce((acc, item) => {
    const instanceId = item.rawData.ppeInstanceId
    // @ts-expect-error
    if (!acc[instanceId]) {
      // @ts-expect-error
      acc[instanceId] = []
    }
    // @ts-expect-error
    acc[instanceId].push(item)
    return acc
  }, {} as any)
  return grouped
}

export const getInstancesByPpeKeys = (instances: PpeInstanceRecordSchema[], ppeKeys: string[]) => {
  return instances.filter((instance) => ppeKeys.includes(instance.ppeKey)) as PpeInstanceRecordSchema[]
}

export const getInstanceBySk = (allInstances: PpeInstanceRecordSchema[], instanceSk: string) => {
  return allInstances.find((instance) => instance.sk === instanceSk)
}

export const getPpeInPossession = (
  profileRepo: ProfileRepository,
  profilePk: ProfilePk,
  ppeKeysToCheck?: string[],
  instances?: PpeInstanceRecordSchema[],
): Record<PpeInstanceRecordSk, PpeActionRecordSchema>[] => {
  let ppeInPossessionBySk: Record<PpeInstanceRecordSk, PpeActionRecordSchema>[] = []
  const profile = profileRepo.getProfileEntity(profilePk)
  const ppe: any = profile?.getPpe()
  const actions = (ppe?.history as PpeActionRecordSchema[]) || ([] as PpeActionRecordSchema[])

  if (actions.length === 0) {
    return [] // or return number rather...?
  }

  let instanceIdsToConsider = [] as PpeInstanceRecordSk[]
  if (ppeKeysToCheck && ppeKeysToCheck.length > 0 && instances) {
    const instancesToConsider = getInstancesByPpeKeys(instances, ppeKeysToCheck)
    instanceIdsToConsider = instancesToConsider.map((instance) => instance.sk) as PpeInstanceRecordSk[]
  }

  const actionsIssued = actions.filter((action) => action.rawData.ppeAction === PpeActions.ISSUE_PPE_ITEM)
  const actionsReturn = actions.filter((action) => action.rawData.ppeAction === PpeActions.INITIATE_RETURN_INSTANCE)
  const latestIssueActionByInstanceId = getLatestActionByInstanceId(actionsIssued)
  const latestReturnActionByInstanceId = getLatestActionByInstanceId(actionsReturn)

  Object.keys(latestIssueActionByInstanceId).forEach((instanceId) => {
    const latestIssueAction = latestIssueActionByInstanceId[instanceId]
    const latestReturnAction = latestReturnActionByInstanceId[instanceId]

    if (latestIssueAction && !latestReturnAction) {
      if (instanceIdsToConsider.length === 0) {
        ppeInPossessionBySk.push({ [instanceId]: latestIssueAction })
        return
      }

      if (instanceIdsToConsider.includes(instanceId)) {
        ppeInPossessionBySk.push({ [instanceId]: latestIssueAction })
        return
      }
    }
    if (latestIssueAction && latestReturnAction) {
      const issueDate = latestIssueAction.rawData.dateActioned
      const returnDate = latestReturnAction.rawData.dateActioned
      if (issueDate > returnDate) {
        if (instanceIdsToConsider.length === 0) {
          ppeInPossessionBySk.push({ [instanceId]: latestIssueAction })
          return
        }

        if (instanceIdsToConsider.includes(instanceId)) {
          ppeInPossessionBySk.push({ [instanceId]: latestIssueAction })
          return
        }
      }
    }
  })
  return ppeInPossessionBySk
}

export const getAllSupplierOptions = (suppliers: PpeSuppliers) => {
  const allSupplierSizingOptions = Object.values(suppliers).map((supplier) => supplier.supplierSizeOptions)
  const selectorItems = [...new Set(allSupplierSizingOptions.flat())]
  return selectorItems
}

export const preparePpeTypeUpsertPayload = (
  item: PpeTypeUpsert & { sku: string },
  selectedAssociation: string,
  associationRepo: AssociationSettingsRepository,
) => {
  const fieldConfig = associationRepo.getFieldConfig(selectedAssociation)
  const existingType = fieldConfig[item.key] as PpeTypeConfig | undefined

  if (existingType && existingType.isPpe) {
    const skuExistsAlready = existingType.skus?.includes(item.sku)
    item.suppliers = existingType.suppliers ? cloneDeep(existingType.suppliers) : {}
    if (skuExistsAlready) {
      item.skus = existingType.skus
      item.suppliers[item.sku] = {
        bookValue: item.bookValue as string,
        bookValueCurrency: 'ZAR',
        supplier: item.supplier as string,
        supplierSizeOptions: item.selectorItems as string[],
      }
    } else {
      item.skus = [...new Set([...existingType.skus, item.sku])]
    }
  } else {
    item.skus = [item.sku]
    item.suppliers = {}
  }

  item.suppliers[item.sku] = {
    bookValue: item.bookValue as string,
    bookValueCurrency: 'ZAR',
    supplier: item.supplier as string,
    supplierSizeOptions: item.selectorItems as string[],
  }

  item.selectorItems = getAllSupplierOptions(item.suppliers)

  const payload = omit(item, ['sku']) // 'sku' was added to aid payload-compiling logic, but it's not a field in the PpeTypeConfig model
  return payload
}

export const getFieldConfigExclPpeItems = (
  associationRepo: AssociationSettingsRepository,
  selectedAssociation: string,
) => {
  const fieldConfig = associationRepo.getFieldConfig(selectedAssociation)
  const fieldConfigExclPpeItems = { ...fieldConfig }
  Object.values(fieldConfigExclPpeItems).forEach((field: any) => {
    if (field.isPpe) {
      delete fieldConfigExclPpeItems[field.key]
    }
  })
  return fieldConfigExclPpeItems
}
