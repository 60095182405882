import { ColorPalette } from '../../config'

export const ConfigSectionHeader = (props: { children: string }) => (
  <h1 style={styles.sectionHeader}>{props.children}</h1>
)

const styles = {
  sectionHeader: {
    marginTop: 0,
    fontSize: '1rem',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    color: ColorPalette.PRIMARY_TEXT,
    textAlign: 'left' as 'left',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid' as 'solid',
    borderBottomColor: ColorPalette.PRIMARY_BLUE,
    padding: '0em 0.5em 0.5em 0.5em',
    display: 'flex',
    alignItems: 'center',
  },
}
