import React, { Component } from 'react'
import Radium from 'radium'
import Grid, { GridSize } from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import CodeIcon from '@material-ui/icons/Code'
import Icon from '@mdi/react'
import { mdiFilterRemove, mdiFilter, mdiFilterSettings } from '@mdi/js'
import equal from 'deep-equal'
import get from 'lodash/get'

import { ColorPalette } from '../../../config/colors'
import FieldFilter from '../../Modals/FieldFilter'
import { DataTableFilterState } from './DataTable'
import ModalPortal from '../../Modals/ModalPortal'
import { ButtonWithFilter } from '../../BaseComponents/Buttons/ButtonWithFilter'

let removingFilters = false

const sortDirectionMap: Record<string, string> = {
  descending: 'desc',
  ascending: 'asc',
}

export interface IColumnConfig {
  id: string
  label: string
  sizeFactor?: number
}

function initialiseSortIcon(
  columnIndexToSort: string,
  currentColumnIndex: string,
  sortDirection: string,
  sortEnabled?: boolean,
): JSX.Element {
  if (sortEnabled === false) {
    return <div />
  }
  let icon = <CodeIcon style={{ ...styles.cellIcon, width: '1.3rem', height: '1.3rem' }} />
  if (columnIndexToSort !== currentColumnIndex) {
    return icon
  }
  if (sortDirectionMap[sortDirection] === 'asc') {
    return <KeyboardArrowUpIcon style={styles.cellIcon} />
  }
  return <KeyboardArrowDownIcon style={styles.cellIcon} />
}

type FilterState = { selectedField: string; filterType: string; filterValue: string; key?: string }[]

interface TableHeaderProps {
  selectionEnabled?: boolean
  tableWidth: GridSize
  allSelected: boolean
  columnConfig: IColumnConfig[]
  combinedSizeFactor: number
  sortDirection: string
  columnIndexToSort: string
  filterModeEnabled?: boolean
  allFilterState?: DataTableFilterState
  customFilterOpen?: boolean
  customComponentConfig: any
  customFilterConfig?: { key: string; label: string }[]
  customFilterState?: FilterState
  sortEnabled?: boolean
  disableHoverEffect?: boolean
  filterData?: (newAllFilterState: DataTableFilterState) => void
  removeFilters?: (rowItemId: string) => void
  onSelectAll: (e: React.ChangeEvent<HTMLInputElement>, checked?: boolean) => void
  onSortRequest: (req: string) => void
  toggleCustomFilter?: (shouldClose: boolean) => void
  updateCustomFilterState: (filterState: FilterState) => void
}

interface TableHeaderState {
  filterMenuVisible: number
}

class TableHeader extends Component<TableHeaderProps, TableHeaderState> {
  state = {
    filterMenuVisible: -1,
  }

  filterData(headerKey: string, newFilters: Record<string, boolean>) {
    if (this.props.filterData === undefined || this.props.allFilterState === undefined) {
      return
    }
    let { allFilterState } = this.props
    allFilterState[headerKey] = newFilters
    this.props.filterData(allFilterState)
  }

  extractFilterState(headerKey: string) {
    if (this.props.allFilterState === undefined) {
      return {}
    }
    return this.props.allFilterState[headerKey]
  }

  filtersOn(headerKey: string) {
    const filterState = this.extractFilterState(headerKey)
    if (!filterState) {
      return false
    }
    let filtersOn = false
    Object.keys(filterState).forEach((filterKey) => (filterState[filterKey] ? (filtersOn = true) : null))
    return filtersOn
  }

  removeFilters(rowItemId: string) {
    if (this.props.removeFilters === undefined) {
      return
    }
    removingFilters = true
    this.props.removeFilters(rowItemId)
    setTimeout(() => (removingFilters = false), 150) // Necessary given that the filter icon button is nested within another button. i.e. Need to ignore the background button event.
  }

  onSortRequest(indexString: string) {
    if (!removingFilters) {
      this.props.onSortRequest(indexString)
    }
  }

  toggleCustomFilter(shouldClose: boolean) {
    if (!this.props.toggleCustomFilter) {
      return
    }
    this.props.toggleCustomFilter(shouldClose)
  }

  openFilterMenu(e: React.MouseEvent, index: number) {
    e.stopPropagation()
    // @ts-ignore
    this.setState({ filterMenuVisible: index })
  }

  renderHeaderRow(configItem: IColumnConfig, i: number, headerWidth: number) {
    const buttonWidth =
      (headerWidth * (configItem.sizeFactor ? configItem.sizeFactor : 1)) / this.props.combinedSizeFactor
    let filterButton = <div />
    if (this.props.filterModeEnabled) {
      if (this.filtersOn(configItem.id)) {
        filterButton = (
          <div
            key={`filterButton_${configItem.id}`}
            style={{
              ...styles.filterIconButton,
              color: ColorPalette.PRIMARY_BLUE_GRADIENT_LIGHT,
            }}
            onClick={(e) => this.openFilterMenu(e, i)}
            title="Click to view applied filter">
            <Icon path={mdiFilterSettings} size={0.65} />
          </div>
        )
      } else {
        filterButton = (
          <div
            key={`filterButton_${configItem.id}`}
            style={{ ...styles.filterIconButton }}
            onClick={(e) => this.openFilterMenu(e, i)}
            title="Click to view filter options">
            <Icon path={mdiFilter} size={0.65} />
          </div>
        )
      }
    }
    const lengthRatioLimit = 9
    const lengthRatio = buttonWidth / configItem.label.length
    const label =
      lengthRatio < lengthRatioLimit
        ? `${configItem.label.slice(0, Math.round(buttonWidth / lengthRatioLimit))}...`
        : configItem.label

    const sortIcon = initialiseSortIcon(
      this.props.columnIndexToSort,
      i.toString(),
      this.props.sortDirection,
      this.props.sortEnabled,
    )

    return (
      <ButtonWithFilter
        key={`${configItem.id}_${i}`}
        style={{ ...styles.cellStyle, width: buttonWidth }}
        label={label}
        onClick={() => this.onSortRequest(i.toString())}
        buttonIndex={i}
        buttonWidth={buttonWidth}
        filterData={(newFilters) => this.filterData(configItem.id, newFilters)}
        filterState={this.extractFilterState(configItem.id)}
        filterMenuVisible={this.state.filterMenuVisible === i}
        resetFilters={() => this.removeFilters(configItem.id)}
        iconBefore={filterButton}
        iconAfter={sortIcon}
        closeFilterMenu={() => this.setState({ filterMenuVisible: -1 })}
        disabled={this.props.disableHoverEffect}
      />
    )
  }

  getHeaderRow(headerWidth: number) {
    return [...this.props.columnConfig].map((configItem: IColumnConfig, i: number) =>
      this.renderHeaderRow(configItem, i, headerWidth),
    )
  }

  render() {
    const checkboxWidth = this.props.selectionEnabled ? 45 : 0
    const leftContainerWidth = get(this.props.customComponentConfig, 'leftComponentWidth', 0)
    const rightContainerWidth = get(this.props.customComponentConfig, 'rightComponentWidth', 0)
    const leftHeaderComponent = get(this.props.customComponentConfig, 'leftHeaderComponent', null)
    const rightHeaderComponent = get(this.props.customComponentConfig, 'rightHeaderComponent', null)
    const customFilterWidth = this.props.customFilterConfig && this.props.customFilterConfig.length ? 40 : 0
    const headerWidth =
      (window.innerWidth * (this.props.tableWidth as number)) / 12 -
      customFilterWidth -
      checkboxWidth -
      leftContainerWidth -
      rightContainerWidth

    let checkbox = null
    if (this.props.selectionEnabled) {
      checkbox = (
        <Checkbox
          style={{
            ...styles.checkbox,
            color: this.props.allSelected ? ColorPalette.PRIMARY_BLUE : ColorPalette.TERTIARY_TEXT,
            marginLeft: 0,
          }}
          checked={this.props.allSelected}
          onChange={this.props.onSelectAll}
        />
      )
    }

    let customFilterButton = null
    if (this.props.customFilterConfig && this.props.customFilterConfig.length) {
      const icon = (
        <div style={styles.customFilterIconContainer}>
          <Icon
            style={styles.customFilterIcon}
            path={this.props.customFilterState?.length ? mdiFilterRemove : mdiFilter}
            color={this.props.customFilterState?.length ? ColorPalette.PRIMARY_BLUE : ColorPalette.CARD_WHITE}
          />
        </div>
      )
      customFilterButton = (
        <CustomFilterButton
          filterConfig={this.props.customFilterConfig}
          filterOpen={this.props.customFilterOpen}
          filterState={this.props.customFilterState}
          toggleFilter={(saveAndClose: boolean) => this.toggleCustomFilter(saveAndClose)}
          updateFilterState={this.props.updateCustomFilterState}
          icon={icon}
          style={{ ...styles.customFilterButton, width: customFilterWidth }}
          // key={`${this.props.customFilterConfig ? Object.keys(this.props.customFilterConfig).join("-") : "-"}`}
        />
      )
    }

    const headerRow = this.getHeaderRow(headerWidth)

    return (
      <Grid style={{ ...styles.rowContainer }} container direction="row" justify="flex-start" alignItems="center">
        {checkbox && (
          <div style={{ ...styles.addonIconContainer, width: checkboxWidth, flexShrink: 0 }}>{checkbox}</div>
        )}
        {customFilterButton}
        <div style={{ ...styles.addonIconContainer, width: leftContainerWidth }}>{leftHeaderComponent}</div>
        {headerRow}
        {rightHeaderComponent}
      </Grid>
    )
  }
}

const styles = {
  rowContainer: {
    height: 45,
    backgroundColor: ColorPalette.DARK_GREY, // "Was SECONDARY TEXT"
    width: '100%',
    flexWrap: 'unset' as 'unset',
    overflow: 'hidden',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  addonIconContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkbox: {
    padding: 0,
    borderRadius: 0,
    marginLeft: 5,
    border: `1px solid ${ColorPalette.SECONDARY_TEXT}`,
    backgroundColor: ColorPalette.CARD_WHITE,
    width: 16,
    height: 16,
  },
  cellStyle: {
    height: 40,
    padding: '2px 20px',
    backgroundColor: ColorPalette.DARK_GREY,
    fontSize: '0.8rem',
    textOverflow: 'ellipsis' as 'ellipsis',
    flexShrink: 1,
    whiteSpace: 'nowrap' as 'nowrap',
    overflow: 'hidden',
  },
  cellIcon: {
    width: '1.5rem',
    height: '1.5rem',
    color: ColorPalette.PRIMARY_LIGHT_TEXT,
    marginLeft: 20,
  },
  filterIconButton: {
    marginRight: 10,
    outline: 0,
    border: 'none',
    backgroundColor: 'transparent',
    color: ColorPalette.OFF_WHITE_LIGHT,
    ':hover': {
      opacity: 0.45,
      color: ColorPalette.PRIMARY_BLUE,
    },
    ':active': {
      opacity: 0.1,
      color: ColorPalette.PRIMARY_BLUE,
    },
  },
  customFilterButton: {
    height: '100%',
    outline: 0,
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    zIndex: 300,
  },
  buttonIcon: {
    width: 18,
    height: 18,
    color: ColorPalette.PRIMARY_BLUE,
    marginRight: 15,
  },
  customFilterIconContainer: {
    marginTop: 4,
    ':hover': {
      opacity: 0.45,
    },
  },
  customFilterIcon: {
    width: 20,
    height: 20,
  },
  overlayContainer: {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    backgroundColor: ColorPalette.MODAL_BACKGROUND_OVERLAY,
    width: window.innerWidth,
    height: window.innerHeight,
    zIndex: 200,
    overflow: 'hidden',
  },
}

export default Radium(TableHeader)

type CustomFilterButtonProps = {
  filterConfig: { key: string; label: string }[]
  filterOpen?: boolean
  filterState?: { selectedField: string; filterType: string; filterValue: string; key?: string }[]
  icon: React.ReactElement
  style: React.CSSProperties
  toggleFilter: (show: boolean) => void
  updateFilterState: (filterState: FilterState) => void
}

const CustomFilterButton = Radium(
  ({
    filterConfig,
    filterOpen,
    filterState,
    toggleFilter,
    updateFilterState,
    icon,
    style,
  }: CustomFilterButtonProps) => {
    let filterArea = null
    if (filterOpen && filterState) {
      filterArea = (
        <>
          <FieldFilter
            filterConfig={filterConfig}
            filterState={filterState}
            updateFilterState={updateFilterState}
            toggleFilter={() => toggleFilter(true)}
          />
          <ModalPortal>
            <div style={styles.overlayContainer}></div>
          </ModalPortal>
        </>
      )
    }

    return (
      <button onClick={() => toggleFilter(false)} style={style}>
        {filterArea}
        {icon}
      </button>
    )
  },
)
